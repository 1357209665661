import { Button, H6, Subtitle1, TextInput, Touchable, useKeyboard } from "@siruplab/capsule"
import _ from "lodash"
import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { FlatList, ListRenderItem, TextInput as NativeInput, View } from "react-native"
import Modal from "react-native-modal"
import { ActivityIndicator } from "react-native-paper"

import { ns } from "../../screens/CreateAssetScreen/i18n/en"
import { BACKDROP_OPACITY, maxWidth } from "../../ThemeApp"
import { useStyles } from "../../utils/useStyles"
import { Item } from "../MultiSelect/MultiSelect"
import { Check2SVG } from "../svg"
import ListSeparator from "./ListSeparator"

interface Props {
  items?: Item[]
  loading?: boolean
  selectedItems?: string[]
  setSelectedItems: Dispatch<SetStateAction<string[]>> | ((args: string[]) => void)
  visible: boolean
  onAdd?: (name: string, postAddFn?: (id: string) => void) => void
  hideModal: () => void
  onUpdate?: (args: string[]) => void
  firstButtonText: string
  secondButtonText: string
  title?: string
}

const keyExtractor = (elem: Item) => elem.value

const ListModal = ({
  items,
  title,
  visible,
  loading,
  onAdd,
  hideModal,
  selectedItems = [],
  firstButtonText,
  setSelectedItems,
  secondButtonText,
}: Props) => {
  const [localSelectedItems, setLocalSelectedItems] = useState(selectedItems)
  const { t } = useTranslation(ns)

  const toggleSelection = (itemValue: string) => {
    setLocalSelectedItems(prevSelectedItems =>
      !prevSelectedItems
        ? [itemValue]
        : prevSelectedItems?.includes(itemValue)
        ? prevSelectedItems?.filter(item => item !== itemValue)
        : [...prevSelectedItems, itemValue],
    )
  }

  useEffect(() => {
    if (!_.isEmpty(selectedItems)) {
      setLocalSelectedItems(selectedItems)
    }
  }, [selectedItems, visible])

  const updateSelectedItems = useCallback(() => {
    setSelectedItems(localSelectedItems)
    hideModal()
  }, [hideModal, localSelectedItems, setSelectedItems])

  const s = useStyles(
    ({
      colors: {
        primary,
        white,
        black,
        surface: { textInput },
      },
      dimensions: { spacing, margin },
    }) => ({
      title: { marginBottom: margin },
      selectButton: { color: primary },
      modal: { margin, width: maxWidth, alignSelf: "center", padding: spacing },
      listItem: {
        padding: spacing,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      },
      underline: {
        color: black.underlineColor,
      },
      textInput: {
        backgroundColor: textInput,
        borderWidth: 0,
        borderBottomWidth: 0,
        marginVertical: spacing,
        borderColor: black.mediumEmphasis,
        borderRadius: 0,
        width: "100%",
        height: 0,
        minWidth: 0,
        maxHeight: 200,
      },
      cancelButton: { color: black.mediumEmphasis, marginHorizontal: spacing + spacing / 4 },
      container: {
        padding: spacing,
        borderRadius: margin / 2,
        backgroundColor: white.highEmphasis,
      },
      buttonContainer: {
        flexDirection: "row",
        alignSelf: "flex-end",
        marginTop: margin * 1.5,
        justifyContent: "flex-end",
      },
      label: { maxWidth: "90%" },
    }),
    [],
  )

  const renderItem: ListRenderItem<Item> = useCallback(
    ({ item }) => (
      <Touchable onPress={() => toggleSelection(item.value)} style={s.listItem}>
        <>
          <Subtitle1 numberOfLines={2} style={s.label} color={s.cancelButton.color}>
            {_.upperFirst(item.label)}
          </Subtitle1>
          {localSelectedItems?.includes(item.value) ? <Check2SVG /> : null}
        </>
      </Touchable>
    ),
    [localSelectedItems, s],
  )

  const keyboardShown = useKeyboard()

  const [value, setValue] = useState<string>()
  const inputRef = useRef<NativeInput>(null)

  const onHide = () => (!keyboardShown ? hideModal() : inputRef.current?.blur())

  return (
    <Modal
      useNativeDriver
      style={s.modal}
      isVisible={visible}
      onDismiss={onHide}
      onBackdropPress={onHide}
      onBackButtonPress={onHide}
      hideModalContentWhileAnimating
      backdropOpacity={BACKDROP_OPACITY}
    >
      <View style={s.container}>
        {title ? <H6 style={s.title}>{title}</H6> : null}
        {onAdd ? (
          <TextInput
            forwardRef={inputRef}
            onChangeText={setValue}
            value={value}
            onSubmitEditing={() => {
              inputRef.current?.blur()
              if (value) {
                onAdd(value, toggleSelection)
              }
              setValue(undefined)
            }}
            style={s.textInput}
            returnKeyType="send"
            changeUnderline={false}
            underlineColor={s.underline.color}
            placeholder={t("newPortfolio")}
          />
        ) : null}
        <FlatList
          data={items}
          renderItem={renderItem}
          keyExtractor={keyExtractor}
          extraData={localSelectedItems}
          ItemSeparatorComponent={ListSeparator}
        />
        <View style={s.buttonContainer}>
          <Button onPress={hideModal} labelStyle={s.cancelButton} mode="text">
            {firstButtonText}
          </Button>
          <Button onPress={updateSelectedItems} labelStyle={s.selectButton} mode="text">
            {loading !== undefined && loading ? <ActivityIndicator /> : secondButtonText}
          </Button>
        </View>
      </View>
    </Modal>
  )
}

export default ListModal
