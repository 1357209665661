import { rootRoutes } from "../../../features/Navigation/Constants"

export const ns = rootRoutes.MAIN_NAVIGATOR

export default {
  [ns]: {
    tabs: {
      assets: "Assets",
      portfolios: "Portfolios",
      account: "Account",
    },
  },
}
