import React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

const BillSVG = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <Path
      d="M19 6H5c-.6 0-1 .4-1 1v10c0 .6.4 1 1 1h14c.6 0 1-.4 1-1V7c0-.6-.4-1-1-1zm-3.9 10H8.9c-.4-1.4-1.5-2.5-2.9-2.9v-2.2c1.4-.4 2.5-1.5 2.9-2.9h6.3c.4 1.4 1.5 2.5 2.9 2.9v2.3c-1.5.3-2.6 1.4-3 2.8z"
      fill="#32352F"
      fillOpacity={0.72}
    />
    <Path d="M12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" fill="#32352F" fillOpacity={0.72} />
  </Svg>
)

export default BillSVG
