import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

const PortfolioSVG = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <Path
      d="M19 17h-5c0 .6-.4 1-1 1h-2c-.6 0-1-.4-1-1H5v2c0 .6.4 1 1 1h12c.6 0 1-.4 1-1v-2zM19 8h-3V5c0-.6-.4-1-1-1H9c-.6 0-1 .4-1 1v3H5c-.6 0-1 .4-1 1v6c0 .6.4 1 1 1h5v-1c0-.6.4-1 1-1h2c.6 0 1 .4 1 1v1h5c.6 0 1-.4 1-1V9c0-.6-.4-1-1-1zm-5 0h-4V6h4v2z"
      fill={props.color}
    />
  </Svg>
)

export default PortfolioSVG
