import { RouteProp } from "@react-navigation/core"
import { NavigationProp } from "@react-navigation/core/lib/typescript/src/types"
import {
  ActionBar,
  ButtonType,
  H6,
  IUserContext,
  MaterialCommunityIcons,
  MaterialIcons,
  useLayout,
  userContext,
  useTextInputDialog,
  useTheme,
} from "@siruplab/capsule"
import _ from "lodash"
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet, useWindowDimensions, View } from "react-native"
import FastImage, { Source } from "react-native-fast-image"
import ImageViewer from "react-native-image-zoom-viewer"
import Pdf from "react-native-pdf"
import Carousel from "react-native-snap-carousel"

import { Asset, UrlAttachment } from "../../features/models/Asset"
import { canEditFn, updateAssetData } from "../../features/models/AssetsFunctions"
import { UserData } from "../../features/models/UserData"
import { RootParamList } from "../../features/Navigation/RootNavigator"
import { useAssets } from "../../features/Providers/AssetProvider"
import { ICON_SIZE } from "../../ThemeApp"
import { isPdf } from "../../utils/isPdf"
import isWeb from "../../utils/isWeb"
import { ns } from "./i18n/en"

interface Props {
  onUpdate?: (val: "photos" | "plans", data: any) => void
  route: RouteProp<RootParamList, "ImageView">
  navigation: NavigationProp<RootParamList, "ImageView">
}

interface ImageType {
  source: Source
}
const keyExtractor = (item: ImageType, idx: number) => item.source.uri ?? String(idx)

enum MenuIcons {
  EDIT,
  PREV,
  NEXT,
}

const ImageViewScreen = ({ route, navigation, onUpdate }: Props) => {
  const { assets, canDoAll } = useAssets()
  const {
    colors: {
      white: { highEmphasis: white },
    },
    dimensions: { spacing },
  } = useTheme()

  const s = useMemo(
    () => ({
      headerTitle: { paddingVertical: spacing },
    }),
    [spacing],
  )

  const { t } = useTranslation(ns)

  const { assetId, plans, index = 0, images: paramsImages } = route.params ?? {}

  const { showTextDialog } = useTextInputDialog()
  const { user, userData } = useContext<IUserContext<UserData>>(userContext)

  const { width: windowWidth } = useWindowDimensions()
  const { onLayout, width, height } = useLayout()
  const carouselRef = useRef<Carousel<ImageType>>(null)

  const asset = useMemo(() => assets.find(elem => elem.id === assetId), [assets, assetId])
  const [currentIndex, setCurrentIndex] = useState(index)

  const attachmentKey: keyof Asset = plans ? "plans1" : "photos1"
  const attachments = (asset?.[attachmentKey] as UrlAttachment[]) ?? paramsImages
  const currentName = attachments?.[currentIndex]?.name

  const onRename = useCallback(async () => {
    const { button, text: name } = await showTextDialog(
      {
        title: t("rename.title"),
        positive: { label: t("common:button.save"), type: ButtonType.POSITIVE },
        negative: { type: ButtonType.NEGATIVE },
      },
      {
        label: t("rename.label"),
      },
      currentName,
    )
    if (button === "positive") {
      const newAttachments = attachments?.map((a, i) => (i === currentIndex ? { ...a, name } : a))
      const value: Partial<Asset> = { [attachmentKey]: newAttachments }
      if (!asset?.id) {
        onUpdate?.(plans ? "plans" : "photos", value)
      }
      // noinspection ES6MissingAwait
      updateAssetData(asset?.id, value)
    }
  }, [
    asset,
    attachmentKey,
    attachments,
    currentIndex,
    currentName,
    onUpdate,
    plans,
    showTextDialog,
    t,
  ])

  const onMenu = useCallback(
    item => {
      switch (item.id) {
        case MenuIcons.PREV:
          carouselRef.current?.snapToPrev(true)
          break
        case MenuIcons.NEXT:
          carouselRef.current?.snapToNext(true)
          break
        default:
          // noinspection JSIgnoredPromiseFromCall
          onRename()
          break
      }
    },
    [onRename],
  )

  useEffect(() => {
    navigation.setOptions({
      headerTitle: () => (
        <H6 numberOfLines={1} style={s.headerTitle}>
          {currentName}
        </H6>
      ),
      headerRight: () => (
        <ActionBar
          icons={[
            ...(isWeb
              ? [
                  {
                    id: MenuIcons.PREV,
                    iconComp: <MaterialIcons name="chevron-left" size={ICON_SIZE} />,
                    disabled: currentIndex === 0,
                  },
                  {
                    id: MenuIcons.NEXT,
                    iconComp: <MaterialIcons name="chevron-right" size={ICON_SIZE} />,
                    disabled: currentIndex === (attachments?.length ?? 0) - 1,
                  },
                ]
              : []),
            ...((canEditFn(asset?.roles, user?.uid) || canDoAll) && asset?.id
              ? [
                  {
                    id: MenuIcons.EDIT,
                    iconComp: <MaterialCommunityIcons name="pencil" size={ICON_SIZE} />,
                  },
                ]
              : []),
          ]}
          onPress={onMenu}
        />
      ),
    })
  }, [
    asset,
    attachments,
    currentIndex,
    currentName,
    canDoAll,
    navigation,
    onMenu,
    s,
    user,
    userData,
  ])

  const renderImage = useCallback(
    ({ item }: { item: ImageType }) =>
      isPdf(item.source.uri ?? "") ? (
        <Pdf {...item} style={{ width, height }} />
      ) : (
        <FastImage {...item} style={{ width, height }} resizeMode="contain" />
      ),
    [height, width],
  )

  const getItemLayout = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (data: ImageType[], index: number) => ({ index, length: width, offset: width * index }),
    [width],
  )

  const hasPdf = attachments ? _.some(attachments, a => isPdf(a.url)) : false

  if (!hasPdf && !isWeb) {
    return (
      <ImageViewer
        index={index}
        imageUrls={attachments?.map(a => ({ url: a.url }))}
        backgroundColor={white}
        onChange={idx => {
          if (idx !== undefined) {
            setCurrentIndex(idx)
          }
        }}
      />
    )
  }

  const images = attachments?.map(a => ({ source: { uri: a.url } }))

  return images ? (
    <View onLayout={onLayout} style={style.container}>
      <Carousel
        ref={carouselRef}
        data={images}
        vertical={false}
        renderItem={renderImage}
        keyExtractor={keyExtractor}
        itemWidth={width || windowWidth}
        sliderWidth={width || windowWidth}
        activeSlideAlignment="center"
        getItemLayout={getItemLayout}
        onScrollIndexChanged={setCurrentIndex}
      />
    </View>
  ) : null
}

const style = StyleSheet.create({ container: { width: "100%", height: "100%" } })

export default ImageViewScreen
