import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

const UserSVG = (props: SvgProps) => (
  <Svg width={25} height={24} viewBox="0 0 25 24" fill="none" {...props}>
    <Path d="M12.667 14a3 3 0 100-6 3 3 0 000 6z" fill={props.color} />
    <Path
      d="M12.667 4c-4.4 0-8 3.6-8 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm4 12.4c-.6-.7-1.4-1.4-3-1.4h-2c-1.6 0-2.4.7-3 1.4-1.2-1.1-2-2.6-2-4.4 0-3.3 2.7-6 6-6s6 2.7 6 6c0 1.8-.8 3.3-2 4.4z"
      fill={props.color}
    />
  </Svg>
)

export default UserSVG
