import { rootRoutes } from "../../../features/Navigation/Constants"

export const ns = rootRoutes.CREATE_ASSET

export default {
  common: {
    [ns]: {
      delete: "Delete",
      title: "New Asset",
      altTitle: "Edit Asset",
    },
  },
  [ns]: {
    addClassification: "Add classifications",
    addPortfolios: "Add portfolios",
    addPhotos: "Add photos",
    newPortfolio: "New portfolio",
    addPlans: "Add floor plans",
    create: "Create",
    save: "Save",
    createAndContinue: "Create and add another",
    description: "Description",
    rentalIncome: "Rental income",
    location: "Location",
    name: "Name",
    optionalInfo: "Optional information",
    size: "Size",
    value: "Value",
    noPortfolios: "You have no portfolios yet",
    coordinates: "Latitude: {{lat}} Longitude: {{lng}}",
    noAddress: "No Address",
    alertDialog:
      "📌 Did you know: you can create an asset by a long-tap on the map – especially useful when your asset doesn't have an address yet",
    alertDialogWeb:
      "📌 Did you know: you can create an asset by a right-click on the map – especially useful when your asset doesn't have an address yet",
  },
}
