import { COMMON_NAMESPACE, H6, IUserContext, userContext, useTheme } from "@siruplab/capsule"
import React, { useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet } from "react-native"
import { View } from "react-native-animatable"
import Modal from "react-native-modal"

import { UserData } from "../features/models/UserData"
import { updateUserData } from "../features/models/UserDataFunctions"
import { CompanyValues } from "../screens/CompanyScreen/CompanyScreen"
import { BACKDROP_OPACITY, maxWidth } from "../ThemeApp"
import CompanyInputs from "./CompanyInputs"
import NameInputs, { NameValues } from "./NameInputs"

interface Props {
  visible: boolean
  hideModal: () => void
  isNameEdit: boolean
  isCompanyEdit: boolean
  namespace: string
}

const UserInfoInputModal = ({
  visible,
  hideModal,
  isNameEdit,
  isCompanyEdit,
  namespace,
}: Props) => {
  const { t } = useTranslation([namespace, COMMON_NAMESPACE])
  const {
    colors: { surface },
    dimensions: { spacing, margin },
  } = useTheme()
  const isTeammatesEdit = !isCompanyEdit

  const { userDocRef } = useContext<IUserContext<UserData>>(userContext)

  const onSubmit = (values: CompanyValues | NameValues) => {
    // noinspection JSIgnoredPromiseFromCall
    updateUserData(userDocRef, values)
    hideModal()
  }

  const s = useMemo(
    () => ({
      container: [
        styles.container,
        {
          padding: spacing,
          backgroundColor: surface.appUi,
          borderRadius: margin / 2,
          height: isNameEdit ? 289 : 239,
        },
      ],
      title: { marginBottom: margin },
    }),
    [isNameEdit, margin, spacing, surface.appUi],
  )

  return (
    <Modal
      useNativeDriver
      isVisible={visible}
      onDismiss={hideModal}
      onBackdropPress={hideModal}
      onBackButtonPress={hideModal}
      backdropOpacity={BACKDROP_OPACITY}
    >
      <View style={s.container}>
        <H6 style={s.title}>
          {isNameEdit ? t("screenTitle") : isCompanyEdit ? t("companyName") : t("inviteTeammates")}
        </H6>
        {isNameEdit ? (
          <NameInputs onSubmit={onSubmit} isEdit />
        ) : (
          <CompanyInputs {...{ isCompanyEdit, isTeammatesEdit, onSubmit }} isEdit />
        )}
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  container: {
    margin: "auto",
    width: maxWidth,
    alignSelf: "center",
  },
})

export default UserInfoInputModal
