import { RouteProp, useNavigation, useRoute } from "@react-navigation/native"
import {
  auth,
  Button,
  ButtonType,
  Caption,
  COMMON_NAMESPACE,
  DialogResponse,
  emailRegex,
  FormikTextInput,
  functions,
  InputFocusProvider,
  KeyboardSafeAreaScrollView,
  StylizedTranslatedText,
  ThemeProvider,
  useAlert,
  userContext,
  useTheme,
} from "@siruplab/capsule"
import { Formik } from "formik"
import _ from "lodash"
import React, { useContext, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet, View } from "react-native"
import { Snackbar } from "react-native-paper"
import * as Yup from "yup"

import { onboardingRoutes } from "../../features/Navigation/Constants"
import { OnboardingParamList } from "../../features/Navigation/RootNavigator"
import { useStyles } from "../../utils/useStyles"
import { AccountParamList } from "../MainScreen/MainTabs"
import { actionCodeSettings } from "../SignUpScreen/SignUpScreen"
import { ns } from "./i18n/en"

const PasswordCreateScreen = () => {
  const { t } = useTranslation([ns, COMMON_NAMESPACE])
  const { logout, login, signInWithEmail } = useContext(userContext)
  const { navigate } = useNavigation()
  const { showSnack, showDialog } = useAlert()
  const [loading, setLoading] = useState(false)

  const { params } = useRoute<
    RouteProp<OnboardingParamList, "PasswordCreate"> | RouteProp<AccountParamList, "Set_Password">
  >()

  const initialMail = params
    ? (params as RouteProp<AccountParamList, "Set_Password">["params"]).email
    : ""

  const goToSignUp = () => {
    navigate(onboardingRoutes.LOGIN)
  }

  const [error, setError] = useState<string>()
  const [email, setEmail] = useState(initialMail)
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")

  const emptyForm = useMemo(
    () => ({
      email: initialMail,
      password: "",
      confirmPassword: "",
    }),
    [initialMail],
  )

  const {
    colors: {
      secondary,
      primary,
      surface,
      white: { highEmphasis: white },
    },
  } = useTheme()
  const s = useStyles(({ colors, typography: { body2 }, dimensions: { spacing } }) => ({
    containerStyle: {
      ...styles.container,
      backgroundColor: surface.appUi,
      paddingHorizontal: spacing,
    },
    buttonLabel: { color: surface.disabled },
    highlighted: { color: primary },
    button: { marginTop: spacing },
    caption: { marginTop: spacing, alignItems: "center" },
    input: {
      fontSize: body2.fontSize,
      marginTop: spacing,
    },
    textStyle: {
      color: colors.error,
    },
  }))

  const onSubmit = async () => {
    try {
      setLoading(true)
      if (!_.isEmpty(initialMail)) {
        await auth()?.currentUser?.updatePassword(password)
        showSnack(t("passwordSuccess"))
        // @ts-ignore https://reactnavigation.org/docs/5.x/params/#passing-params-to-a-previous-screen
        navigate({ name: "Account_Main", params: { password: true }, merge: true })
        return
      }
      await auth().createUserWithEmailAndPassword(email, password)
    } catch (e) {
      if (e.code === "auth/requires-recent-login") {
        const result = await showDialog({
          type: "button",
          title: t("modal.title"),
          message: t("modal.message"),
          positive: {
            textStyle: s.textStyle,
            label: t("common:actions.logout"),
          },
          negative: {
            label: t("common:actions.cancel"),
          },
        })
        if ((result as DialogResponse).button === ButtonType.POSITIVE) {
          // noinspection ES6MissingAwait
          logout()
        }
      }
      if (e.code === "auth/email-already-in-use") {
        try {
          await functions().httpsCallable("setPassword")({
            email,
            password,
          })
          await login(email, password)
          return
        } catch (fnError) {
          const res = await showDialog({
            type: "button",
            title: t("modalLoggedIn.title"),
            message: t("modalLoggedIn.message"),
            positive: {
              textStyle: s.textStyle,
              label: t("modalLoggedIn.ok"),
            },
            negative: {
              label: t("common:actions.cancel"),
            },
          })
          if ((res as DialogResponse).button === ButtonType.POSITIVE) {
            await signInWithEmail(email, actionCodeSettings)
            navigate(onboardingRoutes.SIGNIN_VALIDATION)
          }
          setError("internal/already-logged-in")
        }
      }
      setError(e.code)
    } finally {
      setLoading(false)
    }
  }

  return (
    <ThemeProvider
      customTheme={{
        colors: {
          overrides: { button: primary },
        },
      }}
    >
      <Formik
        initialValues={emptyForm}
        onSubmit={onSubmit}
        validationSchema={Yup.object({
          email: Yup.string()
            .matches(emailRegex, { message: t("invalidEmail"), excludeEmptyString: true })
            .required(t("required")),
          password: Yup.string().min(6, t("passwordLength")).required(t("required")),
          confirmPassword: Yup.string()
            .required(t("confirmError"))
            .oneOf([Yup.ref("password"), null], t("noMatches")),
        })}
      >
        {({ handleSubmit, errors, isValid, touched }) => (
          <KeyboardSafeAreaScrollView style={s.containerStyle} edges={["right", "left"]}>
            <InputFocusProvider>
              {!_.isEmpty(initialMail) ? null : (
                <FormikTextInput
                  dense
                  name="email"
                  value={email}
                  error={!!errors.email}
                  style={s.input}
                  autoCorrect={false}
                  returnKeyType="next"
                  autoCapitalize="none"
                  inputColor={secondary}
                  onChangeText={setEmail}
                  label={t("email")}
                  keyboardType="email-address"
                />
              )}
              <FormikTextInput
                name="password"
                value={password}
                error={!!errors.password}
                style={s.input}
                autoCorrect={false}
                returnKeyType="next"
                secureTextEntry
                autoCapitalize="none"
                inputColor={secondary}
                onChangeText={setPassword}
                label={t("password")}
              />
              <FormikTextInput
                dense
                name="confirmPassword"
                value={confirmPassword}
                error={!!errors.confirmPassword}
                underlineColor="transparent"
                style={s.input}
                autoCorrect={false}
                returnKeyType="done"
                secureTextEntry
                autoCapitalize="none"
                inputColor={secondary}
                onChangeText={setConfirmPassword}
                label={t("confirm")}
              />
            </InputFocusProvider>
            <Button
              onPress={handleSubmit}
              style={s.button}
              labelStyle={{
                color: !isValid || _.isEmpty(touched) ? surface.disabled : white,
              }}
              loading={loading}
              disabled={!isValid || _.isEmpty(touched)}
            >
              {t(!_.isEmpty(initialMail) ? "createPassword" : "createAccount")}
            </Button>
            <View style={s.caption}>
              <StylizedTranslatedText
                highlightedTextStyle={s.highlighted}
                TextComp={Caption}
                i18nKey="passwordCreate.login"
                onPress={goToSignUp}
              />
            </View>
          </KeyboardSafeAreaScrollView>
        )}
      </Formik>
      <Snackbar visible={!_.isEmpty(error)} onDismiss={() => setError(undefined)}>
        {error
          ? t(`common:accountScreen.errors.${error}`, t(`common:accountScreen.errors.default`))
          : ""}
      </Snackbar>
    </ThemeProvider>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  textContainer: {
    flex: 1,
    alignContent: "center",
  },
  text: {
    textAlign: "left",
  },
})

export default PasswordCreateScreen
