import { useNavigation } from "@react-navigation/native"
import { Body2, COMMON_NAMESPACE, H6, IUserContext, userContext } from "@siruplab/capsule"
import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import { SafeAreaView } from "react-native"

import NameInputs from "../../components/NameInputs"
import { UserData } from "../../features/models/UserData"
import { updateUserData } from "../../features/models/UserDataFunctions"
import { useStyles } from "../../utils/useStyles"
import { accountRoutes } from "../MainScreen/Constants"
import { ns } from "./i18n/en"

export interface NameValues {
  firstName: string
  lastName: string
}

interface Props {
  isEdit?: boolean
}

const NameScreen = ({ isEdit = false }: Props) => {
  const { t } = useTranslation([ns, COMMON_NAMESPACE])
  const { userDocRef, userData, user } = useContext<IUserContext<UserData>>(userContext)
  const navigation = useNavigation()

  const onSubmit = (values: NameValues) => {
    if (!user || !user.email) {
      return
    }
    // noinspection JSIgnoredPromiseFromCall
    updateUserData(userDocRef, !userData?.email ? { ...values, email: user.email } : { ...values })
    if (isEdit) {
      navigation.navigate(accountRoutes.MAIN)
    }
  }

  const s = useStyles(({ colors: { surface: { appUi } }, dimensions: { spacing } }) => ({
    text: { alignSelf: "center", textAlign: "center" },
    view: { flex: 1, backgroundColor: appUi },
    title: { alignSelf: "center", paddingTop: spacing * 2 },
  }))

  return (
    <>
      <SafeAreaView style={s.view}>
        {!isEdit ? (
          <>
            <H6 style={s.title}>{t("title")}</H6>
            <Body2 style={s.text}>{t("subtitle")}</Body2>
          </>
        ) : null}
        <NameInputs {...{ isEdit, onSubmit }} />
      </SafeAreaView>
    </>
  )
}

export default NameScreen
