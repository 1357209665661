import { useNavigation } from "@react-navigation/native"
import { MaterialCommunityIcons, useTabLand, useTabTwoPane, useTheme } from "@siruplab/capsule"
import { isEmpty } from "lodash"
import React, { useCallback, useRef, useState } from "react"
import { Pressable, useWindowDimensions, View } from "react-native"
import FastImage from "react-native-fast-image"
import Carousel, { Pagination } from "react-native-snap-carousel"

import { WebRightPlaceholder } from "../../../components/svg"
import { Asset, UrlAttachment } from "../../../features/models/Asset"
import { rootRoutes } from "../../../features/Navigation/Constants"
import { rootNav } from "../../../features/Navigation/NavigationService"
import { useAssets } from "../../../features/Providers/AssetProvider"
import { ICON_SIZE_LARGE, ICON_SIZE_SMALL, maxWidth, tabSizeLand } from "../../../ThemeApp"
import isWeb from "../../../utils/isWeb"
import { useStyles } from "../../../utils/useStyles"
import { PHOTOS_PATH } from "../../CreateAssetScreen/CreateAssetScreen"
import PicChip from "./PicChip"

interface IProps {
  asset: Asset
}

const AssetCarousel = ({ asset }: IProps) => {
  const { width, height } = useWindowDimensions()
  const navigation = useNavigation()

  const [activeSlide, setActiveSlide] = useState(0)
  const { canEdit } = useAssets()

  const isTabLand = useTabLand()
  const isTabTwoPane = useTabTwoPane()

  const {
    colors: {
      black: { mediumEmphasis },
    },
  } = useTheme()

  const s = useStyles(
    ({
      dimensions: { spacing },
      colors: {
        primary,
        white: { highEmphasis: white },
      },
    }) => ({
      arrowLeft: { left: spacing },
      arrowRight: { right: spacing },
      icon: { width: ICON_SIZE_SMALL, height: ICON_SIZE_SMALL },
      dot: { backgroundColor: primary, width: 8, height: 8 },
      inactiveDot: { backgroundColor: white, width: 8, height: 8 },
      paginationView: {
        position: "absolute",
        bottom: 0,
        width: "100%",
        justifyContent: "center",
        paddingVertical: 4,
      },
      image: {
        height: isTabLand ? height / 2 : 200,
        width:
          isTabTwoPane && isWeb
            ? width - maxWidth - tabSizeLand
            : isTabLand && isWeb
            ? width - tabSizeLand
            : width,
      },
      arrowView: { bottom: "50%", borderRadius: 50, position: "absolute", backgroundColor: white },
    }),
  )

  const renderItem = useCallback(
    ({ item: { url: uri }, index }: { item: UrlAttachment; index: number }) => (
      <Pressable
        onPress={() => {
          ;(isTabTwoPane ? rootNav() : navigation).navigate(rootRoutes.IMAGE_VIEW, {
            assetId: asset.id,
            index,
          })
        }}
      >
        <FastImage style={s.image} source={{ uri }} resizeMode="cover" />
      </Pressable>
    ),
    [asset.id, isTabTwoPane, navigation, s.image],
  )

  const carouselRef = useRef<Carousel<UrlAttachment>>(null)

  const snapTo = (idx: number) => () => {
    if (idx === 1 && activeSlide === asset.photos1.length - 1) {
      carouselRef.current?.snapToItem(0, false)
    } else if (idx === 0 && activeSlide === 0) {
      carouselRef.current?.snapToItem(asset.photos1.length, false)
    } else {
      carouselRef.current?.snapToItem(activeSlide + (idx === 0 ? -1 : 1), false)
    }
  }

  const showPicChip = canEdit

  return !isEmpty(asset.photos1) ? (
    <View>
      <Carousel<UrlAttachment>
        itemWidth={width}
        ref={carouselRef}
        vertical={false}
        data={asset.photos1 as UrlAttachment[]}
        sliderWidth={width}
        scrollEnabled={!isWeb}
        inactiveSlideScale={1}
        renderItem={renderItem}
        activeSlideAlignment="center"
        onScrollIndexChanged={setActiveSlide}
      />
      {isTabLand
        ? ["left", "right"].map((elem, idx) => (
            <View key={idx} style={[s.arrowView, idx === 0 ? s.arrowLeft : s.arrowRight]}>
              <MaterialCommunityIcons
                size={ICON_SIZE_LARGE}
                color={mediumEmphasis}
                name={`arrow-${elem}-drop-circle`}
                onPress={snapTo(idx)}
              />
            </View>
          ))
        : null}
      <Pagination
        dotStyle={s.dot}
        inactiveDotStyle={s.inactiveDot}
        inactiveDotOpacity={1}
        inactiveDotScale={1}
        containerStyle={s.paginationView}
        dotsLength={asset.photos1.length}
        activeDotIndex={activeSlide}
      />
      {showPicChip ? <PicChip asset={asset} path={PHOTOS_PATH} isAbsolute /> : null}
    </View>
  ) : (
    <View>
      <View style={s.image}>
        <WebRightPlaceholder preserveAspectRatio="xMinYMin slice" />
      </View>
      {showPicChip ? <PicChip asset={asset} path={PHOTOS_PATH} isAbsolute /> : null}
    </View>
  )
}

export default AssetCarousel
