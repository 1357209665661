import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

const ClearIcon = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" {...props}>
    <Path
      d="M17.721 5.279a.898.898 0 00-1.3 0L11.5 10.2 6.579 5.279a.898.898 0 00-1.3 0 .898.898 0 000 1.3L10.2 11.5l-4.921 4.921a.898.898 0 000 1.3.843.843 0 00.65.279.843.843 0 00.65-.279L11.5 12.8l4.921 4.921c.186.186.465.279.65.279.186 0 .465-.093.65-.279a.898.898 0 000-1.3L12.8 11.5l4.921-4.921a.898.898 0 000-1.3z"
      fill="#32352F"
      fillOpacity={0.72}
    />
  </Svg>
)

export default ClearIcon
