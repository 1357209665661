import { DataRes, PermissionCameraWrapper, WebGalleryPicker } from "@siruplab/capsule"
import React, { PropsWithChildren } from "react"
import DocumentPicker from "react-native-document-picker"
import { Image } from "react-native-image-crop-picker"

import isWeb from "../utils/isWeb"

interface IProps {
  updatePics: (newPics: ImageOrDocument[] | ImageOrDocument) => Promise<void> | void
  allowPdf?: boolean
}

export type ImageOrDocument = Image & { fileCopyUri?: string }

const ImagesUploader = ({ children, updatePics, allowPdf = false }: PropsWithChildren<IProps>) => {
  const onComplete = (data: DataRes[]) => {
    // noinspection JSIgnoredPromiseFromCall
    updatePics(data as ImageOrDocument[])
  }

  return isWeb ? (
    <WebGalleryPicker
      onComplete={onComplete}
      multiple
      accept={allowPdf ? "image/*,.pdf" : undefined}
    >
      {children}
    </WebGalleryPicker>
  ) : (
    <PermissionCameraWrapper
      setSource={updatePics}
      style={{ alignSelf: "center" }}
      imageOptions={{
        maxFiles: 20,
        multiple: true,
        mediaType: allowPdf ? "any" : "photo",
      }}
      documentTypes={[DocumentPicker.types.images, ...(allowPdf ? [DocumentPicker.types.pdf] : [])]}
      downloadFile={allowPdf}
    >
      {children}
    </PermissionCameraWrapper>
  )
}

export default ImagesUploader
