import { Classification } from "../../../features/models/Asset"
import { rootRoutes } from "../../../features/Navigation/Constants"

export const ns = rootRoutes.ASSETS_FILTER

const classification: Record<Classification, string> = {
  hospitality: "Hospitality",
  industrial: "Industrial",
  office: "Office",
  residential: "Residential",
  retail: "Retail",
  other: "Other",
}

export default {
  common: {
    [ns]: {
      title: "Filters",
    },
  },
  [ns]: {
    results: "See results",
    sliderTitles: {
      size: "Size",
      monetaryValue: "Value",
      rentalIncome: "Rental Income",
    },
    selectTitles: {
      classificationsTitle: "Classifications",
      classificationsModalTitle: "Add classifications",
      portfoliosTitle: "Portfolios",
      portfoliosModalTitle: "Add portfolios",
      usersTitle: "Users",
    },
    classification,
    selectModalButtons: {
      cancel: "Cancel",
      select: "Select",
    },
    searchMultiSelect: {
      placeholder: "No filter",
      searchPlaceholder: "Search for other users...",
    },
    clearButtonText: "clear",
    noPortfolios: "You have no portfolios yet",
  },
}
