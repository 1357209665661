import { assetsRoutes, mainRoutes } from "../../MainScreen/Constants"

export const ns = mainRoutes.ASSETS_TAB

export default {
  common: {
    [`${assetsRoutes.MAIN}`]: {
      title: "Assets",
    },
  },
  [`${mainRoutes.ASSETS_TAB}`]: {
    placeholder: "Search assets",
    viewMap: "View map",
    zeroFilters: "Filters",
    filters: "{{count}} filter",
    filters_plural: "{{count}} filters",
    assetSelected: "{{count}} asset selected",
    assetSelected_plural: "{{count}} assets selected",
    emptyMessageTitle: "No Assets Found",
    emptyMessageSubtitle: "Try to change the filters",
    emptyPortfolioSubtitle: "Create assets directly in this portfolio from here",
    addAsset: "Add asset",
    existingAsset: "Existing asset",
    newAsset: "New Asset",
    delete: "Delete portfolio",
    rename: "Rename portfolio",
  },
}
