import { accountRoutes } from "../../MainScreen/Constants"

export const ns = accountRoutes.MAIN

export default {
  common: {
    [ns]: {
      title: "Account",
    },
    urls: {
      help: "https://domaine-docs.web.app/support.html",
      privacy: "https://domaine-docs.web.app/privacy.html",
      tos: "https://domaine-docs.web.app/tos.html",
    },
  },
  [ns]: {
    logout: "Logout?",
    disconnect: "Please make sure you want to logout from your account.",
    deleteAccount: "Delete Account?",
    deleteAccountMessage:
      "This will delete your account and all your data, including assets you have shared with others.",
    confirmDeleteAccount: "Delete account",
    deleteAccountError: "Could not delete your account",
    title: "",
    password: "Password",
    setPassword: "Set a password",
    hasPassword: "**********",
    completeName: "{{firstName}} {{lastName}}",
    confirm: "Logout",
    email: "Email",
    profile: "Name",
    company: "Company",
    domaine: "Domaine",
    logoutButton: "Logout",
    deleteAccountButton: "Delete account",
    inviteTeammates: "Invite teammates",
  },
}
