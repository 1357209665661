import AsyncStorage from "@react-native-async-storage/async-storage"
import { firestore, IUserContext, userContext } from "@siruplab/capsule"
import firebase from "firebase"
import _ from "lodash"
import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useCollectionData, useDocumentData } from "react-firebase-hooks/firestore"

import { collections } from "../../common/types"
import { Item } from "../../components/MultiSelect/MultiSelect"
import { Portfolio } from "../models/Portfolio"
import { UserData } from "../models/UserData"

interface PortfolioContext {
  displayedPortfolioId?: string
  storeDisplayedPortfolioId: (id?: string) => void
  displayedPortfolio?: Portfolio
  portfolios: Portfolio[]
  canDoAll: boolean
  canDelete: boolean
  canEdit: boolean
  handlePortfolioPublicMode: (url: string) => void
  publicPortfolio?: Portfolio
  publicMode: boolean
  setPublicMode: Dispatch<SetStateAction<boolean>>
  canView: boolean
  setDisplayedPortfolioId: Dispatch<SetStateAction<string | null | undefined>>
  portfoliosInfo: Item[]
  loading: boolean
}

interface IProps {
  children: ReactNode
  // TODO: set initial values depending on assets that are displayed on the map
}

const portfolioContext = createContext<PortfolioContext>({} as PortfolioContext)

export const PortfolioProvider = ({ children }: IProps) => {
  const { user, isAdmin } = useContext<IUserContext<UserData>>(userContext)

  const [publicMode, setPublicMode] = useState(false)
  const [displayedPortfolioId, setDisplayedPortfolioId] = useState<string | null>()

  const firebaseOptions = {
    idField: "id",
    transform: useCallback(
      (item: Portfolio) => ({ ...item, numberedName: `${item.name} (${item.assets?.length})` }),
      [],
    ),
  }

  const [publicPortfolio, publicLoading] = useDocumentData<Portfolio>(
    // @ts-ignore
    publicMode && displayedPortfolioId
      ? (firestore().collection(collections.PORTFOLIOS).doc(displayedPortfolioId) as unknown)
      : null,
    firebaseOptions,
  )

  const [portfolios, loading] = useCollectionData<Portfolio>(
    user?.uid
      ? ((firestore()
          .collection(collections.PORTFOLIOS)
          .where(`roles.${user.uid}`, ">", "") as unknown) as firebase.firestore.Query<Portfolio>)
      : null,
    firebaseOptions,
  )

  const storeDisplayedPortfolioId = useCallback((id?: string) => {
    if (!id) {
      // noinspection JSIgnoredPromiseFromCall
      AsyncStorage.removeItem("portfolioId")
    } else {
      // noinspection JSIgnoredPromiseFromCall
      AsyncStorage.setItem("portfolioId", id)
    }
    setDisplayedPortfolioId(id)
  }, [])

  useEffect(() => {
    AsyncStorage.getItem("portfolioId").then(value => {
      setDisplayedPortfolioId(value)
    })
  }, [])

  const portfoliosSorted = useMemo(() => _.orderBy(portfolios, ["name"]), [portfolios])

  const displayedPortfolio = useMemo(
    () =>
      publicMode && publicPortfolio
        ? publicPortfolio
        : _.find(portfolios, portfolio => portfolio.id === displayedPortfolioId),
    [displayedPortfolioId, portfolios, publicMode, publicPortfolio],
  )

  const portfoliosInfo = useMemo(
    () => portfoliosSorted?.map(portfolio => ({ label: portfolio.name, value: portfolio.id })),
    [portfoliosSorted],
  )

  const [loaded, setLoaded] = useState(false)

  const handlePortfolioPublicMode = async url => {
    const portfolioId = url.split("/").pop()
    const portfolio = await firestore().collection(collections.PORTFOLIOS).doc(portfolioId).get()
    const isPublic = portfolio.data()?.isPublic
    if (!isPublic) {
      return
    }
    await AsyncStorage.setItem("publicPortfolioId", `${portfolioId}`)
    setPublicMode(isPublic)
    setDisplayedPortfolioId(portfolioId)
    // TODO: Remove this and find a real solution
    setTimeout(() => setLoaded(true), 1000)
  }

  const role = useMemo(() => displayedPortfolio?.roles[user?.uid ?? ""], [displayedPortfolio, user])

  const canDoAll = isAdmin
  const canDelete = canDoAll || role === "owner"
  const canEdit = canDelete || role === "editor"
  const canView = canEdit || role === "viewer"

  const portfolioValue: PortfolioContext = {
    loading: loading && publicLoading && !loaded,
    canDoAll,
    canDelete,
    canEdit,
    publicMode,
    setPublicMode,
    handlePortfolioPublicMode,
    publicPortfolio,
    canView,
    portfolios: portfoliosSorted,
    portfoliosInfo,
    displayedPortfolio,
    setDisplayedPortfolioId,
    displayedPortfolioId: displayedPortfolioId ?? undefined,
    storeDisplayedPortfolioId,
  }

  return <portfolioContext.Provider value={portfolioValue}>{children}</portfolioContext.Provider>
}

const usePortfolios = () => {
  const context = useContext(portfolioContext)
  if (context === undefined) {
    throw new Error("usePortfolios must be used within a PortfolioProvider")
  }

  return context
}

export default usePortfolios
