import { onboardingRoutes } from "../../../features/Navigation/Constants"

export const ns = onboardingRoutes.LOGIN

export default {
  common: {
    [ns]: {
      title: "Log in",
    },
  },
  [ns]: {
    email: "Email",
    password: "Password",
    login: "Log in",
    recoveryError: "Please enter a valid email address.",
    emailSent: "The recovery email has been sent.",
    forgot: "Forgot your password? Click here to send recovery email.",
  },
}
