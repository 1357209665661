import {
  COMMON_NAMESPACE,
  IUserContext,
  MultipleTextInputFormikConfig,
  MultipleTextInputScreen,
  userContext,
  useTheme,
} from "@siruplab/capsule"
import React, { useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"

import { UserData } from "../features/models/UserData"
import { ns } from "../screens/NameScreen/i18n/en"
import { AppTheme } from "../ThemeApp"

export interface NameValues {
  firstName: string
  lastName: string
}

interface Props {
  isEdit: boolean
  onSubmit: (values: NameValues) => void
}

const NameInputs = ({ isEdit, onSubmit }: Props) => {
  const { t } = useTranslation([ns, COMMON_NAMESPACE])
  const { userData } = useContext<IUserContext<UserData>>(userContext)
  const {
    dimensions: { spacing },
    typography: { body2 },
  } = useTheme<AppTheme>()

  const s = useMemo(
    () => ({
      input: {
        fontSize: body2.fontSize,
        marginVertical: spacing / 2,
        minWidth: 0,
      },
    }),
    [body2, spacing],
  )

  return (
    <MultipleTextInputScreen<NameValues>
      inputsStyle={s.input}
      formikConfig={MultipleTextInputFormikConfig<NameValues>({
        onSubmit,
        values: {
          firstName: isEdit ? userData?.firstName ?? "" : "",
          lastName: isEdit ? userData?.lastName ?? "" : "",
        },
        errorMessage: t("common:fields.required"),
        validationSchema: Yup.object({
          firstName: Yup.string().required(t("common:fields.required")),
          lastName: Yup.string()
            .required(t("common:fields.required"))
            .when("firstName", (firstName, schema) =>
              firstName === userData?.firstName
                ? schema.test(
                    "Should not match the previous value",
                    t("sameValuesError"),
                    value => value !== userData?.lastName,
                  )
                : schema,
            ),
        }),
      })}
      buttonLabel={t(`common:button.${isEdit ? "save" : "continue"}`)}
      inputProps={{
        onBlur: undefined,
        onTouchStart: undefined,
      }}
      inputs={[
        { name: "firstName", label: t("firstName") },
        { name: "lastName", label: t("lastName") },
      ]}
    />
  )
}

export default NameInputs
