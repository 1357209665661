import { rootRoutes } from "../../../features/Navigation/Constants"

export const ns = rootRoutes.ELEM_SHARE

export default {
  [ns]: {
    titleAsset: "Sharing asset",
    titlePortfolio: "Sharing portfolio",
    done: "Done",
    add: "Add to authorised users",
    copyLink: "Copy link",
    linkCopied: "Link copied!",
    copyLinkDesc: "Only authorized people can open the link",
    editor: "Team",
    commonPortfolios: "Common portfolio",
    modalTitle: "User will still have access",
    modalDescription:
      "This user is granted higher rights to this asset through a shared portfolio. If you would like to lower this user's rights, please also do so through portfolio sharing.",

    unknownPortfolios: "Unknown portfolio",
    editorDesc: "View • Modify • Comment • Share",
    teamDesc: "View • Modify • Comment • Share",
    label: "Add emails, comma separated",
    placeholder: "email1@example.com, email2@example.com",
    learnMore: "Learn more",
    team: "Team",
    owner: "Owner",
    partialViewer: "Partial viewer",
    remove: "Remove",
    selectPermissions: "Select Permission",
    viewer: "Viewer",
    viewerDesc: "View all informations",
  },
}
