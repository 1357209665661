import React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

const PlansSVG = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <Path
      d="M18 4H6a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2zm0 2v2H6V6h12zM6 10h3v8H6v-8zm5 8v-8h7v8h-7z"
      fill="#32352F"
      fillOpacity={0.72}
    />
  </Svg>
)

export default PlansSVG
