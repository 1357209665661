import { firestore, IUserContext, logger, userContext } from "@siruplab/capsule"
import firebase from "firebase"
import React, { createContext, PropsWithChildren, useContext } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"

import { collections } from "../../common/types"
import { Comment, CommentToAdd } from "../models/Comment"
import { UserData } from "../models/UserData"

interface AssetCommentsContext {
  loading: boolean
  comments?: Comment[]
  error?: firebase.FirebaseError
  addComment: (newComment: CommentToAdd) => void
}

interface IProps {
  asset?: string
}

const assetCommentsContext = createContext<AssetCommentsContext>({} as AssetCommentsContext)

const AssetCommentsProvider = ({ asset, children }: PropsWithChildren<IProps>) => {
  const { userData, user } = useContext<IUserContext<UserData>>(userContext)
  const [comments, loading, error] = useCollectionData<Comment>(
    (firestore()
      .collection(collections.ASSETS)
      .doc(asset ?? "")
      .collection(collections.COMMENTS)
      .orderBy("timestamp", "asc") as unknown) as firebase.firestore.Query<Comment>,
    { idField: "id" },
  )

  const addComment = (newComment: CommentToAdd) => {
    try {
      if (user && userData && asset) {
        const comment: Omit<Comment, "id"> = {
          ...newComment,
          userLabel: userData.firstName ?? "",
          timestamp: firestore.Timestamp.now(),
          user: user.uid,
        }
        // noinspection JSIgnoredPromiseFromCall
        firestore()
          .collection(collections.ASSETS)
          .doc(asset)
          .collection(collections.COMMENTS)
          .add(comment)
      }
    } catch (e) {
      logger("Add comment error:", e)
    }
  }

  const contextValue: AssetCommentsContext = {
    error,
    loading,
    comments,
    addComment,
  }

  return (
    <assetCommentsContext.Provider value={contextValue}>{children}</assetCommentsContext.Provider>
  )
}

export const useAssetComments = () => {
  const context = useContext(assetCommentsContext)
  if (context === undefined) {
    throw new Error("useAssetComments must be used within a AssetCommentsProvider")
  }
  return context
}

export default AssetCommentsProvider
