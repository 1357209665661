import { rootRoutes } from "../../features/Navigation/Constants"

export const mainRoutes = {
  ASSETS_TAB: "Assets_Tab",
  PORTFOLIOS_TAB: "Portfolios_Tab",
  ACCOUNT_TAB: "Account_Tab",
} as const

export type MainRouteNames = typeof mainRoutes[keyof typeof mainRoutes]

export const assetsRoutes = {
  MAIN: "Assets_Main",
  LIST: "Assets_List",
  ASSETS_FILTER: rootRoutes.ASSETS_FILTER,
  ASSET_VIEW: rootRoutes.ASSET_VIEW,
} as const

export const portfoliosRoutes = {
  LIST: "PortfoliosList",
  ASSETS: "PortfoliosView",
  ASSET_VIEW: rootRoutes.ASSET_VIEW,
} as const

export const accountRoutes = {
  MAIN: "Account_Main",
  SET_PASSWORD: "Set_Password",
  NAMES_EDIT: "Names_Edit",
  COMPANY_EDIT: "Company_Edit",
  TEAMMATES_EDIT: "Teammates_Edit",
  DOMAINE: "Domaine",
} as const

export type AssetsRouteNames = typeof assetsRoutes[keyof typeof assetsRoutes]
export type PortfoliosRouteNames = typeof portfoliosRoutes[keyof typeof portfoliosRoutes]
export type AccountRouteNames = typeof accountRoutes[keyof typeof accountRoutes]
