import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

const CheckSVG = (props: SvgProps) => (
  <Svg width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
    <Path
      d="M7.5 13.475L4.025 10l-1.183 1.175L7.5 15.833l10-10-1.175-1.175L7.5 13.475z"
      fill="#32352F"
      fillOpacity={0.38}
    />
  </Svg>
)

export default CheckSVG
