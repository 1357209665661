import { useNavigation } from "@react-navigation/native"
import {
  BottomButtonContainer,
  Button,
  Caption,
  IUserContext,
  Subtitle1,
  ThemeProvider,
  userContext,
  useTheme,
} from "@siruplab/capsule"
import Dinero from "dinero.js"
import React, { ComponentProps, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { ScrollView, StyleSheet, View, ViewStyle } from "react-native"
import * as RNLocalize from "react-native-localize"
import { SafeAreaView } from "react-native-safe-area-context"

import MultiSelect from "../../components/MultiSelect/MultiSelect"
import RangeSlider from "../../components/RangeSlider/RangeSlider"
import SearchMultiSelect from "../../components/SearchMultiSelect/SearchMultiSelect"
import { Classifications } from "../../features/models/Asset"
import { UserData } from "../../features/models/UserData"
import { useAssetFilter } from "../../features/Providers/AssetFilterProvider"
import usePortfolios from "../../features/Providers/PortfolioProvider"
import { formatMonetaryValue } from "../../utils/formatMonetaryValue"
import isWeb from "../../utils/isWeb"
import { assetsRoutes } from "../MainScreen/Constants"
import { ns } from "./i18n/en"

// TODO: decide when we reset the filters except when clicking on the clear button

export const convertMonetaryValue = async (
  rates:
    | {
        rates?: {
          [key: string]: number
        }
      }
    | undefined,
  value: number,
  unit: string,
) => {
  if (rates?.rates) {
    if (unit === "EUR") {
      return Math.round(value)
    }

    const convertedValue = await Dinero({ amount: Math.round(value) }).convert(unit, {
      endpoint: new Promise(resolve => resolve(rates)),
      roundingMode: "HALF_UP",
    })

    return convertedValue.getAmount()
  }
  return undefined
}

interface Item {
  displayText: [string, string]
  min: number | undefined
  max: number | undefined
  valuesArray: Array<number | undefined>
  setValuesArray: (
    value: ((prevState: number[] | undefined) => number[] | undefined) | number[] | undefined,
  ) => void
  title: string
  style?: ViewStyle
}
type ItemStrict = Exclude<Item, "min" | "max"> & {
  min: number
  max: number
  valuesArray: number[] | undefined
}

const isValid = (item: Item): item is ItemStrict =>
  !!item.min && !!item.max && item.min !== item.max

const AssetsFilterScreen = () => {
  const {
    colors: {
      primary,
      secondary,
      surface: { appUi },
    },
    dimensions: { spacing, margin },
  } = useTheme()

  const {
    rates,

    filterAssets,

    minSurface,
    maxSurface,
    surfaceValues,
    setSurfaceValues,

    minRentalIncome,
    maxRentalIncome,
    rentalIncomeValues,
    setRentalIncomeValues,

    minMonetaryValue,
    maxMonetaryValue,
    monetaryValues,
    setMonetaryValues,

    setSelectedClassifications,
    selectedClassifications,

    setSelectedPortfolios,
    selectedPortfolios,

    clearValues,
    setClearValues,

    resetFilterValues,
    setSelectedUsers,
    selectedUsers,
  } = useAssetFilter()

  const { portfoliosInfo } = usePortfolios()

  const { t, i18n } = useTranslation(ns)
  const navigation = useNavigation()
  const { userData } = useContext<IUserContext<UserData>>(userContext)

  const [localSurfaceValues, setLocalSurfaceValues] = useState(surfaceValues)
  const [localRentalIncomeValues, setLocalRentalIncomeValues] = useState(rentalIncomeValues)
  const [localMonetaryValues, setLocalMonetaryValues] = useState(monetaryValues)

  const [localSelectedClassifications, setLocalSelectedClassifications] = useState(
    selectedClassifications,
  )
  const [localSelectedPortfolios, setLocalSelectedPortfolios] = useState(selectedPortfolios)
  const [localSelectedUsers, setLocalSelectedUsers] = useState(selectedUsers)

  useEffect(() => {
    if (clearValues) {
      setLocalRentalIncomeValues(undefined)
      setLocalSurfaceValues(undefined)
      setLocalMonetaryValues(undefined)
      setLocalSelectedPortfolios([])
      setLocalSelectedClassifications([])
      setLocalSelectedUsers([])
      setClearValues(false)
    }
  }, [clearValues, setClearValues])

  const locale: string = i18n.language.toLowerCase()
  const title = t("selectTitles.usersTitle")

  const { monetaryValueUnit, rentalIncomeUnit, surfaceUnit } = useMemo(
    () => ({
      monetaryValueUnit: userData?.units?.value ?? RNLocalize.getCurrencies()?.[0],
      rentalIncomeUnit: userData?.units?.rentalIncome ?? RNLocalize.getCurrencies()?.[0],
      surfaceUnit: userData?.units?.size ?? (RNLocalize.usesMetricSystem() ? "m2" : "sqft"),
    }),
    [userData?.units],
  )

  const [textLocalMonetaryValues, setTextLocalMonetaryValues] = useState(["", ""])
  const [textLocalRentalIncomeValues, setTextLocalRentalIncomeValues] = useState(["", ""])
  const [textLocalSurfaceValues, setTextLocalSurfaceValues] = useState(["", ""])

  /** Functions definitions */
  const onSelectedItemsChange = useCallback(
    updateSelectedUsers => {
      setSelectedUsers(updateSelectedUsers)
      setLocalSelectedUsers(updateSelectedUsers)
    },
    [setSelectedUsers],
  )

  const getTextValues = useCallback(
    async (valueArray: number[], unit: string) =>
      Promise.all(
        valueArray.map(async value => {
          const convertedValue = await convertMonetaryValue(rates, value, unit)
          return formatMonetaryValue(locale, convertedValue ?? 0, unit, true)
        }),
      ),
    [locale, rates],
  )

  const filterAndNavigate = useCallback(() => {
    setSurfaceValues(localSurfaceValues)
    setMonetaryValues(localMonetaryValues)
    setRentalIncomeValues(localRentalIncomeValues)
    setSelectedClassifications(localSelectedClassifications)
    setSelectedPortfolios(localSelectedPortfolios)
    setSelectedUsers(localSelectedUsers)
    filterAssets()
    if (isWeb) {
      navigation.navigate(assetsRoutes.MAIN)
    } else {
      navigation.goBack()
    }
  }, [
    filterAssets,
    localMonetaryValues,
    localRentalIncomeValues,
    localSelectedClassifications,
    localSelectedPortfolios,
    localSelectedUsers,
    localSurfaceValues,
    navigation,
    setMonetaryValues,
    setRentalIncomeValues,
    setSelectedClassifications,
    setSelectedPortfolios,
    setSurfaceValues,
    setSelectedUsers,
  ])

  /** useEffects */
  useEffect(() => {
    const surfaceValuesArray =
      localSurfaceValues?.length === 2
        ? localSurfaceValues
        : minSurface && maxSurface
        ? [minSurface, maxSurface]
        : null

    if (surfaceValuesArray === null) {
      return
    }

    setTextLocalSurfaceValues(
      surfaceValuesArray.map(
        value =>
          `${new Intl.NumberFormat([locale, "en-UK"], {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          }).format(surfaceUnit === "sqft" ? value * 10.7639 : value)} ${
            surfaceUnit === "sqft" ? "sqft" : "m²"
          }`,
      ),
    )
  }, [locale, maxSurface, minSurface, surfaceUnit, localSurfaceValues])

  useEffect(() => {
    if (localMonetaryValues) {
      getTextValues(localMonetaryValues, monetaryValueUnit).then(data =>
        setTextLocalMonetaryValues(data),
      )
    } else if (minMonetaryValue && maxMonetaryValue) {
      getTextValues([minMonetaryValue, maxMonetaryValue], monetaryValueUnit).then(data =>
        setTextLocalMonetaryValues(data),
      )
    }
  }, [getTextValues, localMonetaryValues, maxMonetaryValue, minMonetaryValue, monetaryValueUnit])

  useEffect(() => {
    if (localRentalIncomeValues) {
      getTextValues(localRentalIncomeValues, rentalIncomeUnit).then(data =>
        setTextLocalRentalIncomeValues(data),
      )
    } else if (minRentalIncome && maxRentalIncome) {
      getTextValues([minRentalIncome, maxRentalIncome], rentalIncomeUnit).then(data =>
        setTextLocalRentalIncomeValues(data),
      )
    }
  }, [getTextValues, localRentalIncomeValues, maxRentalIncome, minRentalIncome, rentalIncomeUnit])

  /** Style */

  const s = useMemo(
    () => ({
      viewStyle: [styles.view, { backgroundColor: appUi }],
      scrollViewContainer: { flex: 1, padding: margin },
      bottomButtonContainer: [styles.bottomButtonContainer, { padding: margin }],
      secondSlider: { marginTop: spacing, marginBottom: spacing },
      button: [styles.view, { borderRadius: spacing / 2 }],
      indicator: { paddingTop: spacing },
      multiselect: { marginTop: spacing },
      color: { color: primary },
      bottomMultiSelect: { marginBottom: spacing * 3 },
      bottomSearchMultiSelect: { marginBottom: spacing },
      filterScreenClearButton: { color: primary, marginHorizontal: margin * 1.5 },
      noPortfolioTitle: { marginTop: (spacing * 3) / 4, marginBottom: spacing / 2 },
    }),
    [appUi, margin, primary, spacing],
  )

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Button onPress={resetFilterValues} mode="text" labelStyle={s.filterScreenClearButton}>
          {t("clearButtonText")}
        </Button>
      ),
      gestureEnabled: false,
    })
  }, [navigation, resetFilterValues, s.filterScreenClearButton, t])

  /** Child components props */

  const sliderProps: Item[] = [
    {
      title: t("sliderTitles.size"),
      min: minSurface,
      max: maxSurface,
      valuesArray: localSurfaceValues ?? [minSurface, maxSurface],
      displayText: textLocalSurfaceValues as [string, string],
      setValuesArray: setLocalSurfaceValues,
    },
    {
      title: t("sliderTitles.monetaryValue"),
      min: minMonetaryValue,
      max: maxMonetaryValue,
      valuesArray: localMonetaryValues ?? [minMonetaryValue, maxMonetaryValue],
      displayText: textLocalMonetaryValues as [string, string],
      setValuesArray: setLocalMonetaryValues,
      style: s.secondSlider,
    },
    {
      title: t("sliderTitles.rentalIncome"),
      min: minRentalIncome,
      max: maxRentalIncome,
      valuesArray: localRentalIncomeValues ?? [minRentalIncome, maxRentalIncome],
      displayText: textLocalRentalIncomeValues as [string, string],
      setValuesArray: setLocalRentalIncomeValues,
    },
  ]
  const multiSelectProps: Array<ComponentProps<typeof MultiSelect>> = [
    {
      title: t("selectTitles.classificationsTitle"),
      inputStyle: s.multiselect,
      containerStyle: s.multiselect,
      selectedItems: localSelectedClassifications,
      items: Classifications.map(c => ({ label: t(`classification.${c}`), value: c })),
      setSelectedItems: setLocalSelectedClassifications,
      modalTitle: t("selectTitles.classificationsModalTitle"),
      firstButtonText: t("selectModalButtons.cancel"),
      secondButtonText: t("selectModalButtons.select"),
    },
    {
      title: t("selectTitles.portfoliosTitle"),
      inputStyle: [s.multiselect, s.bottomMultiSelect],
      containerStyle: s.multiselect,
      selectedItems: localSelectedPortfolios,
      items: portfoliosInfo,
      setSelectedItems: setLocalSelectedPortfolios,
      modalTitle: t("selectTitles.portfoliosModalTitle"),
      firstButtonText: t("selectModalButtons.cancel"),
      secondButtonText: t("selectModalButtons.select"),
    },
  ]

  return (
    <ThemeProvider customTheme={{ colors: { overrides: { button: secondary } } }}>
      <SafeAreaView edges={["left", "right"]} style={s.viewStyle}>
        <ScrollView style={s.scrollViewContainer}>
          {sliderProps.map(item =>
            isValid(item) ? (
              <RangeSlider key={item.title} {...item} />
            ) : (
              <RangeSlider
                key={item.title}
                min={0}
                max={100}
                style={item.style}
                valuesArray={[0, 100]}
                title={item.title}
                displayText={["0", "0"]}
                setValuesArray={item.setValuesArray}
                enabledOne={false}
                enabledTwo={false}
              />
            ),
          )}
          <View style={s.bottomSearchMultiSelect}>
            <Subtitle1 style={[s.color, s.multiselect, s.bottomSearchMultiSelect]}>
              {title}
            </Subtitle1>
            <SearchMultiSelect {...{ onSelectedItemsChange }} />
          </View>
          {multiSelectProps.map((item, index) => {
            if (index === 1 && item.items.length === 0) {
              return (
                <>
                  <Subtitle1 style={s.noPortfolioTitle} color={primary}>
                    {item.title}
                  </Subtitle1>
                  <Caption>{t("noPortfolios")}</Caption>
                </>
              )
            }
            return <MultiSelect key={item.title} {...item} />
          })}
        </ScrollView>
        <BottomButtonContainer style={s.bottomButtonContainer}>
          <Button mode="contained" onPress={filterAndNavigate} uppercase={false} style={s.button}>
            {t("results")}
          </Button>
        </BottomButtonContainer>
      </SafeAreaView>
    </ThemeProvider>
  )
}

const styles = StyleSheet.create({
  view: { flex: 1 },
  bottomButtonContainer: { justifyContent: "center" },
})

export default AssetsFilterScreen
