import { useNavigation } from "@react-navigation/native"
import { logger, useTheme } from "@siruplab/capsule"
import React, { Dispatch, SetStateAction, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { View } from "react-native"
import { Menu, Provider } from "react-native-paper"

import { webRootRoutes } from "../features/Navigation/Constants"
import { ns } from "../screens/AssetMapScreen/i18n/en"

const ContextMenu = ({
  anchor,
  visible,
  setVisible,
  coordinates,
}: {
  anchor: { x: number; y: number }
  visible: boolean
  setVisible: Dispatch<SetStateAction<boolean>>
  coordinates: { latitude: number; longitude: number }
}) => {
  const { t } = useTranslation(ns)
  const navigation = useNavigation()
  const {
    typography: { body2 },
    dimensions: { spacing },
  } = useTheme()

  const s = useMemo(
    () => ({
      title: { fontSize: body2.fontSize },
      item: { height: spacing / 2, padding: spacing / 3 },
    }),
    [body2.fontSize, spacing],
  )
  const closeMenu = () => setVisible(false)
  const navToCreateAsset = () => {
    try {
      if (coordinates) {
        navigation.navigate(webRootRoutes.CREATE_ASSET, {
          coordinate: coordinates,
          fromMap: true,
        })
        closeMenu()
      }
    } catch (e) {
      logger("AddressTextInput", e)
    }
  }

  return (
    <Provider>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Menu visible={visible} onDismiss={closeMenu} anchor={anchor}>
          <Menu.Item
            title={t("createAsset")}
            onPress={navToCreateAsset}
            titleStyle={s.title}
            style={s.item}
          />
        </Menu>
      </View>
    </Provider>
  )
}

export default ContextMenu
