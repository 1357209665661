import { FirebaseFirestoreTypes } from "@react-native-firebase/firestore"
import { firestore } from "@siruplab/capsule"

import { collections } from "../../common/types"
import { formatMonetaryValue } from "../../utils/formatMonetaryValue"
import { Asset } from "./Asset"

// Avoid awaiting result of writing to the DB, it locks up the app waiting for the server, so it breaks offline mode
export const addAsset = (asset: Omit<Asset, "id">) =>
  firestore().collection(collections.ASSETS).add(asset)

// Overloads
export function updateAssetData(
  assetId: string | null | undefined,
  values: Partial<Asset>,
): Promise<void>
export function updateAssetData(
  // eslint-disable-next-line @typescript-eslint/unified-signatures
  assetRef: FirebaseFirestoreTypes.DocumentReference | null,
  values: Partial<Asset>,
): Promise<void>

export function updateAssetData(
  refOrId: FirebaseFirestoreTypes.DocumentReference | string | null | undefined,
  values: Partial<Asset>,
): Promise<void> {
  if (refOrId == null) {
    return Promise.resolve(undefined)
  }

  const assetDocRef =
    typeof refOrId === "string" ? firestore().collection(collections.ASSETS).doc(refOrId) : refOrId

  return assetDocRef?.set(values, { merge: true })
}

export const canEditFn = (roles?: Asset["roles"], uid?: string) =>
  ["owner", "editor"].includes(roles?.[uid ?? ""] ?? "")

export const getMoneyLabels = (locale: string) =>
  ["GBP", "EUR", "USD"].map(elem => ({
    label: formatMonetaryValue(locale, 0, elem, undefined, true),
    value: elem,
  }))

export const areaLabels = ["sqft", "m2"].map(elem => ({ label: elem, value: elem }))
