import * as React from "react"
import Svg, { G, Mask, Path, SvgProps } from "react-native-svg"

const WelcomeBg2SVG = (props: SvgProps) => (
  <Svg viewBox="0 0 650 616" fill="none" {...props}>
    <Mask id="prefix__a" x={0} y={0} width={650} height={616}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M650 307.734V321h-.297C642.367 484.803 499.799 615.469 325 615.469 150.201 615.469 7.634 484.803.297 321H0V0h650v307.734z"
        fill="#FFF"
      />
    </Mask>
    <G mask="url(#prefix__a)">
      <Path
        d="M943.24 688.648H525.283c-31.065 0-56.488-25.423-56.488-56.489v-37.088c0-31.065 25.423-56.488 56.488-56.488h33.475c31.065 0 56.488 25.423 56.488 56.488v648.479"
        stroke="#fff"
        strokeWidth={0.31}
        strokeMiterlimit={10}
      />
      <Path d="M220.844 943.639h630.184V471H220.844v472.639z" fill="#6A6D66" />
      <Path d="M220.845 943.639h179.83V615.011h-179.83v328.628z" fill="#9A9B98" />
      <Path opacity={0.5} d="M535.939 470.99H400.677V615h135.262V470.99z" fill="#32352F" />
      <Path
        d="M943.242 688.649H525.285c-31.065 0-56.488-25.423-56.488-56.489v-37.088c0-31.065 25.423-56.488 56.488-56.488h33.475c31.065 0 56.488 25.423 56.488 56.488v648.478"
        stroke="#fff"
        strokeWidth={0.31}
        strokeMiterlimit={10}
      />
      <Path d="M220.86 471.006h-630.185v472.638H220.86V471.006z" fill="#6A6D66" />
      <Path
        d="M231.116 615.613l-201.83-.064c-31.066 0-56.489-25.454-56.489-56.52V288.982c0-31.065-25.422-56.488-56.488-56.488l-69.961.031c-31.065 0-56.488-25.391-56.488-56.488v-37.088c0-31.066 25.423-56.489 56.488-56.489h33.475c31.066 0 56.489 25.423 56.489 56.489v182.683c0 31.066 25.422 56.489 56.488 56.489h228.045"
        stroke="#fff"
        strokeWidth={0.31}
        strokeMiterlimit={10}
      />
      <Path d="M220.86-1.626h-630.185v472.638H220.86V-1.626z" fill="#6A6D66" />
      <Path
        d="M97.186 177.654c-9.479-25.708-29.925-46.155-55.633-55.633V-1.639h179.292v179.293H97.185z"
        fill="#32352F"
      />
      <Path
        opacity={0.5}
        d="M97.186 177.653c3.74 10.112 5.769 21.08 5.769 32.492 0 51.86-42.033 93.894-93.893 93.894s-93.894-42.034-93.894-93.894c0-51.86 42.033-93.894 93.894-93.894 11.412 0 22.38 2.029 32.492 5.77v55.632h55.632z"
        fill="#32352F"
      />
      <Path
        d="M97.187 177.654H41.554v-55.633c25.74 9.51 46.155 29.925 55.633 55.633z"
        fill="#fff"
      />
      <Path
        d="M527.63 670.738h-648.475c-31.065 0-56.488 25.423-56.488 56.488v33.506c0 31.066 25.423 56.489 56.488 56.489h37.089c31.065 0 56.488-25.423 56.488-56.489V399.264c0-31.066 25.423-56.488 56.489-56.488h228.838c31.065 0 56.488 25.422 56.488 56.488v202.052"
        stroke="#fff"
        strokeWidth={0.31}
        strokeMiterlimit={10}
      />
      <Path
        d="M220.844-124.538c-67.875 0-122.899 55.023-122.899 122.898 0 67.876 55.024 122.899 122.899 122.899S343.742 66.236 343.742-1.639c0-67.876-55.023-122.899-122.898-122.899z"
        fill="#fff"
      />
      <Path d="M220.859 388.552H41.029v82.45h179.83v-82.45z" fill="#9A9B98" />
      <Path d="M220.844 470.999l630.185.001V-1.639H220.844V471z" fill="#6A6D66" />
      <Path
        opacity={0.5}
        d="M220.843 261.276c-115.827 0-209.723 93.896-209.723 209.723 0 115.827 93.896 209.723 209.723 209.723 115.827 0 209.724-93.896 209.724-209.723 0-115.827-93.897-209.723-209.724-209.723z"
        fill="#32352F"
      />
      <Path
        d="M527.631 670.737h-648.475c-31.065 0-56.488 25.423-56.488 56.489v33.506c0 31.065 25.423 56.488 56.488 56.488h37.088c31.066 0 56.489-25.423 56.489-56.488V399.263c0-31.065 25.423-56.488 56.488-56.488H258.06c31.066 0 56.489 25.423 56.489 56.488v202.052"
        stroke="#fff"
        strokeWidth={0.31}
        strokeMiterlimit={10}
      />
      <Path
        d="M220.845-124.538c-67.875 0-122.898 55.023-122.898 122.898s55.023 122.899 122.898 122.899S343.744 66.235 343.744-1.64 288.72-124.538 220.845-124.538z"
        fill="#9A9B98"
      />
      <Path
        d="M220.845-124.536c-67.875 0-122.898 55.023-122.898 122.898 0 67.876 55.023 122.899 122.898 122.899S343.744 66.238 343.744-1.638c0-67.875-55.024-122.898-122.899-122.898z"
        fill="#fff"
      />
      <Path
        d="M220.845-124.537c-67.875 0-122.898 55.024-122.898 122.899S152.97 121.26 220.845 121.26 343.744 66.237 343.744-1.638 288.72-124.537 220.845-124.537z"
        fill="#fff"
      />
      <Path
        d="M-409.341-1.639h832.395c31.065 0 56.488 25.424 56.488 56.489v116.654c0 31.065 25.423 56.488 56.488 56.488h201.26c31.065 0 56.488 25.423 56.488 56.488v45.014c0 31.065-25.423 56.488-56.488 56.488h-83.211c-31.065 0-56.488-25.423-56.488-56.488v-512.992"
        stroke="#fff"
        strokeWidth={0.31}
        strokeMiterlimit={10}
      />
    </G>
  </Svg>
)

export default WelcomeBg2SVG
