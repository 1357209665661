import { onboardingRoutes } from "../../../features/Navigation/Constants"

export const ns = onboardingRoutes.LOADING

export default {
  [ns]: {
    title: "Loading…",
    subtitle: "If the loading takes too long, please check your internet connection and try again.",
  },
}
