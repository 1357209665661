import { useNavigation } from "@react-navigation/native"
import {
  BottomButtonContainer,
  Button,
  ButtonType,
  firestore,
  generateShadow,
  H6,
  ThemeProvider,
  Touchable,
  useAlert,
  useTabLand,
  useTheme,
} from "@siruplab/capsule"
import _ from "lodash"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Platform, StyleSheet, View } from "react-native"
import { Searchbar } from "react-native-paper"
import { SafeAreaView } from "react-native-safe-area-context"

import { collections } from "../../common/types"
import AssetList from "../../components/AssetList"
import { ClearIcon, ClearSearchSVG } from "../../components/svg"
import { updatePortfolioData } from "../../features/models/PortfolioFunctions"
import { rootRoutes } from "../../features/Navigation/Constants"
import { useAssets } from "../../features/Providers/AssetProvider"
import usePortfolios from "../../features/Providers/PortfolioProvider"
import { ICON_SIZE, ICON_SIZE_SMALL, maxWidth } from "../../ThemeApp"
import { portfoliosRoutes } from "../MainScreen/Constants"
import { ns } from "./i18n/en"

const AssetSelectionScreen = () => {
  const {
    colors: {
      secondary,
      surface: { textInput },
      white: { highEmphasis: white },
      black: { mediumEmphasis },
    },
    dimensions: { spacing, margin },
    typography: { body2 },
  } = useTheme()

  const { t } = useTranslation(ns)

  const { assets, filteredDataBySearch, searchInput, setSearchInput, hasEditAccess } = useAssets()
  const { displayedPortfolioId, displayedPortfolio } = usePortfolios()

  /** States */
  const [selectedAssetsIds, setSelectedAssetsIds] = useState<string[]>([])
  const [filteredData, setFilteredData] = useState(assets)
  const navigation = useNavigation()
  const { showDialog } = useAlert()
  const isTabLand = useTabLand()

  /** Callbacks/functions */
  const updatePortfolioAndClose = useCallback(() => {
    if (
      !hasEditAccess(selectedAssetsIds) &&
      Object.keys(displayedPortfolio?.users ?? {}).length > 1
    ) {
      showDialog({
        type: "button",
        title: t("modalTitle"),
        message: t("modalDescription"),
        positive: {
          buttonStyle: styles.dialogRightButton,
          type: ButtonType.POSITIVE,
          label: t(`common:button.ok`),
          labelType: "destructive",
        },
        style: isTabLand ? { margin: "auto", maxWidth } : {},
      })
      return
    }
    // noinspection JSIgnoredPromiseFromCall
    updatePortfolioData(
      firestore()
        .collection(collections.PORTFOLIOS)
        .doc(displayedPortfolioId ?? ""),
      {
        assets: [...(displayedPortfolio?.assets ?? []), ...selectedAssetsIds],
      },
    )
    setSelectedAssetsIds([])
    navigation.navigate(rootRoutes.MAIN_NAVIGATOR)
  }, [
    displayedPortfolio,
    displayedPortfolioId,
    hasEditAccess,
    isTabLand,
    navigation,
    selectedAssetsIds,
    showDialog,
    t,
  ])

  /** Style */
  const s = useMemo(
    () => ({
      firstLine: [
        styles.firstLine,
        {
          paddingTop: spacing,
          borderColor: textInput,
          paddingHorizontal: spacing,
        },
      ],
      portfolioName: [
        styles.portfolioName,
        {
          paddingBottom: spacing,
          paddingLeft: spacing,
        },
      ],
      bottomButtonContainer: [
        styles.bottomButtonContainer,
        {
          padding: margin,
          backgroundColor: white,
          borderBottomLeftRadius: margin / 2,
          borderBottomRightRadius: margin / 2,
        },
      ],
      button: [styles.buttonView, { borderRadius: spacing / 2 }],
      searchBar: [
        {
          borderRadius: margin / 2,
          margin: margin / 2,
        },
        styles.searchbar,
      ],
      input: [
        {
          fontSize: body2.fontSize,
          ...(Platform.OS === "android"
            ? {
                paddingTop: spacing / 4,
                marginTop: margin / 4,
              }
            : {}),
        },
        styles.searchBarInputStyle,
      ],
    }),
    [body2, margin, spacing, textInput, white],
  )

  /** useEffects */
  useEffect(() => {
    setFilteredData(
      _.filter(
        searchInput.length === 0 ? assets : filteredDataBySearch,
        asset => !displayedPortfolio?.assets?.includes(asset.id),
      ),
    )
  }, [assets, displayedPortfolio, filteredDataBySearch, searchInput])

  // useEffect to clear search input on show, and on hide
  useEffect(() => {
    setSearchInput("")
    return () => {
      setSearchInput("")
    }
  }, [setSearchInput])

  return (
    <ThemeProvider
      customTheme={{
        colors: {
          overrides: { button: secondary },
        },
      }}
    >
      <SafeAreaView style={{ flex: 1 }} edges={["left", "right", "top"]}>
        <View style={s.firstLine}>
          <Touchable
            onPress={() => {
              navigation.navigate(rootRoutes.MAIN_NAVIGATOR)
            }}
          >
            <ClearIcon />
          </Touchable>
          <View style={s.portfolioName}>
            <H6 numberOfLines={2} ellipsizeMode="tail">
              {t("selectAssets")}
            </H6>
          </View>
        </View>
        <Searchbar
          value={searchInput}
          iconColor={mediumEmphasis}
          style={s.searchBar}
          inputStyle={s.input}
          onChangeText={setSearchInput}
          placeholder={t("placeholder")}
          clearIcon={
            searchInput.length === 0
              ? undefined
              : () => <ClearSearchSVG width={ICON_SIZE} height={ICON_SIZE} />
          }
        />
        <AssetList
          navPath={portfoliosRoutes.ASSET_VIEW}
          data={filteredData}
          isEmpty={_.isEmpty(filteredData)}
          isPortfolioView={false}
          selectedAssetsIds={selectedAssetsIds}
          setSelectedAssetsIds={setSelectedAssetsIds}
          isModal={true}
        />
        <BottomButtonContainer style={s.bottomButtonContainer}>
          <Button
            mode="contained"
            onPress={updatePortfolioAndClose}
            uppercase={false}
            style={s.button}
            disabled={selectedAssetsIds.length === 0}
          >
            {t("common:actions.select")}
          </Button>
        </BottomButtonContainer>
      </SafeAreaView>
    </ThemeProvider>
  )
}

const styles = StyleSheet.create({
  view: {
    flex: 1,
    paddingBottom: 0,
  },
  buttonView: {
    flex: 1,
  },
  portfolioName: { flex: 1 },
  searchbar: {
    height: 46,
    borderWidth: 1,
    marginBottom: 0,
    borderColor: "transparent",
  },
  dialogRightButton: {
    marginLeft: 0,
    flex: 0,
  },
  searchBarInputStyle: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    includeFontPadding: false,
    textAlignVertical: "center",
    height: "100%",
  },
  iconStyle: { borderRadius: 0 },
  clearIconStyle: { width: ICON_SIZE_SMALL, height: ICON_SIZE_SMALL },
  firstLine: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-start",
    borderBottomWidth: 1,
  },
  bottomButtonContainer: {
    justifyContent: "center",
    ...generateShadow(2),
  },
})

export default AssetSelectionScreen
