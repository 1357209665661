import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

const FiltersSVG = (props: SvgProps) => (
  <Svg width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 5v1.667h15V5h-15zm5.833 10h3.334v-1.667H8.333V15zM15 10.833H5V9.167h10v1.666z"
      fill={props.color || "#32352F"}
    />
  </Svg>
)

export default FiltersSVG
