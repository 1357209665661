import * as React from "react"
import Svg, { G, Mask, Path, SvgProps } from "react-native-svg"

const WelcomeBg1SVG = (props: SvgProps) => (
  <Svg viewBox="0 0 650 616" fill="none" {...props}>
    <Mask id="prefix__a" x={0} y={0} width={650} height={616}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M650 307.734V321h-.297C642.367 484.803 499.799 615.469 325 615.469 150.201 615.469 7.634 484.803.297 321H0V0h650v307.734z"
        fill="#FFF"
      />
    </Mask>
    <G mask="url(#prefix__a)">
      <Path d="M708.526-26.514H-50.151v569.007h758.677V-26.514z" fill="#6A6D66" />
      <Path
        d="M-50.15 189.334c119.21 0 215.848-96.638 215.848-215.848 0-119.21-96.639-215.849-215.849-215.849S-266-145.724-266-26.514s96.639 215.848 215.85 215.848z"
        fill="#9A9B98"
      />
      <Path
        d="M559.653 326.644c4.503-12.174 6.945-25.378 6.945-39.117 0-62.434-50.603-113.038-113.038-113.038-62.434 0-113.038 50.604-113.038 113.038s50.604 113.038 113.038 113.038c13.739 0 26.943-2.442 39.117-6.945v-66.976h66.976z"
        fill="#4E514A"
      />
      <Path
        d="M559.651 326.645h-66.976v66.975c30.95-11.41 55.565-36.025 66.976-66.975z"
        fill="#4E514A"
      />
      <Path
        d="M51.743 446.857v-94.682c-30.377-16.295-65.029-25.53-101.894-25.53-119.221 0-215.849 96.628-215.849 215.848 0 119.221 96.628 215.849 215.849 215.849 119.22 0 215.849-96.628 215.849-215.849 0-34.346-8.015-66.785-22.287-95.636H51.743z"
        fill="#9A9B98"
      />
      <Path
        d="M144.822 449.796c-20.951-42.285-54.687-77.127-96.208-99.376v99.376h96.208z"
        fill="#000"
      />
      <Path
        d="M708.526 690.45c81.714 0 147.957-66.243 147.957-147.957 0-81.715-66.243-147.957-147.957-147.957-81.715 0-147.958 66.242-147.958 147.957 0 81.714 66.243 147.957 147.958 147.957z"
        fill="#fff"
      />
      <Path d="M708.526-26.514H492.028v99.261h216.498v-99.261z" fill="#9A9B98" />
      <Path
        opacity={0.5}
        d="M708.527 225.97c139.443 0 252.485-113.041 252.485-252.485C961.012-165.959 847.97-279 708.527-279c-139.444 0-252.485 113.041-252.485 252.485 0 139.444 113.041 252.485 252.485 252.485z"
        fill="#32352F"
      />
      <Path
        d="M708.528 690.45c81.714 0 147.957-66.243 147.957-147.957 0-81.715-66.243-147.957-147.957-147.957-81.715 0-147.958 66.242-147.958 147.957 0 81.714 66.243 147.957 147.958 147.957z"
        fill="#000"
      />
      <Path d="M708.526 542.493H-50.151V1111.5h758.677V542.493z" fill="#6A6D66" />
      <Path
        d="M106.469 639.922c11.487 0 22.173 3.282 31.255 8.931 17.784-31.37 27.974-67.663 27.974-106.322 0-119.221-96.629-215.849-215.85-215.849-119.22 0-215.848 96.628-215.848 215.849 0 119.221 96.628 215.811 215.849 215.811 38.659 0 74.952-10.19 106.321-27.974-5.648-9.082-8.93-19.768-8.93-31.255.038-32.706 26.523-59.191 59.229-59.191zM708.526 542.493H492.028v395.634h216.498V542.493z"
        fill="#9A9B98"
      />
      <Path
        d="M708.525 690.45c81.714 0 147.957-66.243 147.957-147.957 0-81.715-66.243-147.957-147.957-147.957-81.715 0-147.958 66.242-147.958 147.957 0 81.714 66.243 147.957 147.958 147.957z"
        fill="#fff"
      />
      <Path
        opacity={0.5}
        d="M708.526 690.45c81.714 0 147.957-66.243 147.957-147.958 0-81.714-66.243-147.957-147.957-147.957-81.715 0-147.958 66.243-147.958 147.957 0 81.715 66.243 147.958 147.958 147.958z"
        fill="#32352F"
      />
      <Path
        d="M-115.029 995.576l.076-242.982c0-37.4 30.645-68.006 68.044-68.007H278.2c37.4 0 68.006-30.606 68.006-68.006l-.038-84.225c0-37.4 30.569-68.006 68.006-68.006h44.651c37.399 0 68.006 30.606 68.006 68.006v40.3c0 37.399-30.607 68.006-68.006 68.006H238.893c-37.4 0-68.007 30.607-68.007 68.006v274.543"
        stroke="#fff"
        strokeWidth={0.31}
        strokeMiterlimit={10}
      />
    </G>
  </Svg>
)

export default WelcomeBg1SVG
