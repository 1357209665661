export const images = {
  assets: require("./assets.png"),
  logo: require("./logo.png"),
  map_default: require("./map_default.png"),
  map_pin: require("./map_pin.png"),
  map_pin_web: require("./map_pin_web.png"),
  map_pin_dis: require("./map_pin_dis.png"),
  map_pin_dis_web: require("./map_pin_dis_web.png"),
  map_satellite: require("./map_satellite.png"),
}
