import Bugsnag from "@bugsnag/react-native"
import { crashlytics, logger } from "@siruplab/capsule"
import _ from "lodash"

import { config, version } from "../../../package.json"
import isWeb from "../../utils/isWeb"

const bugsnag =
  // @ts-ignore
  isWeb
    ? Bugsnag.start({
        // @ts-ignore
        apiKey: config.bugsnagKey,
        appVersion: `$version-${version}`,
      })
    : Bugsnag.start()

const reportError = (errorCode: number, errorMessage: string, err?: Error): void => {
  const error =
    err ??
    new Error(
      errorCode + " - " + !_.isString(errorMessage) ? JSON.stringify(errorMessage) : errorMessage,
    )
  bugsnag.leaveBreadcrumb("reportError", { errorCode, errorMessage }, "error")
  crashlytics().recordError(error)
  bugsnag.notify(error)
  logger("reportError", errorCode, errorMessage)
}

const errorCodes = {
  notificationUrl: 10,
  settingsUrl: 11,
  linkHandlingError: 12,
  emailNotFound: 13,
  recoUrl: 14,
  newsUrl: 15,
  safeLinking: 16,
  iap: 17,
  sessionDecrement: 18,
  sessionStart: 19,
  sessionStarted: 20,
}

export default { reportError, errorCodes, bugsnag }
