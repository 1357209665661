import { LatLng, Region } from "react-native-maps"

export const getRegionForCoordinates = (points: LatLng[]): Region => {
  let minX = points[0].latitude
  let maxX = points[0].latitude
  let minY = points[0].longitude
  let maxY = points[0].longitude

  points.map(point => {
    minX = Math.min(minX, point.latitude)
    maxX = Math.max(maxX, point.latitude)
    minY = Math.min(minY, point.longitude)
    maxY = Math.max(maxY, point.longitude)
  })

  return {
    latitude: (minX + maxX) / 2,
    longitude: (minY + maxY) / 2,
    latitudeDelta: maxX - minX,
    longitudeDelta: maxY - minY,
  }
}
