import { RouteProp, useRoute } from "@react-navigation/native"
import {
  auth,
  Button,
  ButtonType,
  Caption,
  delay,
  functions,
  IRowItemProps,
  ISectionHeader,
  IUserContext,
  logger,
  ParametersScreen,
  useAlert,
  userContext,
  useTabTwoPane,
  useTheme,
  VectorIcon,
} from "@siruplab/capsule"
import _ from "lodash"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { SectionListData, StyleSheet, View } from "react-native"
import { ActivityIndicator } from "react-native-paper"

import { config, version } from "../../../package.json"
import UserInfoInputModal from "../../components/UserInfoInputModal"
import { UserData } from "../../features/models/UserData"
import { ICON_SIZE } from "../../ThemeApp"
import { ns as CompanyScreenNamespace } from "../CompanyScreen/i18n/en"
import { accountRoutes } from "../MainScreen/Constants"
import { IMainParamList } from "../MainScreen/MainNavigator"
import { ns as NamescreenNamespace } from "../NameScreen/i18n/en"
import { leftChild, rightChild } from "./Constants"
import { ns } from "./i18n/en"

const ProfileScreen = () => {
  const {
    colors: { surface, black, primary },
    typography: { subtitle1, body2 },
    dimensions: { spacing },
  } = useTheme()
  const { params } = useRoute<RouteProp<IMainParamList, "Account">>()
  const { logout, userData } = useContext<IUserContext<UserData>>(userContext)
  const { showDialog, setIsValid } = useAlert()
  const [nameModalVisible, setNameModalVisible] = useState(false)
  const [companyModalVisible, setCompanyModalVisible] = useState(false)
  const [isCompanyEdit, setIsCompanyEdit] = useState(false)
  const isTabTwoPane = useTabTwoPane()
  const { showSnack } = useAlert()
  const { t } = useTranslation(ns)

  const hideNameModal = useCallback(() => {
    setNameModalVisible(false)
  }, [])

  const showNameModal = useCallback(() => {
    setNameModalVisible(true)
  }, [])

  const hideCompanyModal = useCallback(() => {
    setCompanyModalVisible(false)
  }, [])

  const showCompanyModal = useCallback((isCompany: boolean) => {
    setIsCompanyEdit(isCompany)
    setCompanyModalVisible(true)
  }, [])

  const s = useMemo(
    () => ({
      buttonLabel: {
        color: primary,
      },
      button: [
        styles.button,
        {
          marginTop: spacing,
        },
      ],
      leftIcon: {
        tintColor: black.highEmphasis,
      },
      version: {
        typography: body2,
      },
      rightChildText: {
        marginRight: spacing / 2,
        color: black.mediumEmphasis,
      },
      dialogTextColor: {
        color: black.mediumEmphasis,
      },
      separator: {
        paddingTop: spacing,
      },
    }),
    [black.highEmphasis, black.mediumEmphasis, body2, primary, spacing],
  )

  const onLogoutPress = useCallback(() => {
    setIsValid?.(true)
    showDialog({
      type: "button",
      title: t("logout"),
      message: t(`disconnect`),
      messageStyle: s.dialogTextColor,
      positive: {
        buttonStyle: styles.dialogRightButton,
        onPress: logout,
        type: ButtonType.POSITIVE,
        label: t(`confirm`),
        labelType: "destructive",
      },
      negative: {
        buttonStyle: styles.dialogLeftButton,
        type: ButtonType.NEGATIVE,
        textStyle: s.dialogTextColor,
        label: t(`common:actions.cancel`),
      },
    })
  }, [logout, s.dialogTextColor, setIsValid, showDialog, t])

  const deleteAccount = useCallback(async () => {
    try {
      await Promise.race([functions().httpsCallable("deleteAccount")(), delay(1000)])
      logger("Account deleting, logging out")
      await logout()
    } catch (e) {
      logger("Account deletion failed", e)
      showSnack(t("deleteAccountError"))
    }
  }, [logout, showSnack, t])

  const onDeleteAccountPress = useCallback(() => {
    setIsValid?.(true)
    showDialog({
      type: "button",
      title: t("deleteAccount"),
      message: t(`deleteAccountMessage`),
      messageStyle: s.dialogTextColor,
      positive: {
        buttonStyle: styles.dialogRightButton,
        onPress: deleteAccount,
        type: ButtonType.POSITIVE,
        label: t(`confirmDeleteAccount`),
        labelType: "destructive",
      },
      negative: {
        buttonStyle: styles.dialogLeftButton,
        type: ButtonType.NEGATIVE,
        textStyle: s.dialogTextColor,
        label: t(`common:actions.cancel`),
      },
    })
  }, [deleteAccount, s.dialogTextColor, setIsValid, showDialog, t])

  const renderRightChild = useCallback(
    (text, showIcon) => (
      <View style={styles.rightChildContainer}>
        <Caption style={s.rightChildText}>{text}</Caption>
        {showIcon ? (
          <VectorIcon
            name="chevron-right"
            category="MaterialIcons"
            color={black.mediumEmphasis}
            size={ICON_SIZE}
          />
        ) : null}
      </View>
    ),
    [black.mediumEmphasis, s.rightChildText],
  )

  const [hasPassword, setHasPassword] = useState<boolean>()

  useEffect(() => {
    ;(async () => {
      if (params?.password || params?.password === undefined) {
        const authMethods = await auth().fetchSignInMethodsForEmail(userData?.email ?? "")
        setHasPassword(_.includes(authMethods, "password"))
      }
    })()
  }, [params, userData])

  const editionItems = useMemo(
    () => [
      {
        name: t("email"),
        rightChild: renderRightChild(userData?.email ?? "", false),
      },
      {
        name: t("password"),
        rightChild:
          hasPassword === undefined ? (
            <ActivityIndicator />
          ) : (
            renderRightChild(t(hasPassword ? "hasPassword" : "setPassword"), !hasPassword)
          ),
        route: hasPassword ? undefined : accountRoutes.SET_PASSWORD,
        params: hasPassword
          ? undefined
          : {
              email: userData?.email,
            },
      },
      {
        name: t("profile"),
        rightChild: renderRightChild(
          t("completeName", {
            firstName: userData?.firstName ?? "",
            lastName: userData?.lastName ?? "",
          }),
          true,
        ),
        route: isTabTwoPane ? undefined : accountRoutes.NAMES_EDIT,
        onPress: isTabTwoPane ? showNameModal : undefined,
      },
      {
        name: t("company"),
        rightChild: renderRightChild(userData?.companyName ?? "", true),
        route: isTabTwoPane ? undefined : accountRoutes.COMPANY_EDIT,
        onPress: isTabTwoPane ? () => showCompanyModal(true) : undefined,
      },
      {
        name: t("domaine"),
        rightChild: renderRightChild("", true),
        route: accountRoutes.DOMAINE,
      },
    ],
    [hasPassword, isTabTwoPane, renderRightChild, showCompanyModal, showNameModal, t, userData],
  )

  const linkItems = useMemo(
    () => [
      {
        name: t("common:parameters.termsOfUse"),
        iconName: "check",
        rightChild: renderRightChild("", true),
        url: t("common:urls.tos"),
      },
      {
        name: t("common:parameters.privacy"),
        rightChild: renderRightChild("", true),
        iconName: "lock",
        url: t("common:urls.privacy"),
      },
      {
        name: t("common:parameters.help"),
        rightChild: renderRightChild("", true),
        iconName: "info",
        url: t("common:urls.help"),
      },
    ],
    [renderRightChild, t],
  )

  const middleItems = useMemo(
    () => [
      {
        name: t("inviteTeammates"),
        rightChild: renderRightChild("", true),
        route: isTabTwoPane ? undefined : accountRoutes.TEAMMATES_EDIT,
        onPress: isTabTwoPane ? () => showCompanyModal(false) : undefined,
      },
    ],
    [isTabTwoPane, renderRightChild, showCompanyModal, t],
  )

  const common = useCallback(
    item => ({
      textStyle: subtitle1,
      color: black.highEmphasis,
      name: item?.name,
      rowStyle: styles.row,
      rightChild: item?.rightChild ?? rightChild,
      leftChild: item?.leftChild ?? {
        ...leftChild,
        name: item?.iconName,
        color: black.mediumEmphasis,
      },
    }),
    [black, subtitle1],
  )

  const settingsItems: Array<SectionListData<IRowItemProps, ISectionHeader>> = useMemo(
    () => [
      {
        data: _.map(editionItems, item => ({
          ...common(item),
          ...item,
        })),
        shadows: 1,
        title: "",
      },
      {
        data: [
          {
            name: "separator",
            render: () => <View style={s.separator} />,
          },
        ],
        title: "",
      },
      {
        data: _.map(middleItems, item => ({
          ...common(item),
          ...item,
        })),
        shadows: 1,
        title: "",
      },
      {
        data: [
          {
            name: "separator",
            render: () => <View style={s.separator} />,
          },
        ],
        title: "",
      },
      {
        data: _.map(linkItems, item => ({
          ...common(item),
          url: item?.url,
        })),
        shadows: 1,
        title: "",
      },
    ],
    [common, editionItems, linkItems, middleItems, s],
  )

  const footerComponent = useMemo(
    () => (
      <>
        <Button style={s.button} labelStyle={s.buttonLabel} mode="text" onPress={onLogoutPress}>
          {t("logoutButton")}
        </Button>
        <Button
          style={s.button}
          labelStyle={s.buttonLabel}
          mode="text"
          onPress={onDeleteAccountPress}
        >
          {t("deleteAccountButton")}
        </Button>
      </>
    ),
    [onDeleteAccountPress, onLogoutPress, s.button, s.buttonLabel, t],
  )

  return (
    <>
      <ParametersScreen
        showSeparator
        {...{ footerComponent }}
        sections={settingsItems}
        color={black.mediumEmphasis}
        version={`${version}-${config.build ?? "0"}`}
        optionalViewStyle={{
          borderTopWidth: 1,
          borderTopColor: surface.textInput,
        }}
      />
      {isTabTwoPane ? (
        <>
          <UserInfoInputModal
            hideModal={hideNameModal}
            visible={nameModalVisible}
            isNameEdit
            isCompanyEdit={false}
            namespace={NamescreenNamespace}
          />
          <UserInfoInputModal
            hideModal={hideCompanyModal}
            visible={companyModalVisible}
            isCompanyEdit={isCompanyEdit}
            isNameEdit={false}
            namespace={CompanyScreenNamespace}
          />
        </>
      ) : null}
    </>
  )
}

const styles = StyleSheet.create({
  row: {
    height: 56,
  },
  button: {
    width: "100%",
  },
  rightChildContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  dialogLeftButton: {
    marginRight: 0,
    flex: 0,
  },
  dialogRightButton: {
    marginLeft: 0,
    flex: 0,
  },
})

export default ProfileScreen
