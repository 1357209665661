import { rootRoutes } from "../../../features/Navigation/Constants"

const ns = rootRoutes.MAIN_NAVIGATOR

export default {
  [ns]: {
    tabs: {
      assets: "Actifs",
      portfolios: "Portfolios",
      account: "Compte",
    },
  },
}
