import {
  H6,
  IUserContext,
  PresentationListView,
  Subtitle1,
  userContext,
  useTabTwoPane,
  useTheme,
} from "@siruplab/capsule"
import _ from "lodash"
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react"
import { useTranslation } from "react-i18next"
import { FlatList, StyleSheet, View } from "react-native"

import { Asset } from "../features/models/Asset"
import { canEditFn } from "../features/models/AssetsFunctions"
import { UserData } from "../features/models/UserData"
import { useAssets } from "../features/Providers/AssetProvider"
import { ns } from "../screens/AssetListScreen/i18n/en"
import AssetCard from "./AssetCard"

interface Props {
  isEmpty: boolean
  isPortfolioView: boolean
  isModal?: boolean
  navPath?: string
  data: Asset[]
  selectedAssetsIds: string[]
  setSelectedAssetsIds: Dispatch<SetStateAction<string[]>>
  error?: Error
  uniqueSelection?: boolean
  limitSelection?: boolean
}

const keyExtractor = (elem: Asset) => elem.id

const AssetList = ({
  isEmpty,
  isPortfolioView,
  data,
  navPath,
  selectedAssetsIds,
  setSelectedAssetsIds,
  error,
  uniqueSelection = false,
  limitSelection = false,
}: Props) => {
  const { t } = useTranslation(ns)
  const {
    colors: { primary },
    dimensions: { spacing, margin },
  } = useTheme()

  const { selectedAsset, canDoAll } = useAssets()
  const { user } = useContext<IUserContext<UserData>>(userContext)
  const isTabTwoPane = useTabTwoPane()

  const s = useMemo(
    () => ({
      indicator: { paddingTop: spacing },
      infoEmpty: [styles.infoEmpty, { padding: spacing }],
      infoEmptyTitle: { marginTop: spacing / 4 },
      infoEmptyText: [styles.infoEmptyText, { marginTop: spacing / 2 }],
      itemWrapperStyle: { marginBottom: spacing / 2 },
      listStyle: [styles.listStyle, { marginTop: spacing / 2, paddingHorizontal: margin / 2 }],
    }),
    [margin, spacing],
  )

  const onPress = useCallback(
    (assetId: string) => {
      if (uniqueSelection) {
        setSelectedAssetsIds(prevState => (_.includes(prevState, assetId) ? [] : [assetId]))
      } else {
        setSelectedAssetsIds(prevState =>
          _.includes(prevState, assetId)
            ? prevState.filter(item => item !== assetId)
            : [...prevState, assetId],
        )
      }
    },
    [setSelectedAssetsIds, uniqueSelection],
  )

  const renderItem = useCallback(
    ({ item: asset }) => (
      <AssetCard
        navPath={navPath}
        showCheckbox={limitSelection ? canEditFn(asset, user?.uid) || canDoAll : true}
        asset={asset}
        selected={selectedAssetsIds.includes(asset.id)}
        onCheckboxPress={() => onPress(asset.id)}
        wrapperStyle={s.itemWrapperStyle}
      />
    ),
    [canDoAll, limitSelection, navPath, onPress, s, selectedAssetsIds, user],
  )

  const childInfo = useMemo(
    () => (
      <View style={s.infoEmpty}>
        <H6 style={s.infoEmptyTitle}>{t("emptyMessageTitle")}</H6>
        <Subtitle1 style={s.infoEmptyText}>
          {t(isPortfolioView ? "emptyPortfolioSubtitle" : "emptyMessageSubtitle")}
        </Subtitle1>
      </View>
    ),
    [isPortfolioView, s, t],
  )

  const listRef = useRef<FlatList>(null)

  useEffect(() => {
    const index = _.findIndex(data, elem => elem.id === selectedAsset)
    if (selectedAsset && isTabTwoPane && index >= 0) {
      listRef.current?.scrollToIndex({
        index,
      })
    }
  }, [data, isTabTwoPane, selectedAsset])

  return (
    <PresentationListView
      indicator={{ color: primary, size: "small" }}
      isEmpty={isEmpty}
      childInfo={childInfo}
      error={error}
    >
      <FlatList
        ref={listRef}
        style={s.listStyle}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        extraData={selectedAssetsIds}
        onScrollToIndexFailed={_.noop}
        data={data}
      />
    </PresentationListView>
  )
}

export default AssetList

const styles = StyleSheet.create({
  view: { flex: 1, paddingBottom: 0 },
  infoEmpty: { alignItems: "center" },
  infoEmptyText: { textAlign: "center" },
  listStyle: { width: "100%", flex: 1 },
})
