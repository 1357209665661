import { useNavigation } from "@react-navigation/native"
import { MaterialCommunityIcons, useTheme } from "@siruplab/capsule"
import React from "react"
import { useTranslation } from "react-i18next"
import { Platform } from "react-native"
import { View } from "react-native-animatable"
import { Chip, Searchbar as NativeSearchbar } from "react-native-paper"

import { rootRoutes } from "../features/Navigation/Constants"
import { useAssetFilter } from "../features/Providers/AssetFilterProvider"
import { useAssets } from "../features/Providers/AssetProvider"
import { ns } from "../screens/AssetMapScreen/i18n/en"
import { ICON_SIZE, ICON_SIZE_SMALL } from "../ThemeApp"
import isWeb from "../utils/isWeb"
import { useStyles } from "../utils/useStyles"
import { ClearSearchSVG } from "./svg"

const Searchbar = () => {
  const {
    colors: {
      black: { mediumEmphasis: blackMediumEmphasis },
    },
  } = useTheme()
  const { t } = useTranslation(ns)

  const { setSearchInput, searchInput, setSelectedAsset } = useAssets()

  const { filterNumber } = useAssetFilter()

  const s = useStyles(
    ({
      colors: {
        black: { transparent, separator, highEmphasis: black },
        surface: { card },
        white: { highEmphasis: white },
      },
      dimensions: { spacing },
      typography: { body2 },
    }) => ({
      icon: {
        ...(Platform.OS === "ios" ? { marginTop: spacing / 4 } : {}),
        marginRight: -(spacing / 2.5),
        textAlignVertical: "center",
      },
      chip: {
        height: 32,
        borderWidth: 1,
        borderColor: separator,
        backgroundColor: filterNumber > 0 ? card : white,
        alignItems: "center",
        justifyContent: "center",
      },
      chipLabel: {
        ...(Platform.OS === "android" ? body2 : {}),
        ...(isWeb
          ? {
              lineHeight: 24,
            }
          : {}),
        color: black,
        textAlignVertical: "center",
        includeFontPadding: false,
      },
      searchBar: {
        flex: 1,
        height: 36,
        elevation: 0,
        borderWidth: 1,
        borderRadius: 40,
        marginRight: spacing,
        borderColor: separator,
        shadowColor: transparent,
      },
      headerView: {
        flex: 1,
        height: 50,
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: white,
        paddingVertical: spacing / 2,
        marginHorizontal: spacing / 2,
      },
      input: {
        height: "100%",
        paddingLeft: 0,
        fontSize: body2.fontSize,
        ...(Platform.OS === "android"
          ? {
              paddingTop: 4,
              marginTop: 4,
            }
          : {}),
      },
    }),
    [filterNumber],
  )

  const navigation = useNavigation()

  const navigateToFilters = () => {
    navigation.navigate(rootRoutes.ASSETS_FILTER)
  }

  return (
    <View style={s.headerView}>
      <NativeSearchbar
        value={searchInput}
        iconColor={blackMediumEmphasis}
        style={s.searchBar}
        inputStyle={s.input}
        onFocus={() => setSelectedAsset(undefined)}
        onChangeText={setSearchInput}
        placeholder={t("placeholder")}
        clearIcon={
          searchInput.length === 0
            ? undefined
            : () => <ClearSearchSVG width={ICON_SIZE} height={ICON_SIZE} />
        }
      />
      <Chip
        avatar={
          <MaterialCommunityIcons size={ICON_SIZE_SMALL} style={s.icon} name="filter-variant" />
        }
        onPress={navigateToFilters}
        style={s.chip}
        textStyle={s.chipLabel}
      >
        {filterNumber === 0 ? t("zeroFilters") : t("filters", { count: filterNumber })}
      </Chip>
    </View>
  )
}

export default Searchbar
