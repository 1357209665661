import { portfoliosRoutes } from "../../MainScreen/Constants"

export const ns = portfoliosRoutes.LIST

export default {
  [ns]: {
    assetSelected: "{{count}} asset selected",
    assetSelected_plural: "{{count}} assets selected",
    emptyMessageTitle: "No Assets Found",
    emptyPortfolioSubtitle: "Create assets directly in this portfolio from here",
    addAsset: "Add asset",
    existingAsset: "Add existing assets",
    newAsset: "Add new asset",
    delete: "Delete portfolio",
    modalTitle: "Cannot share portfolio",
    modalDescription: "You need to be editor of all assets before you can share this portfolio.",
    remove: "Remove selected assets",
    errorMessage: "Error displaying portfolio",
    errorDetails: "There was an error displaying the assets for this portfolio",
    deleteTitle: "Delete {{name}}?",
    deleteMessage: "Are you sure you want to delete this portfolio?",
  },
}
