import React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

const SVGComponent = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" {...props}>
    <Path
      d="m19.082 13.166-5.8 3.478a2.5 2.5 0 0 1-2.57 0l-5.794-3.478-.675.4a.5.5 0 0 0 0 .858l7.5 4.5a.5.5 0 0 0 .514 0l7.5-4.5a.5.5 0 0 0 0-.858l-.675-.4z"
      fill="#32352F"
      fillOpacity={0.72}
    />
    <Path
      d="m19.757 9.571-7.5-4.5a.5.5 0 0 0-.514 0l-7.5 4.5a.5.5 0 0 0 0 .858l7.5 4.5a.5.5 0 0 0 .514 0l7.5-4.5a.5.5 0 0 0 0-.858z"
      fill="#32352F"
      fillOpacity={0.72}
    />
  </Svg>
)

export default SVGComponent
