import { FirebaseFirestoreTypes } from "@react-native-firebase/firestore"
import { firestore, logger } from "@siruplab/capsule"

import { collections } from "../../common/types"
import { Portfolio } from "./Portfolio"

// Avoid awaiting result of writing to the DB, it locks up the app waiting for the server, so it breaks offline mode
export const addPortfolio = (portfolio: Omit<Portfolio, "id">) =>
  firestore()
    .collection(collections.PORTFOLIOS)
    .add(portfolio)
    .catch(e => logger("add portfolio error", e))

export const updatePortfolioData = async (
  portfolioDocRef: FirebaseFirestoreTypes.DocumentReference<FirebaseFirestoreTypes.DocumentData> | null,
  values: Partial<Portfolio>,
): Promise<void> => {
  try {
    await portfolioDocRef?.set(values, { merge: true })
  } catch (e) {
    logger("update portfolio error", e)
  }
}
