import { useNavigation } from "@react-navigation/native"
import {
  auth,
  Body2,
  Button,
  COMMON_NAMESPACE,
  getFooterHeight,
  KeyboardAvoidView,
  logger,
  StylizedTranslatedText,
  TextInput,
  ThemeProvider,
  useAlert,
  userContext,
  useTheme,
} from "@siruplab/capsule"
import _ from "lodash"
import React, { useCallback, useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { Keyboard, StyleSheet, TouchableOpacity, View } from "react-native"
import { Snackbar, TextInput as PaperInput } from "react-native-paper"
import { SafeAreaView } from "react-native-safe-area-context"

import { onboardingRoutes } from "../../features/Navigation/Constants"
import { useStyles } from "../../utils/useStyles"
import { ns } from "./i18n/en"

const LoginScreen = () => {
  const { t } = useTranslation([ns, COMMON_NAMESPACE])
  const {
    colors: { primary },
  } = useTheme()
  const { showSnack } = useAlert()
  const navigation = useNavigation()
  const [email, setEmail] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const { error, setError } = useContext(userContext)
  const [loading, setLoading] = useState(false)

  const onNext = async () => {
    try {
      setLoading(true)
      await auth().signInWithEmailAndPassword(email, password)
    } catch (e) {
      logger("login error", e)
      setError(e.code)
    } finally {
      setLoading(false)
    }
  }

  const goToSignUp = () => {
    navigation.navigate(onboardingRoutes.SIGNUP)
  }

  const sendPasswordEmail = async () => {
    if (_.isEmpty(email)) {
      Keyboard.dismiss()
      showSnack({ message: t("recoveryError") })
      return
    }
    try {
      await auth().sendPasswordResetEmail(email)
      showSnack({ message: t("emailSent") })
    } catch (e) {
      showSnack({ message: t("recoveryError") })
    }
  }

  const s = useStyles(
    ({
      colors: {
        white: { highEmphasis: white },
      },
      dimensions: { spacing },
      screenStyle,
    }) => ({
      viewButton: { paddingTop: spacing },
      highlightedText: { color: primary },
      caption: { marginVertical: spacing },
      buttonLabel: { color: primary, textAlign: "center" },
      password: {
        paddingRight: spacing * 2,
        marginVertical: spacing / 2,
      },
      snackbar: { marginBottom: getFooterHeight() + spacing },
      subText: { ...styles.subText, marginVertical: spacing / 2 },
      loginButton: {
        color: white,
      },
      trans: { ...styles.trans, marginTop: spacing / 2 },
      button: {
        ...styles.button,
        marginTop: spacing,
      },
      contentContainer: {
        ...styles.container,
        ...screenStyle,
        paddingHorizontal: spacing,
        paddingVertical: spacing / 2,
      },
      input: { marginVertical: spacing / 2 },
    }),
  )

  const onDismiss = useCallback(() => setError(undefined), [setError])

  const [visiblePassword, setVisiblePassword] = useState(false)

  const toggleVisiblePassword = () => setVisiblePassword(old => !old)

  return (
    <ThemeProvider
      customTheme={{
        colors: {
          overrides: { button: primary },
        },
      }}
    >
      <KeyboardAvoidView style={s.contentContainer}>
        <SafeAreaView style={styles.container} edges={["bottom"]}>
          <TextInput
            value={email}
            error={!!error}
            style={s.input}
            autoCorrect={false}
            autoCapitalize="none"
            onChangeText={setEmail}
            keyboardType="email-address"
            changeUnderline
            label={t("email")}
          />
          <View>
            <TextInput
              error={!!error}
              value={password}
              style={s.password}
              autoCorrect={false}
              secureTextEntry={!visiblePassword}
              autoCapitalize="none"
              right={
                <PaperInput.Icon
                  name={`eye-${visiblePassword ? "outline" : "off"}`}
                  onPress={toggleVisiblePassword}
                />
              }
              onChangeText={setPassword}
              changeUnderline
              label={t("password")}
            />
          </View>
          <Button style={s.button} onPress={onNext} loading={loading} labelStyle={s.loginButton}>
            {t("login")}
          </Button>
          <View style={s.viewButton}>
            <TouchableOpacity style={s.subText} onPress={sendPasswordEmail}>
              <Body2 style={s.buttonLabel}>{t("forgot")}</Body2>
            </TouchableOpacity>
            <StylizedTranslatedText
              TextComp={Body2}
              onPress={goToSignUp}
              textStyle={s.trans}
              i18nKey="login.signUp"
              highlightedTextStyle={s.highlightedText}
            />
          </View>
        </SafeAreaView>
        <SafeAreaView>
          <KeyboardAvoidView>
            {error ? (
              <Snackbar {...{ onDismiss }} style={s.snackbar} visible={!_.isEmpty(error)}>
                {t(`accountScreen.errors.${error}`, t("signIn.errors.default"))}
              </Snackbar>
            ) : null}
          </KeyboardAvoidView>
        </SafeAreaView>
      </KeyboardAvoidView>
    </ThemeProvider>
  )
}

const styles = StyleSheet.create({
  trans: {
    textAlign: "center",
  },
  subText: {
    alignSelf: "center",
  },
  container: {
    flex: 1,
  },
  inputContainer: {
    flex: 1,
  },
  text: {
    flexShrink: 1,
  },
  button: {
    borderWidth: 0,
  },
})

export default LoginScreen
