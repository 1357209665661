export default {
  common: {
    actions: {
      select: "Select",
      cancel: "Cancel",
      confirm: "Confirm",
      logout: "Log out",
    },
    parameters: {
      termsOfUse: "Terms of use",
      privacy: "Privacy policy",
      help: "Help center",
    },
    accountScreen: {
      errors: {
        default: "An unknown error occurred",
        "auth/wrong-password": "Invalid password",
        "auth/user-disabled": "The account was deactivated",
        "auth/weak-password": "Weak password",
        "auth/invalid-email": "Invalid email format",
        "internal/already-logged-in":
          "You cannot set a password if you already logged in at least once",
        emptyField: "Both the email and password fields are required",
        "auth/user-not-found": "No user found for this email",
        "auth/email-already-in-use": "This email address is already used",
      },
    },
    multiSelect: {
      placeholder: "All selected",
    },
  },
}
