import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

const ClearSearchSVG = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 12C2 6.47 6.47 2 12 2s10 4.47 10 10-4.47 10-10 10S2 17.53 2 12zm13.59 5L17 15.59 13.41 12 17 8.41 15.59 7 12 10.59 8.41 7 7 8.41 10.59 12 7 15.59 8.41 17 12 13.41 15.59 17z"
      fill={props.color || "#32352F"}
      fillOpacity={0.72}
    />
  </Svg>
)

export default ClearSearchSVG
