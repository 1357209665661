import { useNavigation } from "@react-navigation/native"
import { StackNavigationProp } from "@react-navigation/stack"
import {
  auth,
  Body2,
  Button,
  emailRegex,
  FormikTextInput,
  H6,
  KeyboardAvoidView,
  logger,
  MaterialCommunityIcons,
  ThemeProvider,
  userContext,
  useTheme,
} from "@siruplab/capsule"
import { Formik } from "formik"
import _ from "lodash"
import React, { useCallback, useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { Keyboard, StyleSheet, View } from "react-native"
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view"
import { Snackbar, TextInput } from "react-native-paper"
import { SafeAreaView } from "react-native-safe-area-context"
import * as Yup from "yup"

import { onboardingRoutes } from "../../features/Navigation/Constants"
import { OnboardingParamList } from "../../features/Navigation/RootNavigator"
import { AppTheme, ICON_SIZE } from "../../ThemeApp"
import isWeb from "../../utils/isWeb"
import { useStyles } from "../../utils/useStyles"
import { ns } from "./i18n/en"

const emptyForm = {
  email: "",
}

const TEST_EMAIL = "testaccount@google.com"

export const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  // @ts-ignore
  url: isWeb ? `${window?.location?.origin}/` : "https://www.domaine-app.com/",
  // This must be true.
  handleCodeInApp: true,
  iOS: {
    bundleId: "com.siruplab.domaine",
  },
  android: {
    packageName: "com.siruplab.domaine",
    installApp: true,
  },
  dynamicLinkDomain: "domaineapp.page.link",
}

const SignUpScreen = () => {
  const {
    colors: { black, secondary },
  } = useTheme<AppTheme>()
  const { t } = useTranslation(ns)
  const { navigate } = useNavigation<StackNavigationProp<OnboardingParamList, "SignUp">>()
  const { setError, error, signInWithEmail } = useContext(userContext)
  const [showUnderline, setShowUnderline] = useState(true)

  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState("")

  const s = useStyles(
    ({
      colors: {
        surface: { appUi, overlay },
        white: { highEmphasis: white },
      },
      dimensions: { spacing, marginSides },
      typography: { body2 },
    }) => ({
      icon: {
        color: secondary,
        paddingRight: spacing,
      },
      separator: {
        ...styles.separator,
        backgroundColor: overlay,
        marginVertical: spacing * 1.5,
      },
      input: {
        fontSize: body2.fontSize,
        ...(showUnderline ? { borderBottomColor: black.mediumEmphasis, borderBottomWidth: 2 } : {}),
      },
      subtitle: { ...styles.text, paddingTop: spacing / 2 },
      subView: { paddingHorizontal: marginSides, paddingVertical: spacing * 3.5 },
      mainView: {
        ...styles.view,
        backgroundColor: appUi,
      },
      inputsView: { paddingTop: spacing * 2 },
      button: { marginTop: spacing },
      sndButton: { backgroundColor: white },
      createPass: { color: white },
      alreadyHave: { color: secondary },
      firstButton: {
        ...styles.buttonText,
        color: email === "" ? black.disabled : white,
      },
    }),
    [showUnderline, email],
  )

  const signUp = async () => {
    setLoading(true)
    try {
      Keyboard.dismiss()
      // Do not delete, this is used to allow Google to test the app without an email link
      if (_.isEqual(email, TEST_EMAIL)) {
        await auth().signInAnonymously()
      } else {
        await signInWithEmail(email, actionCodeSettings, { disableLoading: true })
        navigate(onboardingRoutes.SIGNIN_VALIDATION)
      }
    } catch (e) {
      logger("SignUp error: ", e.code, e.message)
    } finally {
      setLoading(false)
    }
  }

  const onDismiss = useCallback(() => setError(undefined), [setError])

  const removeUnderline = useCallback(() => {
    if (showUnderline) {
      setShowUnderline(false)
    }
  }, [showUnderline])

  const onTouchStart = () => {
    setError(undefined)
  }

  return (
    <ThemeProvider
      customTheme={{
        colors: {
          overrides: { button: secondary },
        },
      }}
    >
      <Formik
        initialValues={emptyForm}
        validationSchema={Yup.object({
          email: Yup.string()
            .matches(emailRegex, { message: t("invalidEmail"), excludeEmptyString: true })
            .required(t("required")),
        })}
        onSubmit={signUp}
      >
        {({ handleSubmit }) => (
          <KeyboardAvoidView style={s.mainView}>
            <SafeAreaView style={styles.view} edges={["left", "right"]}>
              <KeyboardAwareScrollView
                style={styles.view}
                extraScrollHeight={200}
                contentInset={{ bottom: 0 }}
                keyboardShouldPersistTaps="handled"
              >
                <View style={s.subView}>
                  <View style={{ flexDirection: "row", justifyContent: "flex-start" }}>
                    <MaterialCommunityIcons
                      style={s.icon}
                      size={ICON_SIZE}
                      color={secondary}
                      name="form-textbox-password"
                    />
                    <H6 style={styles.text}>{t("passwordTitle")}</H6>
                  </View>
                  <Body2 style={s.subtitle}>{t("passwordSubtitle")}</Body2>
                  <Button
                    style={s.button}
                    labelStyle={s.createPass}
                    onPress={() => navigate(onboardingRoutes.PASSWORD_CREATE)}
                  >
                    {t("createPassword")}
                  </Button>
                  <Button
                    style={s.button}
                    mode="outlined"
                    labelStyle={s.alreadyHave}
                    color="white"
                    onPress={() => navigate(onboardingRoutes.LOGIN)}
                  >
                    {t("passwordKnown")}
                  </Button>
                </View>
                <View style={s.separator} />
                <View style={s.subView}>
                  <View style={{ flexDirection: "row", justifyContent: "flex-start" }}>
                    <MaterialCommunityIcons
                      style={s.icon}
                      size={ICON_SIZE}
                      color={secondary}
                      name="auto-fix"
                    />
                    <H6 style={styles.text}>{t("title")}</H6>
                  </View>
                  <Body2 style={s.subtitle}>{t("subtitle")}</Body2>
                  <View style={s.inputsView}>
                    <FormikTextInput
                      dense
                      name="email"
                      value={email}
                      error={!!error}
                      style={s.input}
                      autoCorrect={false}
                      returnKeyType="done"
                      autoCapitalize="none"
                      inputColor={secondary}
                      onChangeText={setEmail}
                      label={t("placeholder")}
                      onFocus={removeUnderline}
                      onTouchStart={onTouchStart}
                      keyboardType="email-address"
                      // @ts-expect-error
                      onSubmitEditing={handleSubmit}
                      underlineColor={black.mediumEmphasis}
                      left={<TextInput.Icon name="email" color={black.mediumEmphasis} />}
                    />
                    <Button
                      onPress={handleSubmit}
                      style={s.button}
                      loading={loading}
                      disabled={email === "" || loading}
                      labelStyle={s.firstButton}
                    >
                      {t("signUp")}
                    </Button>
                  </View>
                </View>
              </KeyboardAwareScrollView>
            </SafeAreaView>
            <SafeAreaView>
              <Snackbar visible={!_.isEmpty(error)} {...{ onDismiss }}>
                {error
                  ? t(
                      `common:accountScreen.errors.${error}`,
                      t(`common:accountScreen.errors.default`),
                    )
                  : ""}
              </Snackbar>
            </SafeAreaView>
          </KeyboardAvoidView>
        )}
      </Formik>
    </ThemeProvider>
  )
}

const styles = StyleSheet.create({
  separator: {
    height: 1,
    width: "100%",
  },
  view: {
    flex: 1,
  },
  text: {
    alignSelf: "flex-start",
    letterSpacing: 0.25,
    textAlign: "center",
  },
  buttonText: {
    textTransform: "none",
    fontWeight: "500",
    letterSpacing: 0.75,
  },
})

export default SignUpScreen
