import { rootRoutes } from "../../../features/Navigation/Constants"

export const ns = rootRoutes.IMAGE_VIEW

export default {
  [ns]: {
    rename: {
      title: "Rename photo",
      label: "Name",
    },
  },
}
