import AsyncStorage from "@react-native-async-storage/async-storage"
import { useNavigation } from "@react-navigation/native"
import { StackNavigationProp } from "@react-navigation/stack"
import {
  Body2,
  Button,
  Caption,
  H6,
  logger,
  StylizedTranslatedText,
  userContext,
  useTheme,
} from "@siruplab/capsule"
import _ from "lodash"
import React, { FC, useCallback, useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { ScrollView, StyleSheet, View } from "react-native"
import { ActivityIndicator, Snackbar } from "react-native-paper"
import { SafeAreaView } from "react-native-safe-area-context"

import { onboardingRoutes } from "../../features/Navigation/Constants"
import { OnboardingParamList } from "../../features/Navigation/RootNavigator"
import { useStyles } from "../../utils/useStyles"
import { actionCodeSettings } from "../SignUpScreen/SignUpScreen"
import { ns } from "./i18n/en"

const SignInValidationScreen: FC<
  StackNavigationProp<OnboardingParamList, "SignInValidation">
> = () => {
  const {
    colors: { primary, secondary },
  } = useTheme()
  const { t } = useTranslation(ns)

  const { navigate } = useNavigation<
    StackNavigationProp<OnboardingParamList, "Login" | "PasswordCreate">
  >()
  const [email, setEmail] = useState<string | null>()
  const { signInWithEmail: sendSignInEmail, error, setError } = useContext(userContext)

  useEffect(() => {
    ;(async () => {
      const storageEmail = await AsyncStorage.getItem("emailForSignIn")
      setEmail(storageEmail)
    })()
  }, [])

  const resend = async () => {
    try {
      if (email) {
        await sendSignInEmail(email, actionCodeSettings)
      }
    } catch (e) {
      logger("SignUp error: ", e.code, e.message)
    }
  }

  const navToLogin = () => navigate(onboardingRoutes.LOGIN)
  const navToCreatePassword = () => navigate(onboardingRoutes.PASSWORD_CREATE)

  const onDismiss = useCallback(() => setError(undefined), [setError])

  const s = useStyles(
    ({
      colors: {
        surface,
        white: { highEmphasis: white },
        black: { disabledButton },
      },
      dimensions: { spacing, marginSides },
    }) => ({
      containerStyle: {
        ...styles.container,
        backgroundColor: surface.appUi,
      },
      textContainerStyle: {
        ...styles.textContainer,
        paddingHorizontal: spacing,
      },
      title: {
        ...styles.text,
        marginTop: marginSides,
      },
      subtitle: {
        ...styles.text,
        marginVertical: spacing * 2,
      },
      caption: styles.text,
      highlighted: { color: primary },
      indicator: { paddingTop: spacing },
      icon: {
        color: secondary,
        paddingRight: spacing,
      },
      subView: { paddingHorizontal: marginSides },
      button: { marginTop: spacing },
      alreadyHaveBtn: {
        marginTop: spacing,
        borderBottomWidth: 1,
        borderBottomColor: disabledButton,
      },
      createPass: { color: white },
      alreadyHave: { color: secondary },
    }),
  )

  return !email ? (
    <ActivityIndicator style={s.indicator} size="large" color={primary} />
  ) : (
    <>
      <SafeAreaView style={s.containerStyle} edges={["top", "right", "left"]}>
        <ScrollView>
          <View style={s.textContainerStyle}>
            <H6 style={s.title}>{t("title")}</H6>
            <StylizedTranslatedText
              highlightedTextStyle={s.highlighted}
              TextComp={Body2}
              i18nKey="signInValidation.subtitle"
              textStyle={s.subtitle}
              params={{ email }}
            />
            <StylizedTranslatedText
              highlightedTextStyle={s.highlighted}
              TextComp={Caption}
              i18nKey="signInValidation.caption"
              textStyle={s.caption}
              onPress={resend}
            />
          </View>
          <View
            style={{
              height: 1,
              width: "100%",
              backgroundColor: "rgba(50, 53, 47, 0.12)",
              marginVertical: 50,
            }}
          />
          <View style={s.subView}>
            <View style={{ flexDirection: "row", justifyContent: "flex-start" }}>
              <H6 style={styles.text}>{t("passTitle")}</H6>
            </View>
            <Body2 style={s.subtitle}>{t("passSubtitle")}</Body2>
            <Button style={s.button} labelStyle={s.createPass} onPress={navToCreatePassword}>
              {t("createPass")}
            </Button>
            <Button
              style={s.alreadyHaveBtn}
              mode="outlined"
              labelStyle={s.alreadyHave}
              onPress={navToLogin}
            >
              {t("alreadyHave")}
            </Button>
          </View>
        </ScrollView>
      </SafeAreaView>
      <SafeAreaView>
        <Snackbar visible={!_.isEmpty(error)} {...{ onDismiss }}>
          {error
            ? t(`common:accountScreen.errors.${error}`, t(`common:accountScreen.errors.default`))
            : ""}
        </Snackbar>
      </SafeAreaView>
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  textContainer: {
    flex: 1,
    alignContent: "center",
  },
  text: {
    textAlign: "left",
  },
})

export default SignInValidationScreen
