import { rootRoutes } from "../../../features/Navigation/Constants"

export const ns = rootRoutes.ASSET_VIEW

export default {
  [ns]: {
    addComment: "Add comment",
    addPhotos: "Add photos",
    addPlans: "Add plans",
    editPortfolios: "Edit portfolios",
    emptyScreenTitle: "No asset to display",
    emptyScreenSubtitle: "Select an asset in the list to display its details",
    comments: "Comments",
    commentModal: {
      placeholder: "Comment",
      cancel: "Cancel",
      send: "Send",
    },
    delete: "Delete",
    deleteModal: {
      confirm: "Delete",
      cancel: "Cancel",
      message: "Please make sure you want to delete this asset.",
      title: "Delete?",
    },
    plans: "Floor plans",
    reply: "Reply",
    share: "Share the asset",
    signUpModal: {
      cancel: "Not interested",
      ok: "Sign up for free",
      subtitle: "Sign up for free so you can create assets, invite teammates, comment and more.",
      title: "Create your own assets?",
    },
    viewMore: "view more",
  },
}
