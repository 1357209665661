import { Body2, Button, H6, Subtitle1, TextInput, useTheme } from "@siruplab/capsule"
import dayjs from "dayjs"
import _ from "lodash"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useWindowDimensions, View } from "react-native"
import Modal from "react-native-modal"
import { Chip } from "react-native-paper"

import { Comment } from "../../../features/models/Comment"
import { useAssetComments } from "../../../features/Providers/AssetCommentsProvider"
import { AppTheme, maxWidth } from "../../../ThemeApp"
import { useStyles } from "../../../utils/useStyles"
import { ns } from "../i18n/en"

const AssetComments = () => {
  const { comments, addComment, loading, error } = useAssetComments()

  const { t } = useTranslation(ns)

  const {
    colors: {
      black: { underlineColor },
    },
  } = useTheme<AppTheme>()
  const { width } = useWindowDimensions()

  const [value, setValue] = useState<string>()

  const [visible, setVisible] = useState(false)
  const [prevCommentData, setPrevCommentData] = useState<Comment>()

  const toggleVisible = (comment?: Comment) => () => {
    setPrevCommentData(comment)
    setVisible(prev => !prev)
  }

  const s = useStyles(
    ({
      colors: {
        primary,
        secondary,
        surface: { appUi, overlay, textInput },
        black: { separator, mediumEmphasis, highEmphasis: black },
        white: { highEmphasis: white },
      },
      dimensions: { spacing },
    }) => ({
      title: { color: primary },
      commentChipText: { color: white },
      commentChip: { backgroundColor: secondary },
      text: { paddingTop: spacing, color: black },
      innerView: { flexDirection: "row", justifyContent: "space-between" },
      chip: { height: 32, borderWidth: 1, backgroundColor: appUi, borderColor: separator },
      headerView: {
        flexDirection: "row",
        justifyContent: "space-between",
        paddingVertical: spacing,
      },
      quoteView: {
        borderRadius: 8,
        padding: spacing / 2,
        marginTop: spacing / 2,
        backgroundColor: overlay,
      },
      view: {
        borderWidth: 1,
        borderRadius: 8,
        padding: spacing,
        marginBottom: spacing,
        borderColor: separator,
      },
      modalButtons: {
        flexDirection: "row",
        justifyContent: "flex-end",
      },
      modalPressable: {
        flex: 1,
      },
      modalView: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      },
      modal: {
        width: width - spacing * 3,
        maxWidth,
        padding: spacing,
        borderRadius: 8,
        margin: "auto",
        alignSelf: "center",
        backgroundColor: white,
      },
      textInput: {
        backgroundColor: textInput,
        borderWidth: 0,
        borderBottomWidth: 0,
        marginVertical: spacing,
        borderColor: mediumEmphasis,
        borderRadius: 0,
        width: "100%",
        height: 0,
        minWidth: 0,
        maxHeight: 200,
      },
      commonText: {
        color: black,
      },
      dateText: {
        color: mediumEmphasis,
      },
      modalCancel: {
        color: mediumEmphasis,
      },
      modalOk: {
        color: _.isEmpty(value) ? separator : primary,
      },
    }),
    [value],
  )

  if (!comments || loading || error) {
    return null
  }

  const hideModal = () => {
    setVisible(false)
  }

  const onSubmit = () => {
    hideModal()
    addComment({
      text: value ?? "",
      ...(prevCommentData
        ? {
            quoteId: prevCommentData.id,
            quoteText: prevCommentData.text,
            quoteUserLabel: prevCommentData.userLabel,
          }
        : {}),
    })
  }

  return (
    <>
      <View style={s.headerView}>
        <Subtitle1 style={s.title}>{t("comments")}</Subtitle1>
        <Chip style={s.commentChip} textStyle={s.commentChipText} onPress={toggleVisible()}>
          {t("addComment")}
        </Chip>
      </View>
      {_.orderBy(comments, ["timestamp"], "desc").map(elem => (
        <View key={elem.id} style={s.view}>
          <View style={s.innerView}>
            <View>
              <Subtitle1 style={s.commonText}>{elem.userLabel}</Subtitle1>
              <Body2 style={s.dateText}>{dayjs(elem.timestamp.toMillis()).format("MMM D")}</Body2>
            </View>
            <Chip style={s.chip} onPress={toggleVisible(elem)}>
              {t("reply")}
            </Chip>
          </View>
          {elem.quoteId ? (
            <View style={s.quoteView}>
              <Subtitle1 style={s.commonText}>{elem.quoteUserLabel}</Subtitle1>
              <Body2 style={s.text}>{elem.quoteText}</Body2>
            </View>
          ) : null}
          <Body2 style={s.text}>{elem.text}</Body2>
        </View>
      ))}
      <Modal
        useNativeDriver
        isVisible={visible}
        onDismiss={hideModal}
        onBackdropPress={hideModal}
        onBackButtonPress={hideModal}
        onModalHide={() => {
          setPrevCommentData(undefined)
          setValue(undefined)
        }}
      >
        <View style={s.modal}>
          <H6>{t(prevCommentData ? "reply" : "addComment")}</H6>
          {prevCommentData ? (
            <View style={s.quoteView}>
              <Body2>{prevCommentData.text}</Body2>
            </View>
          ) : null}
          <TextInput
            multiline
            onChangeText={setValue}
            value={value}
            style={s.textInput}
            changeUnderline={false}
            underlineColor={underlineColor}
            placeholder={t("commentModal.placeholder")}
          />
          <View style={s.modalButtons}>
            <Button mode="text" labelStyle={s.modalCancel} onPress={() => hideModal()}>
              {t("commentModal.cancel")}
            </Button>
            <Button
              mode="text"
              labelStyle={s.modalOk}
              disabled={_.isEmpty(value)}
              onPress={onSubmit}
            >
              {t("commentModal.send")}
            </Button>
          </View>
        </View>
      </Modal>
    </>
  )
}

export default AssetComments
