import { useRoute } from "@react-navigation/native"
import {
  Body2,
  generateShadow,
  Highlighter,
  IAddress,
  Subtitle2,
  useTabTwoPane,
  useTheme,
} from "@siruplab/capsule"
import _ from "lodash"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Pressable, StyleProp, StyleSheet, View, ViewProps, ViewStyle } from "react-native"
import FastImage from "react-native-fast-image"
import { Card, Checkbox } from "react-native-paper"

import { GeocoderResult, isUrlAttachment } from "../features/models/Asset"
import { rootRoutes } from "../features/Navigation/Constants"
import { NormalizedAsset, useAssets } from "../features/Providers/AssetProvider"
import usePortfolios from "../features/Providers/PortfolioProvider"
import { ns } from "../screens/AssetMapScreen/i18n/en"
import { useMixedNav } from "../screens/MainScreen/MainNavigator"
import { AppTheme, ICON_SIZE } from "../ThemeApp"
import { formatMonetaryValue } from "../utils/formatMonetaryValue"
import { isOfType } from "../utils/isOfType"
import isWeb from "../utils/isWeb"
import { WebRightPlaceholder } from "./svg"

interface Props {
  asset: NormalizedAsset
  onPress?: () => void
  selected: boolean
  onLayout?: ViewProps["onLayout"]
  wrapperStyle?: StyleProp<ViewStyle>
  showCheckbox?: boolean
  navPath?: string
  onCheckboxPress?: () => void
}

const AssetCard: React.FC<Props> = ({
  asset,
  onCheckboxPress,
  selected,
  onLayout,
  wrapperStyle,
  navPath,
  showCheckbox = true,
  onPress,
}) => {
  const {
    colors: {
      black,
      primary,
      secondaryLightBis,
      surface: { textInput },
    },
    dimensions: { spacing },
    fontMaker,
  } = useTheme<AppTheme>()
  const { i18n, t } = useTranslation(ns)

  const isTabTwoPane = useTabTwoPane()
  const route = useRoute()
  const mixedNav = useMixedNav()
  const { publicMode } = usePortfolios()

  const { normalizedSearchInput, setSelectedAsset, selectedAsset } = useAssets()

  const locale: string = i18n.language.toLowerCase()

  const s = useMemo(
    () => ({
      card: [
        generateShadow(2),
        styles.card,
        { borderRadius: spacing / 2, marginVertical: spacing * 0.2 },
        wrapperStyle,
      ] as ViewStyle,
      image: [
        styles.image,
        { borderTopRightRadius: spacing / 2, borderTopLeftRadius: spacing / 2 },
      ],
      cardContent: [
        {
          minHeight: 96,
          paddingHorizontal: spacing / 2,
          paddingBottom: spacing / 2,
          borderBottomLeftRadius: spacing / 2,
          borderBottomRightRadius: spacing / 2,
        },
        selectedAsset === asset.id && isTabTwoPane && { backgroundColor: secondaryLightBis },
      ],
      subtitleStyle: { paddingTop: spacing / 4 },
      title: {
        marginTop: spacing / 4,
        ...fontMaker({ weight: "Bold" }),
      },
      checkboxWrapper: [
        styles.checkboxWrapper,
        {
          borderRadius: spacing / 4,
          backgroundColor: textInput,
        },
      ],
    }),
    [
      asset,
      fontMaker,
      isTabTwoPane,
      secondaryLightBis,
      selectedAsset,
      spacing,
      textInput,
      wrapperStyle,
    ],
  )

  const isAssetSelectionScreen = route?.name === rootRoutes.ASSET_SELECTION

  const onCardPress = () => {
    setSelectedAsset(asset.id)
    mixedNav.navigate(navPath ?? rootRoutes.ASSET_VIEW, { id: asset.id })
  }

  return (
    <Card
      style={s.card}
      onPress={onPress ?? isAssetSelectionScreen ? onCheckboxPress : onCardPress}
      onLayout={onLayout}
    >
      {showCheckbox ? (
        <Pressable
          style={s.checkboxWrapper}
          disabled
          hitSlop={{ bottom: 20, top: 20, left: 20, right: 20 }}
        >
          <Checkbox.Android
            status={selected ? "checked" : "unchecked"}
            uncheckedColor={primary}
            color={primary}
          />
        </Pressable>
      ) : null}
      {asset?.photos1?.length > 0 ? (
        <FastImage
          style={s.image}
          resizeMode="cover"
          source={{ uri: isUrlAttachment(asset.photos1[0]) ? asset.photos1[0].url : undefined }}
          fallback={isWeb}
        />
      ) : (
        <View style={s.image}>
          <WebRightPlaceholder preserveAspectRatio="xMinYMin slice" borderRadius={16} />
        </View>
      )}
      <Card.Content style={s.cardContent}>
        <Subtitle2 color={black.highEmphasis} numberOfLines={1}>
          <Highlighter text={asset.name} searchWords={normalizedSearchInput} />
        </Subtitle2>
        <Body2 numberOfLines={2}>
          <Highlighter
            text={
              !asset.location
                ? t("noAddressFound")
                : isOfType<GeocoderResult>(asset.location, "formatted_address")
                ? asset.location.formatted_address
                : isOfType<IAddress>(asset.location, "postalAddress") && asset.location.details
                ? asset.location.details.formatted_address
                : t("coord", {
                    lat: asset.coordinates?.lat?.toFixed(6),
                    lng: asset.coordinates?.lng?.toFixed(6),
                  })
            }
            searchWords={normalizedSearchInput}
          />
        </Body2>
        {publicMode && asset.size?.value ? (
          <Body2>
            {`${new Intl.NumberFormat([_.split(locale, "-")[0], "en-UK"], {
              style: "decimal",
            }).format(asset.size.value)} ${asset.size.unit}`}
          </Body2>
        ) : null}
        {asset.value?.value && asset.value?.unit && !publicMode ? (
          <Body2 numberOfLines={1} style={s.subtitleStyle}>
            {formatMonetaryValue(locale, asset.value.value, asset.value.unit)}
          </Body2>
        ) : null}
      </Card.Content>
    </Card>
  )
}

const styles = StyleSheet.create({
  image: { width: "100%", height: 118, resizeMode: "cover" },
  card: { width: "100%", maxWidth: 395, minHeight: 214, alignSelf: "center" },
  title: { fontWeight: "700" },
  checkboxWrapper: {
    position: "absolute",
    zIndex: 2,
    right: 0,
    top: 0,
    width: ICON_SIZE,
    height: ICON_SIZE,
    alignItems: "center",
    justifyContent: "center",
  },
})

export default AssetCard
