import "dayjs/locale/fr"

import {
  defaultWeights,
  FmFunc,
  ICSTheme,
  ITypographyTheme,
  logger,
  Theme,
  ThemeProvider,
} from "@siruplab/capsule"
import dayjs from "dayjs"
import localizedFormat from "dayjs/plugin/localizedFormat"
import _ from "lodash"
import numeral from "numeral"
import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { StatusBar, useWindowDimensions } from "react-native"

require("numeral/locales")
export const maxWidth = 420
export const minWidth = 320
export const tabSizeLand = 74
export const ICON_SIZE = 24
export const ICON_SIZE_SMALL = 20
export const ICON_SIZE_LARGE = 32
export const BACKDROP_OPACITY = 0.54

export type AppTheme = Theme & { typoGen: ICSTheme["typoGen"] } & {
  colors: {
    primaryDark: string
    primaryLight: string
    secondaryLight: string
    secondaryLightBis: string
    surface: { overlay: string }
    black: {
      overlay: string
      underlineColor: string
      mediumEmphasisCustom: string
      mediumEmphasisCustomDisabled: string
    }
  }
  dimensions: { inputHeight: number; inputBorderRadius: number }
}

const ThemeApp: FC = ({ children }) => {
  const { width: windowWidth } = useWindowDimensions()
  const { i18n } = useTranslation()

  const dayjsLocale = _.split(_.toLower(i18n.language), "-")[0]
  logger("dayjsLocale", dayjsLocale)
  dayjs.locale(dayjsLocale)
  dayjs.extend(localizedFormat)

  let numeralLocale: string = _.toLower(i18n.language)
  if (!numeral.locales[numeralLocale]) {
    numeralLocale = _.split(numeralLocale, "-")[0]
  }
  logger("numeralLocale", numeralLocale)
  numeral.locale(numeralLocale)

  return (
    <ThemeProvider<AppTheme>
      customTheme={{
        button: { mode: "contained", roundness: 8 },
        colors: {
          error: "#f95c59",
          primary: "#F75D5D",
          primaryLight: "rgba(253, 115, 115, 0.12)",
          primaryDark: "rgb(215,87,86)",
          secondary: "#5A5FFB",
          secondaryLight: "#989AFC",
          secondaryLightBis: "#D5D6FE",
          black: {
            overlay: "rgba(50, 53, 47, 0.12)",
            underlineColor: "#4f5157",
            highEmphasis: "#32352F",
            mediumEmphasis: "#51534f",
            mediumEmphasisCustom: "rgba(50, 53, 47, 0.72)",
            mediumEmphasisCustomDisabled: "rgba(50, 53, 47, 0.38)",
          },
          white: {
            mediumEmphasis: "rgba(255, 255, 255, 0.74)",
          },
          danger: "#E04242",
          overrides: {
            button: "#5A5FFB",
            textInput: "#5A5FFB",
            iconButton: "#32352F",
          },
          surface: {
            disabled: "rgba(50, 53, 47, 0.38)",
            overlay: "rgba(115, 120, 253, 0.12)",
            appUi: "#F7F7F7",
            textInput: "#e0e0e0",
            card: "#c5c5c5",
            progress: { border: "#F75D5D", background: "rgba(50, 53, 47, 0.16)" },
          },
        },
        roundness: 0,
        fonts: {
          title: {
            family: "EncodeSans",
            iosFamily: "Encode Sans",
            weights: { ...defaultWeights, Black: "800" },
          },
          default: {
            family: "Comfortaa",
            iosFamily: "Comfortaa",
            weights: defaultWeights,
          },
        },
        typoGen: ({ fontMaker }: { fontMaker: FmFunc }): Partial<ITypographyTheme> => ({
          h4: {
            ...fontMaker({ weight: "Bold", family: "title" }),
          },
          h6: {
            ...fontMaker({ weight: "Black", family: "title" }),
          },
          subtitle2: {
            ...fontMaker({ weight: "Bold", family: "default" }),
          },
          button: {
            ...fontMaker({ weight: "Bold", family: "default" }),
          },
        }),

        dimensions: {
          marginSides: windowWidth < minWidth ? 16 : 32,
          inputBorderRadius: 8,
          inputHeight: 50,
          isTabLand: ({ width }) => width > maxWidth + tabSizeLand,
          isTabTwoPane: ({ width }) => width > maxWidth * 2 + tabSizeLand,
          textInputStyle: {
            maxHeight: 56,
            borderTopEndRadius: 8,
            borderTopStartRadius: 8,
            height: 50,
          },
        },
        screenStyle: {
          width: "100%",
          alignSelf: "center",
        },
      }}
    >
      <StatusBar barStyle="dark-content" backgroundColor="white" />
      {children}
    </ThemeProvider>
  )
}

export default ThemeApp
