import AssetsSVG from "./assets_svg"
import BillSVG from "./bill_svg"
import Check2SVG from "./check_2_svg"
import CheckSVG from "./check_svg"
import ClearIcon from "./clear_no_background_svg"
import ClearSearchSVG from "./clear_search_svg"
import DeleteSelectSVG from "./delete_select_svg"
import DrawBarSVG from "./draw_bar_svg"
import FiltersSVG from "./filters_svg"
import MapSVG from "./map_svg"
import PlansSVG from "./plans_svg"
import PortfolioSVG from "./portfolio_svg"
import RollMeterSVG from "./roll_meter_svg"
import SearchIconSVG from "./search_svg"
import ShareSVG from "./share_svg"
import UserSVG from "./user_svg"
import WebRightPlaceholder from "./web_right_placeholder"
import Welcome1SVG from "./welcome_1_svg"
import Welcome2SVG from "./welcome_2_svg"
import Welcome3SVG from "./welcome_3_svg"
import Welcome4SVG from "./welcome_4_svg"
import WelcomeBg1SVG from "./welcome_bg_1_svg"
import WelcomeBg2SVG from "./welcome_bg_2_svg"
import WelcomeBg3SVG from "./welcome_bg_3_svg"
import WelcomeBg4SVG from "./welcome_bg_4_svg"

export {
  AssetsSVG,
  PortfolioSVG,
  UserSVG,
  ClearIcon,
  ClearSearchSVG,
  FiltersSVG,
  MapSVG,
  SearchIconSVG,
  ShareSVG,
  Welcome1SVG,
  DrawBarSVG,
  RollMeterSVG,
  PlansSVG,
  Welcome2SVG,
  Welcome3SVG,
  BillSVG,
  Welcome4SVG,
  WelcomeBg1SVG,
  WelcomeBg2SVG,
  WelcomeBg3SVG,
  WelcomeBg4SVG,
  DeleteSelectSVG,
  CheckSVG,
  Check2SVG,
  WebRightPlaceholder,
}
