/** Provider for getting assets from firestore and filtering them depending on search bar input */
import { firestore, functions, IUserContext, logger, userContext } from "@siruplab/capsule"
import firebase from "firebase"
import _ from "lodash"
import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { Region } from "react-native-maps"

import { collections } from "../../common/types"
import { getRegionForCoordinates } from "../../utils/getRegionForCoordinates"
import { isOfType } from "../../utils/isOfType"
import safeAwait from "../../utils/safeAwait"
import { StateType } from "../../utils/StateType"
import { Asset, AssetRole, GeocoderResult } from "../models/Asset"
import { UnknownList } from "../models/Portfolio"
import { UserData } from "../models/UserData"
import usePortfolios from "./PortfolioProvider"

interface AssetContext {
  searchInput: string
  normalizedSearchInput: string[]
  setSearchInput: Dispatch<SetStateAction<string | undefined>>
  filteredDataBySearch?: NormalizedAsset[]
  assets: NormalizedAsset[]
  initialRegion?: Region
  canDoAll: boolean
  canDelete: boolean
  hasEditAccess: (items: string[]) => boolean
  canEdit: boolean
  canView: boolean
  selectedAssetUnknownPortfolios?: UnknownList[]
  selectedAsset?: string
  assetToEdit: StateType<string | undefined>
  deleteSelectedAsset: () => Promise<void | string>
  setSelectedAsset: Dispatch<SetStateAction<string | undefined>>
  error?: firebase.FirebaseError
  loading: boolean
}

export type NormalizedAsset = Asset & {
  normalizedName: string
  normalizedAddress: string
}

// This is the center of France. We may have to implement something more generic in the future.
const standardInitialRegion = {
  latitude: 46.40125794659517,
  longitude: 3.0012720875000065,
  latitudeDelta: 9,
  longitudeDelta: 9,
}

const assetContext = createContext<AssetContext>({} as AssetContext)

export const normalizedText = text => _.deburr(_.toLower(text))

const AssetProvider = ({ children }: PropsWithChildren<any>) => {
  const { user, isAdmin = false } = useContext<IUserContext<UserData>>(userContext)
  const { portfolios, publicPortfolio } = usePortfolios()

  const [selectedAsset, setSelectedAsset] = useState<string>()
  const assetToEdit = useState<string>()

  const [searchInput, setSearchInput] = useState("")
  const [selectedAssetUnknownPortfolios, setSelectedAssetUnknownPortfolios] = useState<
    UnknownList[]
  >()
  const [normalizedSearchInput, setNormalizedSearchInput] = useState<string[]>([])

  const assetTransform = useCallback((asset: Asset) => {
    if (asset.location == null) {
      logger("Asset without location during transform", asset)
    }

    return {
      ...asset,
      normalizedName: normalizedText(asset.name),
      normalizedAddress: normalizedText(
        isOfType<GeocoderResult>(asset?.location, "formatted_address")
          ? asset.location?.formatted_address
          : "",
      ),
    }
  }, [])

  const [rawUnfilteredAssets, loading, error] = useCollectionData<NormalizedAsset>(
    (isAdmin || !user
      ? firestore().collection(collections.ASSETS)
      : (firestore()
          .collection(collections.ASSETS)
          .orderBy(`roles.${user?.uid}`)
          .where(`roles.${user?.uid}`, ">", "") as unknown)) as firebase.firestore.Query<Asset>,
    {
      idField: "id",
      transform: assetTransform,
    },
  )

  const rawAssets = useMemo(
    () =>
      rawUnfilteredAssets?.filter(a => {
        // TODO: This is a quick fix, remove once resolved ASAP
        if (a.location == null) {
          logger("Asset without location", a)
          return false
        }
        return true
      }),
    [rawUnfilteredAssets],
  )

  const getRoleForAsset = useCallback(
    (assetId: string): AssetRole => {
      const res = !user
        ? null
        : _.sortedUniq(
            portfolios
              .filter(elem => elem.assets?.find(item => item === assetId))
              .map(item => item.roles[user.uid]),
          )
      return res?.find(val => val === "owner")
        ? "owner"
        : res?.find(val => val === "editor")
        ? "editor"
        : "viewer"
    },
    [portfolios, user],
  )

  const hasEditAccess = useCallback(
    (itemIds: string[]) =>
      !user || !rawAssets
        ? false
        : _.every(
            rawAssets
              .filter(elem => _.includes(itemIds, elem.id))
              ?.map(elem => elem.roles[user.uid] !== "viewer", Boolean),
          ),
    [rawAssets, user],
  )

  const [rawAssetsPortfolios] = useCollectionData<NormalizedAsset>(
    (firestore().collection(collections.ASSETS) as unknown) as firebase.firestore.Query<Asset>,
    {
      idField: "id",
      transform: assetTransform,
    },
  )

  const assetsPortfolios = useMemo(() => {
    // TODO there has to be a better way to do this: we're loading all assets
    // then doing a bunch of O(n) operations in succession, which is very inefficient
    // and it forces us to keep all assets readable for all logged-in users.
    // We should be doing this server-side in a Firestore trigger function.
    if (!rawAssetsPortfolios || !rawAssets) {
      return
    }
    const portfoliosAssets = _.flatMap(
      publicPortfolio ? [publicPortfolio] : portfolios,
      portfolio =>
        _.filter(portfolio.assets, portfolioAsset =>
          _.findIndex(rawAssets, asset => asset.id === portfolioAsset),
        ) ?? [],
    )
    const filteredAssetsPortfolio = _.filter(rawAssetsPortfolios, asset => {
      if (asset.location == null) {
        logger("Asset without location", asset)
        return false
      }
      return !!_.find(portfoliosAssets, s => s === asset.id)
      return !!(
        _.find(portfoliosAssets, s => s === asset.id) &&
        !_.find(rawAssets, elem => elem.id === asset.id)
      )
    })
    const mapThing = filteredAssetsPortfolio?.map(asset => ({
      ...asset,
      roles: { ...asset.roles, [user?.uid ?? ""]: getRoleForAsset(asset.id) },
    }))
    return mapThing
  }, [getRoleForAsset, publicPortfolio, portfolios, rawAssets, rawAssetsPortfolios, user])

  const assets = useMemo(() => {
    const newVar =
      assetsPortfolios && assetsPortfolios.length > 0
        ? _.unionBy(assetsPortfolios, rawAssets, "id")
        : rawAssets
    return newVar
  }, [assetsPortfolios, rawAssets])

  useEffect(() => {
    ;(async () => {
      const fetchedList = portfolios?.map(elem => elem.id)
      if (!selectedAsset || _.isEmpty(fetchedList)) {
        return
      }
      const res = await functions().httpsCallable("fetchUnknownPortfolios")({
        asset: selectedAsset,
        fetchedList,
      })
      setSelectedAssetUnknownPortfolios(
        _.uniqBy(res.data as UnknownList[], item => item.ownerInfos.name),
      )
    })()
  }, [assets, portfolios, selectedAsset])

  useEffect(() => {
    setNormalizedSearchInput(_.words(normalizedText(searchInput)))
  }, [searchInput])

  const [filteredDataBySearch, setFilteredDataBySearch] = useState<NormalizedAsset[]>()

  const selectedAssetRoles = useMemo(() => assets?.find(item => item.id === selectedAsset)?.roles, [
    assets,
    selectedAsset,
  ])

  const role = selectedAssetRoles?.[user?.uid ?? ""]

  const canDoAll = isAdmin
  const canDelete = canDoAll || role === "owner"
  const canEdit = canDelete || role === "editor"
  const canView = canEdit || role === "viewer"

  const deleteSelectedAsset = async (): Promise<void | string> => {
    try {
      if (canDelete) {
        await safeAwait(
          firestore()
            .collection(collections.ASSETS)
            .doc(selectedAsset ?? "")
            .delete(),
        )
        setSelectedAsset(undefined)
      }
    } catch (e) {
      logger("Delete asset error: ", e)
    }
  }

  const [initialRegion, setInitialRegion] = useState<Region>()

  useEffect(() => {
    if (!!initialRegion) {
      return
    }
    setInitialRegion(
      assets
        ? getRegionForCoordinates(
            assets.map(({ coordinates: { lat, lng } }) => ({ latitude: lat, longitude: lng })),
          )
        : standardInitialRegion,
    )
  }, [assets, initialRegion])

  useEffect(
    () =>
      searchInput?.length ?? 0 > 1
        ? setFilteredDataBySearch(
            _.orderBy(
              _.filter(assets, f =>
                _.some(
                  normalizedSearchInput,
                  w => f.normalizedName.indexOf(w) !== -1 || f.normalizedAddress.indexOf(w) !== -1,
                ),
              ),
              ["size.searchValue", "name"],
              ["desc", "asc"],
            ),
          )
        : setFilteredDataBySearch(_.orderBy(assets, ["size.searchValue", "name"], ["desc", "asc"])),
    [assets, normalizedSearchInput, searchInput?.length],
  )
  const contextValue: AssetContext = {
    normalizedSearchInput,
    selectedAsset,
    deleteSelectedAsset,
    setSelectedAsset,
    initialRegion,
    hasEditAccess,
    canEdit,
    canDoAll,
    selectedAssetUnknownPortfolios,
    canDelete,
    canView,
    assetToEdit,
    searchInput,
    setSearchInput,
    filteredDataBySearch,
    assets: _.orderBy(assets, ["size.searchValue", "name"], ["desc", "asc"]),
    loading,
    error,
  }

  return <assetContext.Provider value={contextValue}>{children}</assetContext.Provider>
}

export const useAssets = () => {
  const context = useContext(assetContext)
  if (context === undefined) {
    throw new Error("usePortfolios must be used within a PortfolioProvider")
  }

  return context
}

export default AssetProvider
