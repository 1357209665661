import { i18n, IntlProvider, UserProvider } from "@siruplab/capsule"
import * as React from "react"
import { getLocales } from "react-native-localize"
import { Provider as PaperProvider } from "react-native-paper"
import { SafeAreaProvider } from "react-native-safe-area-context"

import translation from "../i18n/i18n"
import AssetFilterProvider from "./AssetFilterProvider"
import AssetProvider from "./AssetProvider"
import { PortfolioProvider } from "./PortfolioProvider"

interface IProps {
  children: React.ReactNode
}

export const lng = getLocales()[0].languageCode

const RootProvider: React.FC<IProps> = props => (
  <SafeAreaProvider>
    <PaperProvider>
      <UserProvider disableToken={true}>
        <IntlProvider i18n={i18n({ translation, lng })}>
          <PortfolioProvider>
            <AssetProvider>
              <AssetFilterProvider>{props.children}</AssetFilterProvider>
            </AssetProvider>
          </PortfolioProvider>
        </IntlProvider>
      </UserProvider>
    </PaperProvider>
  </SafeAreaProvider>
)

export default RootProvider
