import {
  Button,
  logger,
  MaterialCommunityIcons,
  ThemeProvider,
  Touchable,
  useTheme,
} from "@siruplab/capsule"
import { useField, useFormikContext } from "formik"
import _ from "lodash"
import React, { useCallback, useMemo } from "react"
import { FlatList, ImageBackground, ListRenderItemInfo, StyleSheet, View } from "react-native"
import FastImage from "react-native-fast-image"
import { Text } from "react-native-paper"

import ImagesUploader, { ImageOrDocument } from "../../../components/ImagesUploader"
import { Attachment, UrlAttachment } from "../../../features/models/Asset"
import { ICON_SIZE_SMALL } from "../../../ThemeApp"
import { isPdf } from "../../../utils/isPdf"
import { isUrl } from "../../../utils/isUrl"
import isWeb from "../../../utils/isWeb"
import { useStyles } from "../../../utils/useStyles"
import { AssetValues } from "../CreateAssetScreen"

interface IProps {
  name: keyof AssetValues
  label: string
  onOpen?: (index: number) => () => void
  allowPdf?: boolean
}

const keyExtractor = (item: UrlAttachment, index: number) =>
  item.url === "" ? index.toString() : `${item.url}-${index}`

const AssetPicsInfos = ({ name, label, onOpen, allowPdf = false }: IProps) => {
  const [field] = useField<Attachment[]>(name)
  const { setFieldValue } = useFormikContext()

  const {
    colors: {
      primary,
      black: { mediumEmphasis },
    },
  } = useTheme()

  const s = useStyles(
    ({
      dimensions: { spacing },
      colors: {
        black: { separator },
      },
      typography: { overline },
    }) => ({
      icon: { margin: spacing / 4, zIndex: 5 },
      flatList: { marginTop: spacing * 2, maxHeight: 154 },
      button: { maxWidth: 160, alignSelf: "center" },
      imageView: { flex: 1, marginTop: spacing },
      image: {
        width: 120,
        height: 120,
        backgroundColor: separator,
        marginHorizontal: spacing / 2,
        alignItems: "flex-end",
      },
      caption: {
        maxWidth: 120,
        margin: spacing / 2,
        marginBottom: 0,
        ...overline,
        textTransform: "none",
        lineHeight: 11,
      },
    }),
  )

  const isEmpty = useMemo(() => _.isEmpty(field.value), [field.value])

  const updatePics = useCallback(
    (newPics: ImageOrDocument[] | ImageOrDocument) => {
      logger("updatePics", newPics)
      setFieldValue(name, [
        ...field.value,
        ..._.castArray(newPics)
          .map(item => ({
            ...item,
            url: item[
              isWeb ? "data" : isPdf(item.path ?? item.fileCopyUri) ? "fileCopyUri" : "path"
            ] as string,
            name: item.filename,
          }))
          .filter(item => item.url),
      ])
    },
    [field, name, setFieldValue],
  )

  const removeElem = useCallback(
    (index: number) => () => {
      setFieldValue(
        name,
        field.value.filter((elem, idx) => idx !== index),
      )
    },
    [field, name, setFieldValue],
  )

  const PdfMock = ({ children }) => (
    <View style={s.image}>
      <View style={[StyleSheet.absoluteFill, { alignItems: "center", justifyContent: "center" }]}>
        <MaterialCommunityIcons name="file-pdf-box-outline" size={80} color={mediumEmphasis} />
      </View>
      {children}
    </View>
  )

  const renderItem = ({ item, index }: ListRenderItemInfo<UrlAttachment>) => {
    if (isEmpty) {
      return <View style={s.image} />
    }

    logger("renderItem", item)
    const ImageComp = isPdf(item.url)
      ? PdfMock
      : isUrl(item.url) && !isWeb
      ? FastImage
      : ImageBackground

    return (
      <Touchable onPress={onOpen?.(index)}>
        <>
          <ImageComp source={{ uri: item.url }} style={s.image} resizeMode="cover">
            <MaterialCommunityIcons
              size={ICON_SIZE_SMALL}
              style={s.icon}
              name="close-circle"
              color={mediumEmphasis}
              onPress={removeElem(index)}
            />
          </ImageComp>
          <Text style={s.caption} numberOfLines={2}>
            {item.name}
          </Text>
        </>
      </Touchable>
    )
  }

  return (
    <>
      <FlatList<Attachment>
        horizontal
        style={s.flatList}
        {...{ renderItem, keyExtractor }}
        data={isEmpty ? _.times(3, () => ({ url: "" })) : field.value}
      />
      <View style={s.imageView}>
        <ImagesUploader {...{ updatePics, allowPdf }}>
          <ThemeProvider customTheme={{ colors: { overrides: { button: primary } } }}>
            <Button style={s.button}>{label}</Button>
          </ThemeProvider>
        </ImagesUploader>
      </View>
    </>
  )
}

export default AssetPicsInfos
