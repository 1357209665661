import type Geocoder from "react-native-geocoding"
import { GooglePlaceDetail, Point } from "react-native-google-places-autocomplete"
import type { LatLng } from "react-native-maps"

export interface IAddress {
  postalAddress: string
  details?: GooglePlaceDetail | null
  location?: Point
}

export type ID = string
export type CurrencyUnit = "GBP" | "EUR" | "USD"
export type AreaUnit = "sqft" | "m2"
export const Classifications = [
  "hospitality",
  "industrial",
  "office",
  "residential",
  "retail",
  "other",
]
export type Classification = typeof Classifications[number]

export type AssetRole = "owner" | "editor" | "viewer"

export interface ValueAndUnit<Unit extends CurrencyUnit | AreaUnit> {
  value: number
  unit: Unit
  searchValue: number
}

interface AttachmentName {
  name?: string
}

export interface UrlAttachment extends AttachmentName {
  url: string
}
interface PathAttachment extends AttachmentName {
  path: string
}

export type Attachment = UrlAttachment | PathAttachment

export const isUrlAttachment = (a: Attachment): a is UrlAttachment => !!(a as UrlAttachment).url

export type GeocoderResult = Geocoder.GeocoderResponse["results"][0]
export type ComputedAddress = IAddress | GeocoderResult | LatLng

export interface Asset {
  id: ID
  name: string // geocoded result from Google Places
  description: string
  // the roles map determines who can access the asset
  roles: {
    [uid: string]: AssetRole
  }
  picDir: string
  users: {
    [uid: string]: {
      name: string
      email: string
    }
  }
  location: ComputedAddress
  // full Google Places resolved object
  coordinates: {
    lat: number
    lng: number
  }
  /* @deprecated */ photos?: string[] // array of Firebase Storage URLs
  photos1: Attachment[]
  classifications: Classification[] // asset type IDs
  size?: ValueAndUnit<AreaUnit>
  value?: ValueAndUnit<CurrencyUnit>
  rentalIncome?: ValueAndUnit<CurrencyUnit>
  /* @deprecated */ plans?: string[] // array of Firebase Storage URLs
  plans1: Attachment[] // array of Firebase Storage URLs
}
