// noinspection HtmlUnknownTarget

import { onboardingRoutes } from "../Constants"

export default {
  common: {
    appName: "Domaine",
    emailError:
      "Sign-in has to be completed on the same device you requested the link. Please request a new link.",
    accountScreen: {
      termsOfUse:
        "<p>By continuing you're agreeing to our <a href='$t(urls.tos)'>Terms of Service</a> and <a href='$t(urls.privacy)'>Privacy Policy</a>.</p>",
    },
    button: {
      continue: "Continue",
    },
    fields: {
      required: "This field is required",
      invalidEmails: "One of these emails is not valid",
    },
    landing: {
      signUp: "",
      signIn: "Continue",
    },
    signInValidation: {
      subtitle: "A sign-in email with additional instructions was sent to <1>{{email}}</1>.",
      caption:
        "You didn't receive it ?\nCheck your spam. It can take up to 5 minutes.\nYou can also <1>Tap to resend</1>.",
    },
    passwordCreate: {
      login: "Already have a password? <1>Log in with password</1>",
    },
    login: {
      signUp: "Don't have an account? <1>Sign up</1>",
    },
  },
  [`${onboardingRoutes.WELCOME}`]: {
    welcome: [
      {
        title: "Domaine",
        description: "One map, All your assets",
      },
      {
        title: "Assets",
        description: "Create, search and view your assets from you phone and laptop",
      },
      {
        title: "Portfolios",
        description: "Organize your assets into personalized portfolios ",
      },
      {
        title: "Private sharing",
        description: "Share portfolios and assets with your team or clients to collaborate",
      },
    ],
  },
}
