import { useNavigation } from "@react-navigation/native"
import {
  COMMON_NAMESPACE,
  functions,
  H6,
  IUserContext,
  userContext,
  useTheme,
} from "@siruplab/capsule"
import React, { useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { SafeAreaView, StyleSheet } from "react-native"

import CompanyInputs from "../../components/CompanyInputs"
import { UserData } from "../../features/models/UserData"
import { updateUserData } from "../../features/models/UserDataFunctions"
import { accountRoutes } from "../MainScreen/Constants"
import { ns } from "./i18n/en"

export interface CompanyValues {
  teammatesEmails: string
  companyName: string
}

interface Props {
  isCompanyEdit?: boolean
  isTeammatesEdit?: boolean
}

const CompanyScreen = ({ isCompanyEdit = false, isTeammatesEdit = false }: Props) => {
  const { t } = useTranslation([ns, COMMON_NAMESPACE])
  const { userDocRef, userData } = useContext<IUserContext<UserData>>(userContext)
  const {
    dimensions: { spacing },
    colors: {
      surface: { appUi },
    },
  } = useTheme()

  const navigation = useNavigation()
  const isEdit = isCompanyEdit || isTeammatesEdit

  const onSubmit = (values: CompanyValues) => {
    if (!userData) {
      return
    }
    if (values.teammatesEmails) {
      functions().httpsCallable("handleSendJoinMails")({
        from: `${userData.firstName} ${userData.lastName}`,
        usersEmails: values.teammatesEmails,
      })
    }
    // noinspection JSIgnoredPromiseFromCall
    updateUserData(userDocRef, values)
    if (isCompanyEdit || isTeammatesEdit) {
      navigation.navigate(accountRoutes.MAIN)
    }
  }

  const s = useMemo(
    () => ({
      view: [styles.view, { backgroundColor: appUi }],
      title: [styles.text, { paddingTop: spacing * 2 }],
    }),
    [appUi, spacing],
  )

  // TODO: Handle sending invitations on signup

  return (
    <SafeAreaView style={s.view}>
      {!isEdit ? <H6 style={s.title}>{t("title")}</H6> : null}
      <CompanyInputs {...{ isCompanyEdit, isEdit, isTeammatesEdit, onSubmit }} />
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  middleView: {
    height: 1,
  },
  text: {
    alignSelf: "center",
    textAlign: "center",
  },
  view: {
    flex: 1,
  },
})

export default CompanyScreen
