import { useNavigation } from "@react-navigation/native"
import { StackNavigationProp } from "@react-navigation/stack"
import {
  Caption,
  COMMON_NAMESPACE,
  IUserContext,
  logger,
  MaterialCommunityIcons,
  MaterialIcons,
  Subtitle1,
  userContext,
  useTheme,
} from "@siruplab/capsule"
import _ from "lodash"
import React, { useCallback, useContext } from "react"
import { useTranslation } from "react-i18next"
import { FlatList, Linking, ListRenderItemInfo, View } from "react-native"
import { List } from "react-native-paper"

import {
  CommonPortfolio,
  OwnerInfos,
  Portfolio,
  UnknownList,
} from "../../../features/models/Portfolio"
import { UserData } from "../../../features/models/UserData"
import { rootRoutes } from "../../../features/Navigation/Constants"
import { RootParamList } from "../../../features/Navigation/RootNavigator"
import usePortfolios from "../../../features/Providers/PortfolioProvider"
import { AppTheme, ICON_SIZE } from "../../../ThemeApp"
import { isOfType } from "../../../utils/isOfType"
import { useStyles } from "../../../utils/useStyles"
import { mainRoutes, portfoliosRoutes } from "../../MainScreen/Constants"
import { ns } from "../i18n/en"

interface Props {
  title: string
  items?: Array<CommonPortfolio | UnknownList>
}

const PortfoliosInfos = ({ items, title }: Props) => {
  const {
    colors: {
      black: { mediumEmphasisCustom },
    },
  } = useTheme<AppTheme>()

  const navigation = useNavigation<StackNavigationProp<RootParamList, "AssetView">>()
  const { user } = useContext<IUserContext<UserData>>(userContext)
  const { t } = useTranslation([ns, COMMON_NAMESPACE])
  const { storeDisplayedPortfolioId } = usePortfolios()

  const s = useStyles(
    ({
      dimensions: { spacing },
      typography: { body2 },
      colors: {
        black: { overlay },
        surface: { disabled },
      },
    }) => ({
      flatList: { marginTop: spacing },
      altIcon: { alignSelf: "center" },
      icon: { marginLeft: spacing / 2 },
      description: { ...body2, color: disabled },
      right: { alignItems: "center", flexDirection: "row" },
      title: { marginTop: spacing * 1.5, marginLeft: spacing / 2 },
      separator: { width: "100%", backgroundColor: overlay, height: 1 },
      contentContainerStyle: { backgroundColor: "white", borderTopWidth: 1, borderColor: overlay },
      listItem: {
        height: 60,
        padding: 0,
        marginRight: spacing,
        marginLeft: spacing / 2,
        justifyContent: "center",
      },
    }),
  )

  const renderItem = useCallback(
    ({ item }: ListRenderItemInfo<(Portfolio & { ownerInfos: OwnerInfos }) | UnknownList>) => {
      if (!user) {
        return <></>
      }
      const props =
        isOfType<Portfolio>(item, "assets") && item.ownerInfos
          ? {
              title: item.ownerInfos.name,
              description: item.ownerInfos.email,
              descriptionStyle: s.description,
              descriptionNumberOfLines: 1,
              onPress: () => {
                storeDisplayedPortfolioId(item.id)
                navigation.replace(rootRoutes.MAIN_NAVIGATOR, {
                  screen: mainRoutes.PORTFOLIOS_TAB,
                  params: {
                    screen: portfoliosRoutes.ASSETS,
                    params: { portfolioId: item.id },
                  },
                })
              },
              right: () => (
                <View style={s.right}>
                  <Caption>{`${item.name} - ${t(item.roles[user?.uid])}`}</Caption>
                  <MaterialCommunityIcons
                    style={s.icon}
                    size={ICON_SIZE}
                    color={mediumEmphasisCustom}
                    name={item.roles[user?.uid] === "editor" ? "pencil" : "eye"}
                  />
                </View>
              ),
            }
          : {
              title: item.ownerInfos.name,
              right: () => (
                <MaterialIcons
                  size={ICON_SIZE}
                  style={s.altIcon}
                  name="chevron-right"
                  color={mediumEmphasisCustom}
                />
              ),
              onPress: async () => {
                try {
                  await Linking.openURL(`mailto:${item.ownerInfos.email}`)
                } catch (e) {
                  logger("Did not send email")
                }
              },
            }

      return (
        <>
          <List.Item style={s.listItem} titleNumberOfLines={1} {...props} />
          <View style={s.separator} />
        </>
      )
    },
    [mediumEmphasisCustom, navigation, s, storeDisplayedPortfolioId, t, user],
  )

  return _.isEmpty(items) ? null : (
    <>
      <Subtitle1 style={s.title}>{title}</Subtitle1>
      <FlatList
        windowSize={10}
        data={items}
        style={s.flatList}
        maxToRenderPerBatch={5}
        keyExtractor={item => item.id}
        contentContainerStyle={s.contentContainerStyle}
        renderItem={renderItem}
      />
    </>
  )
}

export default PortfoliosInfos
