import * as React from "react"
import Svg, { ClipPath, Defs, G, Path, Rect, SvgProps } from "react-native-svg"

const SVGComponent = ({
  width = "100%",
  height = "100%",
  borderRadius = 0,
  ...props
}: SvgProps & { borderRadius?: number }) => (
  <Svg width={width} height={height} viewBox="0 0 795 595" fill="none" {...props}>
    <G clipPath="url(#clip0)" pointerEvents="none">
      <Path d="M198.8 0.299805H0V149.4H198.8V0.299805Z" fill="#6A6D66" />
      <Path
        d="M-5.72205e-05 56.8599C31.2372 56.8599 56.5599 31.5371 56.5599 0.299866C56.5599 -30.9374 31.2372 -56.2601 -5.72205e-05 -56.2601C-31.2373 -56.2601 -56.5601 -30.9374 -56.5601 0.299866C-56.5601 31.5371 -31.2373 56.8599 -5.72205e-05 56.8599Z"
        fill="#9A9B98"
      />
      <Path
        d="M74.6801 69.7398C82.8815 69.7398 89.5301 63.0912 89.5301 54.8898C89.5301 46.6884 82.8815 40.0398 74.6801 40.0398C66.4786 40.0398 59.8301 46.6884 59.8301 54.8898C59.8301 63.0912 66.4786 69.7398 74.6801 69.7398Z"
        fill="#32352F"
      />
      <Path
        d="M159.79 92.8401C156.8 100.95 150.35 107.4 142.24 110.39V149.4H198.8V92.8401H159.79Z"
        fill="#32352F"
      />
      <Path
        d="M159.79 92.84C160.97 89.65 161.61 86.19 161.61 82.59C161.61 66.23 148.35 52.97 131.99 52.97C115.63 52.97 102.37 66.23 102.37 82.59C102.37 98.95 115.63 112.21 131.99 112.21C135.59 112.21 139.05 111.57 142.24 110.39V92.84H159.79Z"
        fill="#FA5C59"
      />
      <Path
        d="M159.79 92.8401H142.24V110.39C150.35 107.4 156.8 100.95 159.79 92.8401Z"
        fill="white"
      />
      <Path
        d="M26.6999 124.34V99.5301C18.7399 95.2601 9.65994 92.8401 -5.72205e-05 92.8401C-31.2401 92.8401 -56.5601 118.16 -56.5601 149.4C-56.5601 180.64 -31.2401 205.96 -5.72205e-05 205.96C31.2399 205.96 56.5599 180.64 56.5599 149.4C56.5599 140.4 54.4599 131.9 50.7199 124.34H26.6999Z"
        fill="#9A9B98"
      />
      <Path
        opacity={0.5}
        d="M49.1701 125.11H70.6701C63.0301 104.41 46.5901 87.9601 25.8901 80.3301V101.05C35.9301 106.44 44.1001 114.87 49.1701 125.11Z"
        fill="#32352F"
      />
      <Path
        d="M51.0899 125.11C45.5999 114.03 36.7599 104.9 25.8799 99.0701V125.11H51.0899Z"
        fill="black"
      />
      <Path
        d="M198.8 188.17C220.212 188.17 237.57 170.812 237.57 149.4C237.57 127.988 220.212 110.63 198.8 110.63C177.388 110.63 160.03 127.988 160.03 149.4C160.03 170.812 177.388 188.17 198.8 188.17Z"
        fill="white"
      />
      <Path d="M198.8 0.299805H142.07V26.3098H198.8V0.299805Z" fill="#9A9B98" />
      <Path
        d="M-29.0898 229.84H102.76C112.56 229.84 120.58 237.86 120.58 247.66V259.36C120.58 269.16 112.56 277.18 102.76 277.18H92.2001C82.4001 277.18 74.3802 269.16 74.3802 259.36V54.7898"
        stroke="white"
        strokeWidth={0.3099}
        strokeMiterlimit={10}
      />
      <Path
        d="M198.795 149.401L397.595 149.401V0.300888L198.795 0.300888L198.795 149.401Z"
        fill="#6A6D66"
      />
      <Path
        d="M397.6 56.8599C428.837 56.8599 454.16 31.5371 454.16 0.299866C454.16 -30.9374 428.837 -56.2601 397.6 -56.2601C366.363 -56.2601 341.04 -30.9374 341.04 0.299866C341.04 31.5371 366.363 56.8599 397.6 56.8599Z"
        fill="#32352F"
      />
      <Path
        opacity={0.5}
        d="M198.8 66.4599C235.339 66.4599 264.96 36.8391 264.96 0.299896C264.96 -36.2393 235.339 -65.8601 198.8 -65.8601C162.261 -65.8601 132.64 -36.2393 132.64 0.299896C132.64 36.8391 162.261 66.4599 198.8 66.4599Z"
        fill="#32352F"
      />
      <Path
        d="M195.56 -48.5901H228.62C238.42 -48.5901 246.44 -40.5701 246.44 -30.7701V54.4199C246.44 64.2199 254.46 72.2399 264.26 72.2399H316.95C326.75 72.2399 334.77 80.2599 334.77 90.0599V101.76C334.77 111.56 326.75 119.58 316.95 119.58H306.39C296.59 119.58 288.57 111.56 288.57 101.76V44.1299C288.57 34.3299 280.55 26.3099 270.75 26.3099H198.81"
        stroke="white"
        strokeWidth={0.3099}
        strokeMiterlimit={10}
      />
      <Path
        d="M198.8 188.17C220.212 188.17 237.57 170.812 237.57 149.4C237.57 127.988 220.212 110.63 198.8 110.63C177.388 110.63 160.03 127.988 160.03 149.4C160.03 170.812 177.388 188.17 198.8 188.17Z"
        fill="black"
      />
      <Path
        d="M397.6 206.03C428.876 206.03 454.23 180.676 454.23 149.4C454.23 118.124 428.876 92.77 397.6 92.77C366.324 92.77 340.97 118.124 340.97 149.4C340.97 180.676 366.324 206.03 397.6 206.03Z"
        fill="#9A9B98"
      />
      <Path d="M198.8 149.4H0V298.5H198.8V149.4Z" fill="#6A6D66" />
      <Path
        d="M41.0399 174.93C44.0499 174.93 46.8499 175.79 49.2299 177.27C53.8899 169.05 56.5599 159.54 56.5599 149.41C56.5599 118.17 31.2399 92.8499 -5.72205e-05 92.8499C-31.2401 92.8499 -56.5601 118.17 -56.5601 149.41C-56.5601 180.65 -31.2401 205.96 -5.72205e-05 205.96C10.1299 205.96 19.6399 203.29 27.8599 198.63C26.3799 196.25 25.5199 193.45 25.5199 190.44C25.5299 181.87 32.4699 174.93 41.0399 174.93Z"
        fill="#9A9B98"
      />
      <Path
        d="M49.2299 177.26C44.1799 186.17 36.7699 193.57 27.8599 198.63C30.5999 203.03 35.4799 205.96 41.0399 205.96C49.6099 205.96 56.5599 199.01 56.5599 190.44C56.5599 184.88 53.6299 180 49.2299 177.26Z"
        fill="white"
      />
      <Path
        d="M49.23 177.26C46.85 175.78 44.05 174.92 41.04 174.92C32.47 174.92 25.52 181.87 25.52 190.44C25.52 193.45 26.38 196.25 27.86 198.63C36.77 193.57 44.17 186.17 49.23 177.26Z"
        fill="#6A6D66"
      />
      <Path d="M198.8 149.4H142.07V253.07H198.8V149.4Z" fill="#9A9B98" />
      <Path opacity={0.5} d="M99.3993 298.503H142.069V253.073H99.3993V298.503Z" fill="#32352F" />
      <Path
        d="M198.8 188.17C220.212 188.17 237.57 170.812 237.57 149.4C237.57 127.988 220.212 110.63 198.8 110.63C177.388 110.63 160.03 127.988 160.03 149.4C160.03 170.812 177.388 188.17 198.8 188.17Z"
        fill="white"
      />
      <Path
        d="M-29.0898 229.84H102.76C112.56 229.84 120.58 237.86 120.58 247.66V259.36C120.58 269.16 112.56 277.18 102.76 277.18H92.2001C82.4001 277.18 74.3802 269.16 74.3802 259.36V54.7898"
        stroke="white"
        strokeWidth={0.3099}
        strokeMiterlimit={10}
      />
      <Path d="M198.795 298.498H397.595V149.398L198.795 149.398L198.795 298.498Z" fill="#6A6D66" />
      <Path
        opacity={0.5}
        d="M397.6 205.96C428.837 205.96 454.16 180.637 454.16 149.4C454.16 118.163 428.837 92.8398 397.6 92.8398C366.363 92.8398 341.04 118.163 341.04 149.4C341.04 180.637 366.363 205.96 397.6 205.96Z"
        fill="#32352F"
      />
      <Path
        opacity={0.5}
        d="M198.8 188.17C220.212 188.17 237.57 170.812 237.57 149.4C237.57 127.988 220.212 110.63 198.8 110.63C177.388 110.63 160.03 127.988 160.03 149.4C160.03 170.812 177.388 188.17 198.8 188.17Z"
        fill="#32352F"
      />
      <Path
        d="M397.6 355.06C428.837 355.06 454.16 329.737 454.16 298.5C454.16 267.263 428.837 241.94 397.6 241.94C366.363 241.94 341.04 267.263 341.04 298.5C341.04 329.737 366.363 355.06 397.6 355.06Z"
        fill="#32352F"
      />
      <Path
        d="M341.04 193.91C312.16 193.91 288.75 217.32 288.75 246.2C288.75 275.08 312.16 298.49 341.04 298.49V193.91Z"
        fill="#9A9B98"
      />
      <Path
        d="M316.04 262.5C324.114 262.5 330.66 255.954 330.66 247.88C330.66 239.805 324.114 233.26 316.04 233.26C307.966 233.26 301.42 239.805 301.42 247.88C301.42 255.954 307.966 262.5 316.04 262.5Z"
        fill="white"
      />
      <Path
        d="M195.56 252.88L259.23 252.9C269.03 252.9 277.05 260.93 277.05 270.73V355.92C277.05 365.72 285.07 373.74 294.87 373.74L316.94 373.73C326.74 373.73 334.76 381.74 334.76 391.55V403.25C334.76 413.05 326.74 421.07 316.94 421.07H306.38C296.58 421.07 288.56 413.05 288.56 403.25V345.62C288.56 335.82 280.54 327.8 270.74 327.8H198.8"
        stroke="white"
        strokeWidth={0.3099}
        strokeMiterlimit={10}
      />
      <Path d="M596.4 149.4H397.6V298.5H596.4V149.4Z" fill="#6A6D66" />
      <Path
        d="M397.6 355.06C428.837 355.06 454.16 329.737 454.16 298.5C454.16 267.263 428.837 241.94 397.6 241.94C366.362 241.94 341.04 267.263 341.04 298.5C341.04 329.737 366.362 355.06 397.6 355.06Z"
        fill="#9A9B98"
      />
      <Path
        d="M478.05 258.76C486.251 258.76 492.9 252.111 492.9 243.91C492.9 235.709 486.251 229.06 478.05 229.06C469.848 229.06 463.2 235.709 463.2 243.91C463.2 252.111 469.848 258.76 478.05 258.76Z"
        fill="#32352F"
      />
      <Path
        d="M557.39 205.96C554.4 197.85 547.95 191.4 539.84 188.41V149.4H596.4V205.96H557.39Z"
        fill="#32352F"
      />
      <Path
        opacity={0.5}
        d="M557.39 205.96C558.57 209.15 559.21 212.61 559.21 216.21C559.21 232.57 545.95 245.83 529.59 245.83C513.23 245.83 499.97 232.57 499.97 216.21C499.97 199.85 513.23 186.59 529.59 186.59C533.19 186.59 536.65 187.23 539.84 188.41V205.96H557.39Z"
        fill="#32352F"
      />
      <Path
        d="M557.39 205.96H539.84V188.41C547.95 191.4 554.39 197.85 557.39 205.96Z"
        fill="white"
      />
      <Path
        d="M693.18 361.51H488.61C478.81 361.51 470.79 369.53 470.79 379.33V389.89C470.79 399.69 478.81 407.71 488.61 407.71H500.31C510.11 407.71 518.13 399.69 518.13 389.89V275.86C518.13 266.06 526.15 258.04 535.95 258.04H608.14C617.94 258.04 625.96 266.06 625.96 275.86V339.6"
        stroke="white"
        strokeWidth={0.3099}
        strokeMiterlimit={10}
      />
      <Path
        d="M596.39 188.17C617.802 188.17 635.16 170.812 635.16 149.4C635.16 127.988 617.802 110.63 596.39 110.63C574.978 110.63 557.62 127.988 557.62 149.4C557.62 170.812 574.978 188.17 596.39 188.17Z"
        fill="white"
      />
      <Path d="M596.4 272.49H539.67V298.5H596.4V272.49Z" fill="#9A9B98" />
      <Path
        d="M397.6 206.03C428.876 206.03 454.23 180.676 454.23 149.4C454.23 118.124 428.876 92.77 397.6 92.77C366.324 92.77 340.97 118.124 340.97 149.4C340.97 180.676 366.324 206.03 397.6 206.03Z"
        fill="#9A9B98"
      />
      <Path d="M596.398 298.498H795.198V149.398L596.398 149.398V298.498Z" fill="#6A6D66" />
      <Path
        d="M795.19 205.96C826.427 205.96 851.75 180.637 851.75 149.4C851.75 118.163 826.427 92.8398 795.19 92.8398C763.953 92.8398 738.63 118.163 738.63 149.4C738.63 180.637 763.953 205.96 795.19 205.96Z"
        fill="#9A9B98"
      />
      <Path
        d="M795.19 355.06C826.427 355.06 851.75 329.737 851.75 298.5C851.75 267.263 826.427 241.94 795.19 241.94C763.953 241.94 738.63 267.263 738.63 298.5C738.63 329.737 763.953 355.06 795.19 355.06Z"
        fill="#32352F"
      />
      <Path
        opacity={0.5}
        d="M596.39 364.66C632.929 364.66 662.55 335.039 662.55 298.5C662.55 261.961 632.929 232.34 596.39 232.34C559.851 232.34 530.23 261.961 530.23 298.5C530.23 335.039 559.851 364.66 596.39 364.66Z"
        fill="#32352F"
      />
      <Path
        d="M693.18 361.51H488.61C478.81 361.51 470.79 369.53 470.79 379.33V389.89C470.79 399.69 478.81 407.71 488.61 407.71H500.31C510.11 407.71 518.13 399.69 518.13 389.89V275.86C518.13 266.06 526.15 258.04 535.95 258.04H608.14C617.94 258.04 625.96 266.06 625.96 275.86V339.6"
        stroke="white"
        strokeWidth={0.3099}
        strokeMiterlimit={10}
      />
      <Path
        d="M596.39 188.17C617.802 188.17 635.16 170.812 635.16 149.4C635.16 127.988 617.802 110.63 596.39 110.63C574.978 110.63 557.62 127.988 557.62 149.4C557.62 170.812 574.978 188.17 596.39 188.17Z"
        fill="#9A9B98"
      />
      <Path d="M596.4 0.299805H397.6V149.4H596.4V0.299805Z" fill="#6A6D66" />
      <Path
        d="M438.64 123.87C441.65 123.87 444.45 123.01 446.83 121.53C451.49 129.75 454.16 139.26 454.16 149.39C454.16 180.63 428.84 205.95 397.6 205.95C366.36 205.95 341.04 180.63 341.04 149.39C341.04 118.15 366.36 92.8298 397.6 92.8298C407.73 92.8298 417.24 95.4998 425.46 100.16C423.98 102.54 423.12 105.34 423.12 108.35C423.12 116.93 430.07 123.87 438.64 123.87Z"
        fill="#9A9B98"
      />
      <Path
        d="M446.83 121.54C441.78 112.63 434.37 105.23 425.46 100.17C428.2 95.7698 433.08 92.8398 438.64 92.8398C447.21 92.8398 454.16 99.7898 454.16 108.36C454.16 113.92 451.23 118.8 446.83 121.54Z"
        fill="white"
      />
      <Path
        d="M446.83 121.54C444.45 123.02 441.65 123.88 438.64 123.88C430.07 123.88 423.12 116.93 423.12 108.36C423.12 105.35 423.98 102.55 425.46 100.17C434.37 105.22 441.77 112.63 446.83 121.54Z"
        fill="#6A6D66"
      />
      <Path d="M596.4 45.73H539.67V149.4H596.4V45.73Z" fill="#9A9B98" />
      <Path
        opacity={0.5}
        d="M496.992 45.7356L539.662 45.7356V0.305595L496.992 0.305595V45.7356Z"
        fill="#32352F"
      />
      <Path
        d="M596.39 188.17C617.802 188.17 635.16 170.812 635.16 149.4C635.16 127.988 617.802 110.63 596.39 110.63C574.978 110.63 557.62 127.988 557.62 149.4C557.62 170.812 574.978 188.17 596.39 188.17Z"
        fill="white"
      />
      <Path
        d="M518.13 -41.0101V90.8399C518.13 100.64 510.11 108.66 500.31 108.66H488.61C478.81 108.66 470.79 100.64 470.79 90.8399V80.2799C470.79 70.4799 478.81 62.4599 488.61 62.4599H693.18"
        stroke="white"
        strokeWidth={0.3099}
        strokeMiterlimit={10}
      />
      <Path
        d="M596.398 149.401L795.198 149.401V0.300888L596.398 0.300888V149.401Z"
        fill="#6A6D66"
      />
      <Path
        opacity={0.5}
        d="M795.19 205.96C826.427 205.96 851.75 180.637 851.75 149.4C851.75 118.163 826.427 92.8398 795.19 92.8398C763.953 92.8398 738.63 118.163 738.63 149.4C738.63 180.637 763.953 205.96 795.19 205.96Z"
        fill="#32352F"
      />
      <Path
        d="M596.39 188.17C617.802 188.17 635.16 170.812 635.16 149.4C635.16 127.988 617.802 110.63 596.39 110.63C574.978 110.63 557.62 127.988 557.62 149.4C557.62 170.812 574.978 188.17 596.39 188.17Z"
        fill="#9A9B98"
      />
      <Path
        d="M795.19 56.8599C826.427 56.8599 851.75 31.5371 851.75 0.299866C851.75 -30.9374 826.427 -56.2601 795.19 -56.2601C763.953 -56.2601 738.63 -30.9374 738.63 0.299866C738.63 31.5371 763.953 56.8599 795.19 56.8599Z"
        fill="#32352F"
      />
      <Path
        d="M738.63 104.89C709.75 104.89 686.34 81.4798 686.34 52.5998C686.34 23.7198 709.75 0.299805 738.63 0.299805V104.89Z"
        fill="#9A9B98"
      />
      <Path
        d="M649.01 53.3298C661.917 53.3298 672.38 42.8667 672.38 29.9598C672.38 17.0529 661.917 6.58984 649.01 6.58984C636.103 6.58984 625.64 17.0529 625.64 29.9598C625.64 42.8667 636.103 53.3298 649.01 53.3298Z"
        fill="white"
      />
      <Path
        d="M518.13 -41.0101V90.8399C518.13 100.64 510.11 108.66 500.31 108.66H488.61C478.81 108.66 470.79 100.64 470.79 90.8399V80.2799C470.79 70.4799 478.81 62.4599 488.61 62.4599H697.79C707.59 62.4599 715.61 70.4799 715.61 80.2799V105.98C715.61 115.78 707.59 123.8 697.79 123.8H665.15C655.35 123.8 647.33 115.78 647.33 105.98V29.9599"
        stroke="white"
        strokeWidth={0.3099}
        strokeMiterlimit={10}
      />
      <Path
        d="M397.6 149.4H660.19C669.99 149.4 678.01 157.42 678.01 167.22V204.02C678.01 213.82 686.03 221.84 695.83 221.84H759.32C769.12 221.84 777.14 229.86 777.14 239.66V253.86C777.14 263.66 769.12 271.68 759.32 271.68H733.07C723.27 271.68 715.25 263.66 715.25 253.86V92.0398"
        stroke="white"
        strokeWidth={0.3099}
        strokeMiterlimit={10}
      />
      <Path
        d="M596.398 596.693L795.198 596.693L795.198 447.593L596.398 447.593L596.398 596.693Z"
        fill="#6A6D66"
      />
      <Path
        d="M795.19 653.26C826.427 653.26 851.75 627.937 851.75 596.7C851.75 565.463 826.427 540.14 795.19 540.14C763.953 540.14 738.63 565.463 738.63 596.7C738.63 627.937 763.953 653.26 795.19 653.26Z"
        fill="#9A9B98"
      />
      <Path
        d="M720.52 556.96C728.721 556.96 735.37 550.311 735.37 542.11C735.37 533.908 728.721 527.26 720.52 527.26C712.319 527.26 705.67 533.908 705.67 542.11C705.67 550.311 712.319 556.96 720.52 556.96Z"
        fill="#32352F"
      />
      <Path
        d="M635.4 504.16C638.39 496.05 644.84 489.6 652.95 486.61V447.6H596.39V504.16H635.4Z"
        fill="#32352F"
      />
      <Path
        d="M635.4 504.16C634.22 507.35 633.58 510.81 633.58 514.41C633.58 530.77 646.84 544.03 663.2 544.03C679.56 544.03 692.82 530.77 692.82 514.41C692.82 498.05 679.56 484.79 663.2 484.79C659.6 484.79 656.14 485.43 652.95 486.61V504.16H635.4Z"
        fill="#9A9B98"
      />
      <Path d="M635.4 504.16H652.95V486.61C644.84 489.6 638.4 496.04 635.4 504.16Z" fill="white" />
      <Path
        d="M768.5 472.65V497.47C776.45 501.74 785.54 504.16 795.2 504.16C826.44 504.16 851.76 478.84 851.76 447.6C851.76 416.36 826.44 391.04 795.2 391.04C763.96 391.04 738.64 416.36 738.64 447.6C738.64 456.6 740.74 465.1 744.48 472.66H768.5V472.65Z"
        fill="#9A9B98"
      />
      <Path
        opacity={0.5}
        d="M746.03 471.89H724.53C732.17 492.59 748.61 509.04 769.31 516.67V495.95C759.27 490.56 751.09 482.13 746.03 471.89Z"
        fill="#32352F"
      />
      <Path
        d="M744.11 471.89C749.6 482.97 758.44 492.1 769.32 497.93V471.89H744.11Z"
        fill="black"
      />
      <Path
        d="M596.39 486.37C617.802 486.37 635.16 469.012 635.16 447.6C635.16 426.188 617.802 408.83 596.39 408.83C574.978 408.83 557.62 426.188 557.62 447.6C557.62 469.012 574.978 486.37 596.39 486.37Z"
        fill="white"
      />
      <Path
        d="M596.398 596.7L653.128 596.7L653.128 570.69L596.398 570.69L596.398 596.7Z"
        fill="#9A9B98"
      />
      <Path
        d="M824.28 367.16H692.43C682.63 367.16 674.61 359.14 674.61 349.34V337.64C674.61 327.84 682.63 319.82 692.43 319.82H702.99C712.79 319.82 720.81 327.84 720.81 337.64V542.2"
        stroke="white"
        strokeWidth={0.3099}
        strokeMiterlimit={10}
      />
      <Path d="M596.4 447.6H397.6V596.7H596.4V447.6Z" fill="#6A6D66" />
      <Path
        d="M397.6 653.26C428.837 653.26 454.16 627.937 454.16 596.7C454.16 565.463 428.837 540.14 397.6 540.14C366.362 540.14 341.04 565.463 341.04 596.7C341.04 627.937 366.362 653.26 397.6 653.26Z"
        fill="#32352F"
      />
      <Path
        opacity={0.5}
        d="M596.39 662.86C632.929 662.86 662.549 633.239 662.549 596.7C662.549 560.161 632.929 530.54 596.39 530.54C559.85 530.54 530.229 560.161 530.229 596.7C530.229 633.239 559.85 662.86 596.39 662.86Z"
        fill="#32352F"
      />
      <Path
        d="M599.63 645.59H566.57C556.77 645.59 548.75 637.57 548.75 627.77V542.58C548.75 532.78 540.73 524.76 530.93 524.76H478.24C468.44 524.76 460.42 516.74 460.42 506.94V495.24C460.42 485.44 468.44 477.42 478.24 477.42H488.8C498.6 477.42 506.62 485.44 506.62 495.24V552.87C506.62 562.67 514.64 570.69 524.44 570.69H596.38"
        stroke="white"
        strokeWidth={0.3099}
        strokeMiterlimit={10}
      />
      <Path
        d="M596.39 486.37C617.802 486.37 635.16 469.012 635.16 447.6C635.16 426.188 617.802 408.83 596.39 408.83C574.978 408.83 557.62 426.188 557.62 447.6C557.62 469.012 574.978 486.37 596.39 486.37Z"
        fill="black"
      />
      <Path
        d="M397.6 504.23C428.876 504.23 454.23 478.876 454.23 447.6C454.23 416.324 428.876 390.97 397.6 390.97C366.324 390.97 340.97 416.324 340.97 447.6C340.97 478.876 366.324 504.23 397.6 504.23Z"
        fill="#9A9B98"
      />
      <Path
        d="M596.397 447.596L795.197 447.596L795.197 298.496L596.397 298.496L596.397 447.596Z"
        fill="#6A6D66"
      />
      <Path
        d="M754.15 422.07C751.14 422.07 748.34 421.21 745.96 419.73C741.3 427.95 738.63 437.46 738.63 447.59C738.63 478.83 763.95 504.15 795.19 504.15C826.43 504.15 851.75 478.83 851.75 447.59C851.75 416.35 826.43 391.03 795.19 391.03C785.06 391.03 775.55 393.7 767.33 398.36C768.81 400.74 769.67 403.54 769.67 406.55C769.67 415.12 762.72 422.07 754.15 422.07Z"
        fill="#9A9B98"
      />
      <Path
        d="M745.96 419.74C751.01 410.83 758.42 403.43 767.33 398.37C764.59 393.97 759.71 391.04 754.15 391.04C745.58 391.04 738.63 397.99 738.63 406.56C738.63 412.12 741.56 417 745.96 419.74Z"
        fill="white"
      />
      <Path
        d="M745.96 419.74C748.34 421.22 751.14 422.08 754.15 422.08C762.72 422.08 769.67 415.13 769.67 406.56C769.67 403.55 768.81 400.75 767.33 398.37C758.42 403.42 751.02 410.82 745.96 419.74Z"
        fill="#6A6D66"
      />
      <Path
        d="M596.397 447.599L653.127 447.599L653.127 343.929L596.397 343.929L596.397 447.599Z"
        fill="#9A9B98"
      />
      <Path opacity={0.5} d="M695.79 298.5H653.12V343.93H695.79V298.5Z" fill="#32352F" />
      <Path
        d="M596.39 486.37C617.802 486.37 635.16 469.012 635.16 447.6C635.16 426.188 617.802 408.83 596.39 408.83C574.978 408.83 557.62 426.188 557.62 447.6C557.62 469.012 574.978 486.37 596.39 486.37Z"
        fill="white"
      />
      <Path
        d="M824.279 367.16H692.429C682.629 367.16 674.609 359.14 674.609 349.34V337.64C674.609 327.84 682.629 319.82 692.429 319.82H702.989C712.789 319.82 720.809 327.84 720.809 337.64V542.2"
        stroke="white"
        strokeWidth={0.3099}
        strokeMiterlimit={10}
      />
      <Path d="M596.4 298.5H397.6V447.6H596.4V298.5Z" fill="#6A6D66" />
      <Path
        opacity={0.5}
        d="M397.6 504.16C428.837 504.16 454.16 478.837 454.16 447.6C454.16 416.363 428.837 391.04 397.6 391.04C366.363 391.04 341.04 416.363 341.04 447.6C341.04 478.837 366.363 504.16 397.6 504.16Z"
        fill="#32352F"
      />
      <Path
        opacity={0.5}
        d="M596.39 486.37C617.802 486.37 635.16 469.012 635.16 447.6C635.16 426.188 617.802 408.83 596.39 408.83C574.978 408.83 557.62 426.188 557.62 447.6C557.62 469.012 574.978 486.37 596.39 486.37Z"
        fill="#32352F"
      />
      <Path
        d="M397.6 355.06C428.837 355.06 454.16 329.737 454.16 298.5C454.16 267.263 428.837 241.94 397.6 241.94C366.363 241.94 341.04 267.263 341.04 298.5C341.04 329.737 366.363 355.06 397.6 355.06Z"
        fill="#32352F"
      />
      <Path
        d="M454.16 403.08C483.04 403.08 506.45 379.67 506.45 350.79C506.45 321.91 483.04 298.5 454.16 298.5V403.08Z"
        fill="#9A9B98"
      />
      <Path
        d="M599.63 344.11L535.96 344.09C526.16 344.09 518.14 336.06 518.14 326.26V241.07C518.14 231.27 510.12 223.25 500.32 223.25L478.25 223.26C468.45 223.26 460.43 215.25 460.43 205.44V193.74C460.43 183.94 468.45 175.92 478.25 175.92H488.81C498.61 175.92 506.63 183.94 506.63 193.74V251.37C506.63 261.17 514.65 269.19 524.45 269.19H596.39"
        stroke="white"
        strokeWidth={0.3099}
        strokeMiterlimit={10}
      />
      <Path
        d="M198.795 447.596L397.595 447.596L397.595 298.496L198.795 298.496L198.795 447.596Z"
        fill="#6A6D66"
      />
      <Path
        d="M317.14 367.94C325.341 367.94 331.99 361.291 331.99 353.09C331.99 344.889 325.341 338.24 317.14 338.24C308.939 338.24 302.29 344.889 302.29 353.09C302.29 361.291 308.939 367.94 317.14 367.94Z"
        fill="#32352F"
      />
      <Path
        d="M237.81 391.04C240.8 399.15 247.25 405.6 255.36 408.59V447.6H198.8V391.04H237.81Z"
        fill="#32352F"
      />
      <Path
        opacity={0.5}
        d="M237.81 391.04C236.63 387.85 235.99 384.39 235.99 380.79C235.99 364.43 249.25 351.17 265.61 351.17C281.97 351.17 295.23 364.43 295.23 380.79C295.23 397.15 281.97 410.41 265.61 410.41C262.01 410.41 258.55 409.77 255.36 408.59V391.04H237.81Z"
        fill="#32352F"
      />
      <Path
        d="M237.81 391.04H255.36V408.59C247.24 405.59 240.8 399.15 237.81 391.04Z"
        fill="white"
      />
      <Path
        d="M102.02 235.49H306.59C316.39 235.49 324.41 227.47 324.41 217.67V207.1C324.41 197.3 316.39 189.28 306.59 189.28H294.89C285.09 189.28 277.07 197.3 277.07 207.1V321.13C277.07 330.93 269.05 338.95 259.25 338.95H187.06C177.26 338.95 169.24 330.93 169.24 321.13V257.39"
        stroke="white"
        strokeWidth={0.3099}
        strokeMiterlimit={10}
      />
      <Path
        d="M198.8 486.37C220.212 486.37 237.57 469.012 237.57 447.6C237.57 426.188 220.212 408.83 198.8 408.83C177.388 408.83 160.03 426.188 160.03 447.6C160.03 469.012 177.388 486.37 198.8 486.37Z"
        fill="white"
      />
      <Path
        d="M198.795 324.509L255.525 324.509L255.525 298.499L198.795 298.499L198.795 324.509Z"
        fill="#9A9B98"
      />
      <Path
        d="M397.6 504.23C428.876 504.23 454.23 478.876 454.23 447.6C454.23 416.324 428.876 390.97 397.6 390.97C366.324 390.97 340.97 416.324 340.97 447.6C340.97 478.876 366.324 504.23 397.6 504.23Z"
        fill="#9A9B98"
      />
      <Path d="M198.8 298.5H0V447.6H198.8V298.5Z" fill="#6A6D66" />
      <Path
        d="M-5.72205e-05 504.16C31.2372 504.16 56.5599 478.837 56.5599 447.6C56.5599 416.363 31.2372 391.04 -5.72205e-05 391.04C-31.2373 391.04 -56.5601 416.363 -56.5601 447.6C-56.5601 478.837 -31.2373 504.16 -5.72205e-05 504.16Z"
        fill="#9A9B98"
      />
      <Path
        d="M-5.72205e-05 355.06C31.2372 355.06 56.5599 329.737 56.5599 298.5C56.5599 267.263 31.2372 241.94 -5.72205e-05 241.94C-31.2373 241.94 -56.5601 267.263 -56.5601 298.5C-56.5601 329.737 -31.2373 355.06 -5.72205e-05 355.06Z"
        fill="#32352F"
      />
      <Path
        opacity={0.5}
        d="M198.8 364.66C235.339 364.66 264.96 335.039 264.96 298.5C264.96 261.961 235.339 232.34 198.8 232.34C162.261 232.34 132.64 261.961 132.64 298.5C132.64 335.039 162.261 364.66 198.8 364.66Z"
        fill="#32352F"
      />
      <Path
        d="M102.02 235.49H306.59C316.39 235.49 324.41 227.47 324.41 217.67V207.1C324.41 197.3 316.39 189.28 306.59 189.28H294.89C285.09 189.28 277.07 197.3 277.07 207.1V321.13C277.07 330.93 269.05 338.95 259.25 338.95H187.06C177.26 338.95 169.24 330.93 169.24 321.13V257.39"
        stroke="white"
        strokeWidth={0.3099}
        strokeMiterlimit={10}
      />
      <Path
        d="M198.8 486.37C220.212 486.37 237.57 469.012 237.57 447.6C237.57 426.188 220.212 408.83 198.8 408.83C177.388 408.83 160.03 426.188 160.03 447.6C160.03 469.012 177.388 486.37 198.8 486.37Z"
        fill="#9A9B98"
      />
      <Path
        d="M198.795 596.693L397.595 596.693L397.595 447.593L198.795 447.593L198.795 596.693Z"
        fill="#6A6D66"
      />
      <Path
        d="M356.55 473.12C353.54 473.12 350.74 473.98 348.36 475.46C343.7 467.24 341.03 457.73 341.03 447.6C341.03 416.36 366.35 391.04 397.59 391.04C428.83 391.04 454.15 416.36 454.15 447.6C454.15 478.84 428.83 504.16 397.59 504.16C387.46 504.16 377.95 501.49 369.73 496.83C371.21 494.45 372.07 491.65 372.07 488.64C372.07 480.07 365.12 473.12 356.55 473.12Z"
        fill="#9A9B98"
      />
      <Path
        d="M348.37 475.46C353.42 484.37 360.83 491.77 369.74 496.83C367 501.23 362.12 504.16 356.56 504.16C347.99 504.16 341.04 497.21 341.04 488.64C341.04 483.08 343.97 478.2 348.37 475.46Z"
        fill="white"
      />
      <Path
        d="M348.37 475.46C350.75 473.98 353.55 473.12 356.56 473.12C365.13 473.12 372.08 480.07 372.08 488.64C372.08 491.65 371.22 494.45 369.74 496.83C360.82 491.77 353.42 484.37 348.37 475.46Z"
        fill="#6A6D66"
      />
      <Path
        d="M198.794 551.26L255.524 551.26L255.524 447.59L198.794 447.59L198.794 551.26Z"
        fill="#9A9B98"
      />
      <Path opacity={0.5} d="M298.2 551.26H255.53V596.69H298.2V551.26Z" fill="#32352F" />
      <Path
        d="M198.8 486.37C220.212 486.37 237.57 469.012 237.57 447.6C237.57 426.188 220.212 408.83 198.8 408.83C177.388 408.83 160.03 426.188 160.03 447.6C160.03 469.012 177.388 486.37 198.8 486.37Z"
        fill="white"
      />
      <Path
        d="M277.06 638V506.15C277.06 496.35 285.08 488.33 294.88 488.33H306.58C316.38 488.33 324.4 496.35 324.4 506.15V516.71C324.4 526.51 316.38 534.53 306.58 534.53H102.01"
        stroke="white"
        strokeWidth={0.3099}
        strokeMiterlimit={10}
      />
      <Path d="M198.8 447.6H0V596.7H198.8V447.6Z" fill="#6A6D66" />
      <Path
        opacity={0.5}
        d="M-5.72205e-05 504.16C31.2372 504.16 56.5599 478.837 56.5599 447.6C56.5599 416.363 31.2372 391.04 -5.72205e-05 391.04C-31.2373 391.04 -56.5601 416.363 -56.5601 447.6C-56.5601 478.837 -31.2373 504.16 -5.72205e-05 504.16Z"
        fill="#32352F"
      />
      <Path
        d="M198.8 486.37C220.212 486.37 237.57 469.012 237.57 447.6C237.57 426.188 220.212 408.83 198.8 408.83C177.388 408.83 160.03 426.188 160.03 447.6C160.03 469.012 177.388 486.37 198.8 486.37Z"
        fill="#9A9B98"
      />
      <Path
        d="M-5.72205e-05 653.26C31.2372 653.26 56.5599 627.937 56.5599 596.7C56.5599 565.463 31.2372 540.14 -5.72205e-05 540.14C-31.2373 540.14 -56.5601 565.463 -56.5601 596.7C-56.5601 627.937 -31.2373 653.26 -5.72205e-05 653.26Z"
        fill="#32352F"
      />
      <Path
        d="M56.5601 492.11C85.4401 492.11 108.85 515.52 108.85 544.4C108.85 573.28 85.4401 596.69 56.5601 596.69V492.11Z"
        fill="#9A9B98"
      />
      <Path
        d="M146.19 590.41C159.097 590.41 169.56 579.947 169.56 567.04C169.56 554.133 159.097 543.67 146.19 543.67C133.283 543.67 122.82 554.133 122.82 567.04C122.82 579.947 133.283 590.41 146.19 590.41Z"
        fill="white"
      />
      <Path
        d="M277.06 638V506.15C277.06 496.35 285.08 488.33 294.88 488.33H306.58C316.38 488.33 324.4 496.35 324.4 506.15V516.71C324.4 526.51 316.38 534.53 306.58 534.53H97.4001C87.6001 534.53 79.5801 526.51 79.5801 516.71V491.01C79.5801 481.21 87.6001 473.19 97.4001 473.19H130.04C139.84 473.19 147.86 481.21 147.86 491.01V567.03"
        stroke="white"
        strokeWidth={0.3099}
        strokeMiterlimit={10}
      />
      <Path
        d="M397.6 447.6H135.01C125.21 447.6 117.19 439.58 117.19 429.78V392.98C117.19 383.18 109.17 375.16 99.3701 375.16H35.8801C26.0801 375.16 18.0601 367.14 18.0601 357.34V343.14C18.0601 333.34 26.0801 325.32 35.8801 325.32H62.1301C71.9301 325.32 79.9501 333.34 79.9501 343.14V504.97"
        stroke="white"
        strokeWidth={0.3099}
        strokeMiterlimit={10}
      />
    </G>
    <Defs>
      <ClipPath id="clip0">
        <Rect width={795} height={595} fill="white" rx={borderRadius} />
      </ClipPath>
    </Defs>
  </Svg>
)

export default SVGComponent
