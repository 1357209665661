import { createBottomTabNavigator } from "@react-navigation/bottom-tabs"
import { ParamListBase } from "@react-navigation/core"
import { PathConfig } from "@react-navigation/core/src/types"
import {
  NavigationContainer,
  NavigationContainerRef,
  useNavigation,
} from "@react-navigation/native"
import { createStackNavigator } from "@react-navigation/stack"
import {
  COMMON_NAMESPACE,
  generateShadow,
  IUserContext,
  TwoPaneScreen,
  userContext,
  useTabLand,
  useTabTwoPane,
  useTheme,
} from "@siruplab/capsule"
import _ from "lodash"
import React, { createRef, useCallback, useContext, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet, ViewStyle } from "react-native"

import { AssetsSVG, PortfolioSVG, UserSVG } from "../../components/svg"
import { UserData } from "../../features/models/UserData"
import { updateUserData } from "../../features/models/UserDataFunctions"
import { rootRoutes, WebRootRoutesNames } from "../../features/Navigation/Constants"
import { useAssets } from "../../features/Providers/AssetProvider"
import usePortfolios from "../../features/Providers/PortfolioProvider"
import { ICON_SIZE, maxWidth, tabSizeLand } from "../../ThemeApp"
import AssetMapScreen from "../AssetMapScreen"
import AssetViewScreen from "../AssetViewScreen/AssetViewScreen"
import CreateAssetScreen from "../CreateAssetScreen/CreateAssetScreen"
import { MainRouteNames, mainRoutes } from "./Constants"
import { ns } from "./i18n/en"
import {
  accountScreens,
  assetsScreens,
  MainAccountTab,
  MainAssetsTab,
  MainPortfoliosTab,
  portfoliosScreens,
} from "./MainTabs"

export const tabNavRef = createRef<NavigationContainerRef>()

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const tabNav = () => tabNavRef.current!

export const useMixedNav = (cdn?: boolean) => {
  const navigation = useNavigation()
  const isTabTwoPane = useTabTwoPane()

  return (cdn !== undefined ? cdn : isTabTwoPane) ? tabNav() : navigation
}

export type assetsWebStackParamList = Record<
  WebRootRoutesNames,
  Record<string, unknown> | undefined
> & {
  CreateAsset: {
    isEdit?: boolean
  }
  Assets_Tab: {
    fromAssetView?: boolean
  }
}

export const assetsWebStack = createStackNavigator<assetsWebStackParamList>()

export interface IMainParamList extends ParamListBase {
  Assets: undefined
  Account: { password?: boolean }
  Portfolios: undefined
}

export const screens: Record<MainRouteNames, string | PathConfig> = {
  Assets_Tab: { path: "assets", screens: assetsScreens },
  Portfolios_Tab: { path: "portfolios", screens: portfoliosScreens },
  Account_Tab: { path: "account", screens: accountScreens },
}

const mainNavigator = createBottomTabNavigator<IMainParamList>()

const MainNavigator = () => {
  const { portfolios } = usePortfolios()
  const { assets } = useAssets()
  const numberOfAssets = useMemo(() => assets.length, [assets])
  const numberOfPortfolios = useMemo(() => portfolios.length, [portfolios])
  const { t } = useTranslation([ns, COMMON_NAMESPACE])
  const {
    dimensions: { spacing },
    typography: { caption, h6 },
    colors: {
      primary,
      black: { highEmphasis: black },
    },
  } = useTheme()

  const { userDocRef } = useContext<IUserContext<UserData>>(userContext)

  useEffect(() => {
    updateUserData(userDocRef, { lastLoginAt: new Date() })
  }, [userDocRef])

  const s = useMemo(
    () => ({
      tabBarLand: {
        // This one need to stay there, otherwise the shadow is never applied
        ...generateShadow(8),
      },
      tabBarLabel: [
        _.omit(caption, "color"),
        {
          marginBottom: spacing / 2,
        },
      ],
    }),
    [caption, spacing],
  )

  const tabBarIcon = useCallback(
    Component => ({ focused }: { focused: boolean }) => (
      <Component width={ICON_SIZE} height={ICON_SIZE} color={focused ? primary : black} />
    ),
    [black, primary],
  )

  const isTabLand = useTabLand()
  const isTabTwoPane = useTabTwoPane()

  return (
    <TwoPaneScreen customWidth={maxWidth + tabSizeLand}>
      <mainNavigator.Navigator
        tabBarOptions={{
          activeTintColor: primary,
          inactiveTintColor: black,
          // @ts-ignore
          contentStyle: isTabLand ? styles.tabBarContentLand : undefined,
          iconStyle: styles.icon,
          labelStyle: s.tabBarLabel,
          labelPosition: "below-icon",
          tabStyle: isTabLand ? styles.tabLand : styles.tab,
          style: isTabLand ? [styles.tabBarLand, s.tabBarLand] : undefined,
        }}
        screenStyle={isTabLand ? styles.screenLand : undefined}
      >
        <mainNavigator.Screen
          name={mainRoutes.ASSETS_TAB}
          component={MainAssetsTab}
          options={{
            tabBarIcon: tabBarIcon(AssetsSVG),
            tabBarLabel: t(`tabs.assets`),
            tabBarBadge: numberOfAssets ? numberOfAssets : undefined,
          }}
        />
        <mainNavigator.Screen
          name={mainRoutes.PORTFOLIOS_TAB}
          component={MainPortfoliosTab}
          options={{
            tabBarIcon: tabBarIcon(PortfolioSVG),
            tabBarLabel: t(`tabs.portfolios`),
            tabBarBadge: numberOfPortfolios ? numberOfPortfolios : undefined,
          }}
        />
        <mainNavigator.Screen
          name={mainRoutes.ACCOUNT_TAB}
          component={MainAccountTab}
          options={{
            tabBarIcon: tabBarIcon(UserSVG),
            tabBarLabel: t(`tabs.account`),
          }}
        />
      </mainNavigator.Navigator>
      {isTabTwoPane ? (
        <NavigationContainer
          /*
           * Ignore exception when containers are nested within another
           * See: https://github.com/react-navigation/react-navigation/commit/d4072e7d885222bc14f33734008f2bd10ff78bc4
           * */
          independent
          ref={tabNavRef}
          // this option prevents this navigator to override the tab title on web (which would then always be "Assets_Tab")
          documentTitle={{ enabled: false }}
        >
          <assetsWebStack.Navigator>
            <assetsWebStack.Screen
              component={AssetMapScreen}
              name={mainRoutes.ASSETS_TAB}
              options={{ headerShown: false }}
            />
            <assetsWebStack.Screen
              component={AssetViewScreen}
              name={rootRoutes.ASSET_VIEW}
              options={{ title: "", headerBackTitleVisible: false, headerTintColor: black }}
            />
            <assetsWebStack.Screen
              component={CreateAssetScreen}
              name={rootRoutes.CREATE_ASSET}
              options={({ route }) => ({
                headerTitleStyle: h6,
                headerStyle: styles.header,
                headerTitle: t(
                  `common:${rootRoutes.CREATE_ASSET}.${
                    route.params?.isEdit ? "altTitle" : "title"
                  }`,
                ),
                title: t(
                  `common:${rootRoutes.CREATE_ASSET}.${
                    route.params?.isEdit ? "altTitle" : "title"
                  }`,
                ),
              })}
            />
          </assetsWebStack.Navigator>
        </NavigationContainer>
      ) : null}
    </TwoPaneScreen>
  )
}

const styles = StyleSheet.create({
  icon: {
    maxHeight: ICON_SIZE,
  },
  header: {
    elevation: 0,
    shadowOpacity: 0,
    borderBottomWidth: 1,
  },
  tabBarLand: {
    position: "absolute",
    left: 0,
    bottom: 0,
    height: "100%",
    width: tabSizeLand,
    borderTopWidth: 0,
  } as ViewStyle,
  tabBarContentLand: {
    flexDirection: "column",
    justifyContent: "center",
    height: 177,
  },
  tabLand: {
    maxHeight: 59,
    paddingHorizontal: 0,
  },
  tab: {
    maxWidth: 600,
    justifyContent: "center",
    alignItems: "center",
    minHeight: 59,
  },
  screenLand: {
    marginLeft: tabSizeLand,
  },
})

export default MainNavigator
