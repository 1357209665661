import {
  Button,
  COMMON_NAMESPACE,
  MaterialCommunityIcons,
  Subtitle1,
  useTheme,
} from "@siruplab/capsule"
import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { View } from "react-native"
import Collapsible from "react-native-collapsible"
import Modal from "react-native-modal"
import { List, RadioButton } from "react-native-paper"

import { Asset, AssetRole } from "../../../features/models/Asset"
import { Portfolio, PortfolioRole } from "../../../features/models/Portfolio"
import { useAssets } from "../../../features/Providers/AssetProvider"
import usePortfolios from "../../../features/Providers/PortfolioProvider"
import { AppTheme, ICON_SIZE, maxWidth } from "../../../ThemeApp"
import { StateType } from "../../../utils/StateType"
import { useStyles } from "../../../utils/useStyles"
import { ns } from "../i18n/en"

interface Props {
  checked?: string
  collapsed: boolean
  isRecap?: boolean
  isPortfolio?: boolean
  selectedUser: StateType<string | undefined>
  rolesToUpdate: StateType<Portfolio["roles"] | Asset["roles"] | undefined>
  usersToRemove: StateType<string[]>
  onItemPress: (elem: { value: AssetRole | PortfolioRole; title: string }) => () => void
  removeUserFromFirebase?: (uid?: string) => Promise<void>
}

type Permission = Array<{
  title: string
  value: AssetRole | PortfolioRole
  desc: string
  icon: string
}>

const assetPermissions: Permission = [
  {
    title: "editor",
    value: "editor",
    desc: "editorDesc",
    icon: "pencil",
  },
  {
    title: "viewer",
    value: "viewer",
    desc: "viewerDesc",
    icon: "eye",
  },
]

const portfolioPermissions: Permission = [
  {
    title: "team",
    value: "editor",
    desc: "teamDesc",
    icon: "pencil",
  },
  {
    title: "viewer",
    value: "viewer",
    desc: "viewerDesc",
    icon: "eye",
  },
]

const PermissionElements = ({
  isRecap,
  isPortfolio,
  collapsed,
  onItemPress,
  checked,
  selectedUser,
  usersToRemove,
  rolesToUpdate,
  removeUserFromFirebase,
}: Props) => {
  const {
    colors: {
      black: { mediumEmphasisCustom, mediumEmphasisCustomDisabled },
    },
  } = useTheme<AppTheme>()
  const { canDelete: canDeleteAssets } = useAssets()
  const { canDelete: canDeletePortfolios } = usePortfolios()

  const { t } = useTranslation([ns, COMMON_NAMESPACE])
  const s = useStyles(
    ({
      dimensions: { spacing },
      typography: { body2, subtitle1 },
      colors: {
        danger,
        black: { overlay },
        surface: { disabled },
        white: { highEmphasis: white },
      },
    }) => ({
      listItem: {
        height: 83,
        borderBottomWidth: 1,
        borderColor: overlay,
        justifyContent: "center",
      },
      modalView: {
        maxWidth,
        width: "100%",
        borderRadius: 8,
        padding: spacing,
        alignSelf: "center",
        backgroundColor: white,
        paddingBottom: spacing / 2,
      },
      icon: { alignSelf: "center" },
      subtitle: { padding: spacing },
      modalIcon: { alignSelf: "center" },
      collapsible: { backgroundColor: white },
      description: { ...body2, color: disabled },
      label: { ...subtitle1, color: danger, marginLeft: 0 },
      border: { borderBottomWidth: 1, borderColor: overlay },
      leftView: { flexDirection: "row", alignSelf: "center" },
      modalLeft: { flexDirection: "row", alignSelf: "center" },
      removeButton: { alignItems: "flex-start", marginLeft: 0, paddingTop: spacing / 2 },
    }),
    [],
  )

  const hideModal = useCallback(() => {
    selectedUser[1](undefined)
  }, [selectedUser])

  const removeUser = useCallback(() => {
    if (isRecap) {
      removeUserFromFirebase?.(selectedUser[0])
    } else {
      usersToRemove[1](prevState => [...prevState, selectedUser[0] ?? ""])
    }

    hideModal()
  }, [hideModal, isRecap, removeUserFromFirebase, selectedUser, usersToRemove])

  const elemsToSpread = isPortfolio ? portfolioPermissions : assetPermissions

  return (
    <>
      <Modal
        useNativeDriver
        onDismiss={hideModal}
        onBackdropPress={hideModal}
        onBackButtonPress={hideModal}
        isVisible={!!selectedUser[0]}
        hideModalContentWhileAnimating
      >
        <View style={s.modalView}>
          {/* TODO: Optimize the spread here to only make it once */}
          {elemsToSpread.map(elem => (
            <List.Item
              key={elem.value}
              onPress={() => {
                rolesToUpdate[1](prev => ({
                  ...prev,
                  ...(selectedUser[0]
                    ? {
                        [selectedUser[0]]: elem.value,
                      }
                    : {}),
                }))
                hideModal()
              }}
              left={() => (
                <View style={s.modalLeft}>
                  <MaterialCommunityIcons
                    size={ICON_SIZE}
                    name={elem.icon}
                    style={s.modalIcon}
                    color={mediumEmphasisCustom}
                  />
                </View>
              )}
              style={s.listItem}
              title={t(elem.title)}
              description={t(elem.desc)}
              descriptionStyle={s.description}
            />
          ))}
          {(isPortfolio ? canDeletePortfolios : canDeleteAssets) ? (
            <Button mode="text" labelStyle={s.label} style={s.removeButton} onPress={removeUser}>
              {t("remove")}
            </Button>
          ) : null}
        </View>
      </Modal>
      <Collapsible collapsed={collapsed}>
        <View style={s.collapsible}>
          <View style={s.border}>
            <Subtitle1 style={s.subtitle}>{t("selectPermissions")}</Subtitle1>
          </View>
          {elemsToSpread.map(elem => (
            <List.Item
              key={elem.value}
              onPress={onItemPress(elem)}
              left={() => (
                <View style={s.leftView}>
                  <RadioButton.Android
                    value={elem.title}
                    status={checked === elem.title ? "checked" : "unchecked"}
                    onPress={onItemPress(elem)}
                  />
                  <MaterialCommunityIcons
                    size={ICON_SIZE}
                    style={s.icon}
                    name={elem.icon}
                    color={
                      elem.title === "partialViewer"
                        ? mediumEmphasisCustomDisabled
                        : mediumEmphasisCustom
                    }
                  />
                </View>
              )}
              style={s.listItem}
              title={t(elem.title)}
              description={t(elem.desc)}
              descriptionStyle={s.description}
            />
          ))}
        </View>
      </Collapsible>
    </>
  )
}

export default PermissionElements
