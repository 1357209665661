import { Caption, COMMON_NAMESPACE, IAddress, Subtitle1, useTheme } from "@siruplab/capsule"
import { FormikHelpers } from "formik"
import React, { ComponentProps, Dispatch, SetStateAction, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { View } from "react-native"

import MultiSelect from "../../../components/MultiSelect/MultiSelect"
import { Classification, Classifications } from "../../../features/models/Asset"
import usePortfolios from "../../../features/Providers/PortfolioProvider"
import { useStyles } from "../../../utils/useStyles"
import { ns as filterNs } from "../../AssetsFilterScreen/i18n/en"
import { ns } from "../i18n/en"

interface Props {
  setNeedExtraScroll: Dispatch<SetStateAction<boolean>>
  initialClassifications?: Classification[]
  initialPortfolios?: string[]
  setFieldValue?: FormikHelpers<IAddress>["setFieldValue"]
}

const MultiSelectElements = ({
  initialClassifications,
  initialPortfolios,
  setNeedExtraScroll,
  setFieldValue,
}: Props) => {
  const { t } = useTranslation([ns, filterNs, COMMON_NAMESPACE])
  const [localSelectedPortfolios, setLocalSelectedPortfolios] = useState(initialPortfolios)
  const [localSelectedClassifications, setLocalSelectedClassifications] = useState(
    initialClassifications,
  )
  const { portfoliosInfo } = usePortfolios()
  const {
    colors: { primary },
  } = useTheme()

  const s = useStyles(({ dimensions: { spacing } }) => ({
    container: { height: 120 },
    multiselect: { marginTop: spacing },
    bottomMultiSelect: { marginBottom: spacing * 3 },
    noPortfolioTitle: { marginBottom: spacing / 2 },
    noPortfolioCaption: { marginBottom: spacing * 2 },
  }))

  const multiSelectProps: Array<ComponentProps<typeof MultiSelect>> & {
    canAdd?: boolean
  } = useMemo(
    () => [
      {
        title: t(`${filterNs}:selectTitles.classificationsTitle`),
        inputStyle: s.multiselect,
        name: "classifications",
        placeholder: t("addClassification"),
        containerStyle: s.multiselect,
        selectedItems: localSelectedClassifications,
        items: Classifications.map(c => ({
          label: t(`${filterNs}:classification.${c}`),
          value: c,
        })),
        setSelectedItems: setLocalSelectedClassifications,
        modalTitle: t(`${filterNs}:selectTitles.classificationsModalTitle`),
        firstButtonText: t(`${filterNs}:selectModalButtons.cancel`),
        secondButtonText: t(`${filterNs}:selectModalButtons.select`),
      },
      {
        canAdd: true,
        name: "portfolios",
        title: t(`${filterNs}:selectTitles.portfoliosTitle`),
        inputStyle: [s.multiselect, s.bottomMultiSelect],
        containerStyle: s.multiselect,
        selectedItems: localSelectedPortfolios,
        items: portfoliosInfo,
        placeholder: t("addPortfolios"),
        setSelectedItems: setLocalSelectedPortfolios,
        modalTitle: t(`${filterNs}:selectTitles.portfoliosModalTitle`),
        firstButtonText: t(`${filterNs}:selectModalButtons.cancel`),
        secondButtonText: t(`${filterNs}:selectModalButtons.select`),
      },
    ],
    [localSelectedClassifications, localSelectedPortfolios, portfoliosInfo, s, t],
  )

  return (
    <>
      {multiSelectProps.map((item, index) => {
        if (index === 1 && item.items.length === 0) {
          return (
            <View key={index}>
              <Subtitle1 style={s.noPortfolioTitle} color={primary}>
                {item.title}
              </Subtitle1>
              <Caption style={s.noPortfolioCaption}>{t("noPortfolios")}</Caption>
            </View>
          )
        }
        return (
          <MultiSelect
            {...item}
            setNeedExtraScroll={setNeedExtraScroll}
            key={item.title ?? index}
            containerStyle={s.container}
            setFieldValue={setFieldValue}
          />
        )
      })}
    </>
  )
}

export default MultiSelectElements
