import { URL } from "react-native-url-polyfill"

export const isPdf = (uri: string | undefined) => {
  try {
    return (
      new URL(uri ?? "").pathname.toLowerCase().endsWith(".pdf") ||
      uri?.startsWith("data:application/pdf")
    )
  } catch (e) {
    return uri?.endsWith(".pdf")
  }
}
