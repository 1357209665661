import { useFocusEffect, useIsFocused, useNavigation } from "@react-navigation/native"
import { StackNavigationProp } from "@react-navigation/stack"
import {
  ButtonType,
  H6,
  IUserContext,
  PresentationListView,
  RowItem,
  Subtitle1,
  userContext,
  useTextInputDialog,
  useTheme,
} from "@siruplab/capsule"
import _ from "lodash"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { FlatList, StyleSheet, View } from "react-native"
import { FAB } from "react-native-paper"

import ListSeparator from "../../components/ListModal/ListSeparator"
import { Portfolio } from "../../features/models/Portfolio"
import { addPortfolio } from "../../features/models/PortfolioFunctions"
import { UserData } from "../../features/models/UserData"
import { useAssetFilter } from "../../features/Providers/AssetFilterProvider"
import usePortfolios from "../../features/Providers/PortfolioProvider"
import { portfoliosRoutes } from "../MainScreen/Constants"
import { PortfoliosParamList } from "../MainScreen/MainTabs"
import { ns } from "./i18n/en"

const keyExtractor = (item: Portfolio) => item.id

const PortfolioListScreen = () => {
  const { user, userData } = useContext<IUserContext<UserData>>(userContext)
  const [prevSelPortfolios, setPrevSelPortfolios] = useState<string[]>()
  const {
    portfolios,
    loading,
    storeDisplayedPortfolioId,
    displayedPortfolioId,
    setDisplayedPortfolioId,
  } = usePortfolios()
  const {
    setSelectedPortfolios,
    selectedPortfolios,
    resetFilterValues,
    setClearValues,
  } = useAssetFilter()
  const { t } = useTranslation(ns)
  const { showTextDialog } = useTextInputDialog({ limit: 3 })
  const {
    dimensions: { spacing },
    colors: { black },
    typography: { subtitle2 },
  } = useTheme()

  /**
   ** Callback & Memos functions
   **/
  /*  const navigateToAssetsMap = useCallback(
    (id: string) => {
      storeDisplayedPortfolioId(id)
      if (displayedPortfolioId) {
        setSelectedPortfolios([id])
      }
      navigation.navigate(assetsRoutes.MAIN, { id })
    },
    [displayedPortfolioId, navigation, setSelectedPortfolios, storeDisplayedPortfolioId],
  )*/
  /**
   ** Styles
   **/
  const s = useMemo(
    () => ({
      label: [subtitle2, { paddingBottom: 0 }],
      dialog: { marginHorizontal: spacing },
      fab: [styles.fab, { bottom: spacing, right: spacing / 2 }],
      infoEmpty: [styles.infoEmpty, { padding: spacing, paddingTop: spacing * 2 }],
      infoEmptyTitle: { marginTop: spacing / 4 },
      infoEmptyText: [styles.infoEmptyText, { marginTop: spacing / 2 }],
    }),
    [spacing, subtitle2],
  )

  useFocusEffect(
    useCallback(() => {
      storeDisplayedPortfolioId(undefined)
    }, [storeDisplayedPortfolioId]),
  )

  const navigation = useNavigation<StackNavigationProp<PortfoliosParamList, "PortfoliosList">>()

  const renderPortfolio = ({ item }: { item: Portfolio }) => (
    <RowItem
      isBodyText={false}
      label={item.numberedName}
      labelStyle={s.label}
      onPress={() => navigation.navigate(portfoliosRoutes.ASSETS, { id: item.id })}
      rightChild={{ category: "MaterialIcons", name: "chevron-right" }}
      labelProps={{ numberOfLines: 1, ellipsizeMode: "tail" }}
    />
  )

  const newPortfolio = async () => {
    const { button, text: name } = await showTextDialog(
      {
        title: t("create.title"),
        positive: { label: t("create.button"), type: ButtonType.POSITIVE },
        negative: { label: t("common:actions.cancel"), type: ButtonType.NEGATIVE },
      },
      {
        autoCapitalize: "words",
        inputColor: black.mediumEmphasis,
        style: s.dialog,
        label: t("create.placeholder"),
      },
    )
    if (button === "positive") {
      if (!user || !userData) {
        return
      }
      addPortfolio({
        name,
        roles: { [user?.uid ?? ""]: "owner" },
        assets: [],
        users: {
          [user.uid]: {
            name: `${userData.firstName} ${userData.lastName}`,
            email: user.email ?? "",
          },
        },
      })
    }
  }

  const childInfo = useMemo(
    () => (
      <View style={s.infoEmpty}>
        <H6 style={s.infoEmptyTitle}>{t("emptyPortfolioListTitle")}</H6>
        <Subtitle1 style={s.infoEmptyText}>{t("emptyPortfolioListSubtitle")}</Subtitle1>
      </View>
    ),
    [s, t],
  )

  const isFocused = useIsFocused()

  useEffect(() => {
    if (isFocused) {
      setDisplayedPortfolioId(undefined)
      setSelectedPortfolios([])
    }
  }, [isFocused, setDisplayedPortfolioId, setSelectedPortfolios])

  /**
   ** Effects
   **/
  useEffect(() => {
    if (displayedPortfolioId && _.indexOf(selectedPortfolios, displayedPortfolioId) === -1) {
      resetFilterValues()
      setClearValues(false)
      setPrevSelPortfolios(selectedPortfolios)
      setSelectedPortfolios([displayedPortfolioId])
    }
  }, [
    displayedPortfolioId,
    prevSelPortfolios,
    resetFilterValues,
    selectedPortfolios,
    setClearValues,
    setSelectedPortfolios,
  ])

  useEffect(
    () => () => {
      setSelectedPortfolios(prevSelPortfolios ?? [])
    },
    // eslint-disable-next-line
    [],
  )

  return (
    <>
      <PresentationListView childInfo={childInfo} isEmpty={_.isEmpty(portfolios)} loading={loading}>
        <FlatList<Portfolio>
          data={portfolios}
          renderItem={renderPortfolio}
          keyExtractor={keyExtractor}
          ItemSeparatorComponent={ListSeparator}
        />
      </PresentationListView>
      <FAB style={s.fab} small icon="plus" onPress={newPortfolio} />
    </>
  )
}

const styles = StyleSheet.create({
  fab: {
    position: "absolute",
    height: 48,
    width: 48,
    justifyContent: "center",
    alignItems: "center",
  },
  infoEmpty: { alignItems: "center" },
  infoEmptyText: { textAlign: "center" },
  listStyle: { width: "100%", flex: 1 },
})

export default PortfolioListScreen
