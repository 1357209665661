import { useTheme } from "@siruplab/capsule"
import { DependencyList, useMemo } from "react"
import { StyleSheet } from "react-native"

import { AppTheme } from "../ThemeApp"

export const useStyles = <T extends StyleSheet.NamedStyles<T> | StyleSheet.NamedStyles<any>>(
  // eslint-disable-next-line no-shadow
  create: (theme: AppTheme) => T,
  deps: DependencyList = [],
): T => {
  const theme = useTheme<AppTheme>()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => StyleSheet.create(create(theme)), [...deps, theme])
}
