import { colors, VectorIconProps } from "@siruplab/capsule"

import { ICON_SIZE } from "../../ThemeApp"

export const rightChild = {
  size: ICON_SIZE,
  name: "chevron-right",
  color: colors.surface.disabled,
  category: "MaterialIcons",
}

export const leftChild: Partial<VectorIconProps> = {
  size: ICON_SIZE,
  category: "MaterialIcons",
}
