import { generateShadow, logger, MaterialCommunityIcons, useTheme } from "@siruplab/capsule"
import _ from "lodash"
import React, { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { Platform, StyleProp, View, ViewStyle } from "react-native"
import { Image } from "react-native-image-crop-picker"
import { ActivityIndicator, Chip } from "react-native-paper"
import { v4 as uuidv4 } from "uuid"

import ImagesUploader from "../../../components/ImagesUploader"
import { Asset } from "../../../features/models/Asset"
import { updateAssetData } from "../../../features/models/AssetsFunctions"
import { ICON_SIZE_LARGE, ICON_SIZE_SMALL } from "../../../ThemeApp"
import isWeb from "../../../utils/isWeb"
import { useStyles } from "../../../utils/useStyles"
import { PHOTOS_PATH, uploadToFullPath } from "../../CreateAssetScreen/CreateAssetScreen"
import { ns } from "../i18n/en"

interface Props {
  asset: Asset
  path: string
  chipStyle?: StyleProp<ViewStyle>
  iconColor?: string
  chipColor?: string
  isAbsolute?: boolean
  allowPdf?: boolean
}

const PicChip = ({
  asset,
  path,
  iconColor,
  chipColor,
  chipStyle,
  isAbsolute = false,
  allowPdf = false,
}: Props) => {
  const { t } = useTranslation(ns)

  const [loading, setLoading] = useState(false)
  const {
    colors: { secondary },
  } = useTheme()

  const updatePics = useCallback(
    async (newPics: Image[] | Image) => {
      logger("updatePics", newPics)
      try {
        setLoading(true)
        const picDir = asset?.picDir ?? uuidv4()
        const picKey = isWeb ? "data" : allowPdf ? "fileCopyUri" : "path"
        const newUploadedPhotos = await uploadToFullPath(
          `${picDir}${path}`,
          _.castArray(newPics).map(item => ({ ...item, url: item[picKey] as string })),
        )
        // TODO: this mutates the whole list of pictures in the DB, which may cause conflicts
        const key: keyof Asset = path === PHOTOS_PATH ? "photos1" : "plans1"
        const value: Partial<Asset> = {
          [key]: [...(asset[key] ?? []), ...newUploadedPhotos],
        }
        await updateAssetData(asset.id, value)
      } catch (e) {
        logger("updatePics error: ", e)
      } finally {
        setLoading(false)
      }
    },
    [allowPdf, asset, path],
  )

  const s = useStyles(
    ({
      dimensions: { spacing },
      colors: {
        white: { highEmphasis: white },
      },
      typography: { body2 },
    }) => ({
      icon: { width: ICON_SIZE_SMALL, height: ICON_SIZE_SMALL },
      chipView: {
        position: isAbsolute ? "absolute" : "relative",
        bottom: 0,
        padding: spacing,
        right: 0,
      },
      chipText: {
        ...(Platform.OS === "android" ? body2 : {}),
        includeFontPadding: false,
      },
      chipAsset: {
        height: 32,
        alignSelf: "flex-end",
        paddingLeft: spacing / 4,
        backgroundColor: chipColor ?? white,
        ...generateShadow(2),
      },
      loadingView: {
        width: 120,
        height: ICON_SIZE_LARGE,
        borderRadius: 20,
        alignSelf: "flex-end",
        backgroundColor: white,
        justifyContent: "center",
      },
    }),
    [chipColor],
  )

  return (
    <View style={s.chipView}>
      {loading ? (
        <View style={s.loadingView}>
          <ActivityIndicator color={secondary} size={ICON_SIZE_SMALL} />
        </View>
      ) : (
        <ImagesUploader updatePics={updatePics} allowPdf={allowPdf}>
          <Chip
            style={[s.chipAsset, chipStyle]}
            textStyle={s.chipText}
            avatar={
              allowPdf ? undefined : (
                <MaterialCommunityIcons
                  name="camera"
                  size={ICON_SIZE_SMALL}
                  style={s.icon}
                  color={iconColor}
                />
              )
            }
          >
            {t(allowPdf ? "addPlans" : "addPhotos")}
          </Chip>
        </ImagesUploader>
      )}
    </View>
  )
}

export default PicChip
