import firebase from "firebase"

import { Asset, GeocoderResult } from "../features/models/Asset"
import { Portfolio } from "../features/models/Portfolio"
import { UserData } from "../features/models/UserData"

export interface DevParams {
  dev?: boolean
}

export const collections = {
  // OnBoarding - Server
  LOGIN: "login",

  // Assets Tab
  ASSETS: "assets",
  CLASSIFICATIONS: "classifications",

  // Portfolios
  PORTFOLIOS: "portfolios",

  COMMENTS: "comments",
} as const

export type CollectionNames = typeof collections[keyof typeof collections]

export type Timestamp = firebase.firestore.Timestamp

export type CAsset = Asset
export type CGeocoderResult = GeocoderResult
export type CUserData = UserData
export type CPortfolio = Portfolio
