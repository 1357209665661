import { NavigationProp } from "@react-navigation/core/lib/typescript/src/types"
import { useNavigation } from "@react-navigation/native"
import { ParamListBase } from "@react-navigation/routers"
import { TransitionPresets } from "@react-navigation/stack"
import { useTabLand, useTheme } from "@siruplab/capsule"
import React, { FC, useLayoutEffect, useMemo } from "react"
import { Platform, Pressable, StyleSheet, useWindowDimensions, View } from "react-native"
import { SafeAreaView } from "react-native-safe-area-context"

import { maxWidth as defaultMaxWidth } from "../ThemeApp"
import isWeb from "../utils/isWeb"

interface Props {
  modalHeader?: (navigation?: NavigationProp<ParamListBase>) => JSX.Element
  maxHeight?: number
  maxWidth?: number
  minHeight?: number
  headerShown?: boolean
}

const ModalScreen: FC<Props> = ({
  children,
  modalHeader,
  maxHeight,
  minHeight,
  maxWidth = defaultMaxWidth,
  headerShown = false,
}) => {
  const {
    colors: {
      white: { highEmphasis: white },
      black,
    },
  } = useTheme()

  const isTabLand = useTabLand()
  const { height } = useWindowDimensions()

  const navigation = useNavigation()

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown,
      detachPreviousScreen: false,
      cardStyle: { backgroundColor: "transparent" },
      // see https://github.com/react-navigation/react-navigation/issues/9177 | otherwise, didn't work on android tablets
      ...(isWeb
        ? {}
        : {
            ...Platform.select({
              android: TransitionPresets.FadeFromBottomAndroid,
              default: TransitionPresets.DefaultTransition,
            }),
          }),
    })
  }, [headerShown, isTabLand, navigation])

  const s = useMemo(
    () => ({
      backdrop: { backgroundColor: black.inactive },
      innerView: [
        isTabLand
          ? {
              borderRadius: 8,
              maxWidth,
              minHeight,
              maxHeight: maxHeight !== undefined ? maxHeight : 0.8 * height,
            }
          : styles.smallScreenStyle,
        {
          backgroundColor: white,
        },
        styles.innerView,
      ],
    }),
    [black, height, isTabLand, maxHeight, maxWidth, minHeight, white],
  )

  return (
    <SafeAreaView edges={["left", "right"]} style={styles.outerContainer}>
      <Pressable style={[StyleSheet.absoluteFill, s.backdrop]} onPress={navigation.goBack} />
      <View style={s.innerView}>
        {modalHeader && isTabLand ? modalHeader() : null}
        {children}
      </View>
    </SafeAreaView>
  )
}

export default ModalScreen

const styles = StyleSheet.create({
  outerContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  smallScreenStyle: { width: "100%", height: "100%" },
  innerView: { flex: 1 },
})
