import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

const Welcome3SVG = (props: SvgProps) => (
  <Svg width={115} height={114} viewBox="0 0 115 114" fill="none" {...props}>
    <Path
      d="M90.75 80.75H67c0 2.85-1.9 4.75-4.75 4.75h-9.5c-2.85 0-4.75-1.9-4.75-4.75H24.25v9.5c0 2.85 1.9 4.75 4.75 4.75h57c2.85 0 4.75-1.9 4.75-4.75v-9.5zM90.75 38H76.5V23.75c0-2.85-1.9-4.75-4.75-4.75h-28.5c-2.85 0-4.75 1.9-4.75 4.75V38H24.25c-2.85 0-4.75 1.9-4.75 4.75v28.5c0 2.85 1.9 4.75 4.75 4.75H48v-4.75c0-2.85 1.9-4.75 4.75-4.75h9.5c2.85 0 4.75 1.9 4.75 4.75V76h23.75c2.85 0 4.75-1.9 4.75-4.75v-28.5c0-2.85-1.9-4.75-4.75-4.75zM67 38H48v-9.5h19V38z"
      fill={props.color || "#FF8F86"}
    />
  </Svg>
)

export default Welcome3SVG
