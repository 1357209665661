import { onboardingRoutes } from "../../../features/Navigation/Constants"

export const ns = onboardingRoutes.SIGNUP

export default {
  common: {
    [ns]: {
      title: "Sign up",
    },
  },
  [ns]: {
    title: "One-click email sign-in",
    passwordTitle: "Password sign-in",
    passwordSubtitle: "Sign in with an email and a password.",
    createPassword: "Create a password",
    passwordKnown: "I already have a password",
    subtitle: "Please enter your professional email.\nWe will send you an activation email.",
    placeholder: "Professional email",
    signUp: "Continue",
    required: "Required",
    invalidEmail: "Invalid email",
  },
}
