import React from "react"
import { Image, StyleSheet } from "react-native"
import { MapMarkerProps, Marker as NativeMarker } from "react-native-maps"

import { images } from "../assets/images"
import { ICON_SIZE, ICON_SIZE_LARGE } from "../ThemeApp"
import { CSMarkerProps } from "./Marker"

const pin = images.map_pin_web
const pinDis = images.map_pin_dis_web

const Marker = ({ id, selected, ...props }: CSMarkerProps & MapMarkerProps) => (
  // eslint-disable-next-line no-console
  <>
    <NativeMarker
      {...props}
      icon={{
        // @ts-ignore
        origin: new google.maps.Point(0, 0),
        // @ts-ignore
        url: selected ? pin.uri : pinDis.uri,
        // @ts-ignore
        scaledSize: new google.maps.Size(ICON_SIZE, ICON_SIZE),
        // @ts-ignore
        size: new google.maps.Size(ICON_SIZE_LARGE, ICON_SIZE_LARGE),
        // @ts-ignore
        anchor: new google.maps.Point(ICON_SIZE_LARGE / 2, ICON_SIZE_LARGE / 2),
      }}
      identifier={id}
      stopPropagation
    />
    {/* These icons needs to be manually imported this way to be used as an icon through their uri*/}
    <Image source={pin} style={styles.img} />
    <Image source={pinDis} style={styles.img} />
  </>
)

const styles = StyleSheet.create({
  img: {
    width: 0,
    height: 0,
  },
})

export default Marker
