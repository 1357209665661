import * as React from "react"
import Svg, { G, Mask, Path, SvgProps } from "react-native-svg"

const WelcomeBg4SVG = (props: SvgProps) => (
  <Svg viewBox="0 0 650 616" fill="none" {...props}>
    <Mask id="prefix__a" x={0} y={0} width={650} height={616}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M650 307.734V321h-.297C642.367 484.803 499.799 615.469 325 615.469 150.201 615.469 7.634 484.803.297 321H0V0h650v307.734z"
        fill="#FFF"
      />
    </Mask>
    <G mask="url(#prefix__a)">
      <Path d="M-180.402 658.078h838.667v-629h-838.667v629z" fill="#6A6D66" />
      <Path
        d="M658.233 896.714c131.779 0 238.607-106.828 238.607-238.607S790.012 419.5 658.233 419.5 419.626 526.328 419.626 658.107s106.828 238.607 238.607 238.607z"
        fill="#9A9B98"
      />
      <Path
        d="M343.225 490.458c34.599 0 62.647-28.048 62.647-62.647 0-34.599-28.048-62.647-62.647-62.647-34.599 0-62.647 28.048-62.647 62.647 0 34.599 28.048 62.647 62.647 62.647z"
        fill="#32352F"
      />
      <Path
        d="M-15.866 267.713c-4.978 13.457-7.678 28.054-7.678 43.241 0 69.017 55.94 124.956 124.957 124.956s124.956-55.939 124.956-124.956-55.939-124.956-124.956-124.956c-15.188 0-29.784 2.699-43.241 7.677v74.038h-74.038z"
        fill="#9A9B98"
      />
      <Path
        d="M-15.867 267.713H58.17v-74.037c-34.213 12.614-61.38 39.782-74.037 74.037zM545.636 134.783V239.49c33.539 18.014 71.886 28.223 112.638 28.223 131.791 0 238.607-106.816 238.607-238.607 0-131.79-106.816-238.606-238.607-238.606-131.79 0-238.606 106.816-238.606 238.606 0 37.968 8.859 73.827 24.636 105.719h101.332v-.042z"
        fill="#9A9B98"
      />
      <Path
        opacity={0.5}
        d="M450.844 131.577h-90.701c32.231 87.326 101.585 156.723 188.911 188.911v-87.41c-42.355-22.739-76.864-58.302-98.21-101.501z"
        fill="#32352F"
      />
      <Path
        d="M442.745 131.577c23.16 46.743 60.453 85.259 106.352 109.854V131.577H442.745z"
        fill="#000"
      />
      <Path d="M-180.402 658.107H58.922V548.38h-239.324v109.727z" fill="#9A9B98" />
      <Path
        d="M780.952-310.241H224.723c-41.342 0-75.176-33.833-75.176-75.176v-49.358c0-41.343 33.834-75.177 75.176-75.177h44.549c41.343 0 75.177 33.834 75.177 75.177v862.966"
        stroke="#fff"
        strokeWidth={0.31}
        strokeMiterlimit={10}
      />
      <Path
        opacity={0.5}
        d="M-180.436 937.213c154.146 0 279.106-124.959 279.106-279.105 0-154.146-124.96-279.106-279.106-279.106-154.146 0-279.106 124.96-279.106 279.106 0 154.146 124.96 279.105 279.106 279.105z"
        fill="#32352F"
      />
      <Path d="M-180.401 29.088h838.667v-629h-838.667v629z" fill="#6A6D66" />
      <Path
        d="M485.099-78.595c-12.698 0-24.511-3.628-34.551-9.872-19.659 34.678-30.923 74.797-30.923 117.532 0 131.79 106.816 238.606 238.607 238.606 131.79 0 238.607-106.816 238.607-238.606 0-131.791-106.817-238.607-238.607-238.607-42.735 0-82.854 11.264-117.532 30.923 6.244 10.04 9.872 21.852 9.872 34.55 0 36.154-29.319 65.474-65.473 65.474zM-180.403 29.104H58.921v-437.347h-239.324V29.104z"
        fill="#9A9B98"
      />
      <Path
        d="M780.954-310.241H224.725c-41.343 0-75.176-33.834-75.176-75.177v-49.358c0-41.343 33.833-75.176 75.176-75.176h44.549c41.343 0 75.176 33.833 75.176 75.176v862.967"
        stroke="#fff"
        strokeWidth={0.31}
        strokeMiterlimit={10}
      />
    </G>
  </Svg>
)

export default WelcomeBg4SVG
