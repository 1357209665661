import { onboardingRoutes } from "../../../features/Navigation/Constants"
import { accountRoutes } from "../../MainScreen/Constants"

export const ns = onboardingRoutes.NAMES
export const nsEdit = accountRoutes.NAMES_EDIT

export default {
  common: {
    [ns]: {
      title: "Names",
    },
    [nsEdit]: {
      title: "Name",
    },
  },
  [ns]: {
    screenTitle: "Name",
    title: "What's your name?",
    subtitle: "We use your name in your comments and invitations",
    firstName: "First name",
    lastName: "Last name",
    sameValuesError: "First name or last name must be different than the previous values",
  },
}
