import { firestore, H6, IAddress, logger, Subtitle1, useTheme } from "@siruplab/capsule"
import _ from "lodash"
import React, { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { FlatList, ListRenderItemInfo, Platform, View } from "react-native"
import { LatLng } from "react-native-maps"
import { Chip } from "react-native-paper"

import { collections } from "../../../common/types"
import ListModal from "../../../components/ListModal/ListModal"
import { PortfolioSVG } from "../../../components/svg"
import { Asset, GeocoderResult } from "../../../features/models/Asset"
import { Portfolio } from "../../../features/models/Portfolio"
import { useAssets } from "../../../features/Providers/AssetProvider"
import usePortfolios from "../../../features/Providers/PortfolioProvider"
import { ICON_SIZE } from "../../../ThemeApp"
import { isOfType } from "../../../utils/isOfType"
import { useStyles } from "../../../utils/useStyles"
import { ns as filterNs } from "../../AssetsFilterScreen/i18n/en"
import { ns as createNs } from "../../CreateAssetScreen/i18n/en"
import { ns } from "../i18n/en"

interface IProps {
  asset: Asset
}

export const alterList = (assetId: string, id: string, toAdd?: boolean) =>
  firestore()
    .collection(collections.PORTFOLIOS)
    .doc(id)
    .update({
      assets: firestore.FieldValue[toAdd ? "arrayUnion" : "arrayRemove"](assetId),
    })

const AssetInfos = ({ asset }: IProps) => {
  const { t } = useTranslation([ns, filterNs])
  const { hasEditAccess } = useAssets()
  const { portfolios, portfoliosInfo } = usePortfolios()
  const [loading, setLoading] = useState(false)
  const {
    colors: {
      black: { highEmphasis: black },
    },
  } = useTheme()

  const isAssetEditor = hasEditAccess([asset.id])

  const assetPortfolios = [
    { name: t("editPortfolios"), id: "0" },
    ...portfolios
      ?.filter(item => item?.assets?.find(elem => elem === asset?.id))
      ?.map(old => ({
        name: old.name,
        id: old.id,
      })),
  ]

  const [visible, setVisible] = useState(false)

  const showModal = useCallback(() => setVisible(true), [setVisible])
  const hideModal = useCallback(() => setVisible(false), [setVisible])

  const s = useStyles(({ dimensions: { spacing }, typography: { body2 } }) => ({
    chipText: {
      ...(Platform.OS === "android" ? body2 : {}),
      includeFontPadding: false,
    },
    view: { margin: spacing },
    multiselect: { marginTop: spacing },
    subtitle: { paddingVertical: spacing / 4 },
    bottomMultiSelect: { marginBottom: spacing * 3 },
    chipView: { height: 32, marginHorizontal: spacing / 2.5 },
    contentContainerStyle: { height: 38, alignItems: "center" },
    portfolio: { flexDirection: "row", alignItems: "center", paddingTop: spacing / 4 },
  }))

  const setSelectedItems = async (newItems: string[]) => {
    try {
      setLoading(true)
      // noinspection ES6MissingAwait
      Promise.all(
        _.difference(
          assetPortfolios.map(item => item.id),
          newItems,
        )
          .filter(item => item !== "0")
          .map(item => alterList(asset.id, item, false)),
      )
      // noinspection ES6MissingAwait
      Promise.all(
        _.difference(
          newItems,
          assetPortfolios.map(item => item.id),
        )
          .filter(item => item !== "0")
          .map(item => alterList(asset.id, item, true)),
      )
    } catch (e) {
      logger("Portfolio update error: ", e)
    } finally {
      setLoading(false)
    }
  }

  const removeFromList = useCallback(
    (elemId: string) => () => {
      try {
        alterList(asset.id, elemId)
      } catch (e) {
        logger("Portfolio update error: ", e)
      }
    },
    [asset],
  )

  const renderItem = useCallback(
    ({ item: { id, name } }: ListRenderItemInfo<Pick<Portfolio, "name" | "id">>) => (
      <Chip
        onClose={id !== "0" ? removeFromList(id) : undefined}
        textStyle={s.chipText}
        style={s.chipView}
        onPress={id === "0" ? showModal : undefined}
      >
        {name}
      </Chip>
    ),
    [removeFromList, s, showModal],
  )

  // @ts-ignore
  return (
    <View style={s.view}>
      <H6>{asset.name}</H6>
      <Subtitle1 style={s.subtitle}>
        {isOfType<IAddress>(asset.location, "postalAddress")
          ? asset.location.details?.formatted_address
          : isOfType<GeocoderResult>(asset.location, "formatted_address")
          ? asset.location.formatted_address
          : isOfType<LatLng>(asset.location, "latitude")
          ? t("coordinates", {
              lat: asset.coordinates?.lat?.toFixed(6),
              lng: asset.coordinates?.lng?.toFixed(6),
              ns: createNs,
            })
          : t("noAddress")}
      </Subtitle1>
      <Subtitle1 style={s.subtitle}>
        {asset.classifications
          ?.map(classification => t(`${filterNs}:classification.${classification}`))
          .join(", ")}
      </Subtitle1>
      {!isAssetEditor ? null : (
        <View style={s.portfolio}>
          <PortfolioSVG width={ICON_SIZE} height={ICON_SIZE} color={black} />
          <FlatList
            horizontal
            data={assetPortfolios}
            renderItem={renderItem}
            contentContainerStyle={s.contentContainerStyle}
          />
        </View>
      )}
      <ListModal
        items={portfoliosInfo}
        selectedItems={assetPortfolios.map(elem => elem.id)}
        title={t(`${filterNs}:selectTitles.portfoliosTitle`)}
        firstButtonText={t(`${filterNs}:selectModalButtons.cancel`)}
        secondButtonText={t(`${filterNs}:selectModalButtons.select`)}
        {...{ loading, visible, hideModal, setSelectedItems }}
      />
    </View>
  )
}

export default AssetInfos
