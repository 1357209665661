import { Body2, Subtitle1, useTheme } from "@siruplab/capsule"
import React, { FC, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Image, StyleSheet, TouchableOpacity, View } from "react-native"
import Modal from "react-native-modal"

import { images } from "../assets/images"
import { MapType } from "../screens/AssetMapScreen"
import { ns } from "../screens/AssetMapScreen/i18n/en"
import { BACKDROP_OPACITY } from "../ThemeApp"
import { StateType } from "../utils/StateType"

interface IProps {
  modalVisible: boolean
  toggleModal: () => void
  mapType: StateType<MapType>
}

const mapTypesData: Array<{ value: MapType; img: any }> = [
  {
    value: "standard",
    img: images.map_default,
  },
  {
    value: "satellite",
    img: images.map_satellite,
  },
]

const MapModal: FC<IProps> = ({ mapType, modalVisible, toggleModal }) => {
  const {
    dimensions: { spacing },
    colors: {
      primary,
      white: { highEmphasis: white },
      black: { highEmphasis: black, transparent },
    },
  } = useTheme()

  const { t } = useTranslation(ns)
  const s = useMemo(
    () => ({
      text: { marginTop: spacing / 2 },
      subView: [styles.subView, { paddingTop: spacing }],
      image: [styles.image, { marginHorizontal: spacing }],
      mainView: [styles.mainView, { padding: spacing, backgroundColor: white }],
    }),
    [spacing, white],
  )

  const onPress = (value: MapType) => () => {
    mapType[1](value)
    toggleModal()
  }

  return (
    <Modal
      useNativeDriver
      isVisible={modalVisible}
      onBackdropPress={toggleModal}
      onBackButtonPress={toggleModal}
      backdropOpacity={BACKDROP_OPACITY}
    >
      <View style={s.mainView}>
        <Subtitle1 color={primary}>Map type</Subtitle1>
        <View style={s.subView}>
          {mapTypesData.map((elem, idx) => (
            <TouchableOpacity key={idx} style={styles.touchable} onPress={onPress(elem.value)}>
              <Image
                style={[
                  s.image,
                  {
                    borderColor: mapType[0] === elem.value ? primary : transparent,
                  },
                ]}
                source={elem.img}
              />
              <Body2 style={[s.text, { color: mapType[0] === elem.value ? primary : black }]}>
                {t(elem.value)}
              </Body2>
            </TouchableOpacity>
          ))}
        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  mainView: {
    width: 250,
    height: 150,
    alignSelf: "center",
    borderRadius: 8,
  },
  touchable: {
    alignItems: "center",
  },
  subView: {
    flexDirection: "row",
    justifyContent: "center",
  },
  image: {
    borderRadius: 8,
    borderWidth: 1,
  },
})

export default MapModal
