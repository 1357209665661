import { accountRoutes } from "../../MainScreen/Constants"

export const nsDomaine = accountRoutes.DOMAINE

export default {
  common: {
    [nsDomaine]: {
      title: "Domaine",
    },
  },
}
