import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

const Welcome1SVG = (props: SvgProps) => (
  <Svg width={115} height={114} viewBox="0 0 115 114" fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M57.747 30.15v-7.517c0-.918-.358-1.713-1.04-2.316a3.025 3.025 0 00-2.43-.76c-14.328 1.73-25.525 14.012-25.525 28.785 0 15.945 13.05 28.996 28.995 28.996s28.996-13.051 28.996-28.996V21.68c0-1.328.795-2.465 2.045-2.92a3.047 3.047 0 013.436.936c14.589 17.562 13.663 43.891-2.789 60.334l-29.502 29.503a3.113 3.113 0 01-4.389 0L26.042 80.03c-17.43-17.43-17.43-45.963 0-63.394C38.122 4.556 55.536.85 70.886 5.51a3.063 3.063 0 012.203 2.963v39.853c0 8.444-6.906 15.35-15.35 15.35-8.445 0-15.35-6.906-15.35-15.35 0-7.562 5.541-13.89 12.77-15.132 1.513-.236 2.588-1.512 2.588-3.042z"
      fill={props.color || "#F75D5D"}
    />
  </Svg>
)

export default Welcome1SVG
