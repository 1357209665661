import { rootRoutes } from "../../../features/Navigation/Constants"

export const ns = rootRoutes.SHARE_RECAP

export default {
  [ns]: {
    title: "Sharing portfolio",
    publicShare: "Public sharing",
    publicShareBtn: "Share public link",
    linkCopied: "Link copied!",
    cannotShare: "Add authorised users to share a private link.",
    publicInfo: "Anyone can share and access this link with limited information.",
    restrictedAccess: "Restricted access",
    you: "(you)",
    privateShareBtn: "Share private link",
    privateInfo: "Only {{usersNbr}} authorised users can access.",
    authorizedUsers: "Authorised users",
    addUser: "Add user",
    alert: {
      title: "Public sharing",
      subtitle: "Anyone can share and access this link with limited information.",
      doNotAskAgain: "Do not ask again",
      cancel: "Cancel",
      proceed: "Proceed",
    },
  },
}
