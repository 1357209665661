import { PathConfig } from "@react-navigation/core/src/types"
import { NavigationContainer, NavigationContainerRef } from "@react-navigation/native"
import { createStackNavigator, StackNavigationOptions } from "@react-navigation/stack"
import {
  dynamicLinks,
  IUserContext,
  logger,
  TwoPaneScreen,
  userContext,
  useTabLand,
  useTabTwoPane,
  useTheme,
} from "@siruplab/capsule"
import _ from "lodash"
import React, { FC, useContext, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { ImageResizeMode, Linking, Platform, StyleSheet, View } from "react-native"
import SplashScreen from "react-native-bootsplash"
import { LatLng } from "react-native-maps"
import { URL } from "react-native-url-polyfill"

import { authAndRemoveToken } from "../../App"
import ModalScreen from "../../components/ModalScreen"
import { AssetsSVG, PortfolioSVG } from "../../components/svg"
import WebRightPlaceholder from "../../components/svg/web_right_placeholder"
import AssetMapScreen from "../../screens/AssetMapScreen"
import AssetSelectionScreen from "../../screens/AssetSelectionScreen.tsx/AssetSelectionScreen"
import AssetsFilterScreen from "../../screens/AssetsFilterScreen/AssetsFilterScreen"
import AssetViewScreen from "../../screens/AssetViewScreen/AssetViewScreen"
import CompanyScreen from "../../screens/CompanyScreen/CompanyScreen"
import CreateAssetScreen from "../../screens/CreateAssetScreen/CreateAssetScreen"
import ImageViewScreen from "../../screens/ImageViewScreen/ImageViewScreen"
import LoginScreen from "../../screens/LoginScreen/LoginScreen"
import { accountRoutes, mainRoutes, portfoliosRoutes } from "../../screens/MainScreen/Constants"
import MainNavigator, {
  assetsWebStack,
  screens as mainScreens,
  tabNavRef,
} from "../../screens/MainScreen/MainNavigator"
import { accountStack, assetsStack, useCommonHeader } from "../../screens/MainScreen/MainTabs"
import NameScreen from "../../screens/NameScreen/NameScreen"
import PasswordCreateScreen from "../../screens/PasswordCreateScreen/PasswordCreateScreen"
import PortfolioShareRecapScreen from "../../screens/PortfolioShareRecap/PortfolioShareRecapScreen"
import PortfolioViewScreen from "../../screens/PortfolioViewScreen/PortfolioViewScreen"
import ShareScreen from "../../screens/ShareScreen/ShareScreen"
import SignInValidationScreen from "../../screens/SignInValidationScreen/SignInValidationScreen"
import SignUpScreen, { actionCodeSettings } from "../../screens/SignUpScreen/SignUpScreen"
import WelcomeScreen from "../../screens/WelcomeScreen/WelcomeScreen"
import { maxWidth, tabSizeLand } from "../../ThemeApp"
import isWeb from "../../utils/isWeb"
import { Attachment } from "../models/Asset"
import { UserData } from "../models/UserData"
import usePortfolios from "../Providers/PortfolioProvider"
import { OnboardingRouteNames, onboardingRoutes, RootRouteNames, rootRoutes } from "./Constants"

// eslint-disable-next-line @typescript-eslint/ban-types
export type OnboardingParamList = Record<OnboardingRouteNames, object | undefined>

export type RootParamList = Record<RootRouteNames, Record<string, unknown> | undefined> & {
  CreateAsset: {
    isEdit?: boolean
    portfolioId?: string
    coordinate: LatLng
    fromMap: boolean
  }
  AssetView: {
    id?: string
  }
  ElemShare: {
    isPortfolio?: boolean
  }
  ImageView: {
    assetId?: string
    index?: number
    plans?: boolean
    images?: Attachment[]
  }
}

const onboardingScreens: Record<OnboardingRouteNames, string | PathConfig> = {
  Names: "names",
  SignUp: "signin",
  Login: "login",
  PasswordCreate: "password_create",
  PasswordLogin: "password_login",
  Loading: "loading",
  Welcome: "welcome",
  Company: "company",
  SignInValidation: "activation",
}

export const onboardingNavigator = createStackNavigator<OnboardingParamList>()
export const rootNavigator = createStackNavigator<RootParamList>()

// @ts-ignore
export const screens: Record<RootRouteNames, string | PathConfig> = {
  AssetsFilter: "filter",
  ElemShare: "elemShare",
  ImageView: "viewer",
  AssetSelection: "assetSelection",
  MainNavigator: { path: "main", screens: mainScreens },
  Onboarding: { screens: onboardingScreens },
  ...(Platform.OS !== "web"
    ? {
        CreateAsset: "createAsset",
      }
    : {}),
}

const RootNavigator: FC = () => {
  const { t } = useTranslation()
  const {
    typography: { h6 },
    colors: {
      black: { mediumEmphasis },
      surface: { appUi },
      white: { highEmphasis: white },
    },
  } = useTheme()

  const isTabTwoPane = useTabTwoPane()
  const isTabLand = useTabLand()

  const { user, initializing, userDataLoading, handleDynamicLink, userData, loading } = useContext<
    IUserContext<UserData>
  >(userContext)

  const rootOptions = useMemo<StackNavigationOptions>(
    () => ({
      title: "",
      headerTitleStyle: h6,
      headerBackTitleVisible: false,
      headerTintColor: mediumEmphasis,
      headerStyle: [styles.header, { backgroundColor: appUi }],
    }),
    [appUi, h6, mediumEmphasis],
  )

  const { publicMode, handlePortfolioPublicMode } = usePortfolios()

  // eslint-disable-next-line no-console
  console.log("Root navigator", {
    user,
    userData,
    publicMode,
    initializing,
    loading,
    userDataLoading,
  })

  useEffect(() => {
    if (isWeb) {
      ;(async () => {
        const url = await Linking.getInitialURL()
        logger("dynamic link", url, user, `${actionCodeSettings.url}main/portfolios/portfolioView`)
        const hasToken = url ? !!new URL(url).searchParams.get("refreshToken") : false
        if (
          url !== null &&
          !_.isEqual(url, actionCodeSettings.url) &&
          new URL(url).search &&
          !hasToken
        ) {
          handleDynamicLink({ url, minimumAppVersion: null })
        } else if (url?.startsWith(`${actionCodeSettings.url}/main/assets/assetView/`)) {
          const newUrl = await authAndRemoveToken({ url, minimumAppVersion: null })
          const assetId = newUrl.url.split("/").pop()
          const navRef = await new Promise<NavigationContainerRef | null>(resolve => {
            const getNavRef = (idx: number) => {
              const nav = tabNavRef.current
              if (nav !== null || idx === 25) {
                resolve(nav)
              } else {
                setTimeout(() => getNavRef(idx + 1), 300)
              }
            }
            getNavRef(0)
          })
          navRef?.navigate(rootRoutes.ASSET_VIEW, { id: assetId })
        } else if (url?.startsWith(`${actionCodeSettings.url}main/portfolios/portfolioView`)) {
          handlePortfolioPublicMode(url)
        }
      })()
    } else {
      ;(async () => {
        const dynamicLink = await dynamicLinks().getInitialLink()
        if (dynamicLink?.url.startsWith(`${actionCodeSettings.url}main/portfolios/portfolioView`)) {
          handlePortfolioPublicMode(dynamicLink.url)
        }
      })()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!initializing && !userDataLoading) {
      logger("hiding splash screen")
      SplashScreen.hide({ fade: true })
      if (!!global.execListener) {
        global.execListener()
        global.execListener = undefined
      }
    }
  }, [initializing, userDataLoading])

  const commonHeader = useCommonHeader(PortfolioSVG, "tabs.portfolios")

  const TwoPaneScreenPublic = () => {
    const noHeaderLeftOptions = {
      headerLeft: undefined,
      headerLeftContainerStyle: undefined,
    }

    const mapHeader = useCommonHeader(AssetsSVG, "tabs.assets", true)

    return (
      <TwoPaneScreen customWidth={maxWidth + tabSizeLand}>
        <rootNavigator.Navigator
          // @ts-ignore
          initialRouteName={portfoliosRoutes.ASSETS}
          screenOptions={rootOptions}
        >
          <rootNavigator.Screen
            // @ts-ignore
            name={portfoliosRoutes.ASSETS}
            component={PortfolioViewScreen}
            initialParams={{ isPublic: true }}
            options={{
              ...commonHeader,
              title: "",
              headerStyle: styles.header,
              ...noHeaderLeftOptions,
            }}
          />
          {!isTabTwoPane ? (
            <>
              <rootNavigator.Screen
                name={portfoliosRoutes.ASSET_VIEW}
                component={AssetViewScreen}
                options={{
                  ...rootOptions,
                  title: "",
                  headerStyle: [
                    rootOptions.headerStyle,
                    { borderBottomWidth: 1, backgroundColor: white },
                  ],
                  ...noHeaderLeftOptions,
                }}
              />
              <rootNavigator.Screen
                component={AssetMapScreen}
                name={mainRoutes.ASSETS_TAB}
                options={mapHeader}
              />
            </>
          ) : null}
        </rootNavigator.Navigator>
        <NavigationContainer independent ref={tabNavRef} documentTitle={{ enabled: false }}>
          <assetsWebStack.Navigator>
            <assetsWebStack.Screen
              component={AssetMapScreen}
              name={mainRoutes.ASSETS_TAB}
              options={{ headerShown: false }}
            />
            <assetsWebStack.Screen
              component={AssetViewScreen}
              name={rootRoutes.ASSET_VIEW}
              options={{ title: "", headerBackTitleVisible: false, headerTintColor: "black" }}
            />
          </assetsWebStack.Navigator>
        </NavigationContainer>
      </TwoPaneScreen>
    )
  }

  const navOnboardingSwitcher = useMemo(() => {
    /** SIGN IN FLOW */
    if (!user) {
      return (
        <>
          <onboardingNavigator.Screen
            name={onboardingRoutes.WELCOME}
            options={{ headerShown: false, title: t("appName") }}
          >
            {props => <WelcomeScreen isOnBoarding={true} props={props} />}
          </onboardingNavigator.Screen>
          <onboardingNavigator.Screen
            name={onboardingRoutes.PASSWORD_CREATE}
            component={PasswordCreateScreen}
            options={{
              title: t(`${onboardingRoutes.PASSWORD_CREATE}.title`),
              headerTitle: "",
            }}
          />
          <onboardingNavigator.Screen
            name={onboardingRoutes.SIGNUP}
            component={SignUpScreen}
            options={{
              title: t(`${onboardingRoutes.SIGNUP}.title`),
              headerTitle: "",
            }}
          />
          <onboardingNavigator.Screen
            name={onboardingRoutes.LOGIN}
            component={LoginScreen}
            options={{
              title: t(`${onboardingRoutes.LOGIN}.title`),
              headerTitle: "",
            }}
          />
          <onboardingNavigator.Screen
            name={onboardingRoutes.SIGNIN_VALIDATION}
            component={SignInValidationScreen}
            options={{
              title: t(`${onboardingRoutes.SIGNIN_VALIDATION}.title`),
              headerTitle: "",
            }}
          />
        </>
      )
    }

    if (user && userData?.guest === true) {
      return null
    }

    if ((user && userData && (!userData?.firstName || !userData?.lastName)) || !userData) {
      return (
        <onboardingNavigator.Screen
          name={onboardingRoutes.NAMES}
          component={NameScreen}
          options={{ title: t(`${onboardingRoutes.NAMES}.title`), headerTitle: "" }}
        />
      )
    }
    if (user && userData && !userData?.companyName) {
      return (
        <onboardingNavigator.Screen
          name={onboardingRoutes.COMPANY}
          component={CompanyScreen}
          options={{ title: t(`${onboardingRoutes.COMPANY}.title`), headerTitle: "" }}
        />
      )
    }

    return null
  }, [t, user, userData])

  const modalHeader = useMemo(() => ({ borderBottomWidth: 1, backgroundColor: white }), [white])

  const navSwitcher = useMemo(
    () =>
      !user ? null : (
        <>
          {/** USER FLOW */}
          <rootNavigator.Screen
            name={rootRoutes.MAIN_NAVIGATOR}
            component={MainNavigator}
            options={{ headerShown: false }}
          />
          <rootNavigator.Screen
            name={rootRoutes.IMAGE_VIEW}
            component={ImageViewScreen}
            options={{
              ...rootOptions,
              headerStyle: [rootOptions.headerStyle, modalHeader],
            }}
          />
          {!isTabTwoPane ? (
            <>
              <rootNavigator.Screen
                name={rootRoutes.CREATE_ASSET}
                component={CreateAssetScreen}
                options={({ route }) => ({
                  ...rootOptions,
                  headerStyle: [rootOptions.headerStyle, modalHeader],
                  title: t(
                    `${rootRoutes.CREATE_ASSET}.${route.params?.isEdit ? "altTitle" : "title"}`,
                  ),
                  headerTitle: t(
                    `${rootRoutes.CREATE_ASSET}.${route.params?.isEdit ? "altTitle" : "title"}`,
                  ),
                })}
              />
            </>
          ) : null}
          <assetsStack.Screen
            name={rootRoutes.ASSET_VIEW}
            component={AssetViewScreen}
            options={{
              ...rootOptions,
              title: "",
              headerStyle: [
                rootOptions.headerStyle,
                { borderBottomWidth: 1, backgroundColor: white },
              ],
            }}
          />
          <rootNavigator.Screen
            name={rootRoutes.ELEM_SHARE}
            options={{
              ...rootOptions,
              title: "",
              headerStyle: [rootOptions.headerStyle, { borderBottomWidth: 1 }],
            }}
          >
            {() => (
              <ModalScreen headerShown={!isTabLand}>
                <ShareScreen />
              </ModalScreen>
            )}
          </rootNavigator.Screen>
          <rootNavigator.Screen
            name={rootRoutes.SHARE_RECAP}
            options={{
              ...rootOptions,
              title: "",
              headerStyle: [rootOptions.headerStyle, { borderBottomWidth: 1 }],
            }}
          >
            {() => (
              <ModalScreen headerShown={!isTabLand} maxWidth={560}>
                <PortfolioShareRecapScreen />
              </ModalScreen>
            )}
          </rootNavigator.Screen>
          <rootNavigator.Screen
            name={rootRoutes.ASSET_SELECTION}
            options={{
              ...rootOptions,
              title: t(`${rootRoutes.ASSET_SELECTION}.title`),
              headerShown: false,
            }}
          >
            {() => (
              <ModalScreen>
                <AssetSelectionScreen />
              </ModalScreen>
            )}
          </rootNavigator.Screen>
          <accountStack.Screen
            name={accountRoutes.DOMAINE}
            options={{
              title: t(`${accountRoutes.DOMAINE}.title`),
              headerStyle: [rootOptions.headerStyle, { borderBottomWidth: 1 }],
            }}
          >
            {props => (
              <ModalScreen headerShown={!isTabLand}>
                <WelcomeScreen isOnBoarding={false} props={props} />
              </ModalScreen>
            )}
          </accountStack.Screen>
          {isTabTwoPane ? null : (
            <>
              <rootNavigator.Screen
                name={rootRoutes.ASSETS_FILTER}
                component={AssetsFilterScreen}
                options={{
                  ...rootOptions,
                  headerStyle: [rootOptions.headerStyle, { backgroundColor: white }],
                  title: t(`${rootRoutes.ASSETS_FILTER}.title`),
                  headerTitle: t(`${rootRoutes.ASSETS_FILTER}.title`),
                  headerLeftContainerStyle: undefined,
                }}
              />
            </>
          )}
        </>
      ),
    [user, rootOptions, modalHeader, isTabTwoPane, t, white, isTabLand],
  )

  return initializing || userDataLoading || loading ? null : publicMode ? (
    <TwoPaneScreenPublic />
  ) : (
    <rootNavigator.Navigator initialRouteName={rootRoutes.ONBOARDING} screenOptions={rootOptions}>
      {navOnboardingSwitcher ? (
        <rootNavigator.Screen name={rootRoutes.ONBOARDING} options={{ headerShown: false }}>
          {() => (
            <Onboarding screenOptions={{ ...rootOptions }}>{navOnboardingSwitcher}</Onboarding>
          )}
        </rootNavigator.Screen>
      ) : user ? (
        navSwitcher
      ) : null}
    </rootNavigator.Navigator>
  )
}

const Onboarding: FC<{ screenOptions?: StackNavigationOptions }> = ({
  children,
  screenOptions,
}) => {
  const isTabLand = useTabLand()

  const screen = (
    <onboardingNavigator.Navigator screenOptions={screenOptions}>
      {children}
    </onboardingNavigator.Navigator>
  )

  // isTabLand or isTabTwoPane?
  if (!isTabLand) {
    return screen
  }

  return (
    <View style={styles.container} {...(isWeb ? { pointerEvents: "none" } : {})}>
      <View style={styles.onboardingWebInnerContainer}>{screen}</View>
      <WebRightPlaceholder preserveAspectRatio="xMinYMin slice" />
    </View>
  )
}

const styles = StyleSheet.create({
  onboardingWebInnerContainer: { width: maxWidth },
  container: { flexDirection: "row", height: "100%" },
  placeholder: { flex: 1, height: "100%" },
  carouselImage: { width: 114, height: 114, resizeMode: "contain" as ImageResizeMode },
  header: { elevation: 0, shadowOpacity: 0, borderBottomWidth: 0 },
  headerTitleContainer: { marginLeft: -16, paddingTop: 2 },
})

export default RootNavigator
