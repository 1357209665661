import Clipboard from "@react-native-clipboard/clipboard"
import { useNavigation } from "@react-navigation/native"
import {
  ButtonType,
  COMMON_NAMESPACE,
  firestore,
  functions,
  IUserContext,
  logger,
  useAlert,
  userContext,
  useTabLand,
} from "@siruplab/capsule"
import { FormikHelpers } from "formik"
import _ from "lodash"
import { useContext, useLayoutEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Keyboard } from "react-native"

import { collections } from "../../../common/types"
import { Asset, AssetRole } from "../../../features/models/Asset"
import { updateAssetData } from "../../../features/models/AssetsFunctions"
import { CommonPortfolio, OwnerInfos, PortfolioRole } from "../../../features/models/Portfolio"
import { updatePortfolioData } from "../../../features/models/PortfolioFunctions"
import { UserData } from "../../../features/models/UserData"
import { useAssets } from "../../../features/Providers/AssetProvider"
import usePortfolios from "../../../features/Providers/PortfolioProvider"
import { maxWidth } from "../../../ThemeApp"
import { getAssetShareUrl } from "../../../utils/getShareUrls"
import safeAwait from "../../../utils/safeAwait"
import { useStyles } from "../../../utils/useStyles"
import { ns } from "../i18n/en"

export interface ShareForm {
  role: AssetRole | PortfolioRole | null
  email: string
}

interface Props {
  isPortfolio?: boolean
}

const useShareScreen = ({ isPortfolio }: Props) => {
  const { portfolios, displayedPortfolioId } = usePortfolios()
  const { assets, selectedAsset } = useAssets()
  const { showDialog } = useAlert()
  const isTab = useTabLand()

  const { user } = useContext<IUserContext<UserData>>(userContext)
  const elemInfos = isPortfolio
    ? portfolios.find(portfolio => portfolio.id === displayedPortfolioId)
    : assets.find(asset => asset.id === selectedAsset)

  const usersToRemove = useState<string[]>([])
  const [checked, setChecked] = useState<string>()
  const [role, setRole] = useState<AssetRole | PortfolioRole>()
  const [modalVisible, setModalVisible] = useState(false)
  const rolesToUpdate = useState(elemInfos?.roles)
  const [collapsed, setCollapsed] = useState(!isPortfolio)

  const navigation = useNavigation()
  const { t } = useTranslation([ns, COMMON_NAMESPACE])

  const commonInfos: CommonPortfolio[] = isPortfolio
    ? []
    : _.uniqBy(
        _.sortBy(
          _.map(
            _.filter(
              portfolios,
              portfolio =>
                !!_.find(portfolio.assets, item => item === selectedAsset) &&
                !(_.findKey(portfolio.roles, itemRole => itemRole === "owner") === user?.uid),
            ),
            portfolio => ({
              ...portfolio,
              ownerInfos: portfolio.users[
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                _.findKey(portfolio.roles, itemRole => itemRole === "owner")!
              ] as OwnerInfos,
            }),
          ),
          portfolio => (user?.uid ? portfolio.roles[user?.uid] : undefined),
        ),
        portfolio => portfolio.ownerInfos.name,
      )

  const users = _.map(elemInfos?.users, (val, key) => ({
    uid: key,
    ...val,
  }))

  const s = useStyles(() => ({
    dialogRightButton: {
      marginLeft: 0,
      flex: 0,
    },
  }))

  const removeUserFromFirebase = async (uid?: string) => {
    const uidsToRemove = uid ? [uid] : usersToRemove[0]
    if (uidsToRemove.length > 0) {
      await safeAwait(
        firestore()
          .collection(collections[isPortfolio ? "PORTFOLIOS" : "ASSETS"])
          .doc(isPortfolio ? displayedPortfolioId : selectedAsset)
          .update(
            uidsToRemove
              .flatMap(e => [`roles.${e}`, `users.${e}`])
              .reduce((e, i) => ({ ...e, [i]: firestore.FieldValue.delete() }), {}),
          ),
      )
    }
  }

  const onSubmit = async ({ email }: ShareForm, { resetForm }: FormikHelpers<ShareForm>) => {
    try {
      if (!user) {
        return
      }
      setCollapsed(true)
      Keyboard.dismiss()

      if (!isPortfolio) {
        const portfoliosForAsset = portfolios.filter(portfolio =>
          portfolio.assets?.find(asset => asset === selectedAsset),
        )
        const removedPreservedAccess =
          usersToRemove[0].length > 0 &&
          _.every(
            _.map(portfoliosForAsset, portfolio =>
              usersToRemove[0].find(assetUser => !!portfolio.users[assetUser]),
            ),
          )
        const updatedRoles = Object.keys(rolesToUpdate[0] as Asset["roles"])
        const updatedPreservedAccess =
          updatedRoles.length > 0 &&
          _.every(
            _.map(portfoliosForAsset, portfolio =>
              updatedRoles.find(userId => portfolio.roles[userId] === "editor"),
            ),
          )
        if (removedPreservedAccess || updatedPreservedAccess) {
          showDialog({
            type: "button",
            title: t("modalTitle"),
            message: t("modalDescription"),
            positive: {
              buttonStyle: s.dialogRightButton,
              type: ButtonType.POSITIVE,
              label: t(`common:button.ok`),
              labelType: "destructive",
            },
            style: isTab ? { margin: "auto", maxWidth } : {},
          })
        }
      }

      await removeUserFromFirebase()

      if (!_.isEqual(rolesToUpdate[0], elemInfos?.roles)) {
        await safeAwait(
          isPortfolio
            ? updatePortfolioData(
                firestore().collection(collections.PORTFOLIOS).doc(displayedPortfolioId),
                {
                  roles: rolesToUpdate[0] as { [uid: string]: PortfolioRole },
                },
              )
            : updateAssetData(selectedAsset, {
                roles: rolesToUpdate[0] as { [uid: string]: AssetRole },
              }),
        )
      }

      if (email.length > 0 && user) {
        const emailsToAdd = email.replace(/\s/g, "").split(",")

        await safeAwait(
          functions().httpsCallable(isPortfolio ? "handlePortfolioShare" : "handleAssetShare")({
            role,
            users: emailsToAdd,
            fromId: user.uid,
            ...(isPortfolio ? { elemId: displayedPortfolioId } : { assetId: selectedAsset }),
          }),
        )
      }
    } catch (e) {
      logger("Share error: ", e)
    } finally {
      resetForm()
      navigation.goBack()
      setRole(undefined)
      setChecked(undefined)
    }
  }

  useLayoutEffect(() => {
    navigation.setOptions({
      title: t(`title${isPortfolio ? "Portfolio" : "Asset"}`),
    })
  }, [isPortfolio, navigation, t])

  const onPress = async () => {
    try {
      setModalVisible(true)
      // Build manually because of the web version
      Clipboard.setString(getAssetShareUrl(selectedAsset))
    } catch (e) {
      logger(e)
    } finally {
      setTimeout(() => {
        setModalVisible(false)
      }, 1000)
    }
  }

  const onItemPress = (elem: { value: AssetRole | PortfolioRole; title: string }) => () => {
    setRole(elem.value as AssetRole | PortfolioRole)
    setChecked(elem.title)
  }

  return {
    isPortfolio,
    users,
    checked,
    role,
    elemInfos,
    rolesToUpdate,
    usersToRemove,
    onSubmit,
    onPress,
    modalVisible,
    setModalVisible,
    collapsed,
    setCollapsed,
    commonInfos,
    onItemPress,
    removeUserFromFirebase,
  }
}

export default useShareScreen
