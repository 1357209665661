import {
  COMMON_NAMESPACE,
  emailListRegex,
  IUserContext,
  MultipleTextInputFormikConfig,
  MultipleTextInputScreen,
  Subtitle1,
  TextInputProps,
  userContext,
  useTheme,
} from "@siruplab/capsule"
import React, { useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet, View } from "react-native"
import * as Yup from "yup"

import { UserData } from "../features/models/UserData"
import { ns } from "../screens/CompanyScreen/i18n/en"
import { AppTheme } from "../ThemeApp"

export interface CompanyValues {
  teammatesEmails: string
  companyName: string
}

interface Props {
  isEdit: boolean
  isCompanyEdit: boolean
  isTeammatesEdit: boolean
  onSubmit: (values: CompanyValues) => void
}

const CompanyInputs = ({ onSubmit, isEdit, isCompanyEdit, isTeammatesEdit }: Props) => {
  const { t } = useTranslation([ns, COMMON_NAMESPACE])
  const { userData } = useContext<IUserContext<UserData>>(userContext)
  const {
    dimensions: { spacing },
    colors: { black },
    typography: { body2 },
  } = useTheme<AppTheme>()

  const s = useMemo(
    () => ({
      input: {
        marginVertical: spacing / 2,
        fontSize: body2.fontSize,
        minWidth: 0,
      },
      middleText: [styles.text, { color: black.highEmphasis, marginVertical: spacing / 2 }],
      middleView: [styles.middleView, { backgroundColor: black.separator, marginTop: spacing / 2 }],
    }),
    [black, body2, spacing],
  )

  // TODO: Handle sending invitations on signup

  const commonEmailProps: TextInputProps & { name: string } = {
    name: "teammatesEmails",
    placeholder: t("teammatesEmailsPlaceholder"),
    autoCapitalize: "none",
  }

  return (
    <MultipleTextInputScreen<CompanyValues>
      inputsStyle={s.input}
      formikConfig={MultipleTextInputFormikConfig<CompanyValues>({
        onSubmit,
        values: {
          companyName: isEdit ? userData?.companyName ?? "" : "",
          teammatesEmails: isEdit ? userData?.teammatesEmails ?? "" : "",
        },
        validationSchema: Yup.object({
          companyName: isCompanyEdit
            ? Yup.string()
                .required(t("common:fields.required"))
                .test(
                  "Should not match the previous value",
                  t("sameValuesError"),
                  value => value !== userData?.companyName,
                )
            : Yup.string().required(t("common:fields.required")),
          teammatesEmails: isTeammatesEdit
            ? Yup.string()
                .matches(emailListRegex, {
                  message: t("invalidEmails"),
                })
                .test(
                  "Should not match the previous value",
                  t("sameValuesError"),
                  value => value !== userData?.teammatesEmails,
                )
                .required(t("common:fields.required"))
            : Yup.string().matches(emailListRegex, {
                message: t("invalidEmails"),
              }),
        }),
      })}
      middleComp={
        !isEdit
          ? [
              <>
                <View style={s.middleView} />
                <Subtitle1 style={s.middleText}>{t("subtitle")}</Subtitle1>
              </>,
            ]
          : []
      }
      buttonLabel={t(`common:button.${isEdit ? "save" : "continue"}`)}
      inputProps={{
        onBlur: undefined,
        onTouchStart: undefined,
      }}
      inputs={
        !isEdit
          ? [
              { name: "companyName", label: t("companyName") },
              {
                ...commonEmailProps,
                label: t("teammatesEmails"),
              },
            ]
          : isCompanyEdit
          ? [{ name: "companyName", label: t("companyName") }]
          : [
              {
                ...commonEmailProps,
                label: t("teammatesEmailsEdit"),
              },
            ]
      }
    />
  )
}

const styles = StyleSheet.create({
  middleView: {
    height: 1,
  },
  text: {
    alignSelf: "center",
    textAlign: "center",
  },
})

export default CompanyInputs
