import AsyncStorage from "@react-native-async-storage/async-storage"
import Clipboard from "@react-native-clipboard/clipboard"
import {
  CommonActions,
  RouteProp,
  useIsFocused,
  useNavigation,
  useNavigationState,
  useRoute,
} from "@react-navigation/native"
import {
  Body2,
  Button,
  Caption,
  EmptyView,
  firestore,
  generateShadow,
  H6,
  IBodyProps,
  IUserContext,
  KeyboardSafeAreaScrollView,
  MaterialCommunityIcons,
  MaterialIcons,
  RowItem,
  StaticMap,
  userContext,
  useTabLand,
  useTabTwoPane,
  useTheme,
} from "@siruplab/capsule"
import _ from "lodash"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { View } from "react-native"
import Modal from "react-native-modal"

import { collections } from "../../common/types"
import Marker from "../../components/Marker"
import { DrawBarSVG, PlansSVG, RollMeterSVG } from "../../components/svg"
import BillSVG from "../../components/svg/bill_svg"
import { UserData } from "../../features/models/UserData"
import { rootRoutes } from "../../features/Navigation/Constants"
import { navigate, rootNav } from "../../features/Navigation/NavigationService"
import { RootParamList } from "../../features/Navigation/RootNavigator"
import AssetCommentsProvider from "../../features/Providers/AssetCommentsProvider"
import { useAssets } from "../../features/Providers/AssetProvider"
import usePortfolios from "../../features/Providers/PortfolioProvider"
import { AppTheme, ICON_SIZE, ICON_SIZE_LARGE, ICON_SIZE_SMALL } from "../../ThemeApp"
import { formatMonetaryValue } from "../../utils/formatMonetaryValue"
import { getAssetShareUrl } from "../../utils/getShareUrls"
import { useStyles } from "../../utils/useStyles"
import { PLANS_PATH } from "../CreateAssetScreen/CreateAssetScreen"
import { ns as createNs } from "../CreateAssetScreen/i18n/en"
import { assetsRoutes, mainRoutes } from "../MainScreen/Constants"
import { useMixedNav } from "../MainScreen/MainNavigator"
import { ns as shareNs } from "../ShareScreen/i18n/en"
import AssetCarousel from "./components/AssetCarousel"
import AssetComments from "./components/AssetComments"
import AssetInfos from "./components/AssetInfos"
import AssetViewHeaderRight from "./components/AssetViewHeaderRight"
import PicChip from "./components/PicChip"
import { ns } from "./i18n/en"

const AssetViewScreen = () => {
  const {
    colors: {
      black: { separator, mediumEmphasis, overlay },
      white: { highEmphasis: white },
    },
  } = useTheme<AppTheme>()
  const { selectedAsset, assets, setSelectedAsset, canEdit, canDelete, canView } = useAssets()
  const { t, i18n } = useTranslation([ns, createNs, shareNs])

  const { displayedPortfolio, publicMode } = usePortfolios()

  const isFromPublic = useMemo(() => displayedPortfolio?.isPublic === true, [
    displayedPortfolio?.isPublic,
  ])

  const { userData, user } = useContext<IUserContext<UserData>>(userContext)

  const { params, name } = useRoute<RouteProp<RootParamList, "AssetView">>()

  const navigation = useNavigation()
  const currentNavState = useNavigationState(navState => navState)

  const isFocused = useIsFocused()
  const isTabTwoPane = useTabTwoPane()
  const isTab = useTabLand()

  const navigationElem = isTabTwoPane ? rootNav() : navigation

  const asset = useMemo(() => assets.find(elem => elem.id === selectedAsset), [
    assets,
    selectedAsset,
  ])
  const mixedNav = useMixedNav()

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => <AssetViewHeaderRight />,
    })
  }, [asset, navigation, selectedAsset, canEdit, canView, canDelete])

  useEffect(() => {
    if (currentNavState.index === 0 && currentNavState.routes[0].name === rootRoutes.ASSET_VIEW) {
      // A setTimeout is required here to wait for the navState to be "ready" for a reset on dynamicLink received
      setTimeout(() => {
        navigation.dispatch(prevState =>
          CommonActions.reset({
            ...prevState,
            routes: [...[{ name: "Assets_Main" }], ...[prevState.routes[0]]],
            index: 1,
          }),
        )
      })
    }
  }, [name, navigation, params, currentNavState])

  const s = useStyles(
    ({
      dimensions: { spacing },
      colors: {
        secondaryLight,
        black: { highEmphasis: black, disabled },
      },
    }) => ({
      indicator: { paddingTop: spacing },
      icon: { width: ICON_SIZE_SMALL, height: ICON_SIZE_SMALL },
      bottomView: { margin: spacing },
      rowChip: { backgroundColor: separator },
      description: { paddingTop: spacing * 2, paddingBottom: spacing },
      additionalRowStyle: { paddingRight: spacing },
      mainView: { flex: 1, backgroundColor: white },
      rightChild: { flexDirection: "row", alignItems: "center" },
      row: { height: 56, borderBottomWidth: 1, borderColor: separator },
      shareButton: generateShadow(2),
      signUpTexts: { margin: spacing, justifyContent: "space-between", flex: 1 },
      signUpSubtitle: { marginVertical: spacing, textAlign: "center" },
      signUpRefusesBtn: { color: black },
      iconBtnView: { flexDirection: "row", alignItems: "flex-end" },
      chip: {
        shadowOpacity: 0,
        borderWidth: 0,
        backgroundColor: overlay,
        elevation: 0,
      },
      copyModal: {
        width: 130,
        height: 130,
        borderRadius: 8,
        alignSelf: "center",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: disabled,
      },
      chipAsset: {
        height: 32,
        alignSelf: "flex-end",
        backgroundColor: white,
        ...generateShadow(2),
      },
      safeAreaTab: {
        maxWidth: 700,
        width: "100%",
        alignSelf: "center",
        marginBottom: isTab ? spacing : 0,
      },
      mapView: {
        width: "100%",
        alignSelf: "center",
        borderRadius: 8,
        borderWidth: 1,
        overflow: "hidden",
        borderColor: separator,
        marginVertical: spacing,
      },
      map: {
        width: "100%",
        height: 220,
        borderRadius: 8,
      },
      shareNbr: {
        backgroundColor: secondaryLight,
        borderRadius: 50,
        marginLeft: -12,
        alignItems: "center",
        justifyContent: "center",
        width: 13,
        height: 13,
      },
      signUpView: {
        flex: 1,
        ...generateShadow(4),
        backgroundColor: white,
        borderRadius: 8,
        width: "100%",
        marginTop: spacing * 2,
      },
      emptyScreenTitle: { marginBottom: spacing },
    }),
  )

  useEffect(() => {
    if (params?.id && isFocused) {
      setSelectedAsset(params.id)
    }
  }, [isFocused, params, selectedAsset, setSelectedAsset])

  const [refusedSignUp, setRefusedSignUp] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)

  useEffect(() => {
    ;(async () => {
      const prev = await AsyncStorage.getItem("refuseSignUp")
      setRefusedSignUp(prev === "yes")
    })()
  }, [])

  if (!asset) {
    return (
      <EmptyView
        titleStyle={s.emptyScreenTitle}
        title={t("emptyScreenTitle")}
        subtitle={t("emptyScreenSubtitle")}
      />
    )
  }

  const locale: string = i18n.language.toLowerCase()

  const getRightChild = (propToCheck: "size" | "value" | "rentalIncome", value?: string) =>
    React.createElement(asset[propToCheck]?.value ? Body2 : H6, {} as IBodyProps, value ?? "·")

  const descElems = [
    {
      name: t(`${rootRoutes.CREATE_ASSET}:size`),
      leftChild: <RollMeterSVG />,
      rightChild: getRightChild(
        "size",
        asset.size?.value
          ? `${new Intl.NumberFormat([_.split(locale, "-")[0], "en-UK"], {
              style: "decimal",
            }).format(asset.size.value)} ${asset.size.unit}`
          : undefined,
      ),
    },
    ...(isFromPublic
      ? []
      : [
          {
            name: t(`${rootRoutes.CREATE_ASSET}:value`),
            leftChild: <BillSVG />,
            rightChild: getRightChild(
              "value",
              asset.value?.value
                ? formatMonetaryValue(locale, asset.value.value, asset.value.unit)
                : undefined,
            ),
          },
          {
            name: t(`${rootRoutes.CREATE_ASSET}:rentalIncome`),
            leftChild: <DrawBarSVG />,
            rightChild: getRightChild(
              "rentalIncome",
              asset.rentalIncome?.value
                ? formatMonetaryValue(locale, asset.rentalIncome.value, asset.rentalIncome.unit)
                : undefined,
            ),
          },
          {
            name: t("plans"),
            leftChild: <PlansSVG />,
            rightChild: (
              <View style={s.rightChild}>
                {canEdit ? (
                  <PicChip
                    asset={asset}
                    path={PLANS_PATH}
                    chipStyle={s.chip}
                    iconColor={mediumEmphasis}
                    allowPdf={true}
                  />
                ) : null}
                <MaterialCommunityIcons
                  size={ICON_SIZE}
                  name="chevron-right"
                  color={mediumEmphasis}
                />
              </View>
            ),
            additionalRowStyle: s.additionalRowStyle,
            onPress: _.isEmpty(asset.plans1)
              ? undefined
              : () =>
                  navigationElem.navigate(rootRoutes.IMAGE_VIEW, {
                    assetId: asset.id,
                    plans: true,
                  }),
          },
        ]),
  ]

  const coordinates = {
    latitudeDelta: 0.01,
    longitudeDelta: 0.01,
    latitude: asset.coordinates.lat,
    longitude: asset.coordinates.lng,
  }

  const removeGuestStatus = () => {
    // noinspection JSIgnoredPromiseFromCall
    if (user) {
      firestore().collection(collections.LOGIN).doc(user.uid).update({
        guest: firestore.FieldValue.delete(),
      })
    }
  }

  const refuseSignUp = () => {
    setRefusedSignUp(true)
    // noinspection JSIgnoredPromiseFromCall
    AsyncStorage.setItem("refuseSignUp", "yes")
  }

  const shareNbr = asset ? Object.keys(asset.roles).filter(e => e !== user?.uid).length : 0

  const iconButton = () => (
    <View style={s.iconBtnView}>
      <MaterialIcons name={canEdit ? "person" : "link"} size={ICON_SIZE} color={white} />
      {canEdit && shareNbr !== 0 ? (
        <View style={s.shareNbr}>
          <Caption>{shareNbr}</Caption>
        </View>
      ) : null}
    </View>
  )

  const onStaticMapPress = () => {
    setSelectedAsset(asset.id)
    mixedNav.navigate(isTabTwoPane || publicMode ? mainRoutes.ASSETS_TAB : assetsRoutes.MAIN, {
      fromAssetView: true,
    })
  }

  const onPress = () => {
    if (canEdit) {
      navigate(rootRoutes.ELEM_SHARE)
      return
    }
    setModalVisible(true)
    Clipboard.setString(getAssetShareUrl(selectedAsset))
    setTimeout(() => {
      setModalVisible(false)
    }, 1000)
  }

  return (
    <AssetCommentsProvider asset={selectedAsset}>
      <Modal
        useNativeDriver
        backdropOpacity={0}
        animationIn="fadeIn"
        animationOut="fadeOut"
        isVisible={modalVisible}
      >
        <View style={s.copyModal}>
          <MaterialCommunityIcons size={ICON_SIZE_LARGE} name="check" />
          <H6>{t(`${rootRoutes.ELEM_SHARE}:linkCopied`)}</H6>
        </View>
      </Modal>
      <KeyboardSafeAreaScrollView
        style={s.mainView}
        edges={["bottom"]}
        safeAreaStyle={isTab ? s.safeAreaTab : undefined}
        upperElem={<AssetCarousel asset={asset} />}
      >
        <AssetInfos asset={asset} />
        {!canView ? (
          <RowItem {...descElems[0]} />
        ) : (
          descElems.map(({ additionalRowStyle, ...rest }, index) => (
            <RowItem key={index} {...rest} rowStyle={[s.row, additionalRowStyle]} />
          ))
        )}
        <View style={s.bottomView}>
          {canView && !isFromPublic ? (
            <Button style={s.shareButton} icon={iconButton} onPress={onPress}>
              {t("share")}
            </Button>
          ) : null}
          {userData?.guest && !refusedSignUp ? (
            <View style={s.signUpView}>
              <View style={s.signUpTexts}>
                <View style={{ alignItems: "center" }}>
                  <H6>{t("signUpModal.title")}</H6>
                  <Body2 style={s.signUpSubtitle}>{t("signUpModal.subtitle")}</Body2>
                </View>
                <Button onPress={removeGuestStatus}>{t("signUpModal.ok")}</Button>
                <Button onPress={refuseSignUp} mode="text" labelStyle={s.signUpRefusesBtn}>
                  {t("signUpModal.cancel")}
                </Button>
              </View>
            </View>
          ) : null}
          {asset.description && !isFromPublic ? (
            <Body2 style={s.description}>{asset.description}</Body2>
          ) : null}
          <View style={s.mapView}>
            <StaticMap
              style={s.map}
              region={coordinates}
              showsBuildings={false}
              onPress={onStaticMapPress}
              showsPointsOfInterest={false}
            >
              <Marker
                selected
                id={asset.id}
                coordinate={coordinates}
                tracksViewChanges={false}
                onPress={onStaticMapPress}
              />
            </StaticMap>
          </View>
          {canEdit ? <AssetComments /> : null}
        </View>
      </KeyboardSafeAreaScrollView>
    </AssetCommentsProvider>
  )
}

export default AssetViewScreen
