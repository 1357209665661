import { useCallback } from "react"

import { rootRoutes } from "../features/Navigation/Constants"
import { navigate } from "../features/Navigation/NavigationService"
import { useAssets } from "../features/Providers/AssetProvider"

export const useAssetSelection = (selectedAssetsIds: string[]) => {
  const { setSelectedAsset } = useAssets()

  const onSharePress = useCallback(() => {
    setSelectedAsset(selectedAssetsIds[0])

    navigate(rootRoutes.ELEM_SHARE)
  }, [selectedAssetsIds, setSelectedAsset])

  return { onSharePress }
}
