import { StackNavigationProp, StackScreenProps } from "@react-navigation/stack"
import {
  Body2,
  H4,
  Subtitle1,
  ThemeProvider,
  useAlert,
  userContext,
  useTabLand,
  useTheme,
  WelcomeScreen as CapsuleWelcomeScreen,
} from "@siruplab/capsule"
import _ from "lodash"
import React, { FC, useContext, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet, useWindowDimensions, View } from "react-native"

import {
  Welcome1SVG,
  Welcome2SVG,
  Welcome3SVG,
  Welcome4SVG,
  WelcomeBg1SVG,
  WelcomeBg2SVG,
  WelcomeBg3SVG,
  WelcomeBg4SVG,
} from "../../components/svg"
import { onboardingRoutes } from "../../features/Navigation/Constants"
import { OnboardingParamList } from "../../features/Navigation/RootNavigator"
import { AccountParamList } from "../MainScreen/MainTabs"

const WelcomeImages = [Welcome1SVG, Welcome2SVG, Welcome3SVG, Welcome4SVG]
const WelcomeBackgroundImages = [WelcomeBg1SVG, WelcomeBg2SVG, WelcomeBg3SVG, WelcomeBg4SVG]

export interface IProps {
  isOnBoarding: boolean
  props:
    | StackScreenProps<OnboardingParamList, "Welcome">
    | StackScreenProps<AccountParamList, "Domaine">
}

const WelcomeScreen: FC<IProps> = ({ props: { navigation }, isOnBoarding }) => {
  const {
    colors: {
      primary,
      surface: { appUi },
      black: { highEmphasis },
      white: { highEmphasis: white },
    },
    dimensions: { spacing, marginSides },
  } = useTheme()

  const { t } = useTranslation()
  const { error, setError } = useContext(userContext)
  const { width: windowWidth, height: windowHeight } = useWindowDimensions()

  const isTab = useTabLand()

  const { showSnack } = useAlert()

  useEffect(() => {
    if (error === "auth/invalid-email" && isOnBoarding) {
      showSnack({ message: t("emailError") })
      setError(undefined)
      ;(navigation as StackNavigationProp<OnboardingParamList, "Welcome">).navigate(
        onboardingRoutes.SIGNUP,
      )
    }
  }, [error, isOnBoarding, navigation, setError, showSnack, t])

  const s = useMemo(
    () => ({
      container: { borderRadius: spacing, height: windowHeight, maxHeight: 880 },
      image: [
        {
          marginTop: isTab ? marginSides : marginSides * 2,
          marginBottom: isTab ? spacing * 2 : spacing / 2,
        },
        styles.carouselImage,
      ],
    }),
    [isTab, marginSides, spacing, windowHeight],
  )

  return (
    <ThemeProvider
      customTheme={{
        colors: {
          surface: { appUi },
          primary: highEmphasis,
          overrides: { button: primary, circleStep: primary },
        },
        dimensions: isTab ? { marginSides: 48 } : {},
      }}
    >
      <CapsuleWelcomeScreen
        containerStyle={s.container}
        showHtmlView
        TitleComponent={H4}
        imageStyle={s.image}
        // @ts-ignore
        transComponents={{ p: <Body2 /> }}
        hideStatusBar={false}
        DescriptionComponent={Subtitle1}
        color={{ color: isTab ? highEmphasis : white }}
        buttonStyles={[
          { hidden: true },
          { color: white, mode: "contained", hidden: !isOnBoarding },
        ]}
        pages={_.map(_.range(0, 4), i => ({
          Component: WelcomeImages[i],
          Background: isTab
            ? undefined
            : () => (
                <View
                  style={{
                    transform: [{ translateX: -(1300 - windowWidth) / 2 }],
                    width: 1300,
                    height: 450,
                    maxHeight: windowHeight / 1.5,
                  }}
                >
                  {React.createElement(WelcomeBackgroundImages[i], {
                    width: 1300,
                    height: _.min([450, windowHeight / 1.5]),
                  })}
                </View>
              ),
          ...(t(`${onboardingRoutes.WELCOME}:welcome.${i}`, { returnObjects: true }) as Record<
            string,
            unknown
          >),
        }))}
        onSubmit={
          isOnBoarding
            ? () =>
                (navigation as StackNavigationProp<OnboardingParamList, "Welcome">).navigate(
                  onboardingRoutes.SIGNUP,
                )
            : undefined
        }
      />
    </ThemeProvider>
  )
}

const styles = StyleSheet.create({
  carouselImage: { width: 114, height: 114, resizeMode: "contain" },
})

export default WelcomeScreen
