// noinspection HtmlUnknownTarget

import { onboardingRoutes } from "../Constants"

export default {
  common: {
    appName: "Domaine",
    emailError:
      "Sign-in has to be completed on the same device you requested the link. Please request a new link.",
    accountScreen: {
      termsOfUse:
        "<p>En continuant vous indiquez que vous acceptez nos <a href='$t(urls.tos)'>conditions générales</a> et notre <a href='$t(urls.privacy)'>politique de vie privée</a>.</p>",
    },
    button: {
      continue: "Continue",
    },
    fields: {
      required: "This field is required",
    },
    landing: {
      signUp: "",
      signIn: "Continuer",
    },
    signInValidation: {
      subtitle:
        "A sign-in email with additional instructions was sent to <1>{{email}}</1>. Check your email to complete sign-in.",
      caption: "You didn't receive it? <1>Tap to resend</1>",
    },
    login: {
      signUp: "Don't have an account? <1>Sign up</1>",
    },
    passwordCreate: {
      login: "Already have a password? <1>Log in with password</1>",
    },
  },
  [`${onboardingRoutes.WELCOME}`]: {
    welcome: [
      {
        title: "Domaine",
        description: "One map, All your assets",
      },
      {
        title: "Actifs",
        description:
          "Créez, recherchez et visualisez vos actifs depuis votre téléphone ou votre ordinateur",
      },
      {
        title: "Portfolios",
        description: "Organisez vos actifs dans des portfolios personnalisés",
      },
      {
        title: "Partage privé",
        description:
          "Collaborez avec votre équipe ou vos clients en partageant vos portfolios et vos actifs",
      },
    ],
  },
}
