import { generateShadow } from "@siruplab/capsule"
import React from "react"
import { View } from "react-native"

import { useStyles } from "../../utils/useStyles"

interface Props {
  disabled?: boolean
}

const CustomMarker = ({ disabled }: Props) => {
  const s = useStyles(
    ({
      colors: {
        primaryDark,
        primary,
        surface: { card },
        black: { shadow },
      },
    }) => ({
      marker: {
        width: 22,
        height: 22,
        borderWidth: 2,
        borderRadius: 11,
        ...generateShadow(1),
        borderColor: disabled ? shadow : primaryDark,
        backgroundColor: disabled ? card : primary,
      },
    }),
  )

  return <View style={s.marker} />
}

export default CustomMarker
