import React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

const SVGComponent = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.501 6.75V5.409a.537.537 0 0 0-.186-.414.54.54 0 0 0-.434-.136c-2.56.31-4.56 2.504-4.56 5.143 0 2.85 2.331 5.181 5.18 5.181 2.85 0 5.181-2.332 5.181-5.18V5.236c0-.237.143-.44.366-.521a.544.544 0 0 1 .614.167c2.607 3.138 2.441 7.843-.499 10.78l-5.271 5.272a.556.556 0 0 1-.784 0l-5.272-5.271c-3.115-3.115-3.115-8.213 0-11.328 2.159-2.159 5.27-2.82 8.013-1.988a.547.547 0 0 1 .393.53v7.12a2.75 2.75 0 0 1-2.742 2.743 2.75 2.75 0 0 1-2.743-2.743c0-1.35.99-2.482 2.282-2.703a.544.544 0 0 0 .462-.544Z"
      fill={props.color ?? "#F75D5D"}
    />
  </Svg>
)

export default SVGComponent
