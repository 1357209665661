import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

const DeleteSelectSVG = (props: SvgProps) => (
  <Svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 9c0-4.147 3.353-7.5 7.5-7.5 4.148 0 7.5 3.353 7.5 7.5 0 4.148-3.352 7.5-7.5 7.5A7.493 7.493 0 011.5 9zm10.193 3.75l1.057-1.057L10.057 9l2.693-2.692-1.057-1.058L9 7.942 6.308 5.25 5.25 6.308 7.942 9 5.25 11.693l1.058 1.057L9 10.057l2.693 2.693z"
      fill="#32352F"
      fillOpacity={0.38}
    />
  </Svg>
)

export default DeleteSelectSVG
