import {
  Body2,
  IAddress,
  IUserContext,
  logger,
  Subtitle1,
  userContext,
  useTheme,
} from "@siruplab/capsule"
import { FormikHelpers } from "formik"
import _ from "lodash"
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import {
  FlatList,
  ListRenderItemInfo,
  StyleProp,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
} from "react-native"
import { Chip } from "react-native-paper"

import { addPortfolio } from "../../features/models/PortfolioFunctions"
import { UserData } from "../../features/models/UserData"
import safeAwait from "../../utils/safeAwait"
import { useStyles } from "../../utils/useStyles"
import ListModal from "../ListModal/ListModal"
import { CheckSVG } from "../svg"

export interface Item {
  label: string
  value: string // ID
}

interface Props {
  items: Item[]
  placeholder?: string
  selectedItems?: string[]
  setSelectedItems: Dispatch<SetStateAction<string[]>>
  firstButtonText: string
  setFieldValue?: FormikHelpers<IAddress>["setFieldValue"]
  secondButtonText: string
  setNeedExtraScroll?: Dispatch<SetStateAction<boolean>>
  title?: string
  canAdd?: boolean
  name?: string
  containerStyle?: StyleProp<ViewStyle>
  inputStyle?: StyleProp<ViewStyle>
  modalTitle?: string
}

const keyExtractor = (elem: Item) => elem.value
const trueFn = () => true

const MultiSelect = ({
  selectedItems,
  items,
  name,
  setFieldValue,
  setSelectedItems,
  title,
  setNeedExtraScroll,
  containerStyle,
  canAdd = false,
  placeholder,
  inputStyle,
  modalTitle,
  firstButtonText,
  secondButtonText,
}: Props) => {
  const {
    colors: { primary },
  } = useTheme()

  const { t } = useTranslation()
  const { user, userData } = useContext<IUserContext<UserData>>(userContext)

  const [visible, setVisible] = useState(false)

  const [data, setData] = useState<Item[]>(
    items?.filter(item => selectedItems?.includes(item.value)),
  )

  useEffect(() => {
    const newItems = items?.filter(item => selectedItems?.includes(item.value))
    setData(newItems)
    if (name) {
      setFieldValue?.(
        name,
        newItems.map(elem => elem.value),
      )
    }
  }, [items, name, selectedItems, setFieldValue])

  const showModal = useCallback(() => {
    setNeedExtraScroll?.(false)
    setVisible(true)
  }, [setNeedExtraScroll])

  const hideModal = useCallback(() => {
    setNeedExtraScroll?.(true)
    setVisible(false)
  }, [setNeedExtraScroll])

  const unselectItem = useCallback(
    (itemValue: string) => () => {
      setSelectedItems(prevSelectedItems => prevSelectedItems.filter(item => item !== itemValue))
    },
    [setSelectedItems],
  )

  const s = useStyles(
    ({
      colors: {
        black,
        surface: { textInput, card },
      },
      dimensions: { spacing, margin },
    }) => ({
      placeholderData: { marginHorizontal: spacing },
      renderItemHitSlop: { left: spacing / 2, right: spacing / 4 },
      renderItemText: { marginHorizontal: margin / 2, color: black.highEmphasis },
      renderItemContainer: {
        height: 32,
        alignSelf: "center",
        backgroundColor: card,
        marginHorizontal: spacing / 2,
        maxWidth: 250,
      },
      input: {
        height: 46,
        justifyContent: "center",
        alignItems: "flex-start",
        paddingRight: spacing / 4,
        backgroundColor: textInput,
        borderTopRightRadius: spacing / 2,
        borderTopLeftRadius: spacing / 2,
        overflow: "hidden",
        borderRightWidth: spacing / 2,
        borderRightColor: textInput,
      },
      chipText: { maxWidth: 180 },
    }),
    [],
  )

  const renderItem = useCallback(
    ({ item }: ListRenderItemInfo<Item>) => (
      <TouchableWithoutFeedback onPress={showModal} hitSlop={s.renderItemHitSlop}>
        <Chip
          avatar={<CheckSVG />}
          onClose={unselectItem(item.value)}
          style={s.renderItemContainer}
          textStyle={s.chipText}
        >
          {_.upperFirst(item.label)}
        </Chip>
      </TouchableWithoutFeedback>
    ),
    [unselectItem, s, showModal],
  )

  const onAdd = async (portfolioName, postAddFn) => {
    try {
      if (!user || !userData) {
        return
      }
      const res = await safeAwait(
        addPortfolio({
          name: portfolioName,
          roles: { [user?.uid ?? ""]: "owner" },
          assets: [],
          users: {
            [user.uid]: {
              name: `${userData.firstName} ${userData.lastName}`,
              email: user.email ?? "",
            },
          },
        }),
      )
      if (!!res) {
        postAddFn?.(res.id)
      }
    } catch (e) {
      logger("Add portfolio error: ", e)
    }
  }

  return (
    <>
      <View style={containerStyle}>
        {title ? <Subtitle1 color={primary}>{title}</Subtitle1> : null}
        <TouchableOpacity style={[s.input, inputStyle]} onPress={showModal}>
          {data?.length ?? 0 > 0 ? (
            <View onStartShouldSetResponder={trueFn}>
              <FlatList
                showsHorizontalScrollIndicator={false}
                horizontal
                {...{ data, renderItem, keyExtractor }}
              />
            </View>
          ) : (
            <Body2 style={s.placeholderData}>{placeholder ?? t("multiSelect.placeholder")}</Body2>
          )}
        </TouchableOpacity>
        <ListModal
          title={modalTitle}
          {...{
            items,
            selectedItems,
            setSelectedItems,
            hideModal,
            visible,
            onAdd: canAdd ? onAdd : undefined,
            firstButtonText,
            secondButtonText,
          }}
        />
      </View>
    </>
  )
}

export default MultiSelect
