import { onboardingRoutes } from "../../../features/Navigation/Constants"
import { nsEdit } from "../../NameScreen/i18n/en"

export const ns = onboardingRoutes.PASSWORD_CREATE

export default {
  common: {
    [ns]: {
      title: "Password creation",
    },
    [nsEdit]: {
      title: "Password creation",
    },
  },
  [ns]: {
    email: "Email",
    invalidEmail: "Invalid email",
    password: "Password",
    required: "This field is required",
    passwordLength: "Your password must be at least 6 characters",
    confirm: "Confirm password",
    passwordSuccess: "Password successfully created!",
    noMatches: "Passwords don't match",
    createAccount: "Create an account",
    createPassword: "Create a password",
    confirmError: "Please confirm your password",
    modalLoggedIn: {
      title: "Unable to Set New Password",
      message:
        "The account you’re trying to access is already active. For your security, you need to be logged in to change the password. Click 'Proceed' to receive a secure email link for logging in. After you’re logged in, you can navigate to your ‘Profile’ page to create a new password.",
      ok: "Proceed",
    },
    modal: {
      title: "Setting a new password",
      message: "You will need to log out and log back in to set a new password.",
    },
  },
}
