import { TextInput, useTheme } from "@siruplab/capsule"
import Dinero from "dinero.js"
import { useField, useFormikContext } from "formik"
import React, { RefObject, useCallback, useState } from "react"
import { Platform, TextInput as RNTextInput, View } from "react-native"
import DropDownPicker, { ItemType } from "react-native-dropdown-picker"

import { AreaUnit, CurrencyUnit, ValueAndUnit } from "../../../features/models/Asset"
import { useAssetFilter } from "../../../features/Providers/AssetFilterProvider"
import isWeb from "../../../utils/isWeb"
import { useStyles } from "../../../utils/useStyles"
import { AssetValues } from "../CreateAssetScreen"

interface IProps {
  name: string
  label: string
  index: number
  values: ItemType[]
  forwardRef?: RefObject<RNTextInput>
}

const SQFT_TO_M2_RATIO = 10.7639

const OptionalAssetInfo = <Unit extends CurrencyUnit | AreaUnit>({
  name,
  index,
  label,
  values,
  forwardRef,
}: IProps) => {
  const [open, setOpen] = useState(false)
  const [field] = useField<ValueAndUnit<Unit>>(name)
  const { setFieldValue, initialValues } = useFormikContext<AssetValues>()
  const { poundsToEuroRates, dollarsToEuroRates } = useAssetFilter()
  const [valueUnit, setValueUnit] = useState(
    field?.value?.unit ?? values[0].value?.toString() ?? "",
  )
  const [textValue, setTextValue] = useState(initialValues?.[name]?.value.toString() ?? "")

  const {
    colors: {
      black: { mediumEmphasis: underlineColor },
    },
  } = useTheme()

  const s = useStyles(
    ({
      dimensions: { spacing },
      typography: { body2 },
      colors: {
        surface: { textInput },
        black: { mediumEmphasis },
      },
    }) => ({
      dropDownView: { flex: 1, marginLeft: spacing },
      textInput: { borderBottomWidth: 0, minHeight: 50 },
      itemSeparator: { height: 1, backgroundColor: mediumEmphasis },
      valuesTextInput: { marginBottom: spacing * (isWeb ? 2 : 0.5), flex: 3 },
      inputs: {
        flex: 1,
        backgroundColor: textInput,
        borderWidth: 0,
        borderBottomWidth: 2,
        borderBottomColor: mediumEmphasis,
        borderRadius: 0,
        height: 50,
        borderTopRightRadius: 8,
        borderTopLeftRadius: 8,
        ...(isWeb
          ? {
              flexDirection: "row",
              alignItems: "center",
            }
          : {}),
      },
      drop: {
        minHeight: 50,
        paddingHorizontal: spacing / 2,
      },
      listItemContainer: {
        flexDirection: "row",
        padding: spacing / 2,
      },
      itemStyle: body2,
      dropdownContainerStyle: {
        borderWidth: 1,
        backgroundColor: textInput,
        borderColor: mediumEmphasis,
      },
      valuesView: {
        flex: 1,
        flexDirection: "row",
        marginTop: spacing / 2,
        ...(Platform.OS !== "android"
          ? {
              zIndex: 3000 / (index + 1),
            }
          : {}),
      },
    }),
  )

  const convertToEUR = useCallback(
    // eslint-disable-next-line no-shadow
    async (v: number, initialUnit: "GBP" | "USD") => {
      const apiRates = initialUnit === "GBP" ? poundsToEuroRates : dollarsToEuroRates
      if (!apiRates || v === undefined) {
        return null
      }

      const rates = { rates: { ...apiRates.rates } }

      const convertedValue = await Dinero({ amount: Math.round(v) }).convert("EUR", {
        endpoint: new Promise(resolve => resolve(rates)),
        roundingMode: "HALF_UP",
      })

      return Math.round(convertedValue.getAmount())
    },
    [dollarsToEuroRates, poundsToEuroRates],
  )

  const getSearchValue = useCallback(
    async (unit: string, valueToConvert: number) => {
      if (unit === "sqft" || unit === "m2") {
        return unit === "sqft" ? Math.round(valueToConvert / SQFT_TO_M2_RATIO) : valueToConvert
      } else if (unit === "USD" || unit === "GBP") {
        return convertToEUR(valueToConvert, unit)
      } else {
        return valueToConvert
      }
    },
    [convertToEUR],
  )

  const handleChangeText = useCallback((textInput: string) => {
    if (/^\d+$/.test(textInput) || textInput.length === 0) {
      setTextValue(textInput)
    }
  }, [])

  const onUpdate = useCallback(
    (unit?: string) => async () => {
      if (textValue.length > 0) {
        setFieldValue(name, {
          value: parseInt(textValue),
          searchValue: await getSearchValue(unit ?? valueUnit, parseInt(textValue)),
          unit: unit ?? valueUnit,
        })
      }
    },
    [getSearchValue, name, setFieldValue, textValue, valueUnit],
  )

  const setUnitValue = useCallback(
    (unitValue: () => Unit) => {
      setValueUnit(unitValue())
      // noinspection JSIgnoredPromiseFromCall
      onUpdate(unitValue())()
    },
    [onUpdate],
  )

  return (
    <View style={s.valuesView}>
      <View style={s.valuesTextInput}>
        <TextInput
          label={label}
          value={textValue}
          onChangeText={handleChangeText}
          onBlur={onUpdate()}
          changeUnderline={false}
          style={[s.inputs, s.textInput]}
          underlineColor={underlineColor}
          keyboardType="number-pad"
          forwardRef={forwardRef}
        />
      </View>
      <View style={s.dropDownView}>
        <DropDownPicker
          {...(Platform.OS === "android"
            ? { zIndex: 3000 / (index + 1), zIndexInverse: 3000 / index }
            : {})}
          open={open}
          value={valueUnit}
          itemSeparator
          items={values}
          setOpen={setOpen}
          setValue={setUnitValue}
          labelStyle={s.itemStyle}
          style={[s.inputs, s.drop]}
          placeholder={values[0].label}
          placeholderStyle={s.itemStyle}
          listItemLabelStyle={s.itemStyle}
          itemSeparatorStyle={s.itemSeparator}
          listItemContainerStyle={s.listItemContainer}
          dropDownContainerStyle={s.dropdownContainerStyle}
        />
      </View>
    </View>
  )
}

export default OptionalAssetInfo
