import React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

const DrawBarSVG = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <Path
      d="M4.999 16.006a.999.999 0 0 1-.706-1.706l4.496-4.496a1 1 0 0 1 1.413 0l3.29 3.29 4.79-4.789a.999.999 0 1 1 1.413 1.413l-5.496 5.496a.999.999 0 0 1-1.413 0l-3.29-3.29-3.79 3.79a.999.999 0 0 1-.707.292z"
      fill="#32352F"
      fillOpacity={0.72}
    />
  </Svg>
)

export default DrawBarSVG
