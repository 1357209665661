import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

const MapSVG = (props: SvgProps) => (
  <Svg width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
    <Path
      d="M15.5 3.408L11.667 4.91l-3-1.502c-.167-.084-.417-.084-.667 0L3.833 5.077c-.333.083-.5.417-.5.75v10.015a.821.821 0 001.167.75l3.833-1.501 3 1.502c.25.083.417.083.667 0l4.167-1.586c.333-.083.5-.417.5-.75V4.158a.821.821 0 00-1.167-.751zM9.167 5.494l1.666.834v8.178l-1.666-.834V5.494zM5 6.412L7.5 5.41v8.178L5 14.59V6.412zm10 7.26l-2.5.918V6.412L15 5.41v8.262z"
      fill={props.color || "#FFFFFF"}
    />
  </Svg>
)

export default MapSVG
