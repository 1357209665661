import * as React from "react"
import Svg, { ClipPath, Defs, G, Path, SvgProps } from "react-native-svg"

const Welcome2SVG = (props: SvgProps) => (
  <Svg width={115} height={114} viewBox="0 0 115 114" fill="none" {...props}>
    <G clipPath="url(#prefix__clip0)">
      <Path
        d="M67 95h19c2.85 0 4.75-1.9 4.75-4.75V47.5c0-1.425-.475-2.85-1.9-3.8l-28.5-23.75c-1.9-1.425-4.275-1.425-6.175 0l-28.5 23.75c-.95.95-1.425 2.375-1.425 3.8v42.75c0 2.85 1.9 4.75 4.75 4.75h19c2.85 0 4.75-1.9 4.75-4.75V76h9.5v14.25c0 2.85 1.9 4.75 4.75 4.75zM48 66.5c-2.85 0-4.75 1.9-4.75 4.75V85.5h-9.5V49.875l23.75-19.95 23.75 19.95V85.5h-9.5V71.25c0-2.85-1.9-4.75-4.75-4.75H48z"
        fill={props.color || "#FF8F86"}
      />
    </G>
    <Defs>
      <ClipPath id="prefix__clip0">
        <Path fill="#fff" transform="translate(19.5 19)" d="M0 0h76v76H0z" />
      </ClipPath>
    </Defs>
  </Svg>
)

export default Welcome2SVG
