import { useTheme } from "@siruplab/capsule"
import React, { useMemo } from "react"
import { StyleProp, View, ViewStyle } from "react-native"

const ListSeparator = () => {
  const {
    colors: {
      surface: { textInput },
    },
  } = useTheme()

  const s = useMemo<StyleProp<ViewStyle>>(
    () => ({
      backgroundColor: textInput,
      height: 1,
      width: "100%",
    }),
    [textInput],
  )
  return <View style={s} />
}

export default ListSeparator
