import { RouteProp, useNavigation, useRoute } from "@react-navigation/native"
import {
  Body2,
  generateShadow,
  LazyMapView,
  MaterialCommunityIcons,
  Touchable,
  useTabLand,
  useTabTwoPane,
  useTheme,
} from "@siruplab/capsule"
import _ from "lodash"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Animated, useWindowDimensions, View } from "react-native"
import Geocoder from "react-native-geocoding"
import MapView, { LatLng, Region } from "react-native-maps"
import { Chip, FAB } from "react-native-paper"
import { SafeAreaView } from "react-native-safe-area-context"
import Carousel from "react-native-snap-carousel"

import { config } from "../../../package.json"
import AssetCard from "../../components/AssetCard"
import ContextMenu from "../../components/ContextMenu"
import MapModal from "../../components/MapModal"
import Marker from "../../components/Marker"
import Searchbar from "../../components/Searchbar"
import LayersSVG from "../../components/svg/layers_svg"
import { Asset } from "../../features/models/Asset"
import { rootRoutes } from "../../features/Navigation/Constants"
import { useAssetFilter } from "../../features/Providers/AssetFilterProvider"
import { NormalizedAsset, useAssets } from "../../features/Providers/AssetProvider"
import { ICON_SIZE_LARGE, ICON_SIZE_SMALL } from "../../ThemeApp"
import { getRegionForCoordinates } from "../../utils/getRegionForCoordinates"
import { useStyles } from "../../utils/useStyles"
import { AssetsRouteNames, assetsRoutes } from "../MainScreen/Constants"
import { assetsWebStackParamList, tabNav } from "../MainScreen/MainNavigator"
import { ns } from "./i18n/en"
import { MapType } from "./index"

interface WebMapView extends Omit<MapView, "getCamera"> {
  setZoom: (zoom: number) => void
  getCamera: () => { zoom: number; center: LatLng; heading: LatLng }
}

interface WebMapEvent {
  pixel: { x: number; y: number }
  latLng: { lat: () => number; lng: () => number }
}

const SELECTED_ASSET_ZOOM = 18

const AssetMapScreenWeb = () => {
  Geocoder.init(config.apiKey)
  /**
   ** Hooks
   **/
  const {
    dimensions: { spacing },
    colors: {
      black: { mediumEmphasis },
    },
  } = useTheme()
  const { t } = useTranslation(ns)
  const navigation = useNavigation()
  const isTabTwoPane = useTabTwoPane()
  const isTab = useTabLand()
  const { width } = useWindowDimensions()
  const { filteredAssets } = useAssetFilter()
  const { params } = useRoute<RouteProp<assetsWebStackParamList, "Assets_Tab">>()
  const { setSelectedAsset, selectedAsset, assets, initialRegion, assetToEdit } = useAssets()

  /**
   ** States
   **/
  const mapType = useState<MapType>("standard")
  const [modalVisible, setModalVisible] = useState(false)
  const [assetsLength, setAssetsLength] = useState(filteredAssets.length)
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 })
  const [coordinates, setCoordinates] = useState({ latitude: 0, longitude: 0 })
  const [visible, setVisible] = useState(false)

  /**
   ** Refs
   **/
  const translationY = useRef(new Animated.Value(215 + spacing * 2)).current
  const carouselRef = useRef<Carousel<NormalizedAsset>>(null)
  const mapRef = useRef<WebMapView>(null)

  /**
   ** Callbacks
   **/
  const selectItem = useCallback(
    async (
      { id, coordinates: { lat: latitude, lng: longitude } }: NormalizedAsset | Asset,
      isSnap?: boolean,
    ) => {
      setSelectedAsset(id)
      if (mapRef.current) {
        const bounds: {
          northEast: LatLng
          southWest: LatLng
        } = await mapRef.current?.getMapBoundaries()
        mapRef.current?.animateToRegion({
          latitude: latitude - 0.2 * (bounds.northEast.latitude - bounds.southWest.latitude),
          longitude,
        } as Region)
        if (isSnap) {
          carouselRef.current?.snapToItem(_.findIndex(assets, elem => elem.id === id))
        }
      }
    },
    [assets, setSelectedAsset],
  )

  const onMarkerPress = useCallback(
    (id: string) => () => {
      if (isTabTwoPane) {
        setSelectedAsset(assets[_.findIndex(assets, elem => elem.id === id)]?.id)
        tabNav().navigate(rootRoutes.ASSET_VIEW)
      } else {
        // noinspection JSIgnoredPromiseFromCall
        selectItem(assets[_.findIndex(assets, elem => elem.id === id)], true)
      }
    },
    // DO NOT DELETE THIS: the tabNav is marked as unnecessary but is, as it won't work correctly if it is not present here
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tabNav, assets],
  )

  const fitMap = useCallback(() => {
    if (initialRegion && !!filteredAssets) {
      mapRef.current?.animateToRegion(
        filteredAssets.length === 0
          ? initialRegion
          : {
              ...getRegionForCoordinates(
                filteredAssets.map(({ coordinates: { lat, lng } }) => ({
                  latitude: lat,
                  longitude: lng,
                })),
              ),
              ...(filteredAssets.length === 1 ? { latitudeDelta: 0.04, longitudeDelta: 0.04 } : {}),
            },
      )
    }
  }, [filteredAssets, initialRegion])

  const renderItem = useCallback(
    ({ item }: { item: NormalizedAsset }) => (
      <AssetCard asset={item} selected={false} showCheckbox={false} />
    ),
    [],
  )

  /**
   ** Standard functions
   **/
  const onPress = useCallback(() => {
    if (selectedAsset) {
      setSelectedAsset(undefined)
    }
  }, [selectedAsset, setSelectedAsset])

  const setZoomLevel = (increase: boolean) => () => {
    mapRef.current?.setZoom(mapRef.current?.getCamera().zoom + (increase ? 1 : -1))
  }

  const zoomOnSelectedAsset = () => {
    mapRef.current?.setZoom(SELECTED_ASSET_ZOOM)
  }

  const onSnapToItem = (item: number) => {
    // noinspection JSIgnoredPromiseFromCall
    selectItem(filteredAssets[item])
  }

  const snapTo = (idx: number) => () => {
    carouselRef.current?.[idx === 0 ? "snapToPrev" : "snapToNext"]()
    if (idx === 1 && carouselRef.current?.currentIndex === filteredAssets.length - 1) {
      carouselRef.current?.snapToItem(0, false)
    }
    if (idx === 0 && carouselRef.current?.currentIndex === 0) {
      carouselRef.current?.snapToItem(filteredAssets.length - 1, false)
    }
  }

  const navTo = (nextScreen: AssetsRouteNames) => () => {
    setSelectedAsset(undefined)
    navigation.navigate(nextScreen)
  }

  const navToCreateAsset = () => {
    assetToEdit[1](undefined)
    navigation.navigate(rootRoutes.CREATE_ASSET)
  }

  const toggleModal = () => {
    setModalVisible(prev => !prev)
  }

  const handleContextMenu = (e: WebMapEvent) => {
    if (e.pixel) {
      setAnchorPoint({ x: e.pixel.x, y: e.pixel.y })
      setVisible(true)
      setCoordinates({ latitude: e.latLng.lat(), longitude: e.latLng.lng() })
    } else {
      setAnchorPoint({ x: 0, y: 0 })
      setVisible(false)
    }
  }

  /**
   ** Effects
   **/
  useEffect(() => {
    ;(async () => {
      if (!params?.fromAssetView || !selectedAsset) {
        return
      }
      await selectItem(assets[_.findIndex(assets, elem => elem.id === selectedAsset)])
      if (params?.fromAssetView) {
        zoomOnSelectedAsset()
      }
    })()
  }, [assets, params, selectItem, selectedAsset])

  useEffect(() => {
    if (filteredAssets.length !== assetsLength) {
      setAssetsLength(filteredAssets.length)
      fitMap()
    }
  }, [assetsLength, filteredAssets, fitMap])

  useEffect(() => {
    Animated.spring(translationY, {
      toValue: selectedAsset !== undefined && filteredAssets.length > 0 ? 0 : 215 + spacing * 2,
      useNativeDriver: true,
    }).start()
  }, [selectedAsset, translationY, spacing, filteredAssets])

  /**
   ** Styles
   **/
  const s = useStyles(
    ({
      typography: { body2 },
      colors: {
        primary,
        surface: { chip },
        white: { highEmphasis: white },
        black: { separator, highEmphasis },
      },
    }) => ({
      fabColor: { color: white },
      arrowLeft: { left: spacing },
      arrowRight: { right: spacing },
      layers: { marginLeft: spacing / 2 },
      chipText: { ...body2, color: chip, lineHeight: 24 },
      mainView: { ...generateShadow(6), flex: 1 },
      dot: { backgroundColor: primary, width: 8, height: 8 },
      icon: { width: ICON_SIZE_SMALL, height: ICON_SIZE_SMALL },
      headerView: { backgroundColor: white, flexDirection: "row" },
      chipAssetText: { color: highEmphasis, alignSelf: "flex-end" },
      paginationView: { position: "absolute", bottom: 0, width: "100%" },
      separator: { height: 1, width: "100%", backgroundColor: separator },
      customContainer: { paddingLeft: spacing, paddingBottom: spacing / 2 },
      chipAsset: { height: 32, backgroundColor: white, ...generateShadow(2) },
      map: {
        width: "100%",
        height: "100%",
        position: "absolute",
        top: isTabTwoPane ? 0 : 50,
      },
      arrowView: { bottom: 110, borderRadius: 50, position: "absolute", backgroundColor: white },
      topRightView: {
        top: isTabTwoPane ? spacing / 2 : spacing / 2 + 50,
        right: 0,
        alignSelf: "center",
        position: "absolute",
      },
      chip: {
        backgroundColor: white,
        marginRight: spacing / 2,
        height: 32,
        paddingHorizontal: spacing / 1.5,
        flexDirection: "row",
        alignItems: "center",
        borderRadius: 40,
        ...generateShadow(2),
      },
      chipView: {
        alignSelf: "center",
        position: "absolute",
        bottom: 215 + spacing * 3,
        transform: [
          {
            translateY: (translationY as unknown) as number,
          },
        ],
      },
      zoomView: {
        marginTop: spacing / 2,
        backgroundColor: white,
        width: 32,
        right: spacing / 2,
        height: 65,
        borderRadius: 8,
        alignItems: "center",
        alignSelf: "flex-end",
        ...generateShadow(2),
        justifyContent: "space-around",
      },
      fab: {
        width: 48,
        height: 48,
        right: spacing / 2,
        position: "absolute",
        alignItems: "center",
        alignSelf: "flex-end",
        justifyContent: "center",
        bottom: 215 + spacing * 3,
        transform: [
          {
            translateY: (translationY as unknown) as number,
          },
        ],
      },

      bottomView: {
        position: "absolute",
        alignSelf: "flex-end",
        bottom: spacing / 2,
        transform: [
          {
            translateY: (translationY as unknown) as number,
          },
        ],
      },
    }),
    [],
  )
  const commonIconProps = {
    size: ICON_SIZE_SMALL,
    style: s.icon,
  }
  return (
    <View style={s.mainView}>
      <MapModal {...{ mapType, modalVisible, toggleModal }} />
      {!isTabTwoPane ? (
        <SafeAreaView edges={["left", "right"]} style={s.headerView}>
          <Searchbar />
        </SafeAreaView>
      ) : null}
      <LazyMapView
        // @ts-ignore
        ref={mapRef}
        style={s.map}
        mapType={mapType[0]}
        showsPointsOfInterest={true}
        {...{
          onPress,
          initialRegion,
        }}
        onRightClick={handleContextMenu}
        // @ts-ignore
        options={{
          zoomControl: false,
          scaleControl: false,
          mapTypeControl: false,
          clickableIcons: false,
          tilt: 0,
          fullscreenControl: false,
          streetViewControl: false,
          draggableCursor: "pointer",
          draggingCursor: "grab",
          zoomControlOptions: { position: 3 },
          styles: ["poi", "transit", "landscape"].map(elem => ({
            featureType: elem,
            elementType: "labels",
            stylers: [{ visibility: "off" }],
          })),
          mapTypeId: mapType[0] === "satellite" ? "satellite" : "roadmap",
        }}
      >
        {filteredAssets?.map(({ coordinates: { lat: latitude, lng: longitude }, id }) => (
          <Marker
            key={id}
            id={id}
            coordinate={{ latitude, longitude }}
            selected={selectedAsset === id}
            onPress={onMarkerPress(id)}
          />
        ))}
      </LazyMapView>
      <ContextMenu
        anchor={anchorPoint}
        visible={visible}
        setVisible={setVisible}
        coordinates={coordinates}
      />
      <View style={s.topRightView}>
        <Touchable onPress={toggleModal} style={s.chip}>
          <Body2>{t(`${mapType[0]}MapName`)}</Body2>
          <View style={s.layers}>
            <LayersSVG />
          </View>
        </Touchable>
        <View style={s.zoomView}>
          {["plus", "separator", "minus"].map((item, idx) =>
            item === "separator" ? (
              <View key={idx} style={s.separator} />
            ) : (
              <MaterialCommunityIcons
                size={24}
                key={idx}
                name={item}
                color={mediumEmphasis}
                onPress={setZoomLevel(item === "plus")}
              />
            ),
          )}
        </View>
      </View>
      {!isTabTwoPane ? (
        <>
          <Animated.View style={s.chipView}>
            <Chip
              style={s.chipAsset}
              textStyle={s.chipAssetText}
              onPress={navTo(assetsRoutes.LIST)}
              avatar={<MaterialCommunityIcons {...commonIconProps} name="menu" />}
            >
              {t("viewList")}
            </Chip>
          </Animated.View>
          <FAB icon="plus" color={s.fabColor.color} style={s.fab} onPress={navToCreateAsset} />
          <Animated.View style={s.bottomView}>
            <Carousel
              vertical={false}
              ref={carouselRef}
              data={filteredAssets}
              activeSlideAlignment="center"
              scrollEnabled={!isTabTwoPane}
              itemWidth={width - (isTab ? 115 : 64)}
              sliderWidth={width + (isTab ? -42 : 32)}
              containerCustomStyle={s.customContainer}
              {...{ renderItem, onSnapToItem }}
            />
          </Animated.View>
          {selectedAsset !== undefined
            ? ["left", "right"].map((elem, idx) => (
                <View key={idx} style={[s.arrowView, idx === 0 ? s.arrowLeft : s.arrowRight]}>
                  <MaterialCommunityIcons
                    size={ICON_SIZE_LARGE}
                    color={mediumEmphasis}
                    name={`arrow-${elem}-drop-circle`}
                    onPress={snapTo(idx)}
                  />
                </View>
              ))
            : null}
        </>
      ) : null}
    </View>
  )
}

export default AssetMapScreenWeb
