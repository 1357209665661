import { useTheme } from "@siruplab/capsule"
import { logger } from "@siruplab/capsule/index"
import React from "react"
import { useTranslation } from "react-i18next"
import { View } from "react-native"
import SearchMultiSelectInput from "react-native-multiple-select"

import { useAssetFilter } from "../../features/Providers/AssetFilterProvider"
import { ns } from "../../screens/AssetsFilterScreen/i18n/en"
import { useStyles } from "../../utils/useStyles"

const SearchMultiSelect = ({ onSelectedItemsChange }) => {
  const { t } = useTranslation(ns)
  /* Theme */
  const {
    colors: {
      primary,
      black: { disabled, highEmphasis: black, mediumEmphasis: blackMedium },
      surface: { textInput, card },
    },
    dimensions: { spacing },
    fontMaker,
  } = useTheme()
  const style = useStyles(
    () => ({
      input: {
        height: 46,
        justifyContent: "center",
        backgroundColor: textInput,
        borderTopRightRadius: spacing / 2,
        borderTopLeftRadius: spacing / 2,
        overflow: "hidden",
        borderRightWidth: spacing / 2,
        borderRightColor: textInput,
      },
      styleDropdownMenuSubsection: { alignItems: "flex-start", paddingRight: spacing },
      dropdown: { marginLeft: spacing },
      searchInputStyle: {
        color: black,
        ...fontMaker(),
      },
      tag: {
        height: 32,
        alignSelf: "center",
        backgroundColor: card,
        marginHorizontal: spacing / 2,
        maxWidth: 250,
      },
    }),
    [],
  )

  /* Hooks */
  const { emailList, selectedUsers } = useAssetFilter()

  return (
    <View>
      <SearchMultiSelectInput
        items={emailList}
        uniqueKey="id"
        onSelectedItemsChange={onSelectedItemsChange}
        selectedItems={selectedUsers}
        selectText={selectedUsers.length <= 0 ? t("searchMultiSelect.placeholder") : ""}
        searchInputPlaceholderText={t("searchMultiSelect.searchPlaceholder")}
        onChangeInput={text => logger(text)}
        displayKey="name"
        hideDropdown
        hideSubmitButton
        searchInputStyle={style.searchInputStyle}
        selectedItemFontFamily="Comfortaa"
        fontFamily="Comfortaa"
        itemFontFamily="Comfortaa"
        styleDropdownMenuSubsection={[style.input, style.styleDropdownMenuSubsection]}
        styleInputGroup={style.input}
        styleTextDropdown={style.dropdown}
        styleTextDropdownSelected={style.dropdown}
        selectedItemIconColor={primary}
        selectedItemTextColor={black}
        tagRemoveIconColor={disabled}
        tagBorderColor={card}
        tagTextColor={blackMedium}
        // @ts-expect-error
        tagContainerStyle={style.tag}
      />
    </View>
  )
}

export default SearchMultiSelect
