import AsyncStorage from "@react-native-async-storage/async-storage"
import Clipboard from "@react-native-clipboard/clipboard"
import { useNavigation } from "@react-navigation/native"
import {
  Body2,
  Button,
  Caption,
  firestore,
  H6,
  IUserContext,
  MaterialCommunityIcons,
  userContext,
  useTabLand,
  useTheme,
} from "@siruplab/capsule"
import _ from "lodash"
import React, { useContext, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { FlatList, Pressable, ScrollView, Text, View } from "react-native"
import Modal from "react-native-modal"

import { collections } from "../../common/types"
import { updatePortfolioData } from "../../features/models/PortfolioFunctions"
import { UserData } from "../../features/models/UserData"
import { rootRoutes } from "../../features/Navigation/Constants"
import usePortfolios from "../../features/Providers/PortfolioProvider"
import { AppTheme, ICON_SIZE, ICON_SIZE_LARGE, maxWidth } from "../../ThemeApp"
import { getPortfolioShareUrl } from "../../utils/getShareUrls"
import { useStyles } from "../../utils/useStyles"
import PermissionElements from "../ShareScreen/components/PermissionElements"
import useShareScreen from "../ShareScreen/hooks/useShareScreen"
import ConfirmationDialog from "./components/ConfirmationDialog"
import RoleItem from "./components/RoleItem"
import { ns } from "./i18n/en"

const PortfolioShareRecapScreen = () => {
  const isTab = useTabLand()
  const {
    colors: {
      secondary,
      black: { mediumEmphasisCustom, mediumEmphasisCustomDisabled },
      white: { mediumEmphasis: whiteMediumEmphasis },
    },
  } = useTheme<AppTheme>()

  const navigation = useNavigation()
  const { t } = useTranslation(ns)
  const { canEdit: canEditPortfolio, displayedPortfolioId } = usePortfolios()

  const selectedUser = useState<string>()
  const [modalVisible, setModalVisible] = useState(false)
  const [dialogVisible, setDialogVisible] = useState(false)
  const [askAgainCheck, setAskAgainCheck] = useState(false)

  const { userData } = useContext<IUserContext<UserData>>(userContext)

  const toggleAskAgainCheck = () => {
    setAskAgainCheck(old => !old)
  }

  const {
    removeUserFromFirebase,
    onItemPress,
    usersToRemove,
    users,
    checked,
    rolesToUpdate,
    elemInfos,
  } = useShareScreen({
    isPortfolio: true,
  })

  const isShareable = useMemo(
    () =>
      _(elemInfos?.users)
        .values()
        .filter(p => p?.email !== userData?.email)
        .size() > 0,
    [elemInfos, userData],
  )

  const onShare = async () => {
    setModalVisible(true)
    Clipboard.setString(getPortfolioShareUrl(displayedPortfolioId))
    setTimeout(() => {
      setModalVisible(false)
    }, 1000)
  }

  const onPublicShare = async () => {
    const portfolioRef = firestore()
      .collection(collections.PORTFOLIOS)
      .doc(displayedPortfolioId ?? "")
    await updatePortfolioData(portfolioRef, {
      isPublic: true,
    })
    // noinspection ES6MissingAwait
    onShare()
  }

  const onPress = async () => {
    const showAlert = (await AsyncStorage.getItem("showAlert")) ?? "false"

    if (showAlert === "false") {
      setDialogVisible(true)
    } else {
      // noinspection ES6MissingAwait
      onPublicShare()
    }
  }

  const s = useStyles(
    ({
      dimensions: { spacing },
      typography: { h6 },
      colors: {
        error,
        primaryLight,
        black: { overlay },
        white: { highEmphasis: white },
        surface: { sheets, overlay: secondaryOverlay, disabled: blackDisabled },
      },
    }) => ({
      hintText: { color: secondary },
      hintTextPrivate: { color: isShareable ? secondary : error },
      buttonLabel: { color: isShareable ? secondary : mediumEmphasisCustomDisabled },
      flatList: { marginTop: spacing },
      subtitle: { ...h6, fontSize: 16 },
      icon: { marginRight: spacing },
      scrollView: { backgroundColor: sheets },
      addUserBtn: { flexDirection: "row", alignItems: "center" },
      upperView: { flex: 1, backgroundColor: white, borderRadius: 8 },
      copyButton: { marginHorizontal: spacing, marginVertical: spacing / 2 },
      mainView: { flex: 1, maxWidth, width: isTab ? maxWidth : "100%", borderRadius: 8 },
      contentContainerStyle: { backgroundColor: white, borderTopWidth: 1, borderColor: overlay },
      addUserView: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginHorizontal: spacing,
      },
      hint: {
        padding: spacing,
        borderRadius: 8,
        marginBottom: spacing,
        marginHorizontal: spacing,
        backgroundColor: secondaryOverlay,
      },
      privateHint: {
        backgroundColor: isShareable ? secondaryOverlay : primaryLight,
      },
      publicShareBtn: {
        flexDirection: "row",
        marginHorizontal: spacing,
        marginTop: spacing * 2,
        marginBottom: spacing,
      },
      copyModal: {
        width: 130,
        height: 130,
        borderRadius: 8,
        alignSelf: "center",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: blackDisabled,
      },
    }),
    [isShareable],
  )

  return (
    <ScrollView>
      <ConfirmationDialog
        {...{ dialogVisible, setDialogVisible, onPublicShare, askAgainCheck, toggleAskAgainCheck }}
      />
      <Modal
        useNativeDriver
        backdropOpacity={0}
        animationIn="fadeIn"
        animationOut="fadeOut"
        isVisible={modalVisible}
      >
        <View style={s.copyModal}>
          <MaterialCommunityIcons size={ICON_SIZE_LARGE} name="check" />
          <H6>{t("linkCopied")}</H6>
        </View>
      </Modal>
      <View style={s.publicShareBtn}>
        <MaterialCommunityIcons size={ICON_SIZE} color={secondary} name="link" style={s.icon} />
        <H6>Public sharing</H6>
      </View>
      <Button
        onPress={onPress}
        style={s.copyButton}
        icon={() => (
          <MaterialCommunityIcons size={ICON_SIZE} color={whiteMediumEmphasis} name="link" />
        )}
      >
        {t("publicShareBtn")}
      </Button>
      <View style={s.hint}>
        <Body2 style={s.hintText}>{t("publicInfo")}</Body2>
      </View>
      <View style={s.publicShareBtn}>
        <MaterialCommunityIcons
          size={ICON_SIZE}
          color={secondary}
          name="link-lock"
          style={s.icon}
        />
        <H6>Restricted access</H6>
      </View>
      <Button
        onPress={onShare}
        mode="outlined"
        disabled={!isShareable}
        style={s.copyButton}
        labelStyle={s.buttonLabel}
        icon={() => (
          <MaterialCommunityIcons
            size={ICON_SIZE}
            color={isShareable ? secondary : mediumEmphasisCustomDisabled}
            name="link-lock"
          />
        )}
      >
        {t("privateShareBtn")}
      </Button>
      <View style={[s.hint, s.privateHint]}>
        <Body2 style={s.hintTextPrivate}>
          {t(isShareable ? "privateInfo" : "cannotShare", { usersNbr: users.length })}
        </Body2>
      </View>
      <PermissionElements
        {...{
          removeUserFromFirebase,
          selectedUser,
          checked,
          collapsed: true,
          onItemPress,
          usersToRemove,
          rolesToUpdate,
          isRecap: true,
          isPortfolio: true,
        }}
      />
      <View style={s.addUserView}>
        <Text style={s.subtitle}>{t("authorizedUsers")}</Text>
        <Pressable
          style={s.addUserBtn}
          onPress={() => {
            navigation.navigate(rootRoutes.ELEM_SHARE, { isPortfolio: true })
          }}
        >
          <Caption>{t("addUser")}</Caption>
          <MaterialCommunityIcons size={ICON_SIZE} color={mediumEmphasisCustom} name="plus" />
        </Pressable>
      </View>
      {!users ? null : (
        <FlatList
          data={users}
          style={s.flatList}
          renderItem={({ item }) => (
            <RoleItem {...{ item, rolesToUpdate, canEditPortfolio, selectedUser }} />
          )}
          keyExtractor={user => user.uid}
          contentContainerStyle={s.contentContainerStyle}
        />
      )}
    </ScrollView>
  )
}

export default PortfolioShareRecapScreen
