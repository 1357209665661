import _ from "lodash"

import { en as assetListScreen_en } from "../../screens/AssetListScreen/i18n"
import { en as assetMapScreen_en } from "../../screens/AssetMapScreen/i18n"
import { en as assetSelectionModal_en } from "../../screens/AssetSelectionScreen.tsx/i18n"
import { en as assets_filters_en } from "../../screens/AssetsFilterScreen/i18n"
import { en as asset_view_en } from "../../screens/AssetViewScreen/i18n"
import { en as company_en } from "../../screens/CompanyScreen/i18n"
import { en as create_asset_en } from "../../screens/CreateAssetScreen/i18n"
import { en as imageView_en } from "../../screens/ImageViewScreen/i18n"
import { en as loading_en } from "../../screens/LoadingScreen/i18n"
import { en as login_en } from "../../screens/LoginScreen/i18n"
import { en as main_en } from "../../screens/MainScreen/i18n"
import { en as names_en } from "../../screens/NameScreen/i18n"
import { en as password_create_en } from "../../screens/PasswordCreateScreen/i18n"
import { en as portfoliosListScreen_en } from "../../screens/PortfolioListScreen/i18n"
import { en as shareRecap_en } from "../../screens/PortfolioShareRecap/i18n"
import { en as portfolioViewScreen_en } from "../../screens/PortfolioViewScreen/i18n"
import { en as profile_en } from "../../screens/ProfileScreen/i18n"
import { en as share_asset_en } from "../../screens/ShareScreen/i18n"
import { en as signInValidation_en } from "../../screens/SignInValidationScreen/i18n"
import { en as signUp_en } from "../../screens/SignUpScreen/i18n"
import { en as welcomeScreen_en } from "../../screens/WelcomeScreen/i18n"
import { en as navigation_en } from "../Navigation/i18n"
import common from "./common"

export default {
  fr: _.merge(
    {},
    common,
    assetMapScreen_en,
    navigation_en,
    main_en,
    signUp_en,
    imageView_en,
    create_asset_en,
    login_en,
    signInValidation_en,
    asset_view_en,
    shareRecap_en,
    share_asset_en,
    names_en,
    password_create_en,
    company_en,
    loading_en,
    assetListScreen_en,
    assets_filters_en,
    portfoliosListScreen_en,
    portfolioViewScreen_en,
    profile_en,
    assetSelectionModal_en,
    welcomeScreen_en,
    {
      common: { lang: "fr" },
    },
  ),
  en: _.merge(
    {},
    common,
    navigation_en,
    assetMapScreen_en,
    login_en,
    main_en,
    signUp_en,
    imageView_en,
    create_asset_en,
    asset_view_en,
    share_asset_en,
    password_create_en,
    signInValidation_en,
    names_en,
    shareRecap_en,
    company_en,
    assetListScreen_en,
    assets_filters_en,
    portfoliosListScreen_en,
    portfolioViewScreen_en,
    profile_en,
    assetSelectionModal_en,
    welcomeScreen_en,
    {
      common: { lang: "en" },
    },
  ),
}
