import { useNavigation } from "@react-navigation/native"
import {
  ActionBar,
  ButtonType,
  Caption,
  COMMON_NAMESPACE,
  IUserContext,
  logger,
  MaterialCommunityIcons,
  MaterialIcons,
  useAlert,
  userContext,
  useTheme,
} from "@siruplab/capsule"
import React, { useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { View } from "react-native"

import { UserData } from "../../../features/models/UserData"
import { rootRoutes } from "../../../features/Navigation/Constants"
import { navigate } from "../../../features/Navigation/NavigationService"
import { useAssets } from "../../../features/Providers/AssetProvider"
import { ICON_SIZE } from "../../../ThemeApp"
import { useStyles } from "../../../utils/useStyles"
import { useMixedNav } from "../../MainScreen/MainNavigator"
import { ns } from "../i18n/en"

const AssetViewHeaderRight = () => {
  const {
    canEdit,
    deleteSelectedAsset,
    assetToEdit,
    assets,
    canDelete,
    selectedAsset,
    setSelectedAsset,
  } = useAssets()
  const { t } = useTranslation([ns, COMMON_NAMESPACE])
  const { user } = useContext<IUserContext<UserData>>(userContext)

  const asset = useMemo(() => assets.find(elem => elem.id === selectedAsset), [
    assets,
    selectedAsset,
  ])

  const { setIsValid, showDialog } = useAlert()

  const {
    colors: {
      black: { highEmphasis: black },
    },
  } = useTheme()
  const navigation = useNavigation()
  const mixedNav = useMixedNav()

  const s = useStyles(({ colors: { black: { mediumEmphasis }, secondaryLight } }) => ({
    dialogLeftButton: {
      marginRight: 0,
      flex: 0,
    },
    dialogRightButton: {
      marginLeft: 0,
      flex: 0,
    },
    shareNbr: {
      backgroundColor: secondaryLight,
      borderRadius: 50,
      marginLeft: -12,
      alignItems: "center",
      justifyContent: "center",
      width: 13,
      height: 13,
    },
    iconBtnView: { flexDirection: "row", alignItems: "flex-end" },
    dialogTextColor: {
      color: mediumEmphasis,
    },
  }))

  const onPress = () => {
    showDialog({
      type: "button",
      title: t("deleteModal.title"),
      message: t("deleteModal.message"),
      messageStyle: s.dialogTextColor,
      positive: {
        buttonStyle: s.dialogRightButton,
        onPress: async () => {
          try {
            setIsValid?.(false)
            await deleteSelectedAsset()
          } catch (e) {
            logger("Asset suppression error: ", e)
          } finally {
            mixedNav.goBack()
            setSelectedAsset(undefined)
            setIsValid?.(true)
          }
        },
        type: ButtonType.POSITIVE,
        loading: true,
        label: t("deleteModal.confirm"),
        labelType: "destructive",
      },
      negative: {
        buttonStyle: s.dialogLeftButton,
        type: ButtonType.NEGATIVE,
        textStyle: s.dialogTextColor,
        label: t("deleteModal.cancel"),
      },
    })
  }

  const shareNbr = asset ? Object.keys(asset.roles).filter(e => e !== user?.uid).length : 0

  const shareElem = useMemo(
    () => ({
      color: black,
      id: 0,
      onPress: () => navigate(rootRoutes.ELEM_SHARE),
      iconComp: (
        <View style={s.iconBtnView}>
          <MaterialIcons name="person" size={ICON_SIZE} />
          {shareNbr !== 0 ? (
            <View style={s.shareNbr}>
              <Caption>{shareNbr}</Caption>
            </View>
          ) : null}
        </View>
      ),
    }),
    [black, s, shareNbr],
  )

  const editElem = useMemo(
    () => ({
      id: 1,
      color: black,
      onPress: () => {
        assetToEdit[1](selectedAsset)
        navigation.navigate(rootRoutes.CREATE_ASSET, { isEdit: true })
      },
      iconComp: <MaterialCommunityIcons name="pencil" size={ICON_SIZE} />,
    }),
    [assetToEdit, black, navigation, selectedAsset],
  )

  return (
    <ActionBar
      icons={canEdit ? [shareElem, editElem] : undefined}
      menu={
        canDelete
          ? [
              {
                id: 0,
                label: t("delete"),
                onPress,
              },
            ]
          : undefined
      }
      menuColor={black}
    />
  )
}

export default AssetViewHeaderRight
