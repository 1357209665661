export const rootRoutes = {
  ONBOARDING: "Onboarding",
  MAIN_NAVIGATOR: "MainNavigator",
  CREATE_ASSET: "CreateAsset",
  ASSET_VIEW: "AssetView",
  ELEM_SHARE: "ElemShare",
  Assets_Tab: "Assets_Tab",
  SHARE_RECAP: "ShareRecap",
  ASSETS_FILTER: "AssetsFilter",
  ASSET_SELECTION: "AssetSelection",
  IMAGE_VIEW: "ImageView",
} as const

export type RootRouteNames = typeof rootRoutes[keyof typeof rootRoutes]

export const webRootRoutes = {
  ASSETS_TAB: "AssetsTab",
  ASSET_VIEW: "AssetsView",
  CREATE_ASSET: "CreateAsset",
}

export type WebRootRoutesNames = typeof webRootRoutes[keyof typeof webRootRoutes]

export const onboardingRoutes = {
  LOADING: "Loading",
  WELCOME: "Welcome",
  PASSWORD_CREATE: "PasswordCreate",
  PASSWORD_LOGIN: "PasswordLogin",
  NAMES: "Names",
  SIGNUP: "SignUp",
  LOGIN: "Login",
  COMPANY: "Company",
  SIGNIN_VALIDATION: "SignInValidation",
} as const

export type OnboardingRouteNames = typeof onboardingRoutes[keyof typeof onboardingRoutes]
