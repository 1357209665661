import AsyncStorage from "@react-native-async-storage/async-storage"
import { Body2, Button, H6 } from "@siruplab/capsule"
import React from "react"
import { useTranslation } from "react-i18next"
import { Pressable, View } from "react-native"
import { Checkbox, Dialog, Portal } from "react-native-paper"

import { useStyles } from "../../../utils/useStyles"
import { ns } from "../i18n/en"

export const ConfirmationDialog = ({
  dialogVisible,
  setDialogVisible,
  toggleAskAgainCheck,
  onPublicShare,
  askAgainCheck,
}) => {
  const { t } = useTranslation(ns)

  const hideDialog = () => setDialogVisible(false)

  const s = useStyles(
    ({
      dimensions: { spacing },
      colors: {
        primary,
        black: { mediumEmphasisCustom },
      },
    }) => ({
      dialog: { borderRadius: spacing, maxWidth: 400, alignSelf: "center" },
      alertNegative: { color: mediumEmphasisCustom },
      alertPositive: { color: primary, marginLeft: spacing * 2 },
      titleView: { padding: spacing },
      title: { paddingTop: spacing },
      pressable: { flexDirection: "row", alignItems: "center", paddingLeft: 8 },
    }),
  )

  return (
    <Portal>
      <Dialog visible={dialogVisible} style={s.dialog} onDismiss={hideDialog}>
        <View style={s.titleView}>
          <H6>{t("alert.title")}</H6>
          <Body2 style={s.title}>{t("alert.subtitle")}</Body2>
        </View>
        <Pressable style={s.pressable} onPress={toggleAskAgainCheck}>
          <Checkbox.Android status={askAgainCheck ? "checked" : "unchecked"} />
          <Body2 emphasis="high">{t("alert.doNotAskAgain")}</Body2>
        </Pressable>
        <Dialog.Actions>
          <Button mode="text" labelStyle={s.alertNegative} onPress={hideDialog}>
            {t("alert.cancel")}
          </Button>
          <Button
            mode="text"
            labelStyle={s.alertPositive}
            onPress={async () => {
              hideDialog()
              await AsyncStorage.setItem("showAlert", askAgainCheck ? "true" : "false")
              // noinspection ES6MissingAwait
              onPublicShare()
            }}
          >
            {t("alert.proceed")}
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  )
}

export default ConfirmationDialog
