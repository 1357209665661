import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

const Welcome4SVG = (props: SvgProps) => (
  <Svg width={115} height={114} viewBox="0 0 115 114" fill="none" {...props}>
    <Path
      d="M57.5 57c10.493 0 19-8.507 19-19s-8.507-19-19-19-19 8.507-19 19 8.507 19 19 19zM95.5 76H86v-9.5h-9.5V76H67v9.5h9.5V95H86v-9.5h9.5V76zM57.5 80.75c0-5.306 1.8-10.15 4.74-14.108a89.814 89.814 0 00-4.74-.142c-15.162 0-26.31 3.776-32.476 6.564C21.637 74.6 19.5 77.981 19.5 81.7V95h42.86c-3.008-3.98-4.86-8.878-4.86-14.25z"
      fill={props.color || "#FF8F86"}
    />
  </Svg>
)

export default Welcome4SVG
