import {
  CommonActions,
  RouteProp,
  useFocusEffect,
  useIsFocused,
  useNavigation,
  useNavigationState,
  useRoute,
} from "@react-navigation/native"
import { HeaderBackButton } from "@react-navigation/stack"
import {
  ActionBar,
  Button,
  ButtonType,
  firestore,
  generateShadow,
  H6,
  IUserContext,
  MaterialIcons,
  ThemeProvider,
  useAlert,
  userContext,
  useTabLand,
  useTabTwoPane,
  useTheme,
} from "@siruplab/capsule"
import _ from "lodash"
import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import { Image, Platform, View } from "react-native"
import { ActivityIndicator, Chip, FAB } from "react-native-paper"
import { SafeAreaView } from "react-native-safe-area-context"

import { images } from "../../assets/images"
import { collections } from "../../common/types"
import AssetList from "../../components/AssetList"
import { ClearIcon, MapSVG, ShareSVG } from "../../components/svg"
import { canEditFn } from "../../features/models/AssetsFunctions"
import { updatePortfolioData } from "../../features/models/PortfolioFunctions"
import { UserData } from "../../features/models/UserData"
import { rootRoutes } from "../../features/Navigation/Constants"
import { useAssetFilter } from "../../features/Providers/AssetFilterProvider"
import { useAssets } from "../../features/Providers/AssetProvider"
import usePortfolios from "../../features/Providers/PortfolioProvider"
import { ICON_SIZE, maxWidth } from "../../ThemeApp"
import { useAssetSelection } from "../../utils/useAssetSelection"
import { useStyles } from "../../utils/useStyles"
import { ns as assetListNs } from "../AssetListScreen/i18n/en"
import { assetsRoutes, mainRoutes, portfoliosRoutes } from "../MainScreen/Constants"
import { useMixedNav } from "../MainScreen/MainNavigator"
import { PortfoliosParamList } from "../MainScreen/MainTabs"
import { ns } from "./i18n/en"

const PortfolioViewScreen = () => {
  /**
   * Hooks
   */
  const { t } = useTranslation([ns, assetListNs])
  const navigation = useNavigation()
  const isTabLand = useTabLand()
  const isTabTwoPane = useTabTwoPane()
  const mixedNav = useMixedNav()
  const { params } = useRoute<RouteProp<PortfoliosParamList, "PortfoliosAssets">>()
  const { assetToEdit, assets, hasEditAccess } = useAssets()
  const {
    displayedPortfolioId,
    displayedPortfolio,
    canEdit,
    loading: portfolioLoading,
    publicMode,
    setPublicMode,
    canDelete,
    setDisplayedPortfolioId,
  } = usePortfolios()
  const { setSelectedPortfolios } = useAssetFilter()
  const isFocused = useIsFocused()
  const { showDialog, setIsValid } = useAlert()
  const currentNavState = useNavigationState(navState => navState)

  const isPublicPortfolio = useMemo(() => (displayedPortfolio?.isPublic && publicMode) ?? false, [
    displayedPortfolio,
    publicMode,
  ])

  useEffect(() => {
    if (currentNavState.index === 0 && currentNavState.routes[0].name === portfoliosRoutes.ASSETS) {
      // A setTimeout is required here to wait for the navState to be "ready" for a reset on dynamicLink received
      setTimeout(() => {
        navigation.dispatch(prevState =>
          CommonActions.reset({
            ...prevState,
            routes: [...[{ name: "PortfoliosList" }], ...[prevState.routes[0]]],
            index: 1,
          }),
        )
      })
    }
  }, [navigation, params, currentNavState])

  useEffect(() => {
    if (params?.id && isFocused) {
      setDisplayedPortfolioId(params.id)
    }
  }, [displayedPortfolio, displayedPortfolioId, isFocused, params, setDisplayedPortfolioId])

  /**
   * States
   */
  const [loading, setLoading] = useState(true)
  const [selectedAssetsIds, setSelectedAssetsIds] = useState<string[]>([])
  const [locallyFilteredAssets, setLocallyFilteredAssets] = useState(assets)
  const [state, setState] = useState({ open: false })

  const { onSharePress } = useAssetSelection(selectedAssetsIds)
  const { user } = useContext<IUserContext<UserData>>(userContext)

  // eslint-disable-next-line no-shadow
  const onStateChange = ({ open }) => setState({ open })

  const { open } = state

  /**
   * Callbacks/functions
   */
  const clearSelection = useCallback(() => {
    setSelectedAssetsIds([])
  }, [])

  const navToCreateAsset = useCallback(() => {
    assetToEdit[1](undefined)
    mixedNav.reset({
      index: 0,
      routes: [{ name: rootRoutes.CREATE_ASSET, params: { portfolioId: displayedPortfolioId } }],
    })
  }, [assetToEdit, displayedPortfolioId, mixedNav])

  const navToAssetSelection = useCallback(() => {
    navigation.navigate(rootRoutes.ASSET_SELECTION)
  }, [navigation])

  const deletePortfolio = useCallback(() => {
    firestore()
      .collection(collections.PORTFOLIOS)
      .doc(displayedPortfolioId ?? "")
      .delete()
    navigation.navigate(portfoliosRoutes.LIST)
  }, [navigation, displayedPortfolioId])

  const removeAssetsFromPortfolio = useCallback(() => {
    const portfolioRef = firestore()
      .collection(collections.PORTFOLIOS)
      .doc(displayedPortfolioId ?? "")
    // noinspection JSIgnoredPromiseFromCall
    updatePortfolioData(portfolioRef, {
      assets: _.without(displayedPortfolio?.assets, ...selectedAssetsIds),
    })
  }, [displayedPortfolioId, displayedPortfolio, selectedAssetsIds])

  const navToAssetsMap = () => {
    navigation.navigate(publicMode ? mainRoutes.ASSETS_TAB : assetsRoutes.MAIN)
  }

  const navToBack = useCallback(() => {
    if (publicMode) {
      setPublicMode(false)
      return
    }
    setSelectedPortfolios([])
    navigation.goBack()
  }, [publicMode, setSelectedPortfolios, navigation, setPublicMode])

  /**
   * Style
   */
  const {
    colors: {
      primary,
      secondary,
      surface: { shadows },
    },
  } = useTheme()

  const s = useStyles(
    ({
      colors: {
        surface: { appUi, textInput },
        white: { highEmphasis: white },
        black: { highEmphasis: black },
      },
      typography: { body2 },
      dimensions: { spacing },
    }) => ({
      indicator: { paddingTop: spacing },
      leftPublicIcon: { height: ICON_SIZE, width: ICON_SIZE, marginLeft: spacing },
      signInBtnLabel: { color: primary },
      portfolioName: {
        flex: 1,
        borderBottomWidth: 1,
        backgroundColor: white,
        borderColor: textInput,
        paddingBottom: spacing,
        paddingLeft: 4.5 * spacing,
      },
      gabGroup: { height: 48, width: 48, justifyContent: "center", alignItems: "center" },
      fab: { backgroundColor: white },
      iconBtnView: { flexDirection: "row", alignItems: "flex-end" },
      buttonIcon: { marginLeft: -spacing / 4 },
      fabMap: {
        bottom: spacing,
        backgroundColor: secondary,
        paddingLeft: spacing / 2,
        position: "absolute",
        ...generateShadow(2),
        alignSelf: "center",
        height: 32,
        ...(publicMode ? { marginBottom: spacing * 2 } : {}),
      },
      invertFab: {
        backgroundColor: black,
      },
      fabMapLabel: {
        ...(Platform.OS === "android" ? body2 : {}),
        color: white,
        alignSelf: "flex-end",
      },
      viewStyle: { backgroundColor: appUi, flex: 1, paddingBottom: 0 },
      icon: { marginRight: spacing / 2 },
      dialogLeftButton: {
        marginRight: 0,
        flex: 0,
      },
      dialogRightButton: {
        marginLeft: 0,
        flex: 0,
      },
    }),
    [publicMode],
  )

  const onDeletePress = useCallback(() => {
    setIsValid?.(true)
    showDialog({
      type: "button",
      title: t("deleteTitle", { name: displayedPortfolio?.name }),
      message: t("deleteMessage"),
      positive: {
        buttonStyle: s.dialogRightButton,
        onPress: deletePortfolio,
        type: ButtonType.POSITIVE,
        label: t("common:actions.confirm"),
        labelType: "destructive",
      },
      negative: {
        buttonStyle: s.dialogLeftButton,
        type: ButtonType.NEGATIVE,
        label: t("common:actions.cancel"),
      },
      style: isTabLand ? { margin: "auto", maxWidth } : {},
    })
  }, [s, deletePortfolio, displayedPortfolio, isTabLand, setIsValid, showDialog, t])

  const fabOptions = useMemo(
    () => [
      ...[
        {
          icon: "plus",
          label: t("newAsset"),
          style: s.fab,
          onPress: navToCreateAsset,
        },
        {
          icon: "plus-box-multiple",
          label: t("existingAsset"),
          style: s.fab,
          onPress: navToAssetSelection,
        },
        {
          icon: "close-box-multiple",
          label: t("remove"),
          style: selectedAssetsIds.length > 0 ? s.fab : s.invertFab,
          color: selectedAssetsIds.length === 0 ? shadows : undefined,
          onPress: selectedAssetsIds.length > 0 ? removeAssetsFromPortfolio : _.noop,
          // @ts-ignore
          labelStyle: selectedAssetsIds.length > 0 ? {} : { backgroundColor: shadows },
        },
      ],
      ...(canDelete
        ? [
            {
              icon: "trash-can",
              label: t("delete"),
              style: s.fab,
              onPress: onDeletePress,
            },
          ]
        : []),
    ],
    [
      t,
      s,
      navToCreateAsset,
      navToAssetSelection,
      selectedAssetsIds,
      shadows,
      removeAssetsFromPortfolio,
      canDelete,
      onDeletePress,
    ],
  )

  /**
   * useEffects
   */
  useLayoutEffect(() => {
    navigation.setOptions({
      title: displayedPortfolio?.numberedName,
      headerLeft: () =>
        !user ? (
          <Image source={images.logo} style={s.leftPublicIcon} />
        ) : (
          <HeaderBackButton labelVisible={false} onPress={navToBack} />
        ),
      ...(user
        ? {}
        : {
            headerRight: () => (
              <Button
                mode="text"
                labelStyle={s.signInBtnLabel}
                onPress={() => {
                  setPublicMode(false)
                }}
              >
                Sign in
              </Button>
            ),
          }),
    })
  }, [s, displayedPortfolio, isPublicPortfolio, navigation, setPublicMode, navToBack, user])

  useFocusEffect(
    useCallback(() => {
      setLocallyFilteredAssets(
        _.filter(assets, asset => displayedPortfolio?.assets?.includes(asset.id) ?? false),
      )
      setLoading(false)

      return () => {
        if (!isFocused) {
          clearSelection()
        }
      }
    }, [assets, clearSelection, displayedPortfolio, isFocused]),
  )

  const showShareButton = useMemo(
    () =>
      selectedAssetsIds.length === 1 &&
      canEditFn(assets?.find(item => item.id === selectedAssetsIds[0])?.roles, user?.uid),
    [assets, selectedAssetsIds, user],
  )

  useEffect(() => {
    if (!user || isPublicPortfolio) {
      return
    }
    navigation.setOptions({
      // headerTitle needs to be a component and NOT a string with h6 style applied, in order to render descenders correctly
      headerTitle: () => (
        <H6>
          {selectedAssetsIds.length === 0
            ? displayedPortfolio?.numberedName
            : t("assetSelected", { count: selectedAssetsIds.length })}
        </H6>
      ),
      headerRight: () => (
        <ActionBar
          icons={
            selectedAssetsIds.length === 0
              ? !canEdit
                ? []
                : [
                    {
                      id: 0,
                      onPress: () => {
                        const res = hasEditAccess(locallyFilteredAssets.map(elem => elem.id))
                        if (!res) {
                          showDialog({
                            type: "button",
                            title: t("modalTitle"),
                            message: t("modalDescription"),
                            positive: {
                              buttonStyle: s.dialogRightButton,
                              type: ButtonType.POSITIVE,
                              label: t("common:button.ok"),
                              labelType: "destructive",
                            },
                            style: isTabLand ? { margin: "auto", maxWidth } : {},
                          })
                        } else {
                          navigation.navigate(rootRoutes.SHARE_RECAP, { isPortfolio: true })
                        }
                      },
                      iconComp: (
                        <View style={s.iconBtnView}>
                          <MaterialIcons name="share" size={ICON_SIZE} />
                        </View>
                      ),
                    },
                  ]
              : [
                  {
                    id: 0,
                    onPress: onSharePress,
                    iconComp: showShareButton ? <ShareSVG /> : <></>,
                  },
                  {
                    id: 1,
                    onPress: clearSelection,
                    iconComp: <ClearIcon />,
                  },
                ]
          }
        />
      ),
    })
  }, [
    locallyFilteredAssets,
    canEdit,
    clearSelection,
    displayedPortfolio,
    navigation,
    onSharePress,
    s,
    selectedAssetsIds,
    showShareButton,
    t,
    user,
    hasEditAccess,
    showDialog,
    isTabLand,
    isPublicPortfolio,
  ])

  return loading || portfolioLoading ? (
    <ActivityIndicator style={s.indicator} size="large" color={primary} />
  ) : (
    <ThemeProvider
      customTheme={{
        colors: {
          overrides: { button: secondary },
        },
        button: { roundness: 28 },
      }}
    >
      <SafeAreaView edges={["left", "right"]} style={s.viewStyle}>
        <AssetList
          data={locallyFilteredAssets}
          isEmpty={_.isEmpty(locallyFilteredAssets)}
          isPortfolioView
          limitSelection
          error={
            displayedPortfolio === undefined
              ? {
                  name: t("errorMessage"),
                  message: t("errorDetails"),
                }
              : undefined
          }
          {...{ selectedAssetsIds, setSelectedAssetsIds }}
        />
        {!canEdit || publicMode ? null : (
          <FAB.Group
            open={open}
            icon={open ? "close" : "pencil"}
            actions={fabOptions}
            onStateChange={onStateChange}
            visible
            fabStyle={s.gabGroup}
            // @ts-ignore
            isFabSmall
          />
        )}
        {!isTabTwoPane ? (
          <Chip
            avatar={<MapSVG style={s.buttonIcon} />}
            onPress={navToAssetsMap}
            style={s.fabMap}
            textStyle={s.fabMapLabel}
          >
            {t(`${mainRoutes.ASSETS_TAB}:viewMap`)}
          </Chip>
        ) : null}
      </SafeAreaView>
    </ThemeProvider>
  )
}

export default PortfolioViewScreen
