import { RouteProp, useRoute } from "@react-navigation/native"
import {
  Body2,
  Button,
  Caption,
  COMMON_NAMESPACE,
  emailListRegex,
  FormikTextInput,
  generateShadow,
  H6,
  IUserContext,
  KeyboardAvoidView,
  MaterialCommunityIcons,
  MaterialIcons,
  useKeyboard,
  userContext,
  useTabLand,
  useTheme,
} from "@siruplab/capsule"
import { Formik } from "formik"
import _ from "lodash"
import React, { useCallback, useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { FlatList, ListRenderItemInfo, ScrollView, View } from "react-native"
import Modal from "react-native-modal"
import { ActivityIndicator, List } from "react-native-paper"
import { SafeAreaView } from "react-native-safe-area-context"
import * as Yup from "yup"

import { UserData } from "../../features/models/UserData"
import { RootParamList } from "../../features/Navigation/RootNavigator"
import { useAssets } from "../../features/Providers/AssetProvider"
import usePortfolios from "../../features/Providers/PortfolioProvider"
import { AppTheme, ICON_SIZE, ICON_SIZE_LARGE, maxWidth } from "../../ThemeApp"
import { useStyles } from "../../utils/useStyles"
import PermissionElements from "./components/PermissionElements"
import PortfoliosInfos from "./components/PortfoliosInfos"
import useShareScreen, { ShareForm } from "./hooks/useShareScreen"
import { ns } from "./i18n/en"

const ShareScreen = () => {
  const {
    colors: {
      black: { underlineColor, mediumEmphasisCustom },
    },
  } = useTheme<AppTheme>()
  const { portfolios, displayedPortfolioId, canEdit: canEditPortfolio } = usePortfolios()
  const {
    selectedAsset,
    assets,
    canEdit: canEditAsset,
    selectedAssetUnknownPortfolios,
  } = useAssets()
  const isTab = useTabLand()
  const keyboardShown = useKeyboard()
  const { params } = useRoute<RouteProp<RootParamList, "ElemShare">>()
  const { t } = useTranslation([ns, COMMON_NAMESPACE])

  const { user } = useContext<IUserContext<UserData>>(userContext)
  const { uid } = user ?? {}
  const isPortfolio = params?.isPortfolio

  const elemInfos = isPortfolio
    ? portfolios.find(elem => elem.id === displayedPortfolioId)
    : assets.find(elem => elem.id === selectedAsset)
  const selectedUser = useState<string>()
  const {
    onItemPress,
    usersToRemove,
    onPress,
    users,
    checked,
    collapsed,
    setCollapsed,
    rolesToUpdate,
    role,
    onSubmit,
    modalVisible,
    commonInfos,
  } = useShareScreen({ isPortfolio })

  const s = useStyles(
    ({
      dimensions: { spacing, inputHeight, inputBorderRadius },
      typography: { body2, subtitle1 },
      colors: {
        danger,
        secondary,
        black: { overlay, disabled: blackDisabled },
        white: { highEmphasis: white },
        surface: { disabled, sheets, overlay: secondaryOverlay },
      },
    }) => ({
      copyButton: { marginHorizontal: spacing, marginVertical: spacing / 2 },
      hintText: { color: secondary },
      flatList: { marginTop: spacing },
      activity: { marginTop: spacing },
      buttonLabel: { color: secondary },
      scrollView: { backgroundColor: sheets },
      description: { ...body2, color: disabled },
      label: { ...subtitle1, color: danger, marginLeft: 0 },
      border: { borderBottomWidth: 1, borderColor: overlay },
      linkView: { backgroundColor: white, marginTop: spacing },
      textInputView: { margin: spacing, backgroundColor: white },
      upperView: { flex: 1, backgroundColor: white, borderRadius: 8 },
      separator: { width: "100%", backgroundColor: overlay, height: 1 },
      mainView: { flex: 1, maxWidth, width: isTab ? maxWidth : "100%", borderRadius: 8 },
      contentContainerStyle: { backgroundColor: white, borderTopWidth: 1, borderColor: overlay },
      input: {
        ...body2,
        height: inputHeight,
        borderTopLeftRadius: inputBorderRadius,
        borderTopRightRadius: inputBorderRadius,
      },
      dialogRightButton: {
        marginLeft: 0,
        flex: 0,
      },
      hint: {
        padding: spacing,
        borderRadius: 8,
        marginBottom: spacing,
        marginHorizontal: spacing,
        backgroundColor: secondaryOverlay,
      },
      listItem: {
        height: 60,
        padding: 0,
        marginRight: spacing,
        marginLeft: spacing / 2,
        justifyContent: "center",
      },
      copyModal: {
        width: 130,
        height: 130,
        borderRadius: 8,
        alignSelf: "center",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: blackDisabled,
      },
      publicShareBtn: {
        flexDirection: "row",
        marginHorizontal: spacing,
        marginTop: spacing * 2,
        marginBottom: spacing / 2,
      },
      doneView: {
        padding: spacing,
        backgroundColor: white,
        ...generateShadow(4),
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
      },
    }),
    [],
  )

  const renderItem = useCallback(
    ({ item }: ListRenderItemInfo<typeof users[0]>) => {
      const selectedRole = rolesToUpdate[0]?.[item.uid]

      const onEditPress =
        (isPortfolio ? canEditPortfolio : canEditAsset) &&
        item.uid !== uid &&
        selectedRole !== "owner"
          ? () => {
              selectedUser[1](item.uid)
            }
          : undefined

      return (
        <>
          <List.Item
            style={s.listItem}
            description={!item.name ? undefined : item?.email}
            onPress={onEditPress}
            descriptionStyle={s.description}
            title={item.name ?? item.email}
            right={() => (
              <View style={{ alignItems: "center", flexDirection: "row" }}>
                <Caption>
                  {t(
                    selectedRole === "viewer"
                      ? "viewer"
                      : // @ts-ignore
                      selectedRole === "viewer-partial"
                      ? "partialViewer"
                      : selectedRole === "owner"
                      ? "owner"
                      : isPortfolio
                      ? "team"
                      : "editor",
                  )}
                </Caption>
                {!onEditPress ? null : (
                  <MaterialIcons
                    name="arrow-drop-down"
                    size={ICON_SIZE}
                    color={mediumEmphasisCustom}
                  />
                )}
              </View>
            )}
          />
          <View style={s.separator} />
        </>
      )
    },
    [
      rolesToUpdate,
      isPortfolio,
      canEditPortfolio,
      canEditAsset,
      uid,
      s,
      selectedUser,
      t,
      mediumEmphasisCustom,
    ],
  )

  return (
    <Formik<ShareForm>
      onSubmit={onSubmit}
      initialValues={{ email: "", role: null }}
      validationSchema={Yup.object({
        email: Yup.string().matches(emailListRegex, { message: t("common:fields.invalidEmails") }),
      })}
    >
      {({ handleSubmit, errors, values, isSubmitting }) => (
        <View style={s.mainView}>
          <View style={s.upperView}>
            <View style={s.textInputView}>
              <FormikTextInput
                name="email"
                style={s.input}
                label={t("label")}
                autoCorrect={false}
                autoCapitalize="none"
                changeUnderline={false}
                keyboardType="email-address"
                placeholder={t("placeholder")}
                underlineColor={underlineColor}
                onFocus={() => setCollapsed(false)}
              />
            </View>
            <Modal
              useNativeDriver
              backdropOpacity={0}
              animationIn="fadeIn"
              animationOut="fadeOut"
              isVisible={modalVisible}
            >
              <View style={s.copyModal}>
                <MaterialCommunityIcons size={ICON_SIZE_LARGE} name="check" />
                <H6>{t("linkCopied")}</H6>
              </View>
            </Modal>
            <ScrollView style={s.scrollView} keyboardShouldPersistTaps="handled">
              <PermissionElements
                {...{
                  selectedUser,
                  checked,
                  collapsed,
                  onItemPress,
                  usersToRemove,
                  rolesToUpdate,
                  isPortfolio,
                }}
              />
              {!users || isPortfolio ? null : (
                <FlatList
                  data={users.filter(
                    e => !(e.uid === uid || (usersToRemove[0] && usersToRemove[0].includes(e.uid))),
                  )}
                  style={s.flatList}
                  renderItem={renderItem}
                  keyExtractor={item => item.uid}
                  contentContainerStyle={s.contentContainerStyle}
                />
              )}
              {isPortfolio
                ? null
                : [
                    {
                      items: commonInfos,
                      title: t("commonPortfolios"),
                    },
                    {
                      title: t("unknownPortfolios"),
                      items: selectedAssetUnknownPortfolios,
                    },
                  ].map((elem, index) =>
                    elem.items ? (
                      <PortfoliosInfos key={index} {...elem} />
                    ) : (
                      <ActivityIndicator key={index} style={s.activity} />
                    ),
                  )}
              {isPortfolio ? null : (
                <View style={s.linkView}>
                  <Button
                    mode="outlined"
                    onPress={onPress}
                    style={s.copyButton}
                    labelStyle={s.buttonLabel}
                  >
                    {t("copyLink")}
                  </Button>
                  <View style={s.hint}>
                    <Body2 style={s.hintText}>{t("copyLinkDesc")}</Body2>
                  </View>
                </View>
              )}
            </ScrollView>
          </View>
          <KeyboardAvoidView hasOffset>
            <SafeAreaView edges={keyboardShown ? ["left", "right"] : ["bottom"]} style={s.doneView}>
              <Button
                loading={isSubmitting}
                onPress={handleSubmit}
                disabled={
                  !(
                    (values.email.length > 0 && !errors.email && role !== undefined) ||
                    !_.isEqual(rolesToUpdate[0], elemInfos?.roles) ||
                    usersToRemove[0].length > 0
                  )
                }
              >
                {t(isPortfolio ? "add" : "done")}
              </Button>
            </SafeAreaView>
          </KeyboardAvoidView>
        </View>
      )}
    </Formik>
  )
}

export default ShareScreen
