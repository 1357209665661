import { mainRoutes } from "../../MainScreen/Constants"

export const ns = mainRoutes.ASSETS_TAB

export default {
  [ns]: {
    standardMapName: "Default map",
    satelliteMapName: "Satellite map",
    standard: "Default",
    satellite: "Satellite",
    chipFilters: "Filters",
    viewList: "View list",
    placeholder: "Search assets",
    noAddressFound: "No address found",
    coord: "Latitude: {{lat}}\nLongitude: {{lng}}",
    createAsset: "Create an asset from here",
  },
}
