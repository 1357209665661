import { Caption, IUserContext, MaterialIcons, userContext, useTheme } from "@siruplab/capsule"
import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import { View } from "react-native"
import { List } from "react-native-paper"

import { UserData } from "../../../features/models/UserData"
import { AppTheme, ICON_SIZE } from "../../../ThemeApp"
import { useStyles } from "../../../utils/useStyles"
import { ns } from "../i18n/en"

const RoleItem = ({ rolesToUpdate, canEditPortfolio, selectedUser, item }) => {
  const selectedRole = rolesToUpdate[0]?.[item.uid]
  const {
    colors: {
      black: { mediumEmphasisCustom },
    },
  } = useTheme<AppTheme>()

  const { t } = useTranslation(ns)

  const { user } = useContext<IUserContext<UserData>>(userContext)
  const { uid } = user ?? {}

  const isSelf = item.uid === uid
  const onEditPress =
    canEditPortfolio && !isSelf && selectedRole !== "owner"
      ? () => {
          selectedUser[1](item.uid)
        }
      : undefined

  const s = useStyles(
    ({
      dimensions: { spacing },
      typography: { body2 },
      colors: {
        black: { overlay, disabled },
      },
    }) => ({
      listItem: {
        height: 60,
        padding: 0,
        marginRight: spacing,
        marginLeft: spacing / 2,
        justifyContent: "center",
      },
      separator: { width: "100%", backgroundColor: overlay, height: 1 },
      description: { ...body2, color: disabled },
    }),
  )

  return (
    <>
      <List.Item
        style={s.listItem}
        description={!item.name ? undefined : item?.email}
        onPress={onEditPress}
        descriptionStyle={s.description}
        title={`${item.name ?? item.email} ${isSelf ? t("you") : ""} `}
        right={() => (
          <View style={{ alignItems: "center", flexDirection: "row" }}>
            <Caption>
              {t(
                selectedRole === "viewer"
                  ? "viewer"
                  : // @ts-ignore
                  selectedRole === "viewer-partial"
                  ? "partialViewer"
                  : selectedRole === "owner"
                  ? "owner"
                  : "team",
              )}
            </Caption>
            {!onEditPress ? null : (
              <MaterialIcons name="arrow-drop-down" size={ICON_SIZE} color={mediumEmphasisCustom} />
            )}
          </View>
        )}
      />
      <View style={s.separator} />
    </>
  )
}

export default RoleItem
