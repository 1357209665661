import _ from "lodash"
import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"

import { StateType } from "../../utils/StateType"
import { Classification, ID } from "../models/Asset"
import { Portfolio } from "../models/Portfolio"
import { NormalizedAsset, useAssets } from "./AssetProvider"
import usePortfolios from "./PortfolioProvider"

// TODO: decide if we want to handle errors and add the info in the value provided by the context

interface MinMaxes {
  maxSurface: number
  minSurface: number
  minRentalIncome: number
  maxRentalIncome: number
  minMonetaryValue: number
  maxMonetaryValue: number
}

type Values<N> = StateType<N | undefined>[1]

interface AssetFilterContext extends Partial<MinMaxes> {
  // using this format because it is the standard one for the API
  rates: {
    rates?: {
      [key: string]: number
    }
  }
  filteredAssets: NormalizedAsset[]
  poundsToEuroRates: {
    rates?: {
      [key: string]: number
    }
  }
  dollarsToEuroRates: {
    rates?: {
      [key: string]: number
    }
  }
  filterAssets: () => void
  filterNumber: number
  monetaryValues?: number[]
  setMonetaryValues: Values<number[]>
  surfaceValues?: number[]
  setSurfaceValues: Values<number[]>
  rentalIncomeValues?: number[]
  setRentalIncomeValues: Values<number[]>
  resetFilterValues: () => void

  // Classifications
  selectedClassifications?: string[]
  setSelectedClassifications: Values<string[]>

  // Portfolios
  selectedPortfolios?: string[]
  setSelectedPortfolios: Values<string[]>

  // Clear values
  clearValues?: boolean
  setClearValues: Dispatch<SetStateAction<boolean>>

  // Users
  selectedUsers: string[]
  setSelectedUsers: Dispatch<SetStateAction<string[]>>

  emailList: UserList[]
  setEmailList: Dispatch<SetStateAction<UserList[]>>
}

interface UserList {
  name: string
  id: string
}

interface IProps {
  children: ReactNode
}

const CONV_API = "https://api.frankfurter.app/latest?from="

const assetFilterContext = createContext<AssetFilterContext>({} as AssetFilterContext)

const minMax = (
  acc: Partial<MinMaxes>,
  field: "Surface" | "RentalIncome" | "MonetaryValue",
  value?: number,
) => {
  acc[`max${field}`] =
    (acc[`max${field}`] ?? Number.NEGATIVE_INFINITY) > (value ?? Number.NEGATIVE_INFINITY)
      ? acc[`max${field}`]
      : value
  acc[`min${field}`] =
    (acc[`min${field}`] ?? Number.POSITIVE_INFINITY) < (value ?? Number.POSITIVE_INFINITY)
      ? acc[`min${field}`]
      : value
}

const getRates = async (url: string, execWith: (params: any) => void) => {
  const res = await fetch(url)
  const parsed = await res.json()
  execWith({ rates: { ...parsed.rates } })
}

const AssetProvider = ({ children }: IProps) => {
  const { portfolios, publicPortfolio } = usePortfolios()

  /** FILTERS for display on list page */

  const [rates, setRates] = useState({ rates: undefined })
  const [poundsToEuroRates, setPoundsToEuroRates] = useState({ rates: undefined })
  const [dollarsToEuroRates, setDollarsToEuroRates] = useState({ rates: undefined })

  // the monetary value in EUR
  const [monetaryValues, setMonetaryValues] = useState<number[]>()

  // the rental income value in EUR
  const [rentalIncomeValues, setRentalIncomeValues] = useState<number[]>()

  // the surface in square meters
  const [surfaceValues, setSurfaceValues] = useState<number[]>()

  const [clearValues, setClearValues] = useState(false)

  useEffect(() => {
    if (publicPortfolio) {
      setSelectedPortfolios([publicPortfolio])
    }
  }, [publicPortfolio])

  const resetFilterValues = () => {
    setClearValues(true)
    setMonetaryValues(undefined)
    setRentalIncomeValues(undefined)
    setSurfaceValues(undefined)
    setSelectedPortfoliosIds([])
    setSelectedPortfolios([])
    setSelectedClassifications([])
    setSelectedUsers([])
  }

  const { assets, filteredDataBySearch } = useAssets()

  // Filter on classifications
  const [selectedClassifications, setSelectedClassifications] = useState<Classification[]>([])

  // Filter on portfolios
  const [selectedPortfoliosIds, setSelectedPortfoliosIds] = useState<ID[]>([])
  const [selectedPortfolios, setSelectedPortfolios] = useState<Portfolio[]>([])

  // Filter on assets
  const [filteredAssets, setFilteredAssets] = useState<NormalizedAsset[]>(assets)
  const [emailList, setEmailList] = useState<UserList[]>([])

  // Filter on users
  const [selectedUsers, setSelectedUsers] = useState<string[]>([])

  const {
    maxSurface,
    minSurface,
    maxRentalIncome,
    minRentalIncome,
    maxMonetaryValue,
    minMonetaryValue,
  }: Partial<MinMaxes> = useMemo(() => {
    if (!assets) {
      return {}
    }
    return assets.reduce<Partial<MinMaxes>>(
      (acc, { size, value, rentalIncome }) => {
        minMax(acc, "Surface", size?.searchValue)
        minMax(acc, "RentalIncome", rentalIncome?.searchValue)
        minMax(acc, "MonetaryValue", value?.searchValue)
        return acc
      },
      {
        maxSurface: undefined,
        minSurface: undefined,
        maxRentalIncome: undefined,
        minRentalIncome: undefined,
        maxMonetaryValue: undefined,
        minMonetaryValue: undefined,
      },
    )
  }, [assets])

  const filterAssets = useCallback(() => {
    setFilteredAssets(
      _.filter(filteredDataBySearch ?? assets, asset => {
        // Size
        const filterSurface = surfaceValues
          ? (asset.size?.searchValue ?? Number.NEGATIVE_INFINITY) >= surfaceValues[0] &&
            (asset.size?.searchValue ?? Number.POSITIVE_INFINITY) <= surfaceValues[1]
          : true

        // Value
        const filterValue = monetaryValues
          ? (asset.value?.searchValue ?? Number.NEGATIVE_INFINITY) >= monetaryValues[0] &&
            (asset.value?.searchValue ?? Number.POSITIVE_INFINITY) <= monetaryValues[1]
          : true

        // Rental income
        const filterRentalIncome = rentalIncomeValues
          ? (asset.rentalIncome?.searchValue ?? Number.NEGATIVE_INFINITY) >=
              rentalIncomeValues[0] &&
            (asset.rentalIncome?.searchValue ?? Number.POSITIVE_INFINITY) <= rentalIncomeValues[1]
          : true

        // Classifications
        const filterClassifications =
          selectedClassifications?.length > 0
            ? _.some(selectedClassifications, a => asset.classifications?.includes(a))
            : true

        // Portfolios
        const filterPortfolios =
          selectedPortfolios?.length > 0
            ? _.every(
                selectedPortfolios?.map(selectedPortfolio => selectedPortfolio.assets),
                assetsArray => assetsArray?.includes(asset.id),
              )
            : true
        // Users
        const filterUsers =
          selectedUsers?.length > 0
            ? _.some(selectedUsers, selectedUserId => asset.users[selectedUserId])
            : true

        return (
          filterSurface &&
          filterValue &&
          filterRentalIncome &&
          filterClassifications &&
          filterPortfolios &&
          filterUsers
        )
      }),
    )
  }, [
    filteredDataBySearch,
    assets,
    surfaceValues,
    monetaryValues,
    rentalIncomeValues,
    selectedClassifications,
    selectedPortfolios,
    selectedUsers,
  ])

  useEffect(() => {
    filterAssets()
  }, [assets, filteredDataBySearch, filterAssets, portfolios])

  useEffect(() => {
    const userList: Array<{ id: string; name: string }> = []
    const users = filteredAssets.map(element => element.users)
    const emailsFromUser = users.map(elt => Object.values(elt).map(user => user.email))
    emailsFromUser.forEach(emailArray =>
      emailArray.forEach(email =>
        !userList.find(item => item.id === email)
          ? userList.push({ id: email, name: email })
          : null,
      ),
    )
    setEmailList(userList)
  }, [filteredAssets])

  const filterNumber = useMemo(
    () =>
      [
        surfaceValues,
        monetaryValues,
        rentalIncomeValues,
        selectedClassifications,
        selectedPortfoliosIds,
        selectedUsers,
      ].filter(item => item !== undefined && item?.length > 0).length,
    [
      monetaryValues,
      rentalIncomeValues,
      selectedClassifications,
      selectedPortfoliosIds,
      surfaceValues,
      selectedUsers,
    ],
  )

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    getRates(`${CONV_API}GBP&to=EUR`, setPoundsToEuroRates)
    // noinspection JSIgnoredPromiseFromCall
    getRates(`${CONV_API}USD&to=EUR`, setDollarsToEuroRates)
    // noinspection JSIgnoredPromiseFromCall
    getRates(`${CONV_API}EUR`, setRates)
  }, [])

  useEffect(() => {
    if (portfolios) {
      setSelectedPortfolios(
        portfolios.filter(portfolio => selectedPortfoliosIds.includes(portfolio.id)),
      )
    }
  }, [portfolios, selectedPortfoliosIds])

  const contextValue: AssetFilterContext = {
    // Assets
    rates,
    poundsToEuroRates,
    dollarsToEuroRates,
    filterNumber,
    filterAssets,
    filteredAssets,
    resetFilterValues,

    // Surface
    maxSurface,
    minSurface,
    surfaceValues,
    setSurfaceValues,

    // Monetary value
    monetaryValues,
    minMonetaryValue,
    maxMonetaryValue,
    setMonetaryValues,

    // Rental Income
    minRentalIncome,
    maxRentalIncome,
    setRentalIncomeValues,
    rentalIncomeValues,

    // Classifications
    setSelectedClassifications,
    selectedClassifications,

    // Portfolios
    selectedPortfolios: selectedPortfoliosIds,
    setSelectedPortfolios: setSelectedPortfoliosIds,

    // Clear values
    clearValues,
    setClearValues,

    // Users
    selectedUsers,
    setSelectedUsers,
    emailList,
    setEmailList,
  }

  return <assetFilterContext.Provider value={contextValue}>{children}</assetFilterContext.Provider>
}

export const useAssetFilter = () => {
  const context = useContext(assetFilterContext)
  if (context === undefined) {
    throw new Error("useAssetFilter must be used within an AssetProvider")
  }

  return context
}

export default AssetProvider
