import { getPathFromState, LinkingOptions, NavigationContainer } from "@react-navigation/native"
import { NavigationState } from "@react-navigation/routers"
import {
  AlertProvider,
  auth,
  dynamicLinks,
  FirebaseDynamicLinksTypes,
  firestore,
  functions,
  logger,
} from "@siruplab/capsule"
import React, { useCallback, useEffect, useRef } from "react"
import { Linking, useWindowDimensions } from "react-native"
import RNBootSplash from "react-native-bootsplash"
import { URL } from "react-native-url-polyfill"

import { collections } from "./common/types"
import { ignoreWarnings } from "./features/config/LogBox"
import { navigationRef } from "./features/Navigation/NavigationService"
import RootNavigator, { screens } from "./features/Navigation/RootNavigator"
import usePortfolios from "./features/Providers/PortfolioProvider"
import RootProvider from "./features/Providers/RootProvider"
import { actionCodeSettings } from "./screens/SignUpScreen/SignUpScreen"
import ScreenTracking from "./services/Analytics/analytics"
import ThemeApp, { maxWidth } from "./ThemeApp"
import isWeb from "./utils/isWeb"
import LoadFonts from "./utils/loadFonts"

// DO NOT DELETE
// if (__DEV__) {
//   setLoggerEnabled(true)
//   setTimeout(() => {
//     // functions().useFunctionsEmulator("http://localhost:5001")
//     // firestore().settings({ host: "localhost:8080", ssl: false })
//   }, 100)
// }

export const authAndRemoveToken = async (
  dynamicLink: FirebaseDynamicLinksTypes.DynamicLink,
): Promise<{ url: string; hasToken: boolean }> => {
  const myUrl = new URL(dynamicLink.url)
  const refreshToken = myUrl.searchParams.get("refreshToken")
  if (refreshToken && !auth().currentUser) {
    const res = await functions().httpsCallable("getCustomTokenFromRefreshToken")({
      refreshToken,
    })
    await auth().signInWithCustomToken(res.data)
    myUrl.searchParams.delete("refreshToken")
    return { url: myUrl.href, hasToken: true }
  }
  return { url: myUrl.href, hasToken: false }
}

// https://domaineapp.page.link/?link=https://www.domaine-app.com/main/portfolios/portfolioView/5xZnQN3ayD5SZLcz8w8V&apn=com.siruplab.domaine&ibi=com.siruplab.domaine

const linking = (handlePortfolioPublicMode): LinkingOptions => ({
  prefixes: [
    "domaine://",
    "http://localhost:3000",
    "https://www.domaine-app.com/",
    "https://domaineapp.page.link",
    "https://domaine-bb731.web.app",
    "https://domaine-bb731.firebaseapp.com",
  ],
  async getInitialURL(): Promise<string> {
    // Check if the app was opened by a deep link
    const url = await Linking.getInitialURL()
    const dynamicLink = await dynamicLinks().getInitialLink()
    if (dynamicLink) {
      if (dynamicLink.url.startsWith(`${actionCodeSettings.url}main/portfolios/portfolioView`)) {
        const portfolioId = dynamicLink.url.split("/").pop()
        const portfolio = await firestore()
          .collection(collections.PORTFOLIOS)
          .doc(portfolioId)
          .get()
        const isPublic = portfolio.data()?.isPublic
        if (isPublic) {
          return dynamicLink.url
        }
      }
      const res = await authAndRemoveToken(dynamicLink)
      return res.url
    }
    if (url) {
      return url
    }
    // If it was not opened by a deep link, go to the assets screen
    return "domaine://MainNavigator"
  },
  // Custom function to subscribe to incoming links
  subscribe(listener: (deeplink: string) => void) {
    // First, you may want to do the default deep link handling
    const onReceiveURL = ({ url }: { url: string }) => listener(url)
    // Listen to incoming links from deep linking
    Linking.addEventListener("url", onReceiveURL)
    const handleDynamicLink = async (dynamicLink: FirebaseDynamicLinksTypes.DynamicLink) => {
      const res = await authAndRemoveToken(dynamicLink)
      if (res.hasToken) {
        // TODO: This is a STRONG anti-pattern, we must find a better way to execute this listener somewhere else in the app
        global.execListener = () => listener(res.url)
      }
      handlePortfolioPublicMode(res.url)
      listener(res.url)
    }
    const unsubscribeToDynamicLinks = dynamicLinks().onLink(handleDynamicLink)
    return () => {
      unsubscribeToDynamicLinks()
      Linking.removeAllListeners("url")
    }
  },
  config: { screens },
  // only == and not === to match the verification made by the package and avoid an error
  getPathFromState: (state, options) => (state == null ? "/" : getPathFromState(state, options)),
})

ignoreWarnings()

const NavigationBuilder = ({ screenTracking }) => {
  const { width: windowWidth } = useWindowDimensions()
  const { handlePortfolioPublicMode } = usePortfolios()

  return (
    <NavigationContainer
      // @ts-ignore
      ref={navigationRef}
      onStateChange={screenTracking}
      linking={linking(handlePortfolioPublicMode)}
    >
      <LoadFonts
        fonts={{
          Comfortaa: [
            { file: "Comfortaa-Regular", weight: 400 },
            { file: "Comfortaa-Medium", weight: 600 },
            { file: "Comfortaa-Bold", weight: 700 },
          ],
          EncodeSans: [
            { file: "EncodeSans-Black", weight: 900 },
            { file: "EncodeSans-Bold", weight: 700 },
            { file: "EncodeSans-ExtraBold", weight: 800 },
            { file: "EncodeSans-Medium", weight: 500 },
            { file: "EncodeSans-Regular", weight: 400 },
            { file: "EncodeSans-SemiBold", weight: 600 },
          ],
        }}
      />
      <ThemeApp>
        <AlertProvider
          customDialogTheme={{
            dimensions: { textInputStyle: { minWidth: windowWidth > maxWidth ? 400 : 280 } },
          }}
        >
          <RootNavigator />
        </AlertProvider>
      </ThemeApp>
    </NavigationContainer>
  )
}

const App = () => {
  const first = useRef(true)
  const routeNameRef = useRef<string | Partial<NavigationState> | undefined>()

  const screenTracking = useCallback((state: NavigationState | undefined) => {
    const previousRouteName = routeNameRef.current
    const currentRouteName = ScreenTracking.getActiveRouteName(state)
    if (first.current || previousRouteName !== currentRouteName) {
      first.current = false
      ScreenTracking.logScreenTracking(currentRouteName)
    }
    // Save the current route name for later comparison
    routeNameRef.current = currentRouteName
  }, [])

  useEffect(() => {
    // @ts-ignore
    const state = navigationRef?.current?.getRootState()
    // Save the initial route name
    routeNameRef.current = ScreenTracking.getActiveRouteName(state)
  }, [])
  useEffect(() => {
    if (isWeb) {
      setTimeout(() => RNBootSplash.hide({ fade: true }), 3000)
    }
  }, [])

  logger("reload App")

  // @ts-ignore
  return (
    <RootProvider>
      <NavigationBuilder screenTracking={screenTracking} />
    </RootProvider>
  )
}

export default App
