import "react-native-get-random-values"

import { RouteProp, StackActions, useNavigation, useRoute } from "@react-navigation/native"
import { StackNavigationProp } from "@react-navigation/stack"
import {
  Button,
  COMMON_NAMESPACE,
  firestore,
  FormikAddressTextInput,
  FormikTextInput,
  IAddress,
  IPaperTextInputProps,
  IUserContext,
  KeyboardSafeAreaScrollView,
  logger,
  storage,
  Subtitle1,
  Subtitle2,
  userContext,
  useTabLand,
  useTabTwoPane,
  useTheme,
} from "@siruplab/capsule"
import { Formik } from "formik"
import haversine from "haversine"
import _ from "lodash"
import React, {
  FormEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import {
  ActivityIndicator,
  Keyboard,
  NativeSyntheticEvent,
  Platform,
  TextInput,
  TextInputFocusEventData,
  View,
} from "react-native"
import Geocoder from "react-native-geocoding"
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view"
import { LatLng } from "react-native-maps"
import { SafeAreaView } from "react-native-safe-area-context"
import { v4 as uuidv4 } from "uuid"
import * as Yup from "yup"

import { collections } from "../../common/types"
import {
  Asset,
  Attachment,
  ComputedAddress,
  GeocoderResult,
  isUrlAttachment,
} from "../../features/models/Asset"
import {
  addAsset,
  areaLabels,
  getMoneyLabels,
  updateAssetData,
} from "../../features/models/AssetsFunctions"
import { updatePortfolioData } from "../../features/models/PortfolioFunctions"
import { UserData } from "../../features/models/UserData"
import { rootRoutes } from "../../features/Navigation/Constants"
import { rootNav } from "../../features/Navigation/NavigationService"
import { RootParamList } from "../../features/Navigation/RootNavigator"
import { useAssets } from "../../features/Providers/AssetProvider"
import usePortfolios from "../../features/Providers/PortfolioProvider"
import { AppTheme } from "../../ThemeApp"
import { isOfType } from "../../utils/isOfType"
import isWeb from "../../utils/isWeb"
import { Nullable } from "../../utils/Nullable"
import { useStyles } from "../../utils/useStyles"
import { ns as mapNs } from "../AssetMapScreen/i18n/en"
import { ns as filterNs } from "../AssetsFilterScreen/i18n/en"
import { alterList } from "../AssetViewScreen/components/AssetInfos"
import { ns as createNs } from "../CreateAssetScreen/i18n/en"
import { mainRoutes } from "../MainScreen/Constants"
import { useMixedNav } from "../MainScreen/MainNavigator"
import AssetPicsInfos from "./components/AssetPicsInfos"
import MultiSelectElements from "./components/MultiSelectElements"
import OptionalAssetInfo from "./components/OptionalAssetInfo"
import { ns } from "./i18n/en"

export const PLANS_PATH = "/plans/"
export const PHOTOS_PATH = "/photos/"

export type AssetValues = Omit<Asset, "id" | "roles" | "users" | "link" | "photos" | "plans"> & {
  portfolios: string[]
}
export type Reducer<State, Action> = (state: State, action: Action) => State

interface State {
  nearestPostalAddress: string
  nearestLocation?: Geocoder.LatLng
  fullAddressOnLongPress?: ComputedAddress
  isLoadingGeocoding: boolean
}

type Action =
  | {
      type: "SET_POSTAL_ADDRESS"
      nearestPostalAddress: string
      fullAddressOnLongPress?: ComputedAddress
    }
  | {
      type: "SET_COORDINATE_FROM_GEOCODING"
      nearestLocation: Geocoder.LatLng
      fullAddressOnLongPress?: ComputedAddress
    }
  | {
      type: "SET_COORDINATE_FROM_MAP"
      fullAddressOnLongPress?: ComputedAddress
    }
  | { type: "IS_LOADING"; isLoadingGeocoding: boolean }

// These values are set to "null" and not "undefined" to avoid firebase web issues
const initialValues: Nullable<AssetValues> = {
  plans1: [],
  photos1: [],
  name: null,
  size: null,
  picDir: null,
  value: null,
  portfolios: [],
  classifications: [],
  location: null,
  description: null,
  coordinates: null,
  rentalIncome: null,
}

export const uploadToFullPath = (dir: string, toUpload: Attachment[]) =>
  Promise.all(
    toUpload.map(async (attach, index) => {
      logger("toUpload", dir, attach)
      if (isUrlAttachment(attach) && attach.url.startsWith("http")) {
        return attach
      }

      const urlOrPath = isUrlAttachment(attach) ? attach.url : attach.path

      const name = isWeb
        ? attach.name ?? `pic${index}.${urlOrPath.split(";")[0].split("/")[1]}`
        : urlOrPath.split("\\").pop()?.split("/").pop()

      const storageRef = storage().ref(`${dir}${name}`)

      const upload = await (isWeb
        ? storageRef.putString(urlOrPath, "data_url")
        : storageRef.putFile(urlOrPath))

      logger("toUpload storage", upload)

      const url = await storage().ref(upload.metadata.fullPath).getDownloadURL()

      logger("toUpload url", upload)

      return { name, url }
    }),
  )
const AddressReducer = (state: State, action: Action) => {
  switch (action.type) {
    case "SET_POSTAL_ADDRESS": {
      return {
        ...state,
        nearestPostalAddress: action.nearestPostalAddress,
        fullAddressOnLongPress: action.fullAddressOnLongPress,
      }
    }
    case "SET_COORDINATE_FROM_GEOCODING": {
      return {
        ...state,
        nearestLocation: action.nearestLocation,
        fullAddressOnLongPress: action.fullAddressOnLongPress,
      }
    }
    case "SET_COORDINATE_FROM_MAP": {
      return { ...state, fullAddressOnLongPress: action.fullAddressOnLongPress }
    }
    case "IS_LOADING": {
      return { ...state, isLoadingGeocoding: true }
    }
    default:
      throw new Error("unexpected action")
  }
}
const initialState: State = {
  nearestPostalAddress: "",
  nearestLocation: undefined,
  fullAddressOnLongPress: undefined,
  isLoadingGeocoding: false,
}

const CreateAssetScreen = () => {
  const { t, i18n } = useTranslation([ns, filterNs, COMMON_NAMESPACE, mapNs, createNs])
  const {
    colors: {
      black: { underlineColor, overlay },
      surface,
    },
    typography: { subtitle1 },
  } = useTheme<AppTheme>()
  const navigation = useNavigation<StackNavigationProp<RootParamList, "ImageView">>()

  const mixedNav = useMixedNav()
  const { params } = useRoute<RouteProp<RootParamList, "CreateAsset">>()

  const { user, userData } = useContext<IUserContext<UserData>>(userContext)
  const { portfolios } = usePortfolios()

  const { assetToEdit, assets, canDelete } = useAssets()

  const scrollRef = useRef<KeyboardAwareScrollView>(null)
  const addressRef = useRef<IPaperTextInputProps>(null)

  const sizeInputRef = useRef<TextInput>(null)
  const valueInputRef = useRef<TextInput>(null)
  const rentalIncomeInputRef = useRef<TextInput>(null)

  const isEdit = useMemo(() => !!assetToEdit[0], [assetToEdit])
  const [needExtraScroll, setNeedExtraScroll] = useState(true)
  const [loading, setLoading] = useState(false)
  const [scrollEnabled, setScrollEnabled] = useState(true)
  const [isSingleCreation, setIsSingleCreation] = useState(true)
  const [distance, setDistance] = useState(undefined)

  const [
    { nearestPostalAddress, nearestLocation, fullAddressOnLongPress, isLoadingGeocoding },
    dispatch,
  ] = useReducer(AddressReducer, initialState)

  const assetToEditProps = useMemo(() => {
    const val = assets.find(elem => elem.id === assetToEdit[0])
    if (!isEdit || !val) {
      return undefined
    }
    const assetPortfolios = portfolios
      ?.filter(item => item?.assets?.find(elem => elem === val?.id))
      .map(elem => elem.id)

    return { ...val, portfolios: assetPortfolios }
  }, [assetToEdit, assets, isEdit, portfolios])

  const isTab = useTabLand()
  const isTabTwoPane = useTabTwoPane()

  const locale: string = i18n.language.toLowerCase()

  const items = [
    {
      name: "size",
      label: t("size"),
      values: areaLabels,
      forwardRef: sizeInputRef,
    },
    {
      name: "value",
      label: t("value"),
      values: getMoneyLabels(locale),
      forwardRef: valueInputRef,
    },
    {
      name: "rentalIncome",
      label: t("rentalIncome"),
      values: getMoneyLabels(locale),
      forwardRef: rentalIncomeInputRef,
    },
  ]

  const blurAllValueFields = useCallback(() => {
    sizeInputRef.current?.blur()
    valueInputRef.current?.blur()
    rentalIncomeInputRef.current?.blur()
  }, [])

  const onLongPressToDb = useMemo(() => {
    if (params?.fromMap && nearestLocation) {
      return { lat: nearestLocation.lat, lng: nearestLocation.lng }
    }
    return { lat: params?.coordinate?.latitude, lng: params?.coordinate?.longitude }
  }, [nearestLocation, params?.coordinate, params?.fromMap])

  const onSubmit = useCallback(
    async (values: AssetValues) => {
      try {
        Keyboard.dismiss()
        setLoading(true)
        const picDir = assetToEditProps?.picDir ?? uuidv4()
        const uploadedPhotos = !_.isEmpty(values.photos1)
          ? await uploadToFullPath(`${picDir}${PHOTOS_PATH}`, values.photos1)
          : []
        const uploadedPlans = !_.isEmpty(values.plans1)
          ? await uploadToFullPath(`${picDir}${PLANS_PATH}`, values.plans1)
          : []

        if (assetToEditProps) {
          logger("values", values, "assetToEditProps", assetToEditProps)

          await updateAssetData(assetToEditProps.id, {
            ..._.omit(values, "portfolios"),
            // @ts-ignore
            coordinates: values.location.location ?? assetToEditProps.coordinates,
            // @ts-ignore
            location: values.location.details ?? assetToEditProps.location,
            name: values.name,
            photos1: uploadedPhotos,
            plans1: uploadedPlans,
          })
          _.difference(assetToEditProps?.portfolios, values.portfolios).map(item =>
            alterList(assetToEditProps.id, item, false),
          )
          _.difference(values.portfolios, assetToEditProps?.portfolios).map(item =>
            alterList(assetToEditProps.id, item, true),
          )
        } else {
          if (user && userData) {
            const location =
              params?.fromMap && nearestPostalAddress
                ? fullAddressOnLongPress
                : isOfType<IAddress>(values.location, "postalAddress") && values.location.details
                ? _.omit(values.location.details, "photos")
                : params?.coordinate
            if (!location) {
              return
            }
            const newAsset = await addAsset({
              classifications: [],
              description: "",
              picDir,
              photos1: uploadedPhotos,
              plans1: uploadedPlans,
              // TODO: Handle the type more properly on capsule (the wrong type in save)
              // if onLongPress, coordinates send to db come from variables otherwise come from values form
              coordinates: params?.fromMap
                ? onLongPressToDb
                : isOfType<IAddress>(values.location, "postalAddress")
                ? {
                    lat: values?.location?.details?.geometry.location.lat as number,
                    lng: values?.location?.details?.geometry.location.lng as number,
                  }
                : isOfType<LatLng>(values.location, "latitude")
                ? {
                    lat: values?.location?.latitude,
                    lng: values?.location?.longitude,
                  }
                : {
                    lat: values?.location?.geometry?.location?.lat,
                    lng: values?.location?.geometry?.location?.lng,
                  },
              name: values.name,
              location: location as Asset["location"],
              roles: {
                [user.uid]: "owner",
              },
              users: {
                [user.uid]: {
                  name: `${userData.firstName} ${userData.lastName}`,
                  email: user.email ?? "",
                },
              },
            })
            if (values.portfolios.length > 0) {
              await Promise.all(
                values.portfolios.map(assetPortfolio => {
                  const portfolioInfos = portfolios.find(
                    portfolio => portfolio.id === assetPortfolio,
                  )
                  if (!portfolioInfos) {
                    return
                  }
                  updatePortfolioData(
                    firestore().collection(collections.PORTFOLIOS).doc(assetPortfolio),
                    {
                      assets: [...(portfolioInfos?.assets ?? []), newAsset.id],
                    },
                  )
                }),
              )
            }
          }
        }
      } catch (e) {
        logger("Create asset error: ", e)
      } finally {
        setLoading(false)
        if (isSingleCreation) {
          assetToEdit[1](undefined)
          if (isTabTwoPane) {
            mixedNav.reset({
              index: 0,
              routes: [{ name: mainRoutes.ASSETS_TAB }],
            })
          } else {
            navigation.goBack()
          }
        } else {
          mixedNav.dispatch(StackActions.replace("CreateAsset"))
          assetToEdit[1](undefined)
        }
      }
    },
    [
      assetToEditProps,
      user,
      userData,
      params,
      nearestPostalAddress,
      fullAddressOnLongPress,
      onLongPressToDb,
      portfolios,
      isSingleCreation,
      assetToEdit,
      isTabTwoPane,
      mixedNav,
      navigation,
    ],
  )

  const onFocus = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
    if (!isWeb) {
      scrollRef.current?.scrollToFocusedInput(e.target)
    }
  }

  const s = useStyles(
    ({
      dimensions: { margin, spacing, inputHeight, inputBorderRadius },
      colors: {
        secondary,
        surface: { textInput },
        white: { highEmphasis: white },
        black: { separator, highEmphasis: black, mediumEmphasis },
      },
    }) => ({
      buttonLabel: { color: black },
      disabledButtonLabel: { color: mediumEmphasis },
      bottomView: { zIndex: -1, marginTop: spacing, marginBottom: spacing * 3 },
      subtitle: { alignSelf: "center", color: black },
      firstButton: {
        width: "100%",
        marginHorizontal: margin,
        maxWidth: 380,
      },
      secondButton: { width: "100%", marginHorizontal: margin, maxWidth: 380, marginTop: spacing },
      button: {
        width: "100%",
        backgroundColor: secondary,
      },
      multiline: {
        maxHeight: 200,
        marginTop: spacing * 2,
        marginBottom: spacing * 2,
        height: isWeb ? 200 : undefined,
      },
      safeAreaTab: {
        maxWidth: 700,
        width: "100%",
        alignSelf: "center",
        marginBottom: isTab ? spacing : 0,
      },
      addressInputView: { height: 50 },
      textInput: {
        width: "100%",
        height: inputHeight,
        borderWidth: 0,
        borderRadius: 0,
        borderBottomWidth: 0,
        backgroundColor: textInput,
        borderColor: mediumEmphasis,
        borderTopRightRadius: inputBorderRadius,
        borderTopLeftRadius: inputBorderRadius,
      },
      alertDialog: {
        width: "100%",
        borderWidth: 0,
        padding: spacing / 2,
        borderRadius: inputBorderRadius,
        borderBottomWidth: 0,
        backgroundColor: textInput,
        borderColor: mediumEmphasis,
        justifyContent: "center",
        marginBottom: spacing,
      },
      staticBottom: {
        padding: spacing,
        alignItems: "center",
        backgroundColor: white,
        ...(isTab
          ? {
              // generateShadow is not used here because a reversed shadow is required
              shadowColor: "#000",
              shadowOffset: {
                width: 0,
                height: -1,
              },
              shadowOpacity: 0.2,
              shadowRadius: 1.41,
            }
          : {}),
      },
      mainView: {
        flex: 1,
        paddingTop: spacing / 2,
        backgroundColor: white,
        paddingHorizontal: spacing,
      },
      separator: {
        height: 1,
        width: "100%",
        marginBottom: spacing,
        marginTop: spacing * 2,
        backgroundColor: separator,
      },
    }),
  )

  const toggleScroll = (newStatus?: boolean) => {
    if (Platform.OS === "android") {
      setScrollEnabled(prevState => newStatus ?? !prevState)
    }
  }

  const textInputProps = { style: s.textInput, underlineColor, changeUnderline: false }

  const onSubmitEditing = () => {
    if (Platform.OS === "android") {
      toggleScroll()
      Keyboard.dismiss()
    }
  }

  const submitButtonProps = useCallback(
    (handleSubmit: (e?: FormEvent<HTMLFormElement>) => void) => ({
      onPress: () => {
        setIsSingleCreation(true)
        blurAllValueFields()

        // TODO: find a better solution than setTimeout to wait for blur
        setTimeout(() => {
          handleSubmit()
        }, 50)
      },
      style: s.firstButton,
      loading: isSingleCreation ? loading : false,
    }),
    [blurAllValueFields, isSingleCreation, loading, s],
  )

  const onOpen = useCallback(
    (plans: boolean, list?: Attachment[] | null) => (index: number) => () => {
      ;(isTabTwoPane ? rootNav() : navigation).navigate(rootRoutes.IMAGE_VIEW, {
        assetId: assetToEditProps?.id,
        plans,
        index,
        images: list ?? undefined,
      })
    },
    [assetToEditProps, isTabTwoPane, navigation],
  )

  const addressInputStyle = {
    listView: {
      height: 200,
      backgroundColor: "white",
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
    },
    separator: {
      backgroundColor: overlay,
      marginHorizontal: 16,
    },
    description: {
      ...subtitle1,
    },
    row: {
      backgroundColor: "white",
      height: Platform.OS === "android" ? 50 : 56,
      marginHorizontal: 8,
      paddingVertical: 12,
    },
    poweredContainer: {
      backgroundColor: "white",
    },
  }

  const calculateAddress = useCallback(
    async ({ latitude, longitude }: LatLng) => {
      try {
        const { results } = await Geocoder.from(latitude, longitude)

        // filter only real address with precise geocode
        const res = results.filter(
          ({ geometry: { location_type } }) =>
            location_type === "ROOFTOP" || location_type === "APPROXIMATIVE",
        )
        setDistance(
          haversine(
            { latitude, longitude },
            {
              latitude: res?.[0]?.geometry?.location.lat,
              longitude: res?.[0]?.geometry?.location.lng,
            },
            {
              unit: "meter",
            },
          ),
        )
        if (res?.[0]?.formatted_address) {
          dispatch({
            type: "SET_POSTAL_ADDRESS",
            nearestPostalAddress: res?.[0]?.formatted_address,
            fullAddressOnLongPress: res?.[0],
          })
        } else if (res?.[0]?.geometry?.location) {
          dispatch({
            type: "SET_COORDINATE_FROM_GEOCODING",
            nearestLocation: res?.[0]?.geometry?.location,
            fullAddressOnLongPress: res?.[0],
          })
        } else if (params?.coordinate) {
          dispatch({
            type: "SET_COORDINATE_FROM_MAP",
            fullAddressOnLongPress: params?.coordinate,
          })
        } else {
          dispatch({
            type: "IS_LOADING",
            isLoadingGeocoding: true,
          })
        }
      } catch (e) {
        logger("geocoding error", e)
      }
    },
    [params?.coordinate],
  )

  useEffect(() => {
    ;(async () => {
      if (params?.fromMap) {
        await calculateAddress(params?.coordinate)
        dispatch({
          type: "IS_LOADING",
          isLoadingGeocoding: false,
        })
      }
    })()
  }, [calculateAddress, params?.coordinate, params?.fromMap])

  const locationValues = useMemo(
    () => ({
      ...initialValues,
      location: fullAddressOnLongPress,
    }),
    [fullAddressOnLongPress],
  )

  const condition = useMemo(() => {
    if (params?.fromMap && locationValues) {
      return locationValues
    } else if (isEdit && !params?.fromMap) {
      return assetToEditProps
    } else {
      return initialValues
    }
  }, [assetToEditProps, isEdit, locationValues, params?.fromMap])

  // loading if no found address yet
  if (params?.fromMap && !isLoadingGeocoding) {
    return <ActivityIndicator />
  }
  return condition ? (
    <Formik<Nullable<Omit<AssetValues, "location">>>
      initialValues={condition}
      onSubmit={onSubmit}
      validationSchema={Yup.object({
        name: Yup.string().required(t("common:fields.required")).nullable(true),
        ...(params?.fromMap || isEdit
          ? {}
          : { location: Yup.object().required(t("common:fields.required")) }),
      })}
    >
      {({ setFieldValue, handleBlur, handleSubmit, values: { photos1 }, isValid }) => (
        <>
          <KeyboardSafeAreaScrollView
            style={s.mainView}
            extraScrollHeight={needExtraScroll ? 100 : 0}
            scrollEnabled={scrollEnabled}
            safeAreaStyle={isTab ? s.safeAreaTab : undefined}
            forwardRef={scrollRef}
            edges={["bottom"]}
            keyboardShouldPersistTaps="handled"
          >
            <View style={s.alertDialog}>
              <Subtitle2 adjustsFontSizeToFit>
                {t(`alertDialog${Platform.OS === "web" ? "Web" : ""}`)}
              </Subtitle2>
            </View>
            <View style={s.addressInputView}>
              {(params?.fromMap && isLoadingGeocoding) ||
              assetToEditProps?.location ||
              !distance ? (
                <FormikAddressTextInput
                  editable={!isEdit || canDelete}
                  onBlur={handleBlur}
                  placeholder=""
                  // @ts-ignore
                  query={{
                    language: undefined,
                    components: undefined,
                  }}
                  inputStyle={addressInputStyle}
                  hasShadow
                  listUnderlayColor="white"
                  name="location"
                  label={t("location")}
                  numberOfLines={2}
                  forwardRef={addressRef}
                  onShow={() => toggleScroll(false)}
                  onHide={() => toggleScroll(true)}
                  customHeight={s.addressInputView.height}
                  getDefaultValue={() => {
                    if (params?.fromMap && !nearestLocation && !nearestPostalAddress) {
                      return `Latitude: ${params.coordinate.latitude.toFixed(
                        6,
                      )} Longitude: ${params.coordinate.longitude.toFixed(6)}`
                    } else if (params?.fromMap && fullAddressOnLongPress && distance) {
                      if (distance < 500) {
                        return nearestPostalAddress
                      } else {
                        return JSON.stringify(nearestLocation)
                      }
                    }
                    if (!assetToEditProps?.location) {
                      return ""
                    } else if (
                      isOfType<GeocoderResult>(assetToEditProps?.location, "formatted_address")
                    ) {
                      return assetToEditProps?.location?.formatted_address
                    } else if (
                      isOfType<IAddress>(assetToEditProps?.location, "postalAddress") &&
                      assetToEditProps?.location.details
                    ) {
                      return assetToEditProps.location.details.formatted_address
                    }
                    // @ts-ignore
                    return assetToEditProps?.location?.formatted_address ?? ""
                  }}
                  textInputProps={{
                    ...textInputProps,
                    style: [
                      textInputProps.style,
                      isEdit && !canDelete
                        ? {
                            backgroundColor: surface.textInputDisabled,
                          }
                        : {},
                    ],
                    underlineColor: !isEdit || canDelete ? underlineColor : "transparent",
                  }}
                  onSubmitEditing={onSubmitEditing}
                />
              ) : null}
            </View>
            <View style={s.bottomView}>
              <FormikTextInput label={t("name")} name="name" {...textInputProps} />
              <View style={s.separator} />
              <Subtitle1 style={s.subtitle}>{t("optionalInfo")}</Subtitle1>
              <AssetPicsInfos
                name="photos1"
                label={t("addPhotos")}
                onOpen={onOpen(false, photos1)}
                allowPdf={false}
              />
              <MultiSelectElements
                setFieldValue={setFieldValue}
                setNeedExtraScroll={setNeedExtraScroll}
                initialPortfolios={
                  params?.portfolioId ? [params?.portfolioId] : assetToEditProps?.portfolios
                }
                initialClassifications={assetToEditProps?.classifications}
              />
              {items.map((item, index) => (
                <OptionalAssetInfo key={index} {...item} index={index} />
              ))}
              <AssetPicsInfos
                name="plans1"
                label={t("addPlans")}
                onOpen={onOpen(true)}
                allowPdf={true}
              />
              <FormikTextInput
                multiline
                numberOfLines={5}
                onFocus={onFocus}
                name="description"
                {...textInputProps}
                textAlignVertical="top"
                label={t("description")}
                style={[s.textInput, s.multiline]}
              />
            </View>
          </KeyboardSafeAreaScrollView>
          <SafeAreaView style={s.staticBottom} edges={["bottom"]}>
            {isEdit && !params.fromMap ? (
              <Button {...submitButtonProps(handleSubmit)}>{t("save")}</Button>
            ) : (
              <>
                <Button disabled={!isValid} {...submitButtonProps(handleSubmit)}>
                  {t("create")}
                </Button>
                <Button
                  mode="outlined"
                  onPress={() => {
                    setIsSingleCreation(false)
                    blurAllValueFields()
                    setTimeout(() => {
                      handleSubmit()
                    }, 50)
                  }}
                  disabled={!isValid}
                  style={s.secondButton}
                  labelStyle={isValid ? s.buttonLabel : s.disabledButtonLabel}
                  loading={isSingleCreation ? false : loading}
                >
                  {t("createAndContinue")}
                </Button>
              </>
            )}
          </SafeAreaView>
        </>
      )}
    </Formik>
  ) : null
}
export default CreateAssetScreen
