import { StackNavigationProp } from "@react-navigation/stack"
import * as React from "react"

import { RootRouteNames } from "./Constants"
import { RootParamList } from "./RootNavigator"

export const navigationRef = React.createRef<StackNavigationProp<RootParamList>>()

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const rootNav = () => navigationRef.current!

export const navigate = (name: RootRouteNames, params?: any) =>
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  rootNav().navigate(name, params)
