import { onboardingRoutes } from "../../../features/Navigation/Constants"
import { accountRoutes } from "../../MainScreen/Constants"

export const ns = onboardingRoutes.COMPANY
export const nsCompanyEdit = accountRoutes.COMPANY_EDIT
export const nsTeammatesEdit = accountRoutes.TEAMMATES_EDIT

export default {
  common: {
    [ns]: {
      title: "Company",
    },
    [nsCompanyEdit]: {
      title: "Company name",
    },
    [nsTeammatesEdit]: {
      title: "Invite teammates",
    },
  },
  [ns]: {
    companyName: "Company name",
    inviteTeammates: "Invite teammates",
    teammatesEmails: "Teammates emails - optional",
    teammatesEmailsEdit: "Teammates emails",
    subtitle: "Invite teammates so it's easier to share assets",
    title: "What's your company or team?",
    teammatesEmailsPlaceholder: "email1@example.com, …",
    invalidEmails: "One of these emails is not valid",
    sameValuesError: "The input should not match the previous value",
  },
}
