import * as React from "react"
import Svg, { G, Mask, Path, SvgProps } from "react-native-svg"

const WelcomeBg3SVG = (props: SvgProps) => (
  <Svg viewBox="0 0 650 616" fill="none" {...props}>
    <Mask id="prefix__a" x={0} y={0} width={650} height={616}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M650 307.734V321h-.297C642.367 484.803 499.799 615.469 325 615.469 150.201 615.469 7.634 484.803.297 321H0V0h650v307.734z"
        fill="#FFF"
      />
    </Mask>
    <G mask="url(#prefix__a)">
      <Path
        d="M745.689 739.62H314.343c-32.061 0-58.298-26.237-58.298-58.298v-38.276c0-32.061 26.237-58.298 58.298-58.298h34.547c32.06 0 58.298 26.237 58.298 58.298v669.244"
        stroke="#fff"
        strokeWidth={0.31}
        strokeMiterlimit={10}
      />
      <Path d="M.15 1002.78h650.373V515.001H.15v487.779z" fill="#6A6D66" />
      <Path opacity={0.5} d="M325.339 514.99H185.745v148.623h139.594V514.99z" fill="#32352F" />
      <Path
        d="M745.69 739.621H314.344c-32.061 0-58.298-26.237-58.298-58.298v-38.276c0-32.061 26.237-58.298 58.298-58.298h34.547c32.061 0 58.298 26.237 58.298 58.298V1312.3"
        stroke="#fff"
        strokeWidth={0.31}
        strokeMiterlimit={10}
      />
      <Path d="M.166 515.006h-650.372v487.774H.166V515.006z" fill="#6A6D66" />
      <Path
        d="M10.751 664.246l-208.296-.066c-32.06 0-58.297-26.27-58.297-58.33V327.151c0-32.06-26.238-58.298-58.298-58.298l-72.202.033c-32.061 0-58.298-26.204-58.298-58.298v-38.276c0-32.061 26.237-58.298 58.298-58.298h34.547c32.06 0 58.298 26.237 58.298 58.298v188.536c0 32.06 26.237 58.298 58.298 58.298H.151"
        stroke="#fff"
        strokeWidth={0.31}
        strokeMiterlimit={10}
      />
      <Path d="M.166 27.234h-650.372v487.779H.166V27.234z" fill="#6A6D66" />
      <Path
        d="M-127.468 212.256c-9.782-26.531-30.883-47.632-57.415-57.414V27.221H.152v185.035h-127.62z"
        fill="#32352F"
      />
      <Path
        d="M316.765 721.136h-669.249c-32.061 0-58.298 26.237-58.298 58.298v34.58c0 32.06 26.237 58.298 58.298 58.298h38.276c32.061 0 58.298-26.238 58.298-58.298V440.966c0-32.061 26.238-58.298 58.298-58.298H38.557c32.06 0 58.298 26.237 58.298 58.298V649.49"
        stroke="#fff"
        strokeWidth={0.31}
        strokeMiterlimit={10}
      />
      <Path
        d="M.15-99.616c-70.05 0-126.836 56.786-126.836 126.836S-69.9 154.056.15 154.056 126.985 97.269 126.985 27.22C126.985-42.83 70.199-99.616.15-99.616z"
        fill="#fff"
      />
      <Path d="M.166 429.911h-185.592v85.091H.166v-85.091z" fill="#9A9B98" />
      <Path d="M.15 515h650.373V27.22H.15V515z" fill="#6A6D66" />
      <Path
        d="M650.523-157.815c-102.192 0-185.035 82.843-185.035 185.036 0 102.192 82.843 185.035 185.035 185.035S835.559 129.413 835.559 27.22c0-102.192-82.844-185.035-185.036-185.035z"
        fill="#9A9B98"
      />
      <Path
        d="M650.523 329.965c-102.192 0-185.035 82.843-185.035 185.035s82.843 185.036 185.035 185.036S835.559 617.192 835.559 515c0-102.192-82.844-185.035-185.036-185.035z"
        fill="#32352F"
      />
      <Path
        opacity={0.5}
        d="M.15 298.557c-119.538 0-216.442 96.905-216.442 216.442 0 119.538 96.904 216.442 216.442 216.442 119.537 0 216.442-96.904 216.442-216.442 0-119.537-96.905-216.442-216.442-216.442z"
        fill="#32352F"
      />
      <Path
        d="M316.766 721.136h-669.249c-32.06 0-58.298 26.237-58.298 58.298v34.58c0 32.06 26.238 58.298 58.298 58.298h38.277c32.06 0 58.298-26.238 58.298-58.298V440.966c0-32.061 26.237-58.298 58.298-58.298H38.558c32.06 0 58.298 26.237 58.298 58.298V649.49"
        stroke="#fff"
        strokeWidth={0.31}
        strokeMiterlimit={10}
      />
      <Path
        d="M.152-99.616c-70.05 0-126.836 56.786-126.836 126.836S-69.897 154.056.152 154.056c70.05 0 126.836-56.787 126.836-126.836 0-70.05-56.787-126.836-126.836-126.836z"
        fill="#9A9B98"
      />
      <Path d="M.167-460.535h-650.372V27.244H.167v-487.779z" fill="#6A6D66" />
      <Path d="M.168-311.899h-185.592V27.257H.168V-311.9z" fill="#9A9B98" />
      <Path
        d="M.15-99.613c-70.05 0-126.835 56.786-126.835 126.835 0 70.05 56.786 126.836 126.835 126.836 70.05 0 126.836-56.786 126.836-126.836S70.2-99.613.15-99.613z"
        fill="#fff"
      />
      <Path d="M.15 27.222h650.373v-487.779H.15V27.222z" fill="#6A6D66" />
      <Path
        opacity={0.5}
        d="M650.522-157.814c-102.193 0-185.036 82.843-185.036 185.035 0 102.193 82.843 185.036 185.036 185.036 102.192 0 185.035-82.843 185.035-185.036 0-102.192-82.843-185.035-185.035-185.035z"
        fill="#32352F"
      />
      <Path
        d="M.152-99.614c-70.05 0-126.836 56.786-126.836 126.835 0 70.05 56.787 126.836 126.836 126.836 70.05 0 126.836-56.786 126.836-126.836S70.201-99.614.152-99.614z"
        fill="#9A9B98"
      />
      <Path
        d="M-650.222 27.221h859.061c32.06 0 58.297 26.238 58.297 58.298V205.91c0 32.06 26.238 58.298 58.298 58.298h207.707c32.061 0 58.298 26.237 58.298 58.298v46.455c0 32.06-26.237 58.298-58.298 58.298h-85.876c-32.061 0-58.298-26.238-58.298-58.298v-529.425"
        stroke="#fff"
        strokeWidth={0.31}
        strokeMiterlimit={10}
      />
    </G>
  </Svg>
)

export default WelcomeBg3SVG
