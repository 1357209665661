import MultiSlider, { MultiSliderProps } from "@ptomasroos/react-native-multi-slider"
import { Subtitle1, useTabLand, useTabTwoPane, useTheme } from "@siruplab/capsule"
import React, { Dispatch, SetStateAction, useCallback } from "react"
import { StyleProp, StyleSheet, useWindowDimensions, View, ViewStyle } from "react-native"

import { maxWidth, tabSizeLand } from "../../ThemeApp"
import { useStyles } from "../../utils/useStyles"
import CustomMarker from "./CustomMarker"

interface Props {
  min: number
  max: number
  valuesArray?: number[]
  title: string
  displayText: [string, string]
  setValuesArray: Dispatch<SetStateAction<number[] | undefined>>
  style?: StyleProp<ViewStyle>
}

const RangeSlider = ({
  min,
  max,
  valuesArray,
  displayText,
  setValuesArray,
  title,
  style: propStyle,
  ...rest
}: Props & MultiSliderProps) => {
  const {
    dimensions: { spacing },
  } = useTheme()

  const isTabLand = useTabLand()
  const isTabTwoPane = useTabTwoPane()

  const { width } = useWindowDimensions()

  const disabled = rest.enabledOne && rest.enabledTwo

  const handleValuesChange = useCallback(
    (values: number[]) => {
      setValuesArray(min === values[0] && max === values[1] ? undefined : values)
    },
    [max, min, setValuesArray],
  )

  const s = useStyles(
    ({ colors: { primary, surface, black } }) => ({
      trackStyle: { backgroundColor: disabled ? black.separator : surface.progress.background },
      selectedStyle: {
        ...{
          backgroundColor: disabled ? black.separator : primary,
        },
        ...styles.selectedStyle,
      },
      label: {
        color: disabled ? black.disabled : undefined,
      },
      labelsRow: {
        width: "100%",
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: spacing,
        marginBottom: spacing / 2,
      },
    }),
    [disabled],
  )

  return (
    <View style={propStyle}>
      <Subtitle1 color={s.label.color}>{title}</Subtitle1>
      <View style={s.labelsRow}>
        <Subtitle1 color={s.label.color}>{displayText[0]}</Subtitle1>
        <Subtitle1 color={s.label.color}>{displayText[1]}</Subtitle1>
      </View>
      <View>
        <MultiSlider
          values={valuesArray}
          sliderLength={
            isTabTwoPane
              ? maxWidth - spacing * 4
              : width - spacing * 3.5 - (isTabLand ? tabSizeLand : 0)
          }
          onValuesChange={handleValuesChange}
          min={min}
          max={max}
          step={100}
          allowOverlap={false}
          customMarker={() => <CustomMarker {...{ disabled }} />}
          enableLabel={false}
          containerStyle={styles.containerStyle}
          trackStyle={s.trackStyle}
          selectedStyle={s.selectedStyle}
          unselectedStyle={styles.trackStyle}
          {...rest}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  containerStyle: { justifyContent: "center", alignItems: "center" },
  labelsRow: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  trackStyle: { height: 2 },
  selectedStyle: { height: 2 },
})

export default RangeSlider
