import React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

const RollMeterSVG = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <Path
      d="M19 9h-8V8c0-1.1-1.567-2-3.5-2S4 6.9 4 8v7c0 1.71 1.935 3 4.5 3H19a1 1 0 0 0 1-1v-7a1 1 0 0 0-1-1zm-1 7h-2v-3h-2v3h-2v-3h-2v3H8.5C6.876 16 6 15.29 6 15v-4.476A6.374 6.374 0 0 0 8.5 11H18v5z"
      fill="#32352F"
      fillOpacity={0.72}
    />
  </Svg>
)

export default RollMeterSVG
