import { FirebaseFirestoreTypes } from "@react-native-firebase/firestore"
import { logger } from "@siruplab/capsule"

import { UserData } from "./UserData"

export const updateUserData = async (
  userDocRef: FirebaseFirestoreTypes.DocumentReference<FirebaseFirestoreTypes.DocumentData> | null,
  values: Partial<UserData>,
) => {
  userDocRef?.set(values, { merge: true }).catch(e => logger("update user error", e))
}
