import _ from "lodash"

export const formatMonetaryValue = (
  locale: string,
  value: number,
  unit: "GBP" | "EUR" | "USD" | string,
  noDigits?: boolean,
  symbolOnly?: boolean,
): string => {
  if (Intl.NumberFormat.supportedLocalesOf([locale]).includes(locale)) {
    locale = _.split(locale, "-")[0]
  }

  const formattedNbr = new Intl.NumberFormat([locale, "en-UK"], {
    style: "currency",
    currency: unit,
    ...(noDigits && { maximumFractionDigits: 0, minimumFractionDigits: 0 }),
  })

  return symbolOnly
    ? formattedNbr.formatToParts(0).find(elem => elem.type === "currency")?.value ?? ""
    : formattedNbr.format(value)
}
