import { mainRoutes } from "../../MainScreen/Constants"

export const ns = mainRoutes.PORTFOLIOS_TAB

export default {
  [`${mainRoutes.PORTFOLIOS_TAB}`]: {
    create: {
      title: "Create portfolio",
      button: "Create",
      placeholder: "Name",
    },
    emptyPortfolioListTitle: "No Portfolios",
    emptyPortfolioListSubtitle: "Create portfolios to organize and share your assets",
  },
}
