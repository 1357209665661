import { rootRoutes } from "../../../features/Navigation/Constants"

export const ns = rootRoutes.ASSET_SELECTION

export default {
  common: {
    [ns]: {
      title: "Asset selection",
    },
  },
  [ns]: {
    modalTitle: "Cannot add assets to shared portfolio",
    modalDescription:
      "You need to be editor of all assets before you can add them to this shared portfolio.",
    selectAssets: "Select assets",
    placeholder: "Filter assets",
  },
}
