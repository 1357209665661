import { useFocusEffect } from "@react-navigation/native"
import {
  ActionBar,
  generateShadow,
  IUserContext,
  ThemeProvider,
  Touchable,
  userContext,
  useTabTwoPane,
  useTheme,
  useTwoPaneNavigation,
} from "@siruplab/capsule"
import _ from "lodash"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Platform, StyleSheet, View } from "react-native"
import { ActivityIndicator, Chip, FAB } from "react-native-paper"
import { SafeAreaView } from "react-native-safe-area-context"

import AssetList from "../../components/AssetList"
import Searchbar from "../../components/Searchbar"
import { AssetsSVG, ClearIcon, MapSVG, ShareSVG } from "../../components/svg"
import { UserData } from "../../features/models/UserData"
import { rootRoutes } from "../../features/Navigation/Constants"
import { useAssetFilter } from "../../features/Providers/AssetFilterProvider"
import { useAssets } from "../../features/Providers/AssetProvider"
import { useAssetSelection } from "../../utils/useAssetSelection"
import { assetsRoutes } from "../MainScreen/Constants"
import { useMixedNav } from "../MainScreen/MainNavigator"
import { useCommonHeader } from "../MainScreen/MainTabs"
import { ns } from "./i18n/en"

const AssetListScreen = () => {
  const {
    colors: {
      primary,
      secondary,
      surface: { appUi },
      white: { highEmphasis: white },
      black: { highEmphasis: black },
    },
    dimensions: { spacing },
    typography: { body2 },
  } = useTheme()

  const { t } = useTranslation(ns)
  const commonHeader = useCommonHeader(AssetsSVG, "tabs.assets", true)

  const navigation = useTwoPaneNavigation()

  const mixedNav = useMixedNav()

  const isTabTwoPane = useTabTwoPane()

  const { userData } = useContext<IUserContext<UserData>>(userContext)

  const { filteredAssets } = useAssetFilter()

  /** States */
  const [loading, setLoading] = useState(true)
  const [selectedAssetsIds, setSelectedAssetsIds] = useState<string[]>([])
  const { onSharePress } = useAssetSelection(selectedAssetsIds)
  const { assetToEdit } = useAssets()

  /** Callbacks/functions */
  const navToAssetsMap = () => {
    navigation.navigate(assetsRoutes.MAIN)
  }

  const clearSelection = useCallback(() => {
    setSelectedAssetsIds([])
  }, [])

  /** Style */
  const s = useMemo(
    () => ({
      indicator: { paddingTop: spacing },
      firstLine: [styles.firstLine, { backgroundColor: white }],
      viewStyle: [styles.view, { backgroundColor: appUi }],
      fab: [
        styles.fab,
        {
          bottom: spacing,
          right: spacing,
        },
      ],
      fabMap: [
        styles.fabMap,
        {
          bottom: spacing,
          backgroundColor: secondary,
          paddingLeft: spacing / 2,
        },
      ],
      fabMapLabel: {
        ...(Platform.OS === "android" ? body2 : {}),
        color: white,
        alignSelf: "flex-end",
      },
      buttonIcon: { marginLeft: -spacing / 4 },
    }),
    [appUi, body2, secondary, spacing, white],
  )

  /** useEffects */
  useEffect(() => {
    navigation.setOptions({
      ...commonHeader,
      headerRight: undefined,
      ...(selectedAssetsIds.length > 0
        ? {
            title: t("assetSelected", { count: selectedAssetsIds.length }),
            headerLeft: () => (
              <Touchable onPress={clearSelection}>
                <ClearIcon />
              </Touchable>
            ),
            headerRight: () => (
              <ActionBar
                icons={[
                  {
                    id: 0,
                    iconComp: <ShareSVG />,
                    onPress: onSharePress,
                  },
                ]}
              />
            ),
          }
        : {}),
    })
  }, [black, clearSelection, commonHeader, navigation, onSharePress, s, selectedAssetsIds, t])

  // clear on blur
  useFocusEffect(
    useCallback(() => {
      setLoading(false)

      return () => {
        clearSelection()
      }
    }, [clearSelection]),
  )

  const navToCreateAsset = () => {
    assetToEdit[1](undefined)
    mixedNav.navigate(rootRoutes.CREATE_ASSET)
  }

  return loading ? (
    <ActivityIndicator style={s.indicator} size="large" color={primary} />
  ) : (
    <ThemeProvider
      customTheme={{
        colors: {
          overrides: { button: secondary },
        },
      }}
    >
      {selectedAssetsIds.length === 0 ? (
        <View style={s.firstLine}>
          <Searchbar />
        </View>
      ) : null}
      <SafeAreaView edges={["left", "right"]} style={s.viewStyle}>
        <AssetList
          data={filteredAssets}
          isEmpty={_.isEmpty(filteredAssets)}
          isPortfolioView={false}
          uniqueSelection
          limitSelection
          {...{ selectedAssetsIds, setSelectedAssetsIds }}
        />
        {userData?.guest ? null : (
          <FAB style={s.fab} small icon="plus" onPress={navToCreateAsset} />
        )}
        {isTabTwoPane ? null : (
          <Chip
            avatar={<MapSVG style={s.buttonIcon} />}
            onPress={navToAssetsMap}
            style={s.fabMap}
            // @ts-expect-error
            textStyle={s.fabMapLabel}
          >
            {t("viewMap")}
          </Chip>
        )}
      </SafeAreaView>
    </ThemeProvider>
  )
}

const styles = StyleSheet.create({
  view: {
    flex: 1,
    paddingBottom: 0,
  },
  searchBarInputStyle: {
    paddingTop: 0,
    paddingBottom: 0,
    includeFontPadding: false,
    textAlignVertical: "center",
  },
  firstLine: {
    width: "100%",
    flexDirection: "row",
  },
  share: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  fab: {
    position: "absolute",
    height: 48,
    width: 48,
    justifyContent: "center",
    alignItems: "center",
  },
  fabMap: {
    position: "absolute",
    ...generateShadow(2),
    alignSelf: "center",
    height: 32,
  },
})

export default AssetListScreen
