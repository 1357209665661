import { PathConfig } from "@react-navigation/core/src/types"
import { createStackNavigator, StackNavigationOptions } from "@react-navigation/stack"
import { StackHeaderOptions } from "@react-navigation/stack/lib/typescript/src/types"
import { useTabTwoPane, useTheme } from "@siruplab/capsule"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Platform, StyleSheet } from "react-native"

import { AssetsSVG, PortfolioSVG, UserSVG } from "../../components/svg"
import { ICON_SIZE_LARGE } from "../../ThemeApp"
import AssetListScreen from "../AssetListScreen/AssetListScreen"
import AssetMapScreen from "../AssetMapScreen"
import AssetsFilterScreen from "../AssetsFilterScreen/AssetsFilterScreen"
import AssetViewScreen from "../AssetViewScreen/AssetViewScreen"
import CompanyScreen from "../CompanyScreen/CompanyScreen"
import NameScreen from "../NameScreen/NameScreen"
import PasswordCreateScreen from "../PasswordCreateScreen/PasswordCreateScreen"
import PortfolioListScreen from "../PortfolioListScreen/PortfolioListScreen"
import PortfolioViewScreen from "../PortfolioViewScreen/PortfolioViewScreen"
import ProfileScreen from "../ProfileScreen/ProfileScreen"
import {
  AccountRouteNames,
  accountRoutes,
  AssetsRouteNames,
  assetsRoutes,
  PortfoliosRouteNames,
  portfoliosRoutes,
} from "./Constants"
import { ns } from "./i18n/en"

// eslint-disable-next-line @typescript-eslint/ban-types
type commonParamList<T extends keyof any> = Record<T, object | undefined>

export type AssetsParamList = commonParamList<AssetsRouteNames>
export type PortfoliosParamList = commonParamList<PortfoliosRouteNames> & {
  PortfoliosAssets: { id: string; isPublic?: boolean }
}
export type AccountParamList = commonParamList<AccountRouteNames> & {
  Set_Password: { email: string }
}

export const assetsScreens: Record<AssetsRouteNames, string | PathConfig> = {
  Assets_Main: "main",
  Assets_List: "list",
  AssetsFilter: "filter",
  AssetView: {
    path: "assetView/:id",
  },
}

export const portfoliosScreens: Record<PortfoliosRouteNames, string | PathConfig> = {
  PortfoliosList: "list",
  PortfoliosView: {
    path: "portfolioView/:id",
  },
  AssetView: {
    path: "assetView/:id",
  },
}

export const accountScreens: Record<AccountRouteNames, string | PathConfig> = {
  Account_Main: "main",
  Names_Edit: "namesEdit",
  Company_Edit: "companyEdit",
  Set_Password: "setPassword",
  Teammates_Edit: "teammatesEdit",
  Domaine: "domaine",
}

export type AssetStackParamList = AssetsParamList & { Assets_Main: { fromAssetView?: boolean } }

export const assetsStack = createStackNavigator<AssetStackParamList>()
export const portfoliosStack = createStackNavigator<PortfoliosParamList>()
export const accountStack = createStackNavigator<AccountParamList>()

const styles = StyleSheet.create({
  headerImage: { height: ICON_SIZE_LARGE, width: ICON_SIZE_LARGE },
  header: {
    elevation: 0,
    shadowOpacity: 0,
    borderBottomWidth: 0,
  },
})

const noHeaderLeftOptions = {
  headerLeft: undefined,
  headerLeftContainerStyle: undefined,
}

export const useCommonHeader = (
  SvgC: (props) => JSX.Element,
  name: string,
  isWhiteHeader = false,
) => {
  const {
    colors: {
      primary,
      surface: { appUi },
      black: { highEmphasis: black },
      white: { highEmphasis: white },
    },
    dimensions: { spacing },
    typography: { h6 },
  } = useTheme()

  const { t } = useTranslation([ns])

  const s = useMemo(
    () => ({
      headerStyle: {
        backgroundColor: isWhiteHeader ? white : appUi,
      },
      headerTitle: {
        marginTop: Platform.OS === "android" ? spacing / 2.5 : 0,
      },
      headerImage: [styles.headerImage, { tintColor: primary }],
      headerLeftContainer: {
        marginLeft: spacing,
        marginRight: spacing / 2,
      },
      headerBackTitleVisible: false,
    }),
    [appUi, isWhiteHeader, primary, spacing, white],
  )

  return {
    headerLeft: () => SvgC({ height: ICON_SIZE_LARGE, width: ICON_SIZE_LARGE, color: primary }),
    headerLeftContainerStyle: s.headerLeftContainer,
    headerTitleStyle: [h6, s.headerTitle],
    title: t(name),
    headerBackTitleVisible: false,
    headerTintColor: black,
    headerStyle: [s.headerStyle, styles.header],
  } as StackHeaderOptions
}

export const MainAssetsTab = () => {
  const commonHeader = useCommonHeader(AssetsSVG, "tabs.assets", true)
  const isTabTwoPane = useTabTwoPane()
  const { t } = useTranslation()
  const {
    colors: { white, black, surface },
    typography: { h6 },
  } = useTheme()

  const rootOptions = useMemo<StackNavigationOptions>(
    () => ({
      title: "",
      headerTitleStyle: h6,
      headerBackTitleVisible: false,
      headerTintColor: black.mediumEmphasis,
      headerStyle: [styles.header, { backgroundColor: surface.appUi }],
    }),
    [black, h6, surface],
  )

  return (
    <assetsStack.Navigator screenOptions={commonHeader}>
      <assetsStack.Screen
        name={assetsRoutes.MAIN}
        component={isTabTwoPane ? AssetListScreen : AssetMapScreen}
      />
      {!isTabTwoPane ? (
        <>
          <assetsStack.Screen name={assetsRoutes.LIST} component={AssetListScreen} />
          <assetsStack.Screen
            name={assetsRoutes.ASSET_VIEW}
            component={AssetViewScreen}
            options={{
              ...rootOptions,
              title: "",
              headerStyle: [
                rootOptions.headerStyle,
                { borderBottomWidth: 1, backgroundColor: white.highEmphasis },
              ],
              ...noHeaderLeftOptions,
            }}
          />
        </>
      ) : null}
      {isTabTwoPane ? (
        <assetsStack.Screen
          name={assetsRoutes.ASSETS_FILTER}
          component={AssetsFilterScreen}
          options={{
            title: t(`${assetsRoutes.ASSETS_FILTER}.title`),
            ...noHeaderLeftOptions,
          }}
        />
      ) : null}
    </assetsStack.Navigator>
  )
}

export const MainPortfoliosTab = () => {
  const commonHeader = useCommonHeader(PortfolioSVG, "tabs.portfolios")
  const {
    colors: {
      white,
      black,
      surface: { textInput, appUi },
    },
    typography: { h6 },
  } = useTheme()
  const isTabTwoPane = useTabTwoPane()

  const rootOptions = useMemo<StackNavigationOptions>(
    () => ({
      title: "",
      gestureEnabled: false,
      headerTitleStyle: h6,
      headerBackTitleVisible: false,
      headerTintColor: black.mediumEmphasis,
      headerStyle: [styles.header, { backgroundColor: appUi }],
    }),
    [appUi, black.mediumEmphasis, h6],
  )

  return (
    <portfoliosStack.Navigator
      screenOptions={{
        ...commonHeader,
        headerStyle: {
          elevation: 0,
          shadowOpacity: 0,
          borderBottomWidth: 1,
          borderBottomColor: textInput,
        },
      }}
    >
      <portfoliosStack.Screen name={portfoliosRoutes.LIST} component={PortfolioListScreen} />
      <portfoliosStack.Screen
        name={portfoliosRoutes.ASSETS}
        component={PortfolioViewScreen}
        options={{
          ...commonHeader,
          title: "",
          headerStyle: styles.header,
          ...noHeaderLeftOptions,
        }}
      />
      {!isTabTwoPane ? (
        <assetsStack.Screen
          name={portfoliosRoutes.ASSET_VIEW}
          component={AssetViewScreen}
          options={{
            ...rootOptions,
            title: "",
            headerStyle: [
              rootOptions.headerStyle,
              { borderBottomWidth: 1, backgroundColor: white.highEmphasis },
            ],
            ...noHeaderLeftOptions,
          }}
        />
      ) : null}
    </portfoliosStack.Navigator>
  )
}

export const MainAccountTab = () => {
  const { t } = useTranslation()

  const commonHeader = useCommonHeader(UserSVG, "tabs.account", true)
  const isTabTwoPane = useTabTwoPane()

  return (
    <accountStack.Navigator screenOptions={commonHeader}>
      <accountStack.Screen name={accountRoutes.MAIN} component={ProfileScreen} />
      {!isTabTwoPane ? (
        <>
          <accountStack.Screen
            name={accountRoutes.SET_PASSWORD}
            component={PasswordCreateScreen}
            options={{
              title: t(`${accountRoutes.NAMES_EDIT}.title`),
              ...noHeaderLeftOptions,
            }}
          />
          <accountStack.Screen
            name={accountRoutes.NAMES_EDIT}
            options={{
              title: t(`${accountRoutes.NAMES_EDIT}.title`),
              ...noHeaderLeftOptions,
            }}
          >
            {() => <NameScreen isEdit />}
          </accountStack.Screen>
          <accountStack.Screen
            name={accountRoutes.COMPANY_EDIT}
            options={{
              title: t(`${accountRoutes.COMPANY_EDIT}.title`),
              ...noHeaderLeftOptions,
            }}
          >
            {() => <CompanyScreen isCompanyEdit />}
          </accountStack.Screen>
          <accountStack.Screen
            name={accountRoutes.TEAMMATES_EDIT}
            options={{
              title: t(`${accountRoutes.TEAMMATES_EDIT}.title`),
              ...noHeaderLeftOptions,
            }}
          >
            {() => <CompanyScreen isTeammatesEdit />}
          </accountStack.Screen>
        </>
      ) : null}
    </accountStack.Navigator>
  )
}
