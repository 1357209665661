import { logger } from "@siruplab/capsule"
import firebase from "firebase/app"

import googleServices from "./google-services.json"

logger("after import")

const app = firebase.initializeApp(googleServices)

logger("after init", app)

const firebaseInit = "Init (web)"

export default firebaseInit
