import { onboardingRoutes } from "../../../features/Navigation/Constants"

export const ns = onboardingRoutes.SIGNIN_VALIDATION

export default {
  common: {
    [ns]: {
      title: "Validation",
    },
  },
  [ns]: {
    title: "Check your email to complete sign-in",
    passTitle: "Trouble receiving email?",
    passSubtitle: "You can log in with a password.",
    createPass: "Create a password",
    alreadyHave: "I already have a password",
  },
}
